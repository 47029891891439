import { ConsultFieldTypeEnum } from 'utils/enums';
import { YES, NO } from 'utils/constants';

import sharedFields from 'features/consults/shared/tabs/sharedFields';

const limbOptions = [
  {
    value: 'NoDrift',
    severity: 0,
    label: '0 = No drift',
    summaryLabel: '[0] = No drift',
  },
  {
    value: 'Drift',
    severity: 1,
    label: '1 = Drift',
    summaryLabel: '[1] = Drift',
  },
  {
    value: 'SomeEffortGravity',
    severity: 2,
    label: '2 = Some effort against gravity',
    summaryLabel: '[2] = Some effort against gravity',
  },
  {
    value: 'NoEffortGravity',
    severity: 3,
    label: '3 = No effort against gravity',
    summaryLabel: '[3] = No effort against gravity',
  },
  {
    value: 'NoMovement',
    severity: 4,
    label: '4 = No movement',
    summaryLabel: '[4] = No movement',
  },
  {
    value: 'AmputationOrJointFusion',
    severity: 0,
    label: 'UN = Amputation or joint fusion',
    summaryLabel: '[0] UN = Amputation or joint fusion',
  },
];

const yesNoOptions = [
  { value: YES, content: YES },
  { value: NO, content: NO },
];

const buildABCD2Item = ({ name, title, options }) => ({
  name,
  title,
  inlineLabel: true,
  type: ConsultFieldTypeEnum.TOGGLE,
  associatedField: 'abcD2Title',
  options: options.map((opt) => ({
    ...opt,
    summaryLabel: `[${opt.severity}] = ${title}: ${opt.label}`,
  })),
});

const scoresFields = {
  nihssExamTimeAt: {
    name: 'nihssExamTimeAt',
    label: 'Time of exam and NIHSS',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  levelOfConsciousness: {
    name: 'levelOfConsciousness',
    title: 'Level of Consciousness',
    summaryLabel: 'Level of Consciousness 1a',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'Alert',
        severity: 0,
        label: '0 = Alert; keenly responsive',
        summaryLabel: '[0] = Alert; keenly responsive',
      },
      {
        value: 'NotAlertButArousable',
        severity: 1,
        label: '1 = Not alert; but arousable by minor stim',
        summaryLabel: '[1] = Not alert; but arousable by minor stim',
      },
      {
        value: 'NotAlertRequiresStim',
        severity: 2,
        label: '2 = Not alert; requires strong or painful stim',
        summaryLabel: '[2] = Not alert; requires strong or painful stim',
      },
      {
        value: 'ReflexMotorOrUnresponsive',
        severity: 3,
        label: '3 = Responds only with reflex motor or unresponsive',
        summaryLabel: '[3] = Responds only with reflex motor or unresponsive',
      },
    ],
  },
  locQuestions: {
    name: 'locQuestions',
    title: 'LOC Questions',
    summaryLabel: 'LOC Questions 1b',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'BothQuestionsCorrectly',
        severity: 0,
        label: '0 = Answers both questions correctly',
        summaryLabel: '[0] = Answers both questions correctly',
      },
      {
        value: 'OneQuestionCorrectly',
        severity: 1,
        label: '1 = Answers one correctly',
        summaryLabel: '[1] = Answers one correctly',
      },
      {
        value: 'NeitherCorrectly',
        severity: 2,
        label: '2 = Answers neither correctly',
        summaryLabel: '[2] = Answers neither correctly',
      },
    ],
  },
  locCommands: {
    name: 'locCommands',
    title: 'LOC Commands',
    summaryLabel: 'LOC Commands 1c',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'BothTasksCorrectly',
        severity: 0,
        label: '0 = Performs both tasks correctly',
        summaryLabel: '[0] = Performs both tasks correctly',
      },
      {
        value: 'OneTaskCorrectly',
        severity: 1,
        label: '1 = Performs one correctly',
        summaryLabel: '[1] = Performs one correctly',
      },
      {
        value: 'NeitherCorrectly',
        severity: 2,
        label: '2 = Performs neither correctly',
        summaryLabel: '[2] = Performs neither correctly',
      },
    ],
  },
  bestGaze: {
    name: 'bestGaze',
    title: 'Best Gaze',
    summaryLabel: 'Best Gaze 2',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'Normal',
        severity: 0,
        label: '0 = Normal',
        summaryLabel: '[0] = Normal',
      },
      {
        value: 'PartialGazePalsy',
        severity: 1,
        label: '1 = Partial gaze palsy',
        summaryLabel: '[1] = Partial gaze palsy',
      },
      {
        value: 'ForcedDeviation',
        severity: 2,
        label: '2 = Forced deviation',
        summaryLabel: '[2] = Forced deviation',
      },
    ],
  },
  visual: {
    name: 'visual',
    title: 'Visual',
    summaryLabel: 'Visual 3',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'NoVisualLoss',
        severity: 0,
        label: '0 = No visual loss',
        summaryLabel: '[0] = No visual loss',
      },
      {
        value: 'PartialHemianopia',
        severity: 1,
        label: '1 = Partial hemianopia',
        summaryLabel: '[1] = Partial hemianopia',
      },
      {
        value: 'CompleteHemianopia',
        severity: 2,
        label: '2 = Complete hemianopia',
        summaryLabel: '[2] = Complete hemianopia',
      },
      {
        value: 'BilateralHemianopia',
        severity: 3,
        label: '3 = Bilateral hemianopia',
        summaryLabel: '[3] = Bilateral hemianopia',
      },
    ],
  },
  facialPalsy: {
    name: 'facialPalsy',
    title: 'Facial Palsy',
    summaryLabel: 'Facial Palsy 4',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'NormalSymmetricalMovements',
        severity: 0,
        label: '0 = Normal symmetrical movements',
        summaryLabel: '[0] = Normal symmetrical movements',
      },
      {
        value: 'MinorParalysis',
        severity: 1,
        label: '1 = Minor paralysis',
        summaryLabel: '[1] = Minor paralysis',
      },
      {
        value: 'PartialParalysis',
        severity: 2,
        label: '2 = Partial paralysis',
        summaryLabel: '[2] = Partial paralysis',
      },
      {
        value: 'CompleteParalysis',
        severity: 3,
        label: '3 = Complete paralysis',
        summaryLabel: '[3] = Complete paralysis',
      },
    ],
  },
  motorArmLeft: {
    name: 'motorArmLeft',
    title: 'Motor Arm Left',
    summaryLabel: 'Motor Arm Left 5a',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: limbOptions,
  },
  motorArmRight: {
    name: 'motorArmRight',
    title: 'Motor Arm Right',
    summaryLabel: 'Motor Arm Right 5b',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: limbOptions,
  },
  motorLegLeft: {
    name: 'motorLegLeft',
    title: 'Motor Leg Left',
    summaryLabel: 'Motor Leg Left 6a',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: limbOptions,
  },
  motorLegRight: {
    name: 'motorLegRight',
    title: 'Motor Leg Right',
    summaryLabel: 'Motor Leg Right 6b',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: limbOptions,
  },
  limbAtaxia: {
    name: 'limbAtaxia',
    title: 'Limb Ataxia',
    summaryLabel: 'Limb Ataxia 7',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'Absent',
        severity: 0,
        label: '0 = Absent',
        summaryLabel: '[0] = Absent',
      },
      {
        value: 'PresentInOneLimb',
        severity: 1,
        label: '1 = Present in one limb',
        summaryLabel: '[1] = Present in one limb',
      },
      {
        value: 'PresentInTwoLimbs',
        severity: 2,
        label: '2 = Present in two limbs',
        summaryLabel: '[2] = Present in two limbs',
      },
      {
        value: 'AmputationOrJointFusion',
        severity: 0,
        label: 'UN = Amputation or joint fusion',
        summaryLabel: '[0] UN = Amputation or joint fusion',
      },
    ],
  },
  sensory: {
    name: 'sensory',
    title: 'Sensory',
    summaryLabel: 'Sensory 8',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'Normal',
        severity: 0,
        label: '0 = Normal',
        summaryLabel: '[0] = Normal',
      },
      {
        value: 'MildModerateSensoryLoss',
        severity: 1,
        label: '1 = Mild-to-moderate sensory loss',
        summaryLabel: '[1] = Mild-to-moderate sensory loss',
      },
      {
        value: 'TotalSensoryLoss',
        severity: 2,
        label: '2 = Severe to total sensory loss',
        summaryLabel: '[2] = Severe to total sensory loss',
      },
    ],
  },
  bestLanguage: {
    name: 'bestLanguage',
    title: 'Best Language',
    summaryLabel: 'Best Language 9',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'NoAphasia',
        severity: 0,
        label: '0 = No aphasia',
        summaryLabel: '[0] = No aphasia',
      },
      {
        value: 'MildModerateSensoryLoss',
        severity: 1,
        label: '1 = Mild-to-moderate aphasia',
        summaryLabel: '[1] = Mild-to-moderate aphasia',
      },
      {
        value: 'SevereAphasia',
        severity: 2,
        label: '2 = Severe aphasia',
        summaryLabel: '[2] = Severe aphasia',
      },
      {
        value: 'Mute',
        severity: 3,
        label: '3 = Mute',
        summaryLabel: '[3] = Mute',
      },
    ],
  },
  dysarthria: {
    name: 'dysarthria',
    title: 'Dysarthria',
    summaryLabel: 'Dysarthria 10',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'Normal',
        severity: 0,
        label: '0 = Normal',
        summaryLabel: '[0] = Normal',
      },
      {
        value: 'MildModerateDysarthria',
        severity: 1,
        label: '1 = Mild-to-moderate dysarthria',
        summaryLabel: '[1] = Mild-to-moderate dysarthria',
      },
      {
        value: 'SevereDysarthria',
        severity: 2,
        label: '2 = Severe dysarthria',
        summaryLabel: '[2] = Severe dysarthria',
      },
      {
        value: 'IntubatedOrOtherPhysicalBarrier',
        severity: 0,
        label: 'UN = Intubated or other physical barrier',
        summaryLabel: '[0] UN = Intubated or other physical barrier',
      },
    ],
  },
  extinctionAndInattention: {
    name: 'extinctionAndInattention',
    title: 'Extinction and Inattention',
    summaryLabel: 'Extinction and Inattention 11',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'NoAbnormality',
        severity: 0,
        label: '0 = No abnormality',
        summaryLabel: '[0] = No abnormality',
      },
      {
        value: 'Inattention',
        severity: 1,
        label:
          '1 = Visual, tactile, auditory, spatial, or personal inattention',
        summaryLabel:
          '[1] = Visual, tactile, auditory, spatial, or personal inattention',
      },
      {
        value: 'MoreThanOneModality',
        severity: 2,
        label:
          '2 = Profound hemi-inattention or extinction to more than one modality',
        summaryLabel:
          '[2] = Profound hemi-inattention or extinction to more than one modality',
      },
    ],
  },
  nihssTotal: {
    name: 'nihssTotal',
    label: 'NIHSS Total',
    type: ConsultFieldTypeEnum.INPUT,
  },
  modifiedRankinScale: sharedFields.modifiedRankinScale,
  mrsTotal: sharedFields.mrsTotal,
  vanArmWeakness: {
    name: 'vanArmWeakness',
    title: 'Arm weakness',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: yesNoOptions,
    excludeFromSummary: true,
  },
  vanVisualDisturbance: {
    name: 'vanVisualDisturbance',
    title: 'Visual disturbance',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: yesNoOptions,
    excludeFromSummary: true,
  },
  vanAphasia: {
    name: 'vanAphasia',
    title: 'Aphasia',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: yesNoOptions,
    excludeFromSummary: true,
  },
  vanNeglect: {
    name: 'vanNeglect',
    title: 'Neglect',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: yesNoOptions,
    excludeFromSummary: true,
  },
  vanTotal: {
    name: 'vanTotal',
    label: 'VAN Total',
    summaryLabel: 'VAN Screening',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 0, label: 'Negative' },
      { value: 1, label: 'Positive' },
    ],
  },
  ich: {
    name: 'ich',
    title: 'ICH Score',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'GCS3To4',
        severity: 2,
        label: 'GCS 3-4',
        summaryLabel: '[2] = GCS 3-4',
      },
      {
        value: 'GCS5To12',
        severity: 1,
        label: 'GCS 5-12',
        summaryLabel: '[1] = GCS 5-12',
      },
      {
        value: 'GCS13To15',
        severity: 0,
        label: 'GCS 13-15',
        summaryLabel: '[0] = GCS 13-15',
      },
    ],
  },
  ichAge: {
    name: 'ichAge',
    associatedField: 'ich',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'GreaterThanOrEqual80',
        severity: 1,
        label: 'age >= 80',
        summaryLabel: '[1] = age >= 80',
      },
      {
        value: 'LessThan80',
        severity: 0,
        label: 'age < 80',
        summaryLabel: '[0] = age < 80',
      },
    ],
  },
  ichVolume: {
    name: 'ichVolume',
    associatedField: 'ich',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'ICHGreaterThanOrEqual30',
        severity: 1,
        label: 'ICH vol >= 30 mL',
        summaryLabel: '[1] = ICH vol >= 30 mL',
      },
      {
        value: 'ICHLessThan30',
        severity: 0,
        label: 'ICH vol < 30 mL',
        summaryLabel: '[0] = ICH vol < 30 mL',
      },
    ],
  },
  ichIntraventricularHemorhage: {
    name: 'ichIntraventricularHemorhage',
    associatedField: 'ich',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'IntraventricularHemorrhage',
        severity: 1,
        label: 'intravent hemorrhage',
        summaryLabel: '[1] = intraventricular hemorrhage',
      },
      {
        value: 'NoIntraventHemorrhage',
        severity: 0,
        label: 'no intravent hemorrhage',
        summaryLabel: '[0] = no intravent hemorrhage',
      },
    ],
  },
  ichInfraventricOrigin: {
    name: 'ichInfraventricOrigin',
    associatedField: 'ich',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'InfratentorialOrigin',
        severity: 1,
        label: 'infratentorial origin of hemorrhage',
        summaryLabel: '[1] = infratentorial origin of hemorrhage',
      },
      {
        value: 'NotInfratentorialOrigin',
        severity: 0,
        label: 'origin of hemorrhage NOT infratentorial',
        summaryLabel: '[0] = origin of hemorrhage NOT infratentorial',
      },
    ],
  },
  ichTotal: {
    name: 'ichTotal',
    label: 'ICH Total',
    summaryLabel: 'ICH Score total',
    type: ConsultFieldTypeEnum.INPUT,
  },
  huntHessSAH: {
    name: 'huntHessSAH',
    title: 'Hunt Hess SAH',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'NoNuchalRigidity',
        severity: 1,
        label: '1 mild headache, A&O, min or no nuchal rigidity',
        summaryLabel: '[1] = mild headache, A&O, min or no nuchal rigidity',
      },
      {
        value: 'NoNeurologicDeficit',
        severity: 2,
        label:
          '2 nuchal rigidity, mod/sev HA, A&O, no neuro def (except CN palsy)',
        summaryLabel:
          '[2] = nuchal rigidity, mod/severe HA, A&O, no neurologic deficit (except CN palsy)',
      },
      {
        value: 'MildFocalNeurologicDeficit',
        severity: 3,
        label: '3 lethargy/confusion, mild focal neuro def',
        summaryLabel: '[3] = lethargy/confusion, mild focal neurologic deficit',
      },
      {
        value: 'SevereFocalDeficit',
        severity: 4,
        label: '4 stuporous, severe focal def',
        summaryLabel: '[4] = stuporous, severe focal deficit',
      },
      {
        value: 'SevereNeurologicImpairment',
        severity: 5,
        label: '5 comatose, showing signs of severe neuro impairment',
        summaryLabel:
          '[5] = comatose, showing signs of severe neurologic impairment',
      },
    ],
  },
  huntHessTotal: {
    name: 'huntHessTotal',
    label: 'Hunt/Hess Total',
    summaryLabel: 'Hunt Hess total',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  abcD2Title: {
    name: 'abcD2Title',
    type: ConsultFieldTypeEnum.TITLE,
    title: 'ABCD2 Score for TIA',
  },
  abcD2AgeAboveOrEqualTo60: buildABCD2Item({
    name: 'abcD2AgeAboveOrEqualTo60',
    title: 'Age >/= 60 years',
    options: [
      {
        value: 'Yes',
        label: 'Yes',
        severity: 1,
      },
      {
        value: 'No',
        label: 'No',
        severity: 0,
      },
    ],
  }),
  abcD2BpAboveOrEqualTo14090: buildABCD2Item({
    name: 'abcD2BpAboveOrEqualTo14090',
    title: 'BP >/= 140/90',
    options: [
      {
        value: 'Yes',
        label: 'Yes',
        severity: 1,
      },
      {
        value: 'No',
        label: 'No',
        severity: 0,
      },
    ],
  }),
  abcD2ClinicalFeatures: buildABCD2Item({
    name: 'abcD2ClinicalFeatures',
    title: 'Clinical features of the TIA',
    options: [
      {
        value: 'UnilateralWeakness',
        label: 'unilateral weakness',
        severity: 2,
      },
      {
        value: 'SpeechDisturbancesWithoutWeakness',
        label: 'speech disturbances without weakness',
        severity: 1,
      },
      {
        value: 'OtherSymptoms',
        label: 'other symptoms',
        severity: 0,
      },
    ],
  }),
  abcD2DurationOfSymptoms: buildABCD2Item({
    name: 'abcD2DurationOfSymptoms',
    title: 'Duration of symptoms',
    options: [
      {
        value: 'LessThan10Minutes',
        label: '< 10 minutes',
        severity: 0,
      },
      {
        value: 'From10To59Minutes',
        label: '10-59 minutes',
        severity: 1,
      },
      {
        value: 'GreaterOrEqualTo60Minutes',
        label: '>/= 60 minutes',
        severity: 2,
      },
    ],
  }),
  abcD2HistoryOfDiabetes: buildABCD2Item({
    name: 'abcD2HistoryOfDiabetes',
    title: 'History of diabetes',
    options: [
      {
        value: 'Yes',
        label: 'Yes',
        severity: 1,
      },
      {
        value: 'No',
        label: 'No',
        severity: 0,
      },
    ],
  }),
  abcD2Total: {
    name: 'abcD2Total',
    label: 'ABCD2 Total',
    type: ConsultFieldTypeEnum.INPUT,
  },
  eyeOpening: {
    name: 'eyeOpening',
    title: 'Eye Opening',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'None',
        severity: 1,
        label: 'none',
        summaryLabel: '[1] = None',
      },
      {
        value: 'Pain',
        severity: 2,
        label: 'pain',
        summaryLabel: '[2] = Pain',
      },
      {
        value: 'Voice',
        severity: 3,
        label: 'voice',
        summaryLabel: '[3] = Voice',
      },
      {
        value: 'Spontaneous',
        severity: 4,
        label: 'spontaneous',
        summaryLabel: '[4] = Spontaneous',
      },
    ],
  },
  verbalResponse: {
    name: 'verbalResponse',
    title: 'Verbal response',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'None',
        severity: 1,
        label: 'none',
        summaryLabel: '[1] = None',
      },
      {
        value: 'IncomprehensibleSounds',
        severity: 2,
        label: 'incomprehensible sounds',
        summaryLabel: '[2] = Incomprehensible Sounds',
      },
      {
        value: 'InappropriateWords',
        severity: 3,
        label: 'inappropriate words',
        summaryLabel: '[3] = Inappropriate Words',
      },
      {
        value: 'Confused',
        severity: 4,
        label: 'confused',
        summaryLabel: '[4] = Confused',
      },
      {
        value: 'Oriented',
        severity: 5,
        label: 'oriented',
        summaryLabel: '[5] = Oriented',
      },
    ],
  },
  motorResponse: {
    name: 'motorResponse',
    title: 'Motor response',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'None',
        severity: 1,
        label: 'none',
        summaryLabel: '[1] = None',
      },
      {
        value: 'AbnormalExtension',
        severity: 2,
        label: 'abnormal extension',
        summaryLabel: '[2] = Abnormal Extension',
      },
      {
        value: 'AbnormalFlexion',
        severity: 3,
        label: 'abnormal flexion',
        summaryLabel: '[3] = Abnormal Flexion',
      },
      {
        value: 'WithdrawsFromPain',
        severity: 4,
        label: 'withdraws from pain',
        summaryLabel: '[4] = Withdraws From Pain',
      },
      {
        value: 'LocalizesToPain',
        severity: 5,
        label: 'localizes to pain',
        summaryLabel: '[5] = Localizes To Pain',
      },
      {
        value: 'ObeysCommands',
        severity: 6,
        label: 'obeys commands',
        summaryLabel: '[6] = Obeys Commands',
      },
    ],
  },
  gcsTotal: {
    name: 'gcsTotal',
    label: 'Glasgow Coma Scale Total',
    type: ConsultFieldTypeEnum.INPUT,
  },
};

export default scoresFields;
