import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HealthSystemsListPage from './HealthSystems/HealthSystemsListPage';
import AddHealthSystemPage from './HealthSystems/AddHealthSystemPage';
import HealthSystemDetailsPage from './HealthSystems/HealthSystemDetailsPage';
import AddFacilityPage from './Facilities/AddFacilityPage';
import FacilityDetailsPage from './Facilities/FacilityDetailsPage';

const HealthSystems = () => (
  <Routes>
    <Route index element={<HealthSystemsListPage />} />
    <Route path=":locationId/add-facility" element={<AddFacilityPage />} />
    <Route
      path=":locationId/facility/:facilityId"
      element={<FacilityDetailsPage />}
    />
    <Route path="add" element={<AddHealthSystemPage />} />
    <Route path=":locationId" element={<HealthSystemDetailsPage />} />
  </Routes>
);

export default HealthSystems;
