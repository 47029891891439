import React from 'react';
import PropTypes from 'prop-types';

import DayTitle from 'features/schedule/components/DayTitle/DayTitle';
import ExpandableDayCard from 'features/schedule/components/ExpandableDayCard/ExpandableDayCard';
import { FlexBoxColumnAlign } from 'styles/layout';
import { CalendarContainer } from './CalendarList.style';

const CalendarList = ({ days }) => (
  <CalendarContainer py={5}>
    {days.map(({ title, today, shifts }) => (
      <FlexBoxColumnAlign key={title}>
        <DayTitle>{title}</DayTitle>
        <ExpandableDayCard isToday={today} items={shifts} />
      </FlexBoxColumnAlign>
    ))}
  </CalendarContainer>
);

CalendarList.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      today: PropTypes.bool.isRequired,
      shifts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ).isRequired,
};

export default CalendarList;
