import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NetworksListPage from './NetworksListPage';
import NetworkAddPage from './NetworkAddPage';
import NetworkDetailsPage from './NetworkDetailsPage';
import FacilityDetailsPage from '../Locations/Facilities/FacilityDetailsPage';

const Networks = () => (
  <Routes>
    <Route index element={<NetworksListPage />} />
    <Route path="add" element={<NetworkAddPage />} />
    <Route path=":networkId" element={<NetworkDetailsPage />} />
    <Route
      path=":networkId/facility/:facilityId"
      element={<FacilityDetailsPage />}
    />
  </Routes>
);

export default Networks;
