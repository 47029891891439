import React from 'react';
import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import schema from './validationSchema';
import { leftPanel, centerPanel, rightPanel } from './data';

const ROS = () => {
  const { control, register, getValues, getFieldError, setFormValues } =
    useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    isEditable,
    handleFieldChange,
    consultMainType,
  } = useConsultData(CONSULT_TABS.ROS, getValues);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  return (
    <GridWrapper>
      <Grid item xs={12} lg={4}>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={!isEditable}
          {...leftPanel(consultMainType)}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={!isEditable}
          {...centerPanel}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={!isEditable}
          {...rightPanel}
        />
      </Grid>
    </GridWrapper>
  );
};

export default ROS;
