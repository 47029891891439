import React from 'react';
import PropTypes from 'prop-types';

import useFacilityNames from 'shared/hooks/useFacilityNames';
import Autocomplete from 'shared/components/forms/Autocomplete';

const FacilitySelect = ({
  placeholder = '-- Facility --',
  healthSystem,
  requiredSystem,
  networkType,
  disabled,
  ...moreProps
}) => {
  const { facilities } = useFacilityNames(networkType);

  const facilityOptions = React.useMemo(() => {
    const options = facilities ?? [];
    if (requiredSystem && !healthSystem) return [];
    return healthSystem
      ? options.filter(
          ({ healthSystemId }) => healthSystem === healthSystemId,
        )
      : options;
  }, [requiredSystem, healthSystem, facilities]);

  return (
    <Autocomplete
      {...moreProps}
      placeholder={placeholder}
      disabled={disabled || (requiredSystem && !healthSystem)}
      options={facilityOptions}
    />
  );
};

FacilitySelect.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  healthSystem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requiredSystem: PropTypes.bool,
  networkType: PropTypes.string,
};

export default FacilitySelect;
