import { ConsultFieldTypeEnum } from 'utils/enums';

export default {
  modifiedRankinScale: {
    name: 'modifiedRankinScale',
    title: 'Modified Rankin Scale (mRS) pre-stroke',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'UnableToAssess',
        label: 'Unable to assess',
        summaryLabel: 'unable to assess',
      },
      {
        value: 'NoSymptoms',
        severity: 0,
        label: '0 No Symptoms',
        summaryLabel: '[0] = No Symptoms',
      },
      {
        value: 'CarryOutAllUsualDuties',
        severity: 1,
        label:
          '1 No signif. disability... carry out all usual duties/activities',
        summaryLabel:
          '[1] = No signif. disability... carry out all usual duties/activities',
      },
      {
        value: 'SlightDisability',
        severity: 2,
        label:
          '2 Slight disability...unable to carry out all prev activities...',
        summaryLabel:
          '[2] = Slight disability...unable to carry out all prev activities...',
      },
      {
        value: 'ModerateDisability',
        severity: 3,
        label: '3 Moderate disability... requires some help...able to walk...',
        summaryLabel:
          '[3] = Moderate disability... requires some help...able to walk...',
      },
      {
        value: 'ModeratelySevereDisability',
        severity: 4,
        label: '4 Moderately severe disability...unable to walk...',
        summaryLabel: '[4] = Moderately severe disability...unable to walk...',
      },
      {
        value: 'SevereDisability',
        severity: 5,
        label: '5 Severe disability... bedridden...',
        summaryLabel: '[5] = Severe disability... bedridden...',
      },
      {
        value: 'Dead',
        severity: 6,
        label: '6 Dead',
        summaryLabel: '[6] = Dead',
      },
    ],
  },
  mrsTotal: {
    name: 'mrsTotal',
    label: 'MRS Total',
    summaryLabel: 'Modified Rankin Scale total',
    type: ConsultFieldTypeEnum.INPUT,
    disabled: true,
    excludeFromSummary: true,
  },
};
