import styled from '@emotion/styled';

import colors from 'styles/colors';
import { FlexBoxColumnAlign, FullPageWrapperCenter } from 'styles/layout';

export const PageWrapper = styled(FullPageWrapperCenter)`
  background: ${colors.backgroundLight};
`;

export const ContentWrapper = styled(FlexBoxColumnAlign)`
  text-align: center;
`;

export const Logo = styled.img`
  max-width: 150px;
  margin-bottom: 40px;
`;

export const Paragraph = styled.p`
  color: ${colors.primary};
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0;
`;
