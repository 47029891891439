import { ServiceTypeEnum } from 'utils/enums';
import { mapChipFieldOptions } from 'features/consults/utils/formFields';
import fields, { teleNeuroRosFields } from './formFields';

export const leftPanel = (consultType) => ({
  elements: [
    mapChipFieldOptions(
      consultType === ServiceTypeEnum.TELE_NEURO
        ? teleNeuroRosFields.additionalPredefined
        : fields.additionalPredefined,
    ),
    fields.additionalFreeText,
    mapChipFieldOptions(fields.unableToObtainRosBecausePredefined),
    fields.unableToObtainRosBecauseFreeText,
    mapChipFieldOptions(fields.constitutionalPredefined),
    fields.constitutionalFreeText,
    mapChipFieldOptions(fields.eyesPredefined),
    fields.eyesFreeText,
    mapChipFieldOptions(fields.entPredefined),
    fields.entFreeText,
    mapChipFieldOptions(fields.respiratoryPredefined),
    fields.respiratoryFreeText,
  ],
});

export const centerPanel = {
  elements: [
    mapChipFieldOptions(fields.cardiovascularPredefined),
    fields.cardiovascularFreeText,
    mapChipFieldOptions(fields.abdominalPainPredefined),
    fields.abdominalPainFreeText,
    mapChipFieldOptions(fields.genitourinaryPredefined),
    fields.genitourinaryFreeText,
    mapChipFieldOptions(fields.musculoskeletalPredefined),
    fields.musculoskeletalFreeText,
    mapChipFieldOptions(fields.integumentaryPredefined),
    fields.integumentaryFreeText,
  ],
};

export const rightPanel = {
  elements: [
    mapChipFieldOptions(fields.neurologicSeeHPIPredefined),
    mapChipFieldOptions(fields.neurologicPredefined),
    fields.neurologicFreeText,
    mapChipFieldOptions(fields.psychiatricPredefined),
    fields.psychiatricFreeText,
    mapChipFieldOptions(fields.endocrinePredefined),
    fields.endocrineFreeText,
    mapChipFieldOptions(fields.hematologicPredefined),
    fields.hematologicFreeText,
    mapChipFieldOptions(fields.allergyPredefined),
    fields.allergyFreeText,
  ],
};
