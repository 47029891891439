import React from 'react';
import PropTypes from 'prop-types';

import { useFormState } from 'shared/hooks';
import { UserRoleEnum, userRolesLabels } from 'utils/enums';
import Select from 'shared/components/forms/Select/ControlledSelect';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import HealthSystemSelect from 'shared/components/data/forms/HealthSystemSelect';
import FacilitySelect from 'shared/components/data/forms/FacilitySelect';
import { FilterElement } from 'features/admin/pages/Consults/ConsultList/ConsultListFilters/ConsultFilters.style';
import { Box, FlexBoxWrap } from 'styles/layout';

import { filterSchema, formFields } from './utils';

const UserListFilter = ({ onFilter, defaultFilters }) => {
  const { control, register, setFormValues, watch } =
    useFormState(filterSchema);

  const currentRole = watch(formFields.role);
  const healthSystem = watch(formFields.healthSystem);

  React.useEffect(() => {
    setFormValues(defaultFilters);
  }, [setFormValues, defaultFilters]);

  const customerSelected = React.useMemo(
    () => currentRole === UserRoleEnum.CUSTOMER,
    [currentRole],
  );

  const handleRoleChange = React.useCallback(
    (role) => {
      onFilter({
        roles: role,
        [formFields.healthSystem]: '',
        [formFields.facility]: '',
      });
    },
    [onFilter],
  );

  return (
    <form>
      <Box>
        <FilterElement>
          <SearchField
            {...register(formFields.search)}
            onChange={(value) => onFilter({ query: value })}
          />
        </FilterElement>
      </Box>
      <FlexBoxWrap>
        <FilterElement>
          <Select
            control={control}
            name={formFields.role}
            placeholder="All roles"
            options={Object.entries(userRolesLabels).map(([value, text]) => ({
              value,
              text,
            }))}
            handleChange={({ target }) => handleRoleChange(target.value)}
          />
        </FilterElement>
        <FilterElement>
          <Select
            control={control}
            name={formFields.status}
            placeholder="Status"
            options={[
              { text: 'Active', value: 'true' },
              { text: 'Inactive', value: 'false' },
            ]}
            handleChange={({ target }) => {
              onFilter({ [target.name]: target.value });
            }}
          />
        </FilterElement>
        {customerSelected && (
          <>
            <FilterElement>
              <HealthSystemSelect
                control={control}
                name={formFields.healthSystem}
                placeholder="Health system"
                handleChange={(value) =>
                  onFilter({
                    [formFields.healthSystem]: value,
                    [formFields.facility]: null,
                  })
                }
              />
            </FilterElement>
            <FilterElement>
              <FacilitySelect
                control={control}
                name={formFields.facility}
                placeholder="Facility"
                healthSystem={healthSystem}
                requiredSystem
                handleChange={(value) =>
                  onFilter({ [formFields.facility]: value })
                }
              />
            </FilterElement>
          </>
        )}
        
      </FlexBoxWrap>
    </form>
  );
};

UserListFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  defaultFilters: PropTypes.shape({}).isRequired,
};

export default UserListFilter;
