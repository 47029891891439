import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';

export const SLICE_PREFIX = 'profile';

export const getProfilePhoto = createAsyncThunk(
  `${SLICE_PREFIX}/getProfilePhoto`,
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await api.getAsset(userId);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getProfile = createAsyncThunk(
  `${SLICE_PREFIX}/getProfile`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { getUserDto },
      } = await api.getProfile();
      dispatch(getProfilePhoto(getUserDto.id));
      return getUserDto;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProfile = createAsyncThunk(
  `${SLICE_PREFIX}/updateProfile`,
  async (profileData, { rejectWithValue }) => {
    try {
      const {
        data: { getUserDto },
      } = await api.updateProfile(profileData);
      return getUserDto;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setMfaList = createAsyncThunk(
  `${SLICE_PREFIX}/setMfaList`,
  (mfaList) => mfaList || [],
);
