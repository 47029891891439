import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useMountEffect, useFormState, usePrevious } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';

import { dateDiffFormatted, parseISO } from 'utils/dates';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { TIMEZONES } from 'utils/timezones';
import schema from './validationSchema';
import {
  eegReadPanel,
  technicalPanel,
  descriptionPanel,
  centerPanel,
  abnormalitiesPanel,
  impressionPanel,
  clinicalCorrelationPanel,
} from './data';
import formFields, {
  impressionTextValue,
  subImpressionDetails,
} from './formFields';

const forceDataFormat = (date) => {
  if (typeof date === 'string') return parseISO(date);
  return date;
};

const formatAddText = (newText, currentText) =>
  currentText ? `${currentText}\n\n${newText}` : newText;

const EEG = ({ errors }) => {
  const {
    control,
    register,
    getValues,
    getFieldError,
    setFormValues,
    setValue,
    watch,
    clearErrors,
    trigger,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    consultMainType,
    isEditable,
    isMacro,
    handleFieldChange,
    saveConsultChange,
    consultFacility,
  } = useConsultData(CONSULT_TABS.EEG, getValues);
  const [impressionAdded, setImpressionAdded] = React.useState([]);

  const impressions = watch(formFields.impressionsPredefined.name);
  const impressionSubtype = watch(formFields.impressionsSubtypePredefined.name);
  const prevImpressionSubtype = usePrevious(impressionSubtype);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  useMountEffect(() => {
    if (!consult?.id) return;
    setImpressionAdded(
      consultTabData[formFields.impressionsSubtypePredefined.name],
    );
  });

  React.useEffect(() => {
    clearErrors();
    if (errors) trigger(errors);
  }, [errors, clearErrors, trigger]);

  const facilityTimezone = React.useMemo(
    () => consultFacility?.timezone || TIMEZONES.UTC,
    [consultFacility?.timezone],
  );

  const handleImpressionChange = (fieldName) => {
    const value = getValues(fieldName);
    const text = impressionTextValue(value);

    setImpressionAdded([]);

    setValue(formFields.impressionsClinicalCorrelationFreeText.name, text);
    setValue(formFields.impressionsSubtypePredefined.name, []);
    setValue(formFields.abnormalityOptionsFreeText.name, '');

    saveConsultChange({
      [fieldName]: value,
      [formFields.impressionsClinicalCorrelationFreeText.name]: text,
      [formFields.abnormalityOptionsFreeText.name]: '',
      [formFields.impressionsSubtypePredefined.name]: [],
    });
  };

  const handleSubImpressionChange = (fieldName) => {
    const currentValues = getValues(fieldName);
    const prevValues = prevImpressionSubtype || consultTabData[fieldName];

    if (currentValues.length < prevValues.length) {
      saveConsultChange({ [fieldName]: currentValues });
      return;
    }

    const newImpression = currentValues.find(
      (val) => !prevValues.includes(val),
    );

    if (!newImpression || impressionAdded.includes(newImpression)) return;

    setImpressionAdded([...impressionAdded, newImpression]);

    const abnormalValueField = formFields.abnormalityOptionsFreeText.name;
    const abnormalDescriptionsField =
      formFields.impressionsClinicalCorrelationFreeText.name;

    const impressionDetails = subImpressionDetails(newImpression);

    const abnormalValuesText = formatAddText(
      impressionDetails.addLabel || impressionDetails.label,
      getValues(abnormalValueField),
    );
    const abnormalDescriptionsText = formatAddText(
      impressionDetails.description,
      getValues(abnormalDescriptionsField),
    );

    setValue(abnormalValueField, abnormalValuesText);
    setValue(abnormalDescriptionsField, abnormalDescriptionsText);

    saveConsultChange({
      [fieldName]: currentValues,
      [abnormalValueField]: abnormalValuesText,
      [abnormalDescriptionsField]: abnormalDescriptionsText,
    });
  };

  const handleFieldChangeOverride = (name, type) => {
    switch (name) {
      case formFields.impressionsPredefined.name:
        handleImpressionChange(name);
        break;
      case formFields.impressionsSubtypePredefined.name:
        handleSubImpressionChange(name);
        break;
      default:
        handleFieldChange(name, type);
    }
  };

  const handleEEGReadChange = (fieldName) => {
    const formValues = getValues();

    const startTime = forceDataFormat(
      formValues[formFields.startTimeOfEEGReadAt.name],
    );
    const stopTime = forceDataFormat(
      formValues[formFields.stopTimeOfEEGReadAt.name],
    );

    const timeSpent =
      !!startTime && !!stopTime ? dateDiffFormatted(startTime, stopTime) : null;

    setValue(formFields.duration.name, timeSpent);

    saveConsultChange({
      [formFields.duration.name]: timeSpent,
      [fieldName]: formValues[fieldName],
    });
  };

  return (
    <GridWrapper>
      <Grid item xs={12} lg={4}>
        <QuestionsPanel
          {...eegReadPanel}
          timezone={facilityTimezone}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleEEGReadChange}
          disabled={isMacro || !isEditable}
        />
        <QuestionsPanel
          {...technicalPanel(consultMainType)}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={!isEditable}
        />
        <QuestionsPanel
          {...descriptionPanel}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <QuestionsPanel
          {...centerPanel}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <QuestionsPanel
          {...abnormalitiesPanel}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={!isEditable}
        />
        <QuestionsPanel
          {...impressionPanel(impressions)}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChangeOverride}
          disabled={!isEditable}
        />
        <QuestionsPanel
          {...clinicalCorrelationPanel}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={!isEditable}
        />
      </Grid>
    </GridWrapper>
  );
};

EEG.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default EEG;
