import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/material';

import DayCard from 'features/schedule/components/DayCard/DayCard';
import {
  CardWrapper,
  ExpandButtonWrapper,
  ExpandButton,
} from './ExpandableDayCard.style';

const hasContentOverflow = (element) => {
  if (!element) {
    return false;
  }

  return element.scrollHeight > element.clientHeight;
};

const ExpandableDayCard = ({ isToday, items = [] }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [hasOverflow, setOverflow] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    setOverflow(hasContentOverflow(cardRef.current));
  }, [cardRef, items]);

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
      <CardWrapper
        width="100%"
        height="100%"
        boxShadow={isExpanded ? 5 : null}
        minHeight={isExpanded ? cardRef.current.scrollHeight : null}
      >
        <DayCard ref={cardRef} isToday={isToday} items={items} />
        {hasOverflow && !isExpanded && (
          <ExpandButtonWrapper _today={isToday ? 'today' : ''}>
            <ExpandButton
              color="primary"
              size="small"
              onClick={() => setExpanded(true)}
            >
              See All
            </ExpandButton>
          </ExpandButtonWrapper>
        )}
      </CardWrapper>
    </ClickAwayListener>
  );
};

ExpandableDayCard.propTypes = {
  isToday: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ExpandableDayCard;
