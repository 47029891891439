import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import { PHONE_NUMBER_REGEX } from 'utils/validators';

const PhoneNumber = ({
  control,
  name,
  label,
  getFieldError,
  onChange,
  disabled,
}) => {
  const handlePhoneChange = useMemo(
    () =>
      debounce((value) => {
        if (PHONE_NUMBER_REGEX.test(value)) {
          onChange(name);
        }
      }, 500),
    [name, onChange],
  );

  return (
    <PhoneNumberInput
      control={control}
      name={name}
      label={label}
      error={getFieldError(name)}
      onChange={(value) => handlePhoneChange(value)}
      disabled={disabled}
    />
  );
};

PhoneNumber.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  getFieldError: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PhoneNumber;
