import React from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@mui/material';

import Sidebar from 'shared/components/layout/Sidebar/Sidebar';
import NewConsultButton from 'features/consults/components/NewConsultButton/NewConsultButton';
import { FlexBoxColumn } from 'styles/layout';

import ConsultSidebarList from './ConsultSidebarList';

const ConsultSidebar = ({ consultType }) => (
  <Hidden smDown>
    <Sidebar>
      <FlexBoxColumn px={2.5}>
        <NewConsultButton consultType={consultType} fullWidth replace />
        <ConsultSidebarList consultType={consultType} />
      </FlexBoxColumn>
    </Sidebar>
  </Hidden>
);

ConsultSidebar.propTypes = {
  consultType: PropTypes.string.isRequired,
};

export default ConsultSidebar;
