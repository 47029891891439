import { ServiceTypeEnum } from 'utils/enums';
import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
  getOptions4Type,
} from 'features/consults/utils/formFields';
import { teleNeuroDataFields as fields } from 'features/consults/shared/tabs/Data/formFields';

export const leftPanel = {
  elements: [
    mapToggleFieldOptions(fields.glucosePredefined),
    fields.glucoseFreeText,
    mapToggleFieldOptions(fields.creatininePredefined),
    fields.creatinineFreeText,
    mapToggleFieldOptions(fields.inrPredefined),
    fields.inrFreeText,
    mapChipFieldOptions(fields.otherLabsPredefined),
    fields.otherLabs,
    mapChipFieldOptions(fields.currentHospitalMedicationsPredefined),
    fields.currentHospitalMedicationsFreeText,
    mapChipFieldOptions(fields.echocardiogram),
    fields.echocardiogramOther,
    fields.eegOther,
  ],
};

export const rightPanel = {
  elements: [
    mapChipFieldOptions(
      getOptions4Type(fields.headCtPredefined, ServiceTypeEnum.TELE_NEURO),
    ),
    fields.headCtFreeText,
    mapChipFieldOptions(fields.ctaHeadPredefined),
    mapChipFieldOptions(fields.ctaNeckPredefined),
    mapChipFieldOptions(fields.ctaNeckPreliminarilyPredefined),
    fields.ctaHeadctaNeckFreeText,
    mapChipFieldOptions(fields.mriPredefined),
    mapChipFieldOptions(fields.mriPreliminarilyPredefined),
    fields.mriFreeText,
    fields.otherImaging,
  ],
};
