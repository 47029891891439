import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { alpha, darken } from '@mui/material/styles';
import debounce from 'lodash/debounce';

import { consultsActions } from 'features/consults/store/slice';
import colors from 'styles/colors';
import { Box } from 'styles/layout';

const FlagsEnum = {
  White: colors.white,
  Red: alpha(colors.notification, 0.8),
  Orange: colors.warningDark,
  Yellow: alpha(colors.warning, 0.8),
  Green: alpha(colors.success, 0.8),
};

const FLAGS = [
  FlagsEnum.White,
  FlagsEnum.Red,
  FlagsEnum.Orange,
  FlagsEnum.Yellow,
  FlagsEnum.Green,
];

const FlagWrapper = styled(Box)`
  background: ${({ background }) => background};
  border-right: 1px solid ${darken(colors.neutral, 0.05)};
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;

const ConsultFlag = ({ consultId, consultType, initialFlag }) => {
  const dispatch = useDispatch();
  const [currentFlag, setCurrentFlag] = useState(
    FlagsEnum[initialFlag] || FlagsEnum.White,
  );

  const updateFlag = useMemo(
    () =>
      debounce((newValue) => {
        const color = Object.keys(FlagsEnum).find(
          (key) => FlagsEnum[key] === newValue,
        );

        dispatch(
          consultsActions.updateConsultInList({
            id: consultId,
            consultType,
            propToUpdate: { colorName: color },
          }),
        );
        dispatch(consultsActions.editConsultFlag({ consultId, color }));
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [consultId],
  );

  const handleFlagChange = useCallback(
    (e) => {
      e.stopPropagation();

      const currentFlagIndex = FLAGS.findIndex((flag) => flag === currentFlag);
      const nextFlag = FLAGS[currentFlagIndex + 1];
      const newValue = nextFlag || FLAGS[0];

      setCurrentFlag(newValue);
      updateFlag(newValue);
    },
    [currentFlag, updateFlag],
  );

  return <FlagWrapper background={currentFlag} onClick={handleFlagChange} />;
};

ConsultFlag.propTypes = {
  consultId: PropTypes.string.isRequired,
  consultType: PropTypes.string.isRequired,
  initialFlag: PropTypes.string,
};

export default ConsultFlag;
