import { useCallback, useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';

import api from 'api';
import { useFeedback } from 'shared/hooks';

const useAddendumState = (consultId) => {
  const { clearDisplay, errorDisplay } = useFeedback();

  const [consultAdenda, setConsultAdenda] = useState({
    data: [],
    consultId: null,
  });

  const fetchAddenda = useCallback(async () => {
    try {
      const { data } = await api.getConsultAddenda(consultId);
      setConsultAdenda({
        data: data.addendum,
        consultId,
      });
    } catch {
      errorDisplay('Error fetching addendum');
    }
  }, [consultId, errorDisplay]);

  const addenda = useMemo(
    () =>
      consultAdenda.consultId === consultId
        ? orderBy(consultAdenda.data, ['addedAt'], ['desc'])
        : [],
    [consultAdenda, consultId],
  );

  const onAddAddendum = useCallback(
    async (formValues) => {
      clearDisplay();
      try {
        const { data } = await api.addConsultAddendum(consultId, formValues);
        setConsultAdenda({
          data: [...addenda, data],
          consultId,
        });
        return true;
      } catch {
        errorDisplay('Error adding addendum');
        return false;
      }
    },
    [consultId, addenda, clearDisplay, errorDisplay],
  );

  return {
    addenda,
    fetchAddenda,
    onAddAddendum,
  };
};

export default useAddendumState;
