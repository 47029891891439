import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { appStateActions } from 'store/appStateSlice';
import { profileActions } from 'features/profile/store/slice';
import { useAssets, useEditMode, useFormState, useProfile } from 'shared/hooks';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import EditButton from 'shared/components/buttons/EditButton';
import ImageInput from 'shared/components/forms/ImageInput';

import { FlexBoxColumnFull, FlexBoxSpaceBetweenCenter } from 'styles/layout';

import { userImageSchema } from './helpers';

const FormProfileImage = ({ title }) => {
  const dispatch = useDispatch();
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { profilePic, profileData } = useProfile();
  const { uploadAsset } = useAssets();
  const { control, handleSubmit, getFieldError, setFormValues, canSubmitForm } =
    useFormState(userImageSchema, {
      stateSlice: 'profile',
      showAsSnackbar: true,
    });

  const initValues = React.useMemo(
    () => ({ userImage: profilePic }),
    [profilePic],
  );

  React.useEffect(() => {
    setFormValues(initValues);
  }, [initValues, setFormValues]);

  const onSubmit = async ({ userImage }) => {
    await uploadAsset(profileData.id, userImage);
    dispatch(profileActions.getProfilePhoto(profileData.id));
    disableEditMode();
  };

  return (
    <FlexBoxColumnFull>
      <FlexBoxSpaceBetweenCenter>
        <h3>{title}</h3>
        {!isEditMode && <EditButton onClick={enableEditMode} />}
      </FlexBoxSpaceBetweenCenter>
      <FormWrapper
        isSubmitDisabled={!canSubmitForm}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => {
          setFormValues();
          disableEditMode();
          dispatch(appStateActions.hideSnackbar());
        }}
        formDisabled={!isEditMode}
      >
        <ImageInput
          control={control}
          name="userImage"
          disabled={!isEditMode}
          error={getFieldError('userImage')}
        />
      </FormWrapper>
    </FlexBoxColumnFull>
  );
};

FormProfileImage.propTypes = {
  title: PropTypes.string,
};

export default FormProfileImage;
