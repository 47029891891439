import * as yup from 'yup';

import { MAX_IMAGE_FILE_SIZE, requiredString } from 'utils/constants';
import { isFile } from 'utils/helpers';
import { PHONE_NUMBER_REGEX } from 'utils/validators';
import { UserRoleEnum } from 'utils/enums';

export const FormFields = {
  userImage: 'userImage',
  enabled: 'enabled',
  healthSystem: 'healthSystemId',
  autologout: 'autologout',
  title: 'title',
  roles: 'roles',
};

export const isPhoneRequired = (roles) =>
  !roles?.every(
    (role) =>
      role === UserRoleEnum.ADMINISTRATOR || role === UserRoleEnum.CUSTOMER,
  );

export const schema = yup.object().shape({
  [FormFields.userImage]: yup
    .mixed()
    .test(
      'fileSize',
      `The uploaded file exceeds the maximum allowed size of ${
        MAX_IMAGE_FILE_SIZE / 1024 ** 2
      }MB`,
      (value) => {
        if (isFile(value)) {
          return value.size <= MAX_IMAGE_FILE_SIZE;
        }

        return true;
      },
    ),
  [FormFields.enabled]: yup.string().required(requiredString),
  firstName: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `First Name should not exceed ${max} characters`),
  lastName: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `Last Name should not exceed ${max} characters`),
  [FormFields.title]: yup.string().nullable().trim(),
  email: yup
    .string()
    .trim()
    .required(requiredString)
    .email('Invalid Email')
    .max(2048, ({ max }) => `Email should not exceed ${max} characters`),
  [FormFields.roles]: yup.array().of(yup.string()).required(requiredString),
  phoneNumber: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
    .when(FormFields.roles, {
      is: (roles) => isPhoneRequired(roles),
      then: () =>
        yup.string().required(requiredString).typeError(requiredString),
    }),
  shitAdminId: yup.string(),
  npi: yup.string().nullable(),
  [FormFields.autologout]: yup.boolean(),
  inactivityLogOutThreshold: yup
    .number()
    .when(FormFields.autologout, ([autologout], scheme) => {
      if (!autologout) return yup.string().nullable();
      return scheme
        .typeError('Must be a number')
        .positive('Please enter a number greater then zero')
        .integer('Please enter a integer value')
        .required('Required');
    }),
});

export const enabledOptions = [
  { label: 'Enabled', value: '1' },
  { label: 'Disabled', value: '0' },
];

export const mapForm2Api = (data) => ({
  ...data,
  [FormFields.title]: data.title || null,
  shiftAdminUserId: data.shiftAdminUserId
    ? Number(data.shiftAdminUserId)
    : null,
  enabled: Boolean(Number(data.enabled)),
  showAllConsultRequests: Boolean(Number(data.showAllConsultRequests)),
  viewClosedConsultRequests: Boolean(Number(data.viewClosedConsultRequests)),
  viewAndEditSettings: Boolean(Number(data.viewAndEditSettings)),
  showFullPatientNames: Boolean(Number(data.showFullPatientNames)),
  viewAdminConsultTable: Boolean(Number(data.viewAdminConsultTable)),
  createSharedMacros: Boolean(Number(data.createSharedMacros)),
  numberOfCharactersToShowPatientNames: Number(
    data.numberOfCharactersToShowPatientNames,
  ),
  inactivityLogOutThreshold: data.inactivityLogOutThreshold
    ? Number(data.inactivityLogOutThreshold)
    : null,
});

export const mapApi2Form = ({
  facilities,
  enabled,
  showAllConsultRequests,
  viewClosedConsultRequests,
  viewAndEditSettings,
  showFullPatientNames,
  viewAdminConsultTable,
  createSharedMacros,
  inactivityLogOutThreshold,
  ...rest
}) => ({
  ...rest,
  enabled: enabled ? '1' : '0',
  [FormFields.autologout]: Boolean(inactivityLogOutThreshold),
  inactivityLogOutThreshold,
  showAllConsultRequests: showAllConsultRequests ? '1' : '0',
  viewClosedConsultRequests: viewClosedConsultRequests ? '1' : '0',
  viewAndEditSettings: viewAndEditSettings ? '1' : '0',
  showFullPatientNames: showFullPatientNames ? '1' : '0',
  viewAdminConsultTable: viewAdminConsultTable ? '1' : '0',
  createSharedMacros: createSharedMacros ? '1' : '0',
  [FormFields.healthSystem]:
    facilities?.reduce(
      (res, f) =>
        res.includes(f.healthSystemId) ? res : [...res, f.healthSystemId],
      [],
    ) || [],
  facilities: facilities?.map(({ facilityId }) => facilityId),
});
