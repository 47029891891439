import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { Box } from 'styles/layout';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { PanelField } from 'features/consults/components/tabFields/QuestionsPanel';

const OptionPanel = ({
  elements = [],
  register,
  control,
  onChange,
  getFieldError,
  disabled,
  disabledElements = [],
}) =>
  elements.map(({ name, type, options, associatedField, ...rest }) => (
    <Box mb={0.5} key={name}>
      <PanelField
        {...rest}
        disabled={disabled || disabledElements?.includes(name)}
        name={name}
        type={type}
        options={options}
        register={register}
        control={control}
        onChange={onChange}
        getFieldError={getFieldError}
      />
    </Box>
  ));

const ExamPanel = ({ section, ...rest }) => (
  <Box mb={1}>
    <Grid container>
      <Grid item xs={12} lg={5}>
        {section.label && <InputLabel>{section.label}</InputLabel>}
        <OptionPanel elements={section.leftPanel} {...rest} />
      </Grid>
      <Grid item xs={12} lg={7}>
        <OptionPanel elements={section.rightPanel} {...rest} />
      </Grid>
      <Grid item xs={12}>
        <OptionPanel elements={section.bottomPanel} {...rest} />
      </Grid>
    </Grid>
  </Box>
);

ExamPanel.propTypes = {
  section: PropTypes.shape({
    label: PropTypes.string,
    leftPanel: PropTypes.arrayOf(PropTypes.shape({})),
    rightPanel: PropTypes.arrayOf(PropTypes.shape({})),
    bottomPanel: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default ExamPanel;
