import { mapToggleFieldOptions } from 'features/consults/utils/formFields';

import fields from './formFields';

export const NIHSSFields = [
  mapToggleFieldOptions(fields.levelOfConsciousness),
  mapToggleFieldOptions(fields.locQuestions),
  mapToggleFieldOptions(fields.locCommands),
  mapToggleFieldOptions(fields.bestGaze),
  mapToggleFieldOptions(fields.visual),
  mapToggleFieldOptions(fields.facialPalsy),
  mapToggleFieldOptions(fields.motorArmLeft),
  mapToggleFieldOptions(fields.motorArmRight),
  mapToggleFieldOptions(fields.motorLegLeft),
  mapToggleFieldOptions(fields.motorLegRight),
  mapToggleFieldOptions(fields.limbAtaxia),
  mapToggleFieldOptions(fields.sensory),
  mapToggleFieldOptions(fields.bestLanguage),
  mapToggleFieldOptions(fields.dysarthria),
  mapToggleFieldOptions(fields.extinctionAndInattention),
];

export const ICHFields = [
  mapToggleFieldOptions(fields.ich),
  mapToggleFieldOptions(fields.ichAge),
  mapToggleFieldOptions(fields.ichVolume),
  mapToggleFieldOptions(fields.ichIntraventricularHemorhage),
  mapToggleFieldOptions(fields.ichInfraventricOrigin),
];

const VanFields = [
  mapToggleFieldOptions(fields.vanArmWeakness),
  mapToggleFieldOptions(fields.vanVisualDisturbance),
  mapToggleFieldOptions(fields.vanAphasia),
  mapToggleFieldOptions(fields.vanNeglect),
];

export const HuntHessSAHFields = [mapToggleFieldOptions(fields.huntHessSAH)];

export const ABCD2Fields = [
  fields.abcD2Title,
  mapToggleFieldOptions(fields.abcD2AgeAboveOrEqualTo60),
  mapToggleFieldOptions(fields.abcD2BpAboveOrEqualTo14090),
  mapToggleFieldOptions(fields.abcD2ClinicalFeatures),
  mapToggleFieldOptions(fields.abcD2DurationOfSymptoms),
  mapToggleFieldOptions(fields.abcD2HistoryOfDiabetes),
];

export const examPanel = {
  elements: [fields.nihssExamTimeAt],
};

export default {
  NIHSS: {
    name: fields.nihssTotal.name,
    label: fields.nihssTotal.label,
    fields: NIHSSFields,
  },
  ICH: {
    name: fields.ichTotal.name,
    label: fields.ichTotal.label,
    fields: ICHFields,
  },
  HUNT: {
    name: fields.huntHessTotal.name,
    label: fields.huntHessTotal.label,
    fields: HuntHessSAHFields,
  },
  ABCD2: {
    name: fields.abcD2Total.name,
    label: fields.abcD2Total.label,
    fields: ABCD2Fields,
  },
  VAN: {
    name: fields.vanTotal.name,
    label: fields.vanTotal.label,
    fields: VanFields,
  },
};
