import React from 'react';

import { useQueryParams } from 'shared/hooks/useQueryParams';

const renderArray = (queryValue, resValue) => {
  if (Array.isArray(queryValue)) return [...resValue, ...queryValue];
  return [...resValue, queryValue];
};

export const useFilterState = (defaultFilters = {}, setPage = null) => {
  const { queryParams } = useQueryParams();
  const [filters, setFilters] = React.useState(
    Object.entries(defaultFilters).reduce(
      (res, [key, defaultvalue]) =>
        (Object.prototype.hasOwnProperty.call(queryParams, key) && {
          ...res,
          [key]: Array.isArray(defaultvalue)
            ? renderArray(queryParams[key], res[key])
            : queryParams[key],
        }) ||
        res,
      defaultFilters,
    ),
  );

  const handleFilter = React.useCallback(
    (value) => {
      setFilters({ ...filters, ...value });
      if (setPage) setPage(1);
    },
    [filters, setPage],
  );

  const handleResetFilters = React.useCallback(
    (value) => {
      setFilters(value);
      if (setPage) setPage(1);
    },
    [setPage],
  );

  return {
    filters,
    handleFilter,
    handleResetFilters,
  };
};

export default useFilterState;
