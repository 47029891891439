  import React from 'react';
  import PropTypes from 'prop-types';
  
  import { useFormState } from 'shared/hooks';
  import SearchField from 'shared/components/forms/SearchField/SearchField';
  import { FilterElement } from 'features/admin/pages/Consults/ConsultList/ConsultListFilters/ConsultFilters.style';
  import { FlexBoxWrap } from 'styles/layout';
  
  import { filterSchema, formFields } from './utils';
  
  const HealthSystemsListFilters = ({ onFilter, defaultFilters }) => {
    const { register, setFormValues } =
    useFormState(filterSchema);
  
    React.useEffect(() => {
      setFormValues(defaultFilters);
    }, [setFormValues, defaultFilters]);
  
    const handleSubmit = (event) => {
      event.preventDefault(); // Prevent form submission and page reload
    };
  
    return (
      <form onSubmit={handleSubmit}>
        <FlexBoxWrap>
          <FilterElement>
            <SearchField
              {...register(formFields.search)}
              onChange={(value) => onFilter({ query: value })}
            />
          </FilterElement>
        </FlexBoxWrap>
      </form>
    );
  };
  
  HealthSystemsListFilters.propTypes = {
    onFilter: PropTypes.func.isRequired,
    defaultFilters: PropTypes.shape({}).isRequired,
  };
  
  export default HealthSystemsListFilters;
  