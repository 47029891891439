import React from 'react';

import DemographicsHeader from 'features/consults/components/DemographicsHeader/DemographicsHeader';
import Summary from 'features/consults/shared/tabs/Summary';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import Demographics from './DemographicsEEG';
import EEG from './EEG';
import SignatureEEG from './SignatureEEG';

export default {
  demographics: {
    value: CONSULT_TABS.DEMOGRAPHICS,
    label: <DemographicsHeader />,
    component: Demographics,
  },
  eeg: {
    value: CONSULT_TABS.EEG,
    label: 'EEG',
    component: EEG,
  },
  summary: {
    value: CONSULT_TABS.SUMMARY,
    label: 'Summary',
    component: Summary,
  },
  signature: {
    value: CONSULT_TABS.SIGNATURE,
    label: 'Signature/Billing',
    component: SignatureEEG,
  },
};
