import React from 'react';
import PropTypes from 'prop-types';
import { useConsult } from 'shared/context/ConsultContext';

import { Box, FlexBoxWrap, FlexBoxColumn } from 'styles/layout';
import { MacroTypes } from 'utils/enums';

import MacroButton from './MacrosList.style';

const MacrosList = ({ items, type, onSelect }) => {
  const { consult } = useConsult();
  if (items.length === 0) {
    return null;
  }

  return (
    <FlexBoxColumn mb={2}>
      <Box mb={1}>
        {type === MacroTypes.SHARED ? 'Shared Macros' : 'Your Macros'}
      </Box>
      <FlexBoxWrap>
        {items.map((item) => (
          <MacroButton
            key={item.id}
            _applied={consult.appliedMacros?.includes(item.id) ? "applied" : ""}
            variant="outlined"
            onClick={() => onSelect(item.id)}
          >
            {item.name}
          </MacroButton>
        ))}
      </FlexBoxWrap>
    </FlexBoxColumn>
  );
};

MacrosList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default MacrosList;
