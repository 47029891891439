import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { successActionSelector } from 'store/selectors';
import { adminActions } from 'features/admin/store/slice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';

import BackButton from 'navigation/components/BackButton/BackButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import UserForm from 'features/admin/components/UserForm';

import { FlexBoxColumn, FlexBoxColumnFull, FlexBoxAlign } from 'styles/layout';

const UserAddPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const successAction = useSelector(successActionSelector);

  usePerformActionOnSuccess(SuccessActionTypesEnum.USER_ADDED, () => {
    navigate(`../${successAction.payload.userId}`, { replace: true });
  });

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton />
        <PageTitle>Create New User</PageTitle>
      </FlexBoxAlign>
      <FlexBoxColumnFull p={2}>
        <UserForm
          initValues={{ autologout: false }}
          onSubmit={(values) => dispatch(adminActions.addUser(values))}
          onCancel={() => navigate('..')}
        />
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default UserAddPage;
