import React from 'react';
import PropTypes from 'prop-types';

import { RESET_OPTION } from 'features/consults/utils/constants';
import Button from 'shared/components/buttons/Button/Button';

const ClearGroupButton = ({ form, disabled, label, group, onSave }) => {
  const clearGroup = React.useCallback(() => {
    const formValues = form.getValues();
    const updateFields = group.fields.reduce(
      (res, field) => ({ ...res, [field.name]: RESET_OPTION.value }),
      {},
    );
    updateFields[group.name] = '';
    form.setFormValues({
      ...formValues,
      ...updateFields,
    });
    onSave(updateFields);
  }, [group, form, onSave]);

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={clearGroup}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

ClearGroupButton.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setFormValues: PropTypes.func,
  }),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  group: PropTypes.shape({
    name: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onSave: PropTypes.func.isRequired,
};

export default ClearGroupButton;
