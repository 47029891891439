import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { errorSelector } from 'store/selectors';
import { appStateActions } from 'store/appStateSlice';

const useClearError = (
  hasFormErrors,
  stateSlice,
  isSnackbarError,
  visibleSnackBar,
) => {
  const dispatch = useDispatch();
  const stateError = useSelector(errorSelector(stateSlice));
  const clearAction = { type: `${stateSlice}/clearError` };

  useEffect(() => {
    if (isSnackbarError && !visibleSnackBar && stateError) {
      dispatch(clearAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSnackbarError, visibleSnackBar, stateError, dispatch]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // Clear any errors when component is destroyed
    return () => {
      dispatch(clearAction);

      if (isSnackbarError) {
        dispatch(appStateActions.hideSnackbar());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Clear server errors when form validation errors appear
    if (hasFormErrors && stateError) {
      dispatch(clearAction);

      if (isSnackbarError) {
        dispatch(appStateActions.hideSnackbar());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFormErrors]);
};

export default useClearError;
