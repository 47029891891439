import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import sortFields from 'api/sortFields';
import { useMountEffect, useUpdateEffect, useTableState } from 'shared/hooks';
import { adminActions } from 'features/admin/store/slice';
import { getTableHeaders, prepareTableData } from 'utils/tables';

import AddButton from 'navigation/components/AddButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';

import { Box, FlexBoxColumn, FlexBoxSpaceBetweenCenter } from 'styles/layout';

const tableColumns = [
  {
    header: {
      id: sortFields.networks.name,
      value: 'Network Name',
      isSortable: true,
    },
    dataKey: 'name',
    width: '80%',
  },
  {
    header: {
      id: sortFields.networks.facilityCount,
      value: 'Number of Facilities',
      isSortable: true,
    },
    dataKey: 'facilitiesCount',
    align: 'center',
  },
];

const NetworksListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: networks, pageCount } = useSelector(
    ({ admin }) => admin.networks,
  );
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setQueryParams,
  } = useTableState(sortFields.networks.name);

  const fetchList = React.useCallback(() => {
    dispatch(adminActions.listNetworks({ page, sortBy, sortOrder }));
    setQueryParams({
      page,
      sortBy,
      sortOrder,
    });
  }, [dispatch, page, sortBy, sortOrder, setQueryParams]);

  useMountEffect(fetchList);

  useUpdateEffect(() => {
    fetchList();
  }, [page, sortBy, sortOrder]);

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter>
        <PageTitle>Networks</PageTitle>
        <Box>
          <AddButton to="add">Add Network</AddButton>
        </Box>
      </FlexBoxSpaceBetweenCenter>
      <FlexBoxColumn mt={3}>
        <PaginatedTable
          headings={getTableHeaders(tableColumns)}
          data={prepareTableData(networks, tableColumns)}
          fallbackMsg="No Networks found"
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onClickRow={(rowId) => navigate(`${rowId}`)}
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default NetworksListPage;
