import { useMemo } from 'react';

import { useProfile } from 'shared/hooks';
import Paths, { RoutesForRoleEnum } from 'navigation/paths';

const useRoleBasedRouting = () => {
  const { roles } = useProfile();

  const homePage = useMemo(() => {
    const primaryRole = roles
      ?.map((role) => RoutesForRoleEnum[role])
      ?.sort((a, b) => a.priority - b.priority)[0];

    return primaryRole?.routes[0];
  }, [roles]);

  const permittedRoutes = useMemo(
    () => [
      ...new Set([
        ...roles.flatMap((role) => RoutesForRoleEnum[role]?.routes),
        Paths.Schedule,
        Paths.Profile,
      ]),
    ],
    [roles],
  );

  return {
    homePage,
    permittedRoutes,
  };
};

export default useRoleBasedRouting;
