import {
  getOptions4Type,
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields, { impressionValues } from './formFields';

export const eegReadPanel = {
  elements: [
    fields.startTimeOfEEGReadAt,
    fields.stopTimeOfEEGReadAt,
    fields.duration,
  ],
  mb: 1,
};

export const technicalPanel = (consultType) => ({
  elements: [
    mapChipFieldOptions(getOptions4Type(fields.technicalDetailsPredefined, consultType)),
    fields.technicalDetailsFreeText,
    mapChipFieldOptions(
      getOptions4Type(fields.indicationPredefined, consultType),
    ),
    fields.indicationFreeText,
    fields.additionalPatientHistory,
  ],
  mb: 2,
});

export const descriptionPanel = {
  title: 'Description',
  elements: [
    mapToggleFieldOptions(fields.photicStimulationPredefined),
    fields.photicStimulationFreeText,
    mapToggleFieldOptions(fields.hyperventilationPredefined),
    fields.hyperventilationFreeText,
  ],
};

export const centerPanel = {
  elements: [
    mapChipFieldOptions(fields.phasesCapturedPredefined),
    fields.phasesCapturedFreeText,
    mapToggleFieldOptions(fields.symmetryPredefined),
    fields.symmetryFreeText,
    mapToggleFieldOptions(fields.posteriorDominantRhythmPredefined),
    fields.posteriorDominantRhythmFreeText,
    mapToggleFieldOptions(fields.predominantFrequenciesFrequency),
    mapToggleFieldOptions(fields.predominantFrequenciesFrequencyDuration),
    fields.predominantFrequenciesFreeText,
    mapToggleFieldOptions(fields.superimposedFrequenciesFrequency),
    mapToggleFieldOptions(fields.superimposedFrequenciesFrequencyDuration),
    fields.superimposedFrequenciesFreeText,
    mapToggleFieldOptions(fields.amplitudePredefined),
    fields.amplitudeFreeText,
    mapToggleFieldOptions(fields.reactivityPredefined),
    fields.reactivityFreeText,
    mapToggleFieldOptions(fields.variabilityPredefined),
    fields.variabilityFreeText,
    mapToggleFieldOptions(fields.continuityPredefined),
    fields.continuityFreeText,
    mapToggleFieldOptions(fields.ekgPredefined),
    fields.ekgFreeText,
  ],
};

export const abnormalitiesPanel = {
  title: 'Abnormalities',
  elements: [
    mapChipFieldOptions(fields.stimulationPredefined),
    fields.stimulationFreeText,
    mapChipFieldOptions(fields.epileptiformAbnormalitiesPredefined),
    fields.epileptiformAbnormalitiesFreeText,
    mapToggleFieldOptions(fields.focalSlowingPredefined),
    fields.focalSlowingFreeText,
    mapChipFieldOptions(fields.seizurePredefined),
    fields.seizureFreeText,
    fields.artifact,
  ],
  mb: 2,
};

export const impressionPanel = (impressionValue) => ({
  title: 'Impression',
  elements:
    impressionValue === impressionValues.abnormal
      ? [
          mapToggleFieldOptions(fields.impressionsPredefined),
          mapChipFieldOptions(fields.impressionsSubtypePredefined),
          fields.abnormalityOptionsFreeText,
        ]
      : [mapToggleFieldOptions(fields.impressionsPredefined)],
  mb: 2,
});

export const clinicalCorrelationPanel = {
  title: 'Clinical Correlation',
  elements: [
    fields.impressionsClinicalCorrelationFreeText,
    fields.additionalComments,
  ],
};
