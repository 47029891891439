import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/components/buttons/Button/Button';
import { FlexBox } from 'styles/layout';

const ActionButton = ({
  children,
  type = 'submit',
  variant = 'contained',
  icon = null,
  ...props
}) => (
  <Button type={type} variant={variant} {...props}>
    {icon && <FlexBox pr={0.75}>{icon}</FlexBox>}
    {children}
  </Button>
);

ActionButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.node,
};

export default ActionButton;
