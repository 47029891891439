import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import Paths from 'navigation/paths';
import MenuList from 'navigation/components/MenuList/MenuList';
import { FlexBoxColumn, FlexBoxColumnSpaceBetween } from 'styles/layout';
import {
  NavDrawer,
  CloseBtnWrapper,
  CloseDrawerBtn,
  DrawerMenuBtn,
} from './DrawerMenu.style';

const DrawerMenu = ({ isOpen, showProfile, onClose, onLogout }) => (
  <NavDrawer isOpen={isOpen} onClose={onClose}>
    <FlexBoxColumnSpaceBetween height="100%">
      <FlexBoxColumn>
        <CloseBtnWrapper mb={1}>
          <CloseDrawerBtn onClick={onClose}>
            <Close />
          </CloseDrawerBtn>
        </CloseBtnWrapper>
        <MenuList isMobile onNavigationChange={onClose} />
      </FlexBoxColumn>
      <FlexBoxColumn>
        {showProfile && (
          <DrawerMenuBtn
            sx={{ marginLeft: 3.5, marginBottom: 2 }}
            onClick={onClose}
            component={Link}
            to={Paths.Profile}
          >
            PROFILE
          </DrawerMenuBtn>
        )}
        <DrawerMenuBtn
          sx={{ marginLeft: 3.5, marginBottom: 4 }}
          onClick={onLogout}
        >
          Logout
        </DrawerMenuBtn>
      </FlexBoxColumn>
    </FlexBoxColumnSpaceBetween>
  </NavDrawer>
);

DrawerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showProfile: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default DrawerMenu;
