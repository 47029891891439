import { useRef } from 'react';

import { useFeedback } from 'shared/hooks';

const useSummaryCopy = () => {
  const { clearDisplay, successDisplay, errorDisplay } = useFeedback();
  const summaryContainerRef = useRef(null);

  const copyNote = () => {
    const elem = summaryContainerRef.current;
    const selection = window.getSelection();
    const range = document.createRange();

    selection.removeAllRanges();
    range.selectNodeContents(elem);
    selection.addRange(range);

    clearDisplay();
    try {
      document.execCommand('copy');
      selection.removeAllRanges();
      successDisplay('Copied successfully');
    } catch {
      errorDisplay('Press Ctrl + C to copy note to clipboard');
    }
  };

  return {
    summaryContainerRef,
    copyNote,
  };
};

export default useSummaryCopy;
