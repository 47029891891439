import { ServiceTypeEnum } from 'utils/enums';
import {
  getOptions4Type,
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/Plan/formFields';

export const recommendationsPanel = {
  elements: [fields.recommendations],
};

export const rightPanel = {
  elements: [
    mapChipFieldOptions(
      getOptions4Type(fields.labsPredefined, ServiceTypeEnum.CLINIC),
    ),
    fields.labsFreeText,
    mapChipFieldOptions(fields.imagingPredefined),
    fields.imagingFreeText,
    mapChipFieldOptions(
      getOptions4Type(fields.diagnosticTestPredefined, ServiceTypeEnum.CLINIC),
    ),
    fields.diagnosticTestFreeText,
    mapChipFieldOptions(
      getOptions4Type(
        fields.therapyEvaluationPredefined,
        ServiceTypeEnum.CLINIC,
      ),
    ),
    fields.therapyEvaluationFreeText,
    mapChipFieldOptions(
      getOptions4Type(fields.medicationsPredefined, ServiceTypeEnum.CLINIC),
    ),
    fields.medicationsFreeText,
    mapChipFieldOptions(
      getOptions4Type(fields.otherPredefined, ServiceTypeEnum.CLINIC),
    ),
    fields.otherFreeText,
    fields.additionalRecommendations,
    mapToggleFieldOptions(fields.followUpPredefined),
    fields.followUpFreeText,
    fields.timeSpentInDirectCareOfPatientMinutes,
    mapChipFieldOptions(
      fields.timeSpentInDirectCareOfPatientCounselingCoordinationPredefined,
    ),
  ],
};
