import { formatSummaryTimestamp } from 'features/consults/utils/dates';

// In case if current signature doesn't exists
export const buildNewSignature = ({
  signatureData: { closedAt, closedById },
  consultTimezone,
  consultProviders,
}) => {
  const closedByName = consultProviders.find(
    ({ id }) => id === closedById,
  )?.name;

  return `${formatSummaryTimestamp(
    closedAt,
    consultTimezone,
  )} by ${closedByName}`;
};

// extract signature text from summary
export const extractSignature = (summary) => {
  // To make sure is not on addeda data we just pick text after the last demographics
  const demographicsSections = summary?.split('# Demographics') || [];
  const lastDemographics =
    demographicsSections[demographicsSections.length - 1];
  return lastDemographics?.split('Electronically signed at')?.[1];
};
