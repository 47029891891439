import React from 'react';

import { CONSULT_TABS } from 'features/consults/utils/constants';
import DemographicsHeader from 'features/consults/components/DemographicsHeader/DemographicsHeader';

import PMHFHSH from 'features/consults/shared/tabs/PMHFHSH';
import ROS from 'features/consults/shared/tabs/ROS';
import Assessment from 'features/consults/shared/tabs/Assessment';
import Summary from 'features/consults/shared/tabs/Summary';

import Demographics from './Demographics';
import Hpi from './Hpi';
import Scores from './Scores';
import Exam from './Exam';
import Data from './Data';
import Plan from './Plan';
import Stroke from './Stroke';
import Signature from './Signature';

export default {
  demographics: {
    value: CONSULT_TABS.DEMOGRAPHICS,
    label: <DemographicsHeader />,
    component: Demographics,
  },
  hpi: {
    value: CONSULT_TABS.HPI,
    label: 'Hpi',
    component: Hpi,
  },
  pmh: {
    value: CONSULT_TABS.PMH,
    label: 'PMH-FH-SH',
    component: PMHFHSH,
  },
  ros: {
    value: CONSULT_TABS.ROS,
    label: 'ROS',
    component: ROS,
  },
  scores: {
    value: CONSULT_TABS.SCORES,
    label: 'Scores',
    component: Scores,
  },
  exam: {
    value: CONSULT_TABS.EXAM,
    label: 'Exam',
    component: Exam,
  },
  data: {
    value: CONSULT_TABS.DATA,
    label: 'Data',
    component: Data,
  },
  assessment: {
    value: CONSULT_TABS.ASSESSMENT,
    label: 'Assessment',
    component: Assessment,
  },
  plan: {
    value: CONSULT_TABS.PLAN,
    label: 'Plan',
    component: Plan,
  },
  stroke: {
    value: CONSULT_TABS.STROKE,
    label: 'Stroke Core Measures',
    component: Stroke,
  },
  summary: {
    value: CONSULT_TABS.SUMMARY,
    label: 'Summary',
    component: Summary,
  },
  signature: {
    value: CONSULT_TABS.SIGNATURE,
    label: 'Signature/Billing',
    component: Signature,
  },
};
