import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Button from 'shared/components/buttons/Button/Button';
import { FlexBoxCenter, FlexBoxColumnAlign } from 'styles/layout';

const ProfileErrorPage = ({ error, onSignOut }) => (
  <FlexBoxCenter style={{ height: '100vh' }}>
    <Paper elevation={3} style={{ padding: '2rem' }}>
      <FlexBoxColumnAlign>
        <Typography variant="h6" style={{ color: 'red', marginBottom: '1rem' }}>
          {error}
        </Typography>
        <Button variant="contained" color="primary" onClick={onSignOut}>
          Back to Login
        </Button>
      </FlexBoxColumnAlign>
    </Paper>
  </FlexBoxCenter>
);

ProfileErrorPage.propTypes = {
  error: PropTypes.string,
  onSignOut: PropTypes.func.isRequired,
};

export default ProfileErrorPage;
