import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import { useFormState } from 'shared/hooks';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import InputField from 'shared/components/forms/InputField/InputField';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import Autocomplete from 'shared/components/forms/Autocomplete';
import Switch from 'shared/components/forms/Switch/Switch';
import { Box } from 'styles/layout';
import listOfStates from 'utils/states';
import { mapFormFieldsToApiFormat } from 'utils/mappers';

import schema from './validationSchema';

const HealthSystemForm = ({
  initValues,
  disabled,
  onSave,
  onCancel,
  buttonsPosition,
}) => {
  const {
    register,
    control,
    getFieldError,
    setFormValues,
    handleSubmit,
    canSubmitForm,
  } = useFormState(schema, {
    stateSlice: 'admin',
    showAsSnackbar: true,
  });

  React.useEffect(() => {
    if (!initValues) return;
    setFormValues(initValues);
  }, [initValues, setFormValues]);

  const onSubmit = (values) => {
    onSave(mapFormFieldsToApiFormat(values));
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit(onSubmit)}
      onCancel={() => {
        setFormValues();
        onCancel();
      }}
      isSubmitDisabled={!canSubmitForm}
      formDisabled={disabled}
      buttonsPosition={buttonsPosition}
    >
      <Box mb={2}>
        <Grid container spacing={6}>
          <Grid container item xs={12} md={8} spacing={2}>
            <Grid item md={6} xs={12}>
              <Switch
                control={control}
                name="active"
                label="Active"
                disabled={disabled}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputField
                  {...register('name', {
                    required: true,
                    disabled,
                  })}
                  required
                  label="Healthcare System Name"
                  error={getFieldError('name')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField
                  {...register('siteCode', {
                    disabled,
                  })}
                  label="Site Code"
                  error={getFieldError('siteCode')}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputField
                  {...register('contact.name', {
                    disabled,
                  })}
                  label="Contact Name"
                  error={getFieldError('contact.name')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PhoneNumberInput
                  control={control}
                  name="contact.phoneNumber"
                  label="Contact Phone Number"
                  disabled={disabled}
                  error={getFieldError('contact.phoneNumber')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputField
                {...register('address.streetAddress', {
                  disabled,
                })}
                label="Address"
                error={getFieldError('address.streetAddress')}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={4}>
                <InputField
                  {...register('address.locality', {
                    disabled,
                  })}
                  name="address.locality"
                  label="City"
                  error={getFieldError('address.locality')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  control={control}
                  name="address.region"
                  label="State"
                  options={listOfStates}
                  disabled={disabled}
                  error={getFieldError('address.region')}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  {...register('address.postalCode', {
                    disabled,
                  })}
                  label="Zip Code"
                  inputProps={{ maxLength: 5 }}
                  error={getFieldError('address.postalCode')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              {...register('note', {
                disabled,
              })}
              multiline
              rows={6}
              label="BSL Healthcare System Note"
              error={getFieldError('note')}
            />
          </Grid>
        </Grid>
      </Box>
    </FormWrapper>
  );
};

HealthSystemForm.propTypes = {
  disabled: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initValues: PropTypes.shape({}),
  buttonsPosition: PropTypes.string,
};

export default HealthSystemForm;
