import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import api from 'api';
import { useFeedback, useRequest, useMountEffect } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';
import { LocationPaths } from 'features/admin/paths';

import BackButton from 'navigation/components/BackButton/BackButton';
import FacilityForm, {
  facilityFields,
} from 'features/admin/components/FacilityForm';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';
import { FlexBoxAlign, FlexBoxColumn } from 'styles/layout';

const AddFacilityPage = () => {
  const { locationId } = useParams();
  const navigate = useNavigate();
  const { goBack } = useGoBack();
  const { clearDisplay, errorDisplay } = useFeedback();

  const { data: healthSystem, doRequest } = useRequest(api.getHealthSystemById);

  useMountEffect(() => {
    doRequest(locationId);
  });

  const currentSystem = React.useMemo(
    () => healthSystem?.healthSystem,
    [healthSystem],
  );

  const onCreate = React.useCallback(
    (facility) => {
      clearDisplay();
      api
        .createFacility({
          locationId,
          facility,
        })
        .then(({ data }) => {
          navigate(`../${locationId}/${LocationPaths.Facility}/${data.id}`, {
            replace: true,
          });
        })
        .catch(({ response }) => {
          errorDisplay(
            response?.data?.title || 'Unknown error creating facility',
          );
        });
    },
    [locationId, clearDisplay, errorDisplay, navigate],
  );

  if (!currentSystem) return <LoadingSpinner />;

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton />
        <PageTitle>{`${currentSystem?.name}: New Facility`}</PageTitle>
      </FlexBoxAlign>
      <FacilityForm
        initValues={{
          [facilityFields.active]: true,
          [facilityFields.codeFromSystem]: true,
        }}
        siteCode={currentSystem?.siteCode}
        onSave={onCreate}
        onCancel={() => goBack()}
      />
    </FlexBoxColumn>
  );
};

export default AddFacilityPage;
