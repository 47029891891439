import { useMemo } from 'react';

import { useProfile } from 'shared/hooks';
import { ServiceTypeLabels, ConsultType2RoleEnum } from 'utils/enums';

const useMacroOptions = () => {
  const { roles } = useProfile();

  const macroOptions = useMemo(
    () =>
      Object.entries(ServiceTypeLabels)
        .filter(([service]) => roles.includes(ConsultType2RoleEnum[service]))
        .map(([service, label]) => ({
          value: service,
          text: label,
        })),
    [roles],
  );

  return { macroOptions };
};

export default useMacroOptions;
