import React from 'react';
import { signInWithRedirect } from 'aws-amplify/auth';
import { Button, TextField, useTheme } from '@aws-amplify/ui-react';

import { useFeedback } from 'shared/hooks';

const providers = {
  'davewsursumcorda.onmicrosoft.com': 'AzureInternal',
  'bannerhealth.com': 'BannerHealth',
  'phsi.us': 'PrimeHealthCare',
  'duosso.davewsursumcorda.onmicrosoft.com': 'PrimeHealthCare',
  'slhs.org': 'StLukes',
  'mchs.com': 'MtCarmels'
};

const CorporateLogin = () => {
  const { tokens } = useTheme();
  const [email, setEmail] = React.useState('');
  const { errorDisplay } = useFeedback();

  const handleSignIn = React.useCallback(() => {
    const emailArgs = email.split('@');
    const custom = providers[emailArgs[1]];
    if (!custom) {
      errorDisplay(
        'Corporate login is not enabled for your account. Please login with email and password.',
      );
      return;
    }
    signInWithRedirect({ provider: { custom } });
  }, [email, errorDisplay]);

  return (
    <>
      <TextField onChange={({ target }) => setEmail(target.value)} />
      <Button
        type="button"
        marginTop={tokens.space.medium}
        variation="primary"
        isFullWidth
        onClick={handleSignIn}
      >
        Corporate Login
      </Button>
    </>
  );
};

export default CorporateLogin;
