import React from 'react';
import PropTypes from 'prop-types';

import { Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from 'shared/components/buttons/IconButton/IconButton';

const ConsultIntegrationDialog = ({ children, open, onClose }) => (
  <Dialog open={open} maxWidth="lg" onClose={onClose}>
    <DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ minWidth: '30vw', minHeight: '30vh' }}>{children}</Box>
    </DialogContent>
  </Dialog>
);

ConsultIntegrationDialog.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ConsultIntegrationDialog;
