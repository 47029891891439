import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import TabContext from '@mui/lab/TabContext';

import { useBreakpoints, useMountEffect, useUpdateEffect } from 'shared/hooks';
import { SpeechButtonsProvider } from 'shared/context/SpeechButtonsContext';
import { consultsActions } from 'features/consults/store/slice';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import neuroTabs from 'features/consults/pages/neuro/tabs';
import clinicTabs from 'features/consults/pages/clinic/tabs';
import eegTabs from 'features/consults/pages/eeg/tabs';
import teleNeuroTabs from 'features/consults/pages/tele-neuro/tabs';
import { ServiceTypeEnum } from 'utils/enums';
import { FlexBoxAlign, FlexBoxSpaceBetween } from 'styles/layout';
import CloseButton from 'shared/components/buttons/CloseButton/CloseButton';
import { consultErrors } from 'features/consults/utils/validators';

import { TabsContainer, TabHeader, TabContent, Locked } from './Tabs.style';
import IntegrationLink from './IntegrationLink';

const consultTabs = {
  [ServiceTypeEnum.NEURO]: neuroTabs,
  [ServiceTypeEnum.CLINIC]: clinicTabs,
  [ServiceTypeEnum.EEG]: eegTabs,
  [ServiceTypeEnum.PEDS_EEG]: eegTabs,
  [ServiceTypeEnum.TELE_NEURO]: teleNeuroTabs,
};

const getDefaultTab = (isClosed) =>
  isClosed ? CONSULT_TABS.SUMMARY : CONSULT_TABS.DEMOGRAPHICS;

const Tabs = ({ isClosed = false, consultId, onClose, consultType }) => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = React.useState(getDefaultTab(isClosed));
  const { smDown } = useBreakpoints();

  const { consultLock, currentConsult, currentConsultFacility } = useSelector(
    ({ consults }) => consults,
  );

  const fetchFacility = React.useCallback(() => {
    dispatch(
      consultsActions.getConsultFacility(
        currentConsult?.demographics?.facilityId,
      ),
    );
  }, [dispatch, currentConsult?.demographics?.facilityId]);

  useMountEffect(fetchFacility);

  useUpdateEffect(() => {
    fetchFacility();
  }, [fetchFacility]);

  const errors = React.useMemo(
    () =>
      consultLock.requested
        ? consultErrors(currentConsult, consultType, currentConsultFacility)
        : null,
    [
      currentConsult,
      consultType,
      consultLock.requested,
      currentConsultFacility,
    ],
  );

  useUpdateEffect(() => {
    setCurrentTab(getDefaultTab(isClosed));
  }, [consultId, isClosed]);

  const tabList = React.useMemo(() => consultTabs[consultType], [consultType]);

  const tabsData = React.useMemo(
    () =>
      isClosed && currentTab === CONSULT_TABS.SUMMARY
        ? [tabList[CONSULT_TABS.SUMMARY]]
        : Object.values(tabList),
    [tabList, isClosed, currentTab],
  );

  const tabsHeaders = React.useMemo(
    () =>
      tabsData.map(({ value, label }) => (
        <TabHeader
          key={uuid()}
          label={label}
          value={value}
          _selected={currentTab === value ? 'selected' : ''}
          _error={errors?.[value] ? 'error' : ''}
        />
      )),
    [currentTab, tabsData, errors],
  );

  return (
    <TabContext value={currentTab}>
      <FlexBoxSpaceBetween style={{ alignItems: 'flex-start' }}>
        <TabsContainer onChange={(_e, newTab) => setCurrentTab(newTab)}>
          {tabsHeaders}
        </TabsContainer>
        <FlexBoxAlign>
          {isClosed && <Locked />}
          <IntegrationLink
            consultId={consultId}
            consultType={consultType}
            isClosed={isClosed}
            currentConsult={currentConsult}
          />
          {!smDown && <CloseButton onClick={onClose} />}
        </FlexBoxAlign>
      </FlexBoxSpaceBetween>
      {tabsData.map(({ value, component: Component }) => (
        <TabContent key={value} value={value}>
          <SpeechButtonsProvider>
            <Component errors={errors?.[value]} />
          </SpeechButtonsProvider>
        </TabContent>
      ))}
    </TabContext>
  );
};

Tabs.propTypes = {
  isClosed: PropTypes.bool,
  consultId: PropTypes.string,
  onClose: PropTypes.func,
  consultType: PropTypes.string,
};

export default Tabs;
