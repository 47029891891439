import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';

import { useBreakpoints } from 'shared/hooks';

const GridContainer = styled(Grid)`
  ${({ _sm }) =>
    _sm === 'sm' &&
    `
      .MuiGrid-item {
        &:not(:first-of-type) {
          padding-top: 0;
        }

        &:not(:last-of-type) {
          padding-bottom: 0;
        }
      }
  `}
`;

const GridWrapper = ({ children, spacing = 6 }) => {
  const { smDown } = useBreakpoints();

  return (
    <GridContainer container spacing={spacing} _sm={smDown ? 'sm' : ''}>
      {children}
    </GridContainer>
  );
};

GridWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  spacing: PropTypes.number,
};

export default GridWrapper;
