import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ServiceTypeEnum } from 'utils/enums';

import ToDo from './ToDo';
import { neuroTodoList } from './data';

const ToDoList = ({ consult, consultType }) => {
  const toDoList = useMemo(() => {
    switch (consultType) {
      case ServiceTypeEnum.NEURO:
        return neuroTodoList;
      default:
        return null;
    }
  }, [consultType]);

  return (
    toDoList &&
    toDoList.map(({ text, isComplete }) => (
      <ToDo key={text} text={text} isCompleted={Boolean(isComplete(consult))} />
    ))
  );
};

ToDoList.propTypes = {
  consult: PropTypes.shape({}).isRequired,
  consultType: PropTypes.string.isRequired,
};

export default ToDoList;
