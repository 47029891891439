import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

const Wrapper = styled(Paper)`
  margin: 20px;
  padding: 8px 30px;
  width: ${({ width }) => width || '100%'};

  ${({ _sm }) =>
    _sm === 'sm' &&
    `
      margin: 20px 10px;
      padding: 8px 10px;
      overflow: hidden;
  `}
`;

const MainWrapper = ({ children, sm = false, ...props }) => (
  <Wrapper elevation={2} _sm={sm ? 'sm' : ''} {...props}>
    {children}
  </Wrapper>
);

MainWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  sm: PropTypes.bool,
};

export default MainWrapper;
