import React from 'react';
import PropTypes from 'prop-types';

import LinkIcon from '@mui/icons-material/Link';

import { IntegrationStatusEnum } from 'utils/enums';
import colors from 'styles/colors';

const IntegrationIcon = ({ integrationStatus }) => {
  if (
    !integrationStatus ||
    integrationStatus === IntegrationStatusEnum.NON_INTEGRATED
  )
    return null;
  return (
    <LinkIcon
      sx={{
        color:
          integrationStatus === IntegrationStatusEnum.MATCHED
            ? colors.success
            : colors.neutralDark,
      }}
    />
  );
};

IntegrationIcon.propTypes = {
  integrationStatus: PropTypes.string,
};

export default IntegrationIcon;
