import * as yup from 'yup';

export default yup.object().shape({
  pastMedicalHistory: yup.string().trim(),
  pastSurgicalHistory: yup.string().trim(),
  familyHistory: yup.string().trim(),
  socialHistory: yup.string().trim(),
  medications: yup.string().trim(),
  allergies: yup.string().trim(),
});
