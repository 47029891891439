import React from 'react';
import PropTypes from 'prop-types';

import { Label, Required } from './InputLabel.style';

const InputLabel = ({
  children,
  htmlFor,
  required = false,
  disabled = false,
  inline = false,
  ...props
}) => (
  <Label htmlFor={htmlFor} disabled={disabled} inline={inline} {...props}>
    {children}
    {required && <Required> *</Required>}
  </Label>
);

InputLabel.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
};

export default InputLabel;
