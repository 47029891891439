import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';

import { Box } from 'styles/layout';

const ErrorMsg = ({ text, dense = false, ...props }) => (
  <Box my={1} {...props}>
    <Alert sx={{ padding: `${dense ? '0' : '6px'} 16px` }} severity="error">
      {Array.isArray(text)
        ? text.map((msg) => <Box key={msg}>{msg}</Box>)
        : text}
    </Alert>
  </Box>
);

ErrorMsg.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  dense: PropTypes.bool,
};

export default ErrorMsg;
