export const SuccessActionTypesEnum = {
  VERIFICATION_CODE_SENT: 'VERIFICATION_CODE_SENT',
  PASSWORD_RESET: 'PASSWORD_RESET',
  PASSWORD_SET: 'PASSWORD_SET',
  USER_ADDED: 'USER_ADDED',
  USER_UPDATED: 'USER_UPDATED',
  PROFILE_UPDATED: 'PROFILE_UPDATED',
  CONSULT_CREATED: 'CONSULT_CREATED',
  CONSULT_REQUEST_ACCEPTED: 'CONSULT_REQUEST_ACCEPTED',
  FORCE_CONSULT_RELOAD: 'FORCE_CONSULT_RELOAD',
  CONSULT_REQUEST_DELETED: 'CONSULT_REQUEST_DELETED',
  UPDATED_ADMIN_CONSULT: 'UPDATED_ADMIN_CONSULT',
  ADDENDUM_ADDED: 'ADDENDUM_ADDED',
  MACRO_CREATED: 'MACRO_CREATED',
  MACRO_APPLIED: 'MACRO_APPLIED',
  ADMIN_USER_PASSWORD_RESET: 'ADMIN_USER_PASSWORD_RESET',
  ADMIN_USER_REINVITED: 'ADMIN_USER_REINVITED',
  ORDER_LINKED: 'ORDER_LINKED',
  ORDER_UNLINKED: 'ORDER_UNLINKED',
  ADMIN_ORDER_LINKED: 'ADMIN_ORDER_LINKED',
  ADMIN_ORDER_UNLINKED: 'ADMIN_ORDER_UNLINKED',
  LOGOUT_RESET: 'LOGOUT_RESET',
};

export default { SuccessActionTypesEnum };
