import React from 'react';
import PropTypes from 'prop-types';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultsListState from 'features/consults/hooks/useConsultsListState';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import SearchField from 'shared/components/forms/SearchField/SearchField';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import { smallConsultsTableColumns } from 'features/consults/components/ConsultsList/tableColumns';
import ConsultRequestList from 'features/consults/components/ConsultRequests/ConsultRequestList';
import { Box } from 'styles/layout';

const ConsultSidebarList = ({ consultType }) => {
  const {
    consults,
    page,
    pageCount,
    sortBy,
    sortOrder,
    filters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    navigateToConsult,
    handleRefresh,
  } = useConsultsListState(consultType);

  const { consult: selectedConsult } = useConsultData();
  const { register } = useFormState();

  const tableColumns = React.useMemo(
    () => smallConsultsTableColumns(consultType),
    [consultType],
  );

  const data = React.useMemo(
    () =>
      consults.map((consult) => {
        if (consult.id === selectedConsult.id) {
          const { firstName, lastName, facilityName } =
            selectedConsult[CONSULT_TABS.DEMOGRAPHICS];

          return {
            ...consult,
            patientFirstName: firstName,
            patientLastName: lastName,
            facilityName:
              facilityName !== undefined ? facilityName : consult.facilityName,
          };
        }

        return consult;
      }),
    [consults, selectedConsult],
  );

  return (
    <Box mt={2}>
      <ConsultRequestList
        consultType={consultType}
        handleRefresh={handleRefresh}
        sm
      />
      <Box mb={2.5}>
        <SearchField
          {...register('query')}
          defaultValue={filters.query}
          onChange={(value) => handleFilter({ query: value })}
        />
      </Box>
      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(data, tableColumns)}
        fallbackMsg="No consults found"
        sortBy={sortBy}
        sortOrder={sortOrder}
        currentPage={page}
        totalPages={pageCount}
        selectedRowId={selectedConsult.id}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
        onClickRow={(rowId) => {
          navigateToConsult(rowId, { replace: true });
        }}
      />
    </Box>
  );
};

ConsultSidebarList.propTypes = {
  consultType: PropTypes.string.isRequired,
};

export default ConsultSidebarList;
