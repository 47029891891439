import styled from '@emotion/styled';
import { Box, FlexBoxAlignWrap } from 'styles/layout';

export const FiltersWrapper = styled(FlexBoxAlignWrap)``;

export const FilterElement = styled(Box)`
  width: 220px;
  margin-bottom: 8px;
  margin-right: 8px;
`;
