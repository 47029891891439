import React from 'react';
import PropTypes from 'prop-types';

import {
  ServiceTypeEnum,
  ServiceTypeLabels,
  NeuroConsultLabels,
  EegConsultLabels,
} from 'utils/enums';
import { patientLocationLabels } from 'features/consults/shared/tabs/Demographics/formFields';

import { PatientName, RequestDetails } from './ConsultRequestDetails.style';

const NeuroConsultDetails = ({
  neuroConsultType,
  neuroCameraName,
  directBeamInRequested,
}) => (
  <>
    <RequestDetails>{`Consult Type: ${NeuroConsultLabels[neuroConsultType]}`}</RequestDetails>
    <RequestDetails>{`Camera Name: ${neuroCameraName || ''}`}</RequestDetails>
    {directBeamInRequested && (
      <RequestDetails>Direct Beam-In Requested</RequestDetails>
    )}
  </>
);

NeuroConsultDetails.propTypes = {
  neuroConsultType: PropTypes.string,
  neuroCameraName: PropTypes.string,
  directBeamInRequested: PropTypes.bool,
};

const ConsultRequestTypeDetails = ({
  consultRequest: {
    firstName,
    lastName,
    age,
    type,
    statReadRequested,
    eegReadConsultType,
    notes,
    patientLocation,
    facilityName,
    ...rest
  },
}) => (
  <>
    <PatientName>{`Patient: ${firstName} ${lastName}`}</PatientName>
    {age && <RequestDetails>{`Age: ${age}`}</RequestDetails>}
    <RequestDetails>{`Facility: ${facilityName}`}</RequestDetails>
    <RequestDetails>{`Consult type: ${ServiceTypeLabels[type]}`}</RequestDetails>
    {eegReadConsultType && (
      <RequestDetails>{`EEG Type: ${EegConsultLabels[eegReadConsultType]}`}</RequestDetails>
    )}
    {statReadRequested && <RequestDetails>STAT Read Requested</RequestDetails>}
    {type.toLowerCase() === ServiceTypeEnum.NEURO && (
      <NeuroConsultDetails {...rest} />
    )}
    {!!patientLocation && (
      <RequestDetails>{`Patient Location: ${patientLocationLabels[patientLocation]}`}</RequestDetails>
    )}
    {!!notes && <RequestDetails>{`Notes: ${notes}`}</RequestDetails>}
  </>
);

ConsultRequestTypeDetails.propTypes = {
  consultRequest: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    eegReadConsultType: PropTypes.string,
    age: PropTypes.number,
    type: PropTypes.string,
    statReadRequested: PropTypes.bool,
    notes: PropTypes.string,
    patientLocation: PropTypes.string,
    facilityName: PropTypes.string,
  }).isRequired,
};

export default ConsultRequestTypeDetails;
