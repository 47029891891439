import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { LOCKED_ERROR_CODE } from 'utils/constants';

export const SLICE_PREFIX = 'consults';
export const UPDATE_CONSULT_ACTION = `${SLICE_PREFIX}/editConsult`;

export const listConsults = createAsyncThunk(
  `${SLICE_PREFIX}/listConsults`,
  async (
    { consultType, page, sortBy, sortOrder, filters },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.getConsults(consultType, {
        page,
        sortBy,
        sortOrder,
        filters,
      });

      return {
        consultType,
        data: data.consults,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getConsultById = createAsyncThunk(
  `${SLICE_PREFIX}/getConsultById`,
  async ({ consultId, forceReload }, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsultById(consultId);

      return { id: String(consultId), ...data.consult, forceReload };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getConsultFacility = createAsyncThunk(
  `${SLICE_PREFIX}/getConsultFacility`,
  async (facilityId, { rejectWithValue }) => {
    try {
      if (!facilityId) return null;
      const { data } = await api.getFacilityById(facilityId);
      return data.facility;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addNewConsult = createAsyncThunk(
  `${SLICE_PREFIX}/addNewConsult`,
  async (consultType, { rejectWithValue }) => {
    try {
      const { data } = await api.createConsult({
        type: consultType,
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editConsult = createAsyncThunk(
  UPDATE_CONSULT_ACTION,
  async ({ consultId, tab, changes, cb }, { rejectWithValue }) => {
    try {
      const { data } = await api.updateConsult(consultId, tab, changes);
      if (cb) cb();
      return data;
    } catch (err) {
      return rejectWithValue(
        err.response.status === LOCKED_ERROR_CODE
          ? { title: err.response.data }
          : err.response.data,
      );
    }
  },
);

export const updateConsultOwner = createAsyncThunk(
  `${SLICE_PREFIX}/updateConsultOwner`,
  async ({ consultId, ownerId }, { rejectWithValue }) => {
    try {
      await api.updateConsultOwner(consultId, ownerId);
      return ownerId;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const lockConsult = createAsyncThunk(
  `${SLICE_PREFIX}/lockConsult`,
  async (
    { consultId, lockById, lockAt, lockAction, summary, summaryFormatted },
    { rejectWithValue },
  ) => {
    try {
      await api.lockConsult(consultId, { summary, summaryFormatted });
      return { lockById, lockAt, lockAction, summary, summaryFormatted };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSummary = createAsyncThunk(
  `${SLICE_PREFIX}/updateSummary`,
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await api.updateConsultSummary(values);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getConsultCount = createAsyncThunk(
  `${SLICE_PREFIX}/getConsultCount`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsultCount();
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editConsultFlag = createAsyncThunk(
  `${SLICE_PREFIX}/editConsultFlag`,
  async ({ consultId, color }, { rejectWithValue }) => {
    try {
      const { data } = await api.setConsultFlag(consultId, { color });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const listMacros = createAsyncThunk(
  `${SLICE_PREFIX}/listMacros`,
  async (
    { page, sortBy, sortOrder, pageSize, filters },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.getMacros({
        page,
        sortBy,
        sortOrder,
        pageSize,
        filters,
      });

      return {
        data: data.macros,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const listSharedMacros = createAsyncThunk(
  `${SLICE_PREFIX}/listSharedMacros`,
  async (
    { page, sortBy, sortOrder, pageSize, filters },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.getMacros({
        page,
        sortBy,
        sortOrder,
        pageSize,
        filters,
      });

      return {
        data: data.macros,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addNewMacro = createAsyncThunk(
  `${SLICE_PREFIX}/addNewMacro`,
  async ({ consultId, name, type, macroType, ...rest }, { rejectWithValue }) => {
    try {
      const { data } = await api.createMacro({
        consultId,
        name,
        macroType,
        type,
      });
      return { id: data.id, name, macroType, ...rest };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMacroById = createAsyncThunk(
  `${SLICE_PREFIX}/getMacroById`,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await api.getMacroById(id);
      return { id: String(id), ...data.consultMacro };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const applyMacro = createAsyncThunk(
  `${SLICE_PREFIX}/applyMacro`,
  async ({ consultId, consultMacroId }, { rejectWithValue }) => {
    try {
      const { data } = await api.applyMacro({ consultId, consultMacroId });

      return { id: consultId, ...data.consult };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateMacro = createAsyncThunk(
  `${SLICE_PREFIX}/updateMacro`,
  async ({ macroId, tab, changes }, { rejectWithValue }) => {
    try {
      await api.updateMacro({ macroId, tab, changes });
      return {};
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const linkOrder = createAsyncThunk(
  `${SLICE_PREFIX}/linkOrder`,
  async ({ hl7OrderId, consultId, consultType }, { rejectWithValue }) => {
    try {
      const { data } = await api.linkOrder({ hl7OrderId, consultId });
      return { ...data, consultId, consultType };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const unlinkOrder = createAsyncThunk(
  `${SLICE_PREFIX}/unlinkOrder`,
  async ({ consultId, consultType }, { rejectWithValue }) => {
    try {
      const { data } = await api.unlinkOrder({ consultId });
      return { ...data, consultId, consultType };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const doGetConsultRequests = createAsyncThunk(
  `${SLICE_PREFIX}/doGetConsultRequests`,
  async ({ consultType, isAutoRefresh }, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsultRequestsNotifications({
        consultType,
        isAutoRefresh,
      });
      return { consultType, data: data.data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const doGetConsultRequestCount = createAsyncThunk(
  `${SLICE_PREFIX}/doGetConsultRequestCount`,
  async (isAutoRefresh, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsultRequestsCount(isAutoRefresh);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addIcd = createAsyncThunk(
  `${SLICE_PREFIX}/addIcd`,
  async ({ isMacro, ...icdData }, { rejectWithValue }) => {
    try {
      const addFunc = isMacro ? api.addMacroIcd : api.addConsultIcd;
      const { data } = await addFunc(icdData);
      return { ...icdData, id: data };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteIcd = createAsyncThunk(
  `${SLICE_PREFIX}/deleteIcd`,
  async ({ isMacro, id }, { rejectWithValue }) => {
    try {
      const deleteFunc = isMacro ? api.deleteMacroIcd : api.deleteConsultIcd;
      await deleteFunc(id);
      return { icdId: id };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
