import React from 'react';
import { Navigate, NavLink, Routes, Route } from 'react-router-dom';

import { useBreakpoints } from 'shared/hooks';

import styled from '@emotion/styled';
import colors from 'styles/colors';

import Paths from 'navigation/paths';

import { MacroTypes } from 'utils/enums';

import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import Sidebar from 'shared/components/layout/Sidebar/Sidebar';
import RestrictedPage from 'auth/pages/RestrictedPage/RestrictedPage';

import MacrosListPage from './pages/MacrosListPage';
import MacroUpdatePage from './pages/MacroUpdatePage';

import MacrosPaths from './paths';

const SidebarItem = styled(NavLink)`
  margin: 10px 0;
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.secondary};

  &:hover {
    background: ${colors.background};
  }

  &.active {
    color: ${colors.white};
    background: ${colors.secondary};
  }
`;

const availableRoutes = {
  [MacrosPaths.SharedMacros]: {
    label: 'Shared Macros',
    element: <MacrosListPage macroType={MacroTypes.SHARED} />,
  },
  [MacrosPaths.Macros]: {
    label: 'My Macros',
    element: <MacrosListPage macroType={MacroTypes.PERSONAL} />,
  },
};

const homeRoute = Object.keys(availableRoutes)[0];

const Macros = () => {
  const { smDown } = useBreakpoints();
  return(
    <>
      <Sidebar>
        {Object.entries(availableRoutes).map(([path, { label }]) => (
          <SidebarItem
            key={path}
            to={`/${Paths.Macros}/${path}`}
            replace
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {label}
          </SidebarItem>
        ))}
      </Sidebar>
      <MainWrapper sm={smDown}>
        <Routes>
          {Object.entries(availableRoutes).map(([path, { element }]) => (
            <Route key={path} path={`${path}/*`} element={element} />
          ))}
          <Route path="/" element={<Navigate to={homeRoute} />} />
          <Route path="/shared/:macroId" element={<MacroUpdatePage />} />
          <Route path="/macros/:macroId" element={<MacroUpdatePage />} />
          <Route path="*" element={<RestrictedPage />} />
        </Routes>
      </MainWrapper>
    </>
  );
};

export default Macros;
