import React from 'react';
import { Routes, Route } from 'react-router-dom';

import RestrictedPage from 'auth/pages/RestrictedPage/RestrictedPage';
import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import { useBreakpoints } from 'shared/hooks';

import ConsultRequestListPage from './pages/ConsultRequestListPage/ConsultRequestListPage';
import NewConsultRequestPage from './pages/NewConsultRequestPage/NewConsultRequestPage';

const CustomerDashboard = () => {
  const { smDown } = useBreakpoints();

  return (
    <MainWrapper sm={smDown}>
      <Routes>
        <Route path="/" element={<ConsultRequestListPage />} />
        <Route
          path="/new-consult-request"
          element={<NewConsultRequestPage />}
        />
        <Route path="*" element={<RestrictedPage />} />
      </Routes>
    </MainWrapper>
  );
};

export default CustomerDashboard;
