import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Grid } from '@mui/material';

import { RESET_OPTION } from 'features/consults/utils/constants';
import { YES, NO } from 'utils/constants';
import InputField from 'shared/components/forms/InputField/InputField';
import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import { Box } from 'styles/layout';

import { SectionHeader } from './index.styles';
import { getValueToPositive } from './utils';

const ScoreGroup = ({ group, form, header, disabled, isPositive, onSave }) => {
  const calculatePositive = React.useCallback(() => {
    const groupValues = form.getValues();

    const mainValue = groupValues[group.fields[0].name];
    if (!mainValue || mainValue === RESET_OPTION.value) return null;
    if (mainValue === NO) return 0;

    if (group.fields.slice(1).every((field) => groupValues[field.name] === NO))
      return 0;

    if (group.fields.slice(1).some((field) => groupValues[field.name] === YES))
      return 1;

    return null;
  }, [form, group]);

  const calculatePositiveTotal = React.useCallback(
    (fieldName, fieldValue) => {
      const positiveTotal = calculatePositive();
      form.setValue(group.name, getValueToPositive(positiveTotal));
      onSave({
        [fieldName]: fieldValue,
        [group.name]: positiveTotal,
      });
    },
    [calculatePositive, form, group, onSave],
  );

  const calculateTotal = React.useCallback(
    (fieldName) => {
      const groupValues = form.getValues();

      const total = group.fields.reduce((res, field) => {
        const fieldValue = groupValues[field.name];
        if (!fieldValue || fieldValue === RESET_OPTION.value) return res;

        const fieldCount = field.options.find(
          (opt) => opt.value === fieldValue,
        )?.severity;

        return res ? res + fieldCount : fieldCount;
      }, '');

      form.setValue(group.name, total);

      onSave({
        [fieldName]: groupValues[fieldName],
        [group.name]: total,
      });
    },
    [form, group, onSave],
  );

  return (
    <Grid item xs={12}>
      {!!header && <SectionHeader>{header}</SectionHeader>}
      {group.fields.map((field) => (
        <Box key={uuid()} mb={1}>
          {field.type === 'title' ? (
            <div>{field.title}</div>
          ) : (
            <ToggleButtonGroup
              control={form.control}
              name={field.name}
              title={field.title}
              buttons={field.options}
              inlineLabel={field.inlineLabel || isPositive}
              compact
              handleChange={(value) =>
                isPositive
                  ? calculatePositiveTotal(field.name, value)
                  : calculateTotal(field.name)
              }
              disabled={disabled}
            />
          )}
        </Box>
      ))}
      <Box mb={3}>
        <InputField
          {...form.register(group.name, {
            disabled: true,
          })}
          type="text"
          label={group.label}
          inlineLabel
          error={form.getFieldError(group.name)}
        />
      </Box>
    </Grid>
  );
};

ScoreGroup.propTypes = {
  onSave: PropTypes.func.isRequired,
  group: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  form: PropTypes.shape({
    control: PropTypes.shape({}),
    register: PropTypes.func,
    getFieldError: PropTypes.func,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
  }).isRequired,
  header: PropTypes.string,
  disabled: PropTypes.bool,
  isPositive: PropTypes.bool,
};

export default ScoreGroup;
