import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';

const TableResultCount = ({ totalCount }) => (
  <Chip
    label={`${Number.isInteger(totalCount) ? totalCount : 'loading'} results`}
  />
);

TableResultCount.propTypes = {
  totalCount: PropTypes.number,
};

export default TableResultCount;
