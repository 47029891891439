import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import { KeyboardArrowDown } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';

import colors from 'styles/colors';

export const DropdownIcon = styled(KeyboardArrowDown)`
  cursor: pointer;
`;

export const ConsultOption = styled(MenuItem)`
  text-transform: uppercase;
  color: ${colors.primary};
  margin: 5px 0;

  &:hover {
    background: ${alpha(colors.primary, 0.3)};
  }
`;
