import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import api from 'api';
import { useRequest } from 'shared/hooks';
import { consultsActions } from 'features/consults/store/slice';
import { adminActions } from 'features/admin/store/slice';
import { mapDoctorNames } from 'utils/mappers';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { TIMEZONES } from 'utils/timezones';
import { ConsultType2RoleEnum } from 'utils/enums';

import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';

const ConsultContext = React.createContext({});

export const ConsultProvider = ({ consultId, isAdmin, isMacro, children }) => {
  const dispatch = useDispatch();
  const currentConsult = useSelector((state) =>
    isAdmin ? state.admin.consult : state.consults.currentConsult,
  );

  const {
    doRequest: fetchProviders,
    data: providerData,
    setData: setProviderData,
  } = useRequest(api.getUserNames);
  const {
    doRequest: fetchFacilities,
    data: facilityData,
    setData: setFacilityData,
  } = useRequest(api.getFacilityNames);

  React.useEffect(() => {
    setFacilityData(null);
    setProviderData(null);
    if (isMacro) {
      dispatch(consultsActions.getMacroById(consultId));
    } else if (isAdmin) {
      dispatch(adminActions.fetchConsult(consultId));
    } else {
      dispatch(consultsActions.getConsultById({ consultId }));
    }
  }, [dispatch, consultId, setFacilityData, setProviderData, isMacro, isAdmin]);

  const consult = React.useMemo(
    () => (currentConsult.id === consultId ? currentConsult : null),
    [currentConsult, consultId],
  );

  React.useEffect(() => {
    if (!consult?.type) return;
    fetchFacilities({
      NetworkType: consult?.type,
      pageSize: MAX_PAGE_SIZE,
    });
    fetchProviders({
      roles: [ConsultType2RoleEnum[consult?.type]],
      pageSize: MAX_PAGE_SIZE,
    });
  }, [consult?.type, fetchFacilities, fetchProviders]);

  const consultFacilities = React.useMemo(
    () => facilityData?.facilities || [],
    [facilityData],
  );

  const consultFacility = React.useMemo(
    () =>
      consultFacilities.find(
        ({ id }) => id === consult?.demographics?.facilityId,
      ),
    [consult?.demographics?.facilityId, consultFacilities],
  );

  const isLoaded = React.useMemo(
    () => !!(consult && facilityData && providerData),
    [consult, facilityData, providerData],
  );

  const consultProviders = React.useMemo(
    () => mapDoctorNames(providerData?.users || []),
    [providerData?.users],
  );

  const value = React.useMemo(
    () => ({
      consult,
      consultFacilities,
      consultFacility,
      consultTimezone: consultFacility?.timezone || TIMEZONES.UTC,
      consultFaxNumber: consultFacility?.faxNumber,
      consultProviders,
    }),
    [consult, consultFacilities, consultFacility, consultProviders],
  );

  return (
    <ConsultContext.Provider value={value}>
      {isLoaded ? children : <LoadingSpinner />}
    </ConsultContext.Provider>
  );
};

ConsultProvider.propTypes = {
  consultId: PropTypes.string.isRequired,
  children: PropTypes.node,
  isAdmin: PropTypes.bool,
  isMacro: PropTypes.bool,
};

export const useConsult = () => React.useContext(ConsultContext);
