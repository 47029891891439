import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MaterialBox from '@mui/material/Box';

export const alignCenter = css`
  align-items: center;
`;

export const justifyCenter = css`
  justify-content: center;
`;

export const flexCenter = css`
  ${alignCenter}
  ${justifyCenter}
`;

export const Box = styled(MaterialBox)``;

export const FlexBox = styled(Box)`
  display: flex;
`;

export const FlexBoxWrap = styled(FlexBox)`
  flex-wrap: wrap;
`;

export const FlexBoxColumn = styled(FlexBox)`
  flex-direction: column;
`;

export const FlexBoxFull = styled(FlexBox)`
  flex: 1;
`;

export const FlexBoxColumnFull = styled(FlexBoxColumn)`
  flex: 1;
`;

export const FlexBoxColumnTune = styled(FlexBoxColumn)`
  flex: ${(props) => props.size};
`;

export const FlexBoxAlign = styled(FlexBox)`
  ${alignCenter}
`;

export const FlexBoxAlignReverse = styled(FlexBox)`
  flex-direction: row-reverse;
`;

export const FlexBoxAlignWrap = styled(FlexBoxAlign)`
  flex-wrap: wrap;
`;

export const FlexBoxJustify = styled(FlexBox)`
  ${justifyCenter}
`;

export const FlexBoxSpaceBetween = styled(FlexBox)`
  justify-content: space-between;
`;

export const FlexBoxCenter = styled(FlexBox)`
  ${flexCenter}
`;

export const FlexBoxColumnAlign = styled(FlexBoxColumn)`
  ${alignCenter}
`;

export const FlexBoxColumnJustify = styled(FlexBoxColumn)`
  ${justifyCenter}
`;

export const FlexBoxColumnSpaceBetween = styled(FlexBoxColumn)`
  justify-content: space-between;
`;

export const FlexBoxColumnCenter = styled(FlexBoxColumn)`
  ${flexCenter}
`;

export const FlexBoxSpaceBetweenCenter = styled(FlexBoxSpaceBetween)`
  ${alignCenter}
`;

export const FullPageWrapper = styled(FlexBox)`
  width: 100%;
  min-height: 100vh;
`;

export const FullPageColumnWrapper = styled(FullPageWrapper)`
  flex-direction: column;
`;

export const FullPageWrapperCenter = styled(FullPageWrapper)`
  ${flexCenter};
`;

export const HiddenWrapper = styled(Box)`
  position: absolute;
  top: -999999px;
  left: -999999px;
`;
