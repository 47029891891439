import React from 'react';
import { Grid } from '@mui/material';

import { useUpdateEffect, useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { getInitialValueByFieldType } from 'features/consults/utils/formFields';

import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import MRSPanel from 'features/consults/shared/tabs/Plan/MRSPanel';

import formFields, {
  thrombolyticValues,
  doseValues,
} from 'features/consults/shared/tabs/Plan/formFields';

import schema from './validationSchema';
import {
  thrombolyticElement,
  bloodPressureElements,
  thrombolyticElements,
  rightElements,
} from './data';

const Plan = () => {
  const {
    control,
    register,
    getFieldError,
    getValues,
    setFormValues,
    watch,
    setValue,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultFacility,
    consultTabData,
    isEditable,
    isMacro,
    handleFieldChange,
    saveConsultChange,
  } = useConsultData(CONSULT_TABS.PLAN, getValues);

  const watchedValues = watch();
  const currentThrombolyticValue =
    watchedValues[formFields.thrombolyticInterventionPredefined.name];

  const thrombolyticSection = thrombolyticElements[currentThrombolyticValue];

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  const defaultDose = React.useMemo(
    () => doseValues[consultFacility?.thrombolytic],
    [consultFacility?.thrombolytic],
  );

  const updateDosing = React.useCallback(() => {
    const values = getValues();

    if (
      !defaultDose ||
      values.dose !== null ||
      ![
        thrombolyticValues.IVThrombolytic,
        thrombolyticValues.IVAndIAIntervention,
        thrombolyticValues.IVThrombolyticPossibleIACandidate,
      ].includes(currentThrombolyticValue)
    )
      return;

    setValue(formFields.dose.name, defaultDose);
    handleFieldChange(formFields.dose.name);
  }, [
    currentThrombolyticValue,
    defaultDose,
    getValues,
    setValue,
    handleFieldChange,
  ]);

  useUpdateEffect(() => {
    updateDosing();
  }, [updateDosing]);

  const handleThrombolyticChange = React.useCallback(
    (fieldName) => {
      const newThrombolyticValue = getValues(fieldName);

      // If no section was previously visible, do NOT reset anything
      if (!newThrombolyticValue) {
        saveConsultChange({
          [fieldName]: newThrombolyticValue,
        });
        return;
      }

      const newFields =
        thrombolyticElements[newThrombolyticValue]?.map(({ name }) => name) ??
        [];

      // Get all fields from the previously visible section
      // and reset them to their initial values based on type
      const resetValues = Object.values(thrombolyticSection ?? {}).reduce(
        (res, { name, type }) =>
          newFields.includes(name)
            ? res
            : { ...res, [name]: getInitialValueByFieldType(type) },
        {},
      );

      const resetValuesFixed = Object.keys(resetValues).includes(
        formFields.dose.name,
      )
        ? resetValues
        : {
            ...resetValues,
            [formFields.dose.name]: getValues(formFields.dose.name),
          };

      // Reset the form values of the section fields
      setFormValues({
        ...watchedValues,
        ...resetValuesFixed,
        [fieldName]: newThrombolyticValue,
      });

      saveConsultChange({
        ...resetValuesFixed,
        [fieldName]: newThrombolyticValue,
      });
    },
    [
      getValues,
      saveConsultChange,
      setFormValues,
      thrombolyticSection,
      watchedValues,
    ],
  );

  return (
    <GridWrapper>
      <Grid item sm={12} lg={6}>
        <QuestionsPanel
          control={control}
          register={register}
          onChange={handleThrombolyticChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
          elements={thrombolyticElement}
        />
        <QuestionsPanel
          control={control}
          register={register}
          onChange={handleFieldChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
          elements={thrombolyticSection}
          disableElements={
            isMacro
              ? [
                  formFields.timeIAInterventionRecommended.name,
                  formFields.timeIVThrombolyticRecommendedMountainTime.name,
                ]
              : []
          }
        />
        <MRSPanel
          form={{
            control,
            register,
            setValue,
            getValues,
            getFieldError,
          }}
          saveConsultChange={saveConsultChange}
          disabled={!isEditable}
        />
        <QuestionsPanel
          control={control}
          register={register}
          onChange={handleFieldChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
          elements={bloodPressureElements}
        />
      </Grid>
      <Grid item sm={12} lg={6}>
        <QuestionsPanel
          control={control}
          register={register}
          onChange={handleFieldChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
          elements={rightElements}
        />
      </Grid>
    </GridWrapper>
  );
};

export default Plan;
