import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { renderToString } from 'react-dom/server';

import { Grid, Stack } from '@mui/material';

import { useProfile, useMountEffect } from 'shared/hooks';
import useSummaryCopy from 'features/consults/hooks/useSummaryCopy';
import { consultsActions } from 'features/consults/store/slice';
import {
  extractSignature,
  buildNewSignature,
} from 'features/consults/shared/tabs/Summary/utils';
import { useConsult } from 'shared/context/ConsultContext';
import { ServiceTypeEnum, ConsultStates } from 'utils/enums';

import Button from 'shared/components/buttons/Button/Button';
import SummaryNote from 'features/consults/components/SummaryNote/SummaryNote';
import { Box, FlexBoxSpaceBetween } from 'styles/layout';

import AddendumFax from './components/AddendumFax';
import ConsultCopyButton from './components/ConsultCopyButton';
import QualityForm from './components/QualityForm';
import useAddendumState from './hooks/useAddendumState';
import { CopyIcon } from './Summary.style';

export const ConsultSummary = ({ isDoctor, onUpdateSummary }) => {
  const { consult, consultTimezone, consultProviders } = useConsult();
  const summaryRef = React.useRef(null);
  const { summaryContainerRef, copyNote } = useSummaryCopy();
  const { fetchAddenda, addenda, onAddAddendum } = useAddendumState(consult.id);
  const { profileData } = useProfile();

  const isClosed = React.useMemo(
    () => consult?.signature?.state === ConsultStates.CLOSED,
    [consult?.signature?.state],
  );

  React.useEffect(() => {
    if (isClosed) fetchAddenda();
  }, [consult.id, isClosed, fetchAddenda]);

  const showQuality = React.useMemo(
    () => isClosed && consult.type !== ServiceTypeEnum.CLINIC,
    [isClosed, consult.type],
  );

  useMountEffect(() => {
    if (!isClosed || consult.summaryFormatted || !onUpdateSummary) return;

    const signature =
      extractSignature(consult.summary) ||
      buildNewSignature({
        signatureData: consult.signature,
        consultProviders,
        consultTimezone,
      }).trim();

    const eSignature = (
      <div
        style={{ marginTop: '10px' }}
      >{`Electronically signed at ${signature}`}</div>
    );

    const { innerText, innerHTML } = summaryRef.current;
    onUpdateSummary({
      summary: consult.summary || `${innerText}${eSignature.props.children}`,
      summaryFormatted: `${innerHTML}${renderToString(eSignature)}`,
    });
  });

  return (
    <Box>
      <Stack spacing={2}>
        <Box>
          <Button variant="contained" color="secondary" onClick={copyNote}>
            <span>Copy</span>
            <CopyIcon />
          </Button>
        </Box>
        {isClosed && (
          <FlexBoxSpaceBetween>
            <AddendumFax onAddAddendum={onAddAddendum} />
            {isDoctor && <ConsultCopyButton consult={consult} />}
          </FlexBoxSpaceBetween>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} md={showQuality ? 7 : 12}>
          <SummaryNote
            copyRef={summaryContainerRef}
            ref={summaryRef}
            addenda={addenda}
            isClosed={isClosed}
          />
        </Grid>
        {showQuality && (
          <Grid item xs={12} md={5}>
            <Box mt={2}>
              <QualityForm
                consult={consult}
                disabled={
                  !isDoctor || consult?.signature?.closedById !== profileData.id
                }
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

ConsultSummary.propTypes = {
  isDoctor: PropTypes.bool,
  onUpdateSummary: PropTypes.func,
};

export default () => {
  const dispatch = useDispatch();
  const { consult } = useConsult();

  const onUpdateSummary = async (values) => {
    dispatch(
      consultsActions.updateSummary({
        ...values,
        consultId: consult.id,
      }),
    );
  };

  return <ConsultSummary isDoctor onUpdateSummary={onUpdateSummary} />;
};
