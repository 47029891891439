import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import api from 'api';
import sortFields from 'api/sortFields';
import {
  useMountEffect,
  useUpdateEffect,
  useRequest,
  useFilterState,
  useTableState,
} from 'shared/hooks';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import {
  BillingStatusEnum,
  ServiceTypeEnum,
  ServiceTypeLabels,
  ServiceShortEnum,
  NeuroConsultLabels,
  TeleNeuroConsultLabels,
  EegConsultLabels,
} from 'utils/enums';
import { US_DATE_FORMAT, TIME_FORMAT, formatToUserTimezone } from 'utils/dates';

import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import TableResultCount from 'shared/components/data/Table/TableResultCount';
import IntegrationIcon from 'shared/components/icons/IntegrationIcon';
import BillingIcon from 'shared/components/icons/BillingIcon';
import SentIcon from 'shared/components/icons/SentIcon';

import {
  FlexBoxColumn,
  FlexBoxSpaceBetween,
  FlexBoxSpaceBetweenCenter,
} from 'styles/layout';

import BillingUploadButton from './BillingUploadButton';
import BillingFilters, { filterFields } from './BillingFilters';
import BillingDashboardCSVDownload from './BillingDashboardCSVDownload';

const tableColumns = [
  {
    header: {
      id: sortFields.consults.facilityName,
      value: 'Facility',
      isSortable: true,
    },
    dataKey: 'facilityName',
  },
  {
    header: {
      id: sortFields.consults.firstName,
      value: 'First Name',
      isSortable: true,
    },
    dataKey: 'patientFirstName',
    width: '15%',
  },
  {
    header: {
      id: sortFields.consults.lastName,
      value: 'Last Name',
      isSortable: true,
    },
    dataKey: 'patientLastName',
    width: '15%',
  },
  {
    header: {
      id: 'Type',
      value: 'Service',
      isSortable: true,
    },
    formatValue: ({ consultType }) => ServiceTypeLabels[consultType],
    width: '10%',
  },
  {
    header: {
      id: 'demographicConsultType',
      value: 'Consult Type',
    },
    formatValue: ({ consultType, demographicConsultType }) => {
      switch (consultType) {
        case ServiceTypeEnum.NEURO:
          return NeuroConsultLabels[demographicConsultType];
        case ServiceTypeEnum.TELE_NEURO:
          return TeleNeuroConsultLabels[demographicConsultType];
        case ServiceTypeEnum.EEG:
        case ServiceTypeEnum.PEDS_EEG:
          return EegConsultLabels[demographicConsultType];
        default:
          return demographicConsultType;
      }
    },
    width: '15%',
  },
  {
    header: {
      id: sortFields.consults.createdAt,
      value: 'Date/Time',
      isSortable: true,
    },
    formatValue: (item) =>
      formatToUserTimezone(
        new Date(item.createdAt),
        `${US_DATE_FORMAT} - ${TIME_FORMAT}`,
      ),
  },
  {
    header: { id: 'integration', value: '' },
    formatValue: ({ integrationStatus }) => (
      <IntegrationIcon integrationStatus={integrationStatus} />
    ),
    width: '3.5rem',
  },
  {
    header: { id: 'billing', value: '' },
    formatValue: ({ billingStatus }) => (
      <BillingIcon
        isOn={[
          BillingStatusEnum.hasBillingInfo,
          BillingStatusEnum.sent,
        ].includes(billingStatus)}
      />
    ),
    width: '3.5rem',
  },
  {
    header: { id: 'billing2', value: '' },
    formatValue: ({ billingStatus }) => (
      <SentIcon isOn={billingStatus === BillingStatusEnum.sent} />
    ),
    width: '3.5rem',
  },
];

const BillingDashboard = ({ isChild }) => {
  const navigate = useNavigate();
  const { doRequest: getList, data: listData } = useRequest(api.getConsults);
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setPage,
    setQueryParams,
  } = useTableState(sortFields.consults.createdAt, 'desc');
  const { filters, handleFilter } = useFilterState(
    Object.values(filterFields).reduce(
      (res, { name }) => ({ ...res, [name]: null }),
      { query: '' },
    ),
    setPage,
  );
  const fecthList = React.useCallback(() => {
    getList(filters.consultType, {
      page,
      sortBy,
      sortOrder,
      filters: {
        IsBilled: true,
        ...filters,
      },
    });
    if (!isChild) {
      setQueryParams({
        ...filters,
        page,
        sortBy,
        sortOrder,
      });
    }
  }, [getList, page, sortOrder, sortBy, filters, isChild, setQueryParams]);

  useMountEffect(fecthList);

  useUpdateEffect(() => {
    fecthList();
  }, [fecthList]);

  const onRowClick = React.useCallback(
    (rowId) => {
      const item = listData?.consults.find(({ id }) => id === rowId);
      navigate(`${ServiceShortEnum[item.consultType]}/${rowId}`);
    },
    [navigate, listData?.consults],
  );

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter>
        <PageTitle>Billing</PageTitle>
        <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
          <BillingUploadButton />
          <BillingDashboardCSVDownload
            filters={filters}
            sort={{ sortBy, sortOrder }}
          />
          <TableResultCount totalCount={listData?.totalCount} />
        </Stack>
      </FlexBoxSpaceBetweenCenter>
      <FlexBoxSpaceBetween>
        <BillingFilters filters={filters} handleFilter={handleFilter} />
      </FlexBoxSpaceBetween>
      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(listData?.consults ?? [], tableColumns)}
        fallbackMsg="No Data"
        sortBy={sortBy}
        sortOrder={sortOrder}
        currentPage={page}
        totalPages={listData?.pageCount ?? 0}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
        onClickRow={onRowClick}
      />
    </FlexBoxColumn>
  );
};

BillingDashboard.propTypes = {
  isChild: PropTypes.bool,
};

export default BillingDashboard;
