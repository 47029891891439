import styled from '@emotion/styled';
import { Chip as MaterialChip } from '@mui/material';

import colors from 'styles/colors';
import { MultiChipStatesEnum } from './MultiChipStates.enum';

const ChipEnum = Object.entries(MultiChipStatesEnum).reduce(
  (res, [key, value]) => ({ ...res, [key]: String(value) }),
  {},
);

const chipBackground = (chipState) => {
  switch (chipState) {
    case ChipEnum.YES:
      return colors.primary;
    case ChipEnum.NO:
      return colors.notification;
    default:
      return colors.white;
  }
};

export const ChipContainer = styled.label`
  margin-right: 5px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const FormChip = styled(MaterialChip)`
  background: ${({ _state }) => `${chipBackground(_state)} !important`};
  color: ${({ _state }) =>
    _state === ChipEnum.UNKNOWN ? colors.dark : colors.white};
  ${({ _state }) => _state === ChipEnum.NO && 'text-decoration: line-through'};
  & svg {
    color: ${({ _state }) =>
      _state === ChipEnum.UNKNOWN ? colors.dark : colors.white} !important;
  }
  @media (hover: hover) {
    &:hover {
      ${({ _state }) =>
        _state === ChipEnum.UNKNOWN &&
        `background-color: ${colors.secondary} !important;`}
    }
  }
`;
