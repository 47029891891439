import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';

import {
  ConsultFieldTypeEnum,
  ConsultAttestationEnum,
  ConsultAttestationLabels,
} from 'utils/enums';

export const attestationOfNeuroOptions = [
  ConsultAttestationEnum.videoConsultEmr,
  ConsultAttestationEnum.videoConsultTechDifficulty,
  ConsultAttestationEnum.videoConsultFax,
  ConsultAttestationEnum.phoneConsultNoteEmr,
  ConsultAttestationEnum.phoneConsultTechDifficulty,
  ConsultAttestationEnum.phoneConsultFax,
  ConsultAttestationEnum.cancelled,
  ConsultAttestationEnum.followUpPhoneCall,
  ConsultAttestationEnum.duplicate,
].map((value) => ({ value, label: ConsultAttestationLabels[value] }));

export const attestationOfTeleNeuroOptions = [
  ConsultAttestationEnum.appVideo,
  ConsultAttestationEnum.mdVideo,
  ConsultAttestationEnum.appAndMDVideo,
  ConsultAttestationEnum.phoneOnly,
  ConsultAttestationEnum.duplicate,
  ConsultAttestationEnum.cancelled,
].map((value) => ({ value, label: ConsultAttestationLabels[value] }));

export const attestationOfEEGOptions = [
  ConsultAttestationEnum.eegEmr,
  ConsultAttestationEnum.eegConsultTechnicalDifficulty,
  ConsultAttestationEnum.eegConsultFax,
  ConsultAttestationEnum.cancelled,
  ConsultAttestationEnum.followUpPhoneCall,
  ConsultAttestationEnum.duplicate,
  ConsultAttestationEnum.hookUp,
  ConsultAttestationEnum.cEEGDisconnect,
].map((value) => ({ value, label: ConsultAttestationLabels[value] }));

export const TelemedicineValues = {
  Telemedicine: 'Telemedicine',
  PhoneOnly: 'PhoneOnly',
};

const telemedicineOptions = [
  {
    value: TelemedicineValues.Telemedicine,
    label: 'telemedicine',
    summaryLabel:
      'Interactive 2 way audio and visual telecommunication technology was utilized during this visit',
  },
  {
    value: TelemedicineValues.PhoneOnly,
    label: 'phone only',
  },
];

export const ConsentValues = {
  ConsentObtained: 'ConsentObtained',
  ConsentUnobtainable: 'ConsentUnobtainable',
};

const consentOptions = [
  {
    value: ConsentValues.ConsentObtained,
    label: 'consent obtained',
    summaryLabel:
      'Verbal consent was obtained from the patient and/or family for this encounter.',
  },
  {
    value: ConsentValues.ConsentUnobtainable,
    label: 'consent unobtainable',
    summaryLabel:
      'Verbal consent was unable to be obtained due to patient condition, lack of family presence, or emergent nature of consult.',
  },
];

const ecptCodes = {
  95717: '2-12 hr no video',
  95718: '2-12 hr with video',
  95719: '12-26 hr no video',
  95720: '12-26 hr with video',
  95721: '36-60 hr no video',
  95722: '36-60 hr with video',
  95723: '60-84 hr no video',
  95724: '60-84 hr with video',
  95725: '>84 hr no video',
  95726: '>84 hr with video',
  95812: '41-60 minutes',
  95813: '61-119 minutes',
  95816: '20-40 minutes, awake and drowsy',
  95819: '20-40 minutes, awake and asleep',
  95822: '20-40 minutes, coma or sleep only',
};

const signatureFields = {
  attestationOfConsultCompletion: {
    name: 'attestationOfConsultCompletion',
    label: 'Attestation of consult completion',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
    required: true,
    options: attestationOfNeuroOptions,
    summaryContent: ({ fieldValue, facilityName = '[no facility]' }) => {
      switch (fieldValue) {
        case ConsultAttestationEnum.videoConsultEmr:
        case ConsultAttestationEnum.videoConsultTechDifficulty:
        case ConsultAttestationEnum.videoConsultFax:
        case ConsultAttestationEnum.appVideo:
        case ConsultAttestationEnum.mdVideo:
        case ConsultAttestationEnum.appAndMDVideo:
          return `The patient is located at: ${facilityName}. Facility staff participated in the visit. I performed this telemedicine visit from my offsite office utilizing interactive 2 way audio and visual telecommunication technology.`;
        case ConsultAttestationEnum.phoneConsultNoteEmr:
        case ConsultAttestationEnum.phoneConsultTechDifficulty:
        case ConsultAttestationEnum.phoneConsultFax:
        case ConsultAttestationEnum.followUpPhoneCall:
        case ConsultAttestationEnum.phoneOnly:
          return `The patient is located at: ${facilityName}. I performed this phone consultation from my offsite office`;
        default:
          return ConsultAttestationLabels[fieldValue];
      }
    },
  },
  attestationOfEEGCompletion: {
    name: 'attestationOfEEGCompletion',
    label: 'Attestation of EEG completion',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
    required: true,
    options: attestationOfEEGOptions,
    excludeFromSummary: true,
  },
  consent: {
    name: 'consent',
    title: 'Consent',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: consentOptions,
  },
  eegTelemedicine: {
    name: 'eegTelemedicine',
    title: 'Telemedicine: remote EEG review',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'EEGReviewedRemotely', label: 'EEG reviewed remotely' },
      { value: 'PhoneOnly', label: 'phone only' },
    ],
  },
  telemedicineOptions: {
    name: 'telemedicineOptions',
    title: 'Telemedicine',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: telemedicineOptions,
  },
  patientConsent: {
    name: 'patientConsent',
    associatedField: 'telemedicineOptions',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'PtConsentedToNotUseFaceToFace',
        label: 'pt consented to not use face-to-face',
        summaryLabel: 'the patient consented to not using face-to-face service',
      },
    ],
  },
  providerLocationPredefined: {
    name: 'providerLocationPredefined',
    title: 'Provider Location',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Clinic', label: 'clinic' },
      { value: 'Hospital', label: 'hospital' },
      { value: 'Home', label: 'home' },
    ],
    excludeFromSummary: true,
  },
  providerLocationFreeText: {
    name: 'providerLocationFreeText',
    associatedField: 'providerLocationPredefined',
    label: 'Provider Location',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  patientLocation: {
    name: 'patientLocation',
    label: 'Patient Location',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  totalFaceToFaceTime: {
    name: 'totalFaceToFaceTime',
    label: 'Total time spent in telemedicine encounter',
    type: ConsultFieldTypeEnum.INPUT,
    inputType: 'number',
    fullWidth: false,
    required: true,
    endAdornment: <InputAdornment position="end">min</InputAdornment>,
    summaryContent: ({ fieldValue, consult }) => {
      switch (consult.signature.attestationOfConsultCompletion) {
        case ConsultAttestationEnum.videoConsultEmr:
        case ConsultAttestationEnum.videoConsultTechDifficulty:
        case ConsultAttestationEnum.videoConsultFax:
        case ConsultAttestationEnum.appVideo:
        case ConsultAttestationEnum.mdVideo:
        case ConsultAttestationEnum.appAndMDVideo:
          return `I spent ${fieldValue} minutes reviewing clinical data and/or imaging, obtaining history, examining the patient, communicating with the onsite care team, and in preparation of this report.`;
        case ConsultAttestationEnum.phoneConsultNoteEmr:
        case ConsultAttestationEnum.phoneConsultTechDifficulty:
        case ConsultAttestationEnum.phoneConsultFax:
        case ConsultAttestationEnum.phoneOnly:
          return `I spent ${fieldValue} minutes in reviewing clinical data and/or imaging, obtaining history, communicating with the onsite care team, and in preparation of this report.`;
        default:
          return `${fieldValue} minutes`;
      }
    },
  },
  criticalCareTime: {
    name: 'criticalCareTime',
    label: 'Critical Care time',
    summaryContent: ({ fieldValue }) =>
      `${fieldValue} minutes of this encounter were critical care time. Due to a high probability of clinically significant, life-threatening neurologic deterioration, the patient required my highest level of preparedness to intervene emergently. I spent this critical care time managing the patient in conjunction with on-site providers who requested my consultation. In addition to the above, this critical care time included recommendation and review of studies, including imaging; arranging an urgent treatment and management plan with on-site providers; evaluation of patient's response to treatment; and documentation. This critical care time was performed to assess and manage the high probability of imminent, life-threatening deterioration that could result in neurologic catastrophe.`,
    type: ConsultFieldTypeEnum.INPUT,
    inputType: 'number',
    inlineLabel: true,
    endAdornment: <InputAdornment position="end">min</InputAdornment>,
  },
  billingCode: {
    name: 'billingCode',
    label: 'Billing Code (CPT)',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
    options: [
      {
        value: 'G0425',
        label:
          'G0425 - Init Telehealth, problem focused hx, exam, & low complexity',
      },
      {
        value: 'G0426',
        label:
          'G0426 - Init Telehealth consult, detailed hx, exam, & mod complexity',
      },
      {
        value: 'G0427',
        label:
          'G0427 - Init Telehealth consult, comp hx, exam, & high complexity',
      },
      {
        value: 'G0508',
        label: 'G0508 - Init Telehealth crit care consult',
      },
      {
        value: 'G0406',
        label: 'G0406 - Subsequent inpt consult. limited',
      },
      {
        value: 'G0407',
        label: 'G0407 - Subsequent inpt consult. intermediate',
      },
      {
        value: 'G0408',
        label: 'G0408 - Subsequent inpt consult. complex',
      },
      {
        value: 'G0509',
        label: 'G0509 - Subsequent telehealth crit care',
      },
      {
        value: 'A_99446',
        label:
          '99446 - Doctor-to-doctor discussion/review/documentation, no patient contact (5-10 minutes)',
      },
      {
        value: 'A_99447',
        label:
          '99447 - Doctor-to-doctor discussion/review/documentation, no patient contact (11-20 minutes)',
      },
      {
        value: 'A_99499TS',
        label: '99499TS - Brief beam in without patient assessment (no charge)',
      },
    ],
    excludeFromSummary: true,
  },
  eegcptCode: {
    name: 'eegcptCode',
    label: 'EEG CPT code',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
    options: Object.entries(ecptCodes).map(([code, label]) => ({
      label: `${code}: ${label}`,
      value: code,
    })),
    excludeFromSummary: true,
  },
  additionalTelemedicineParticipants: {
    name: 'additionalTelemedicineParticipants',
    label: 'Additional telemedicine participant(s)',
    type: ConsultFieldTypeEnum.INPUT,
  },
  qualityReview: {
    name: 'qualityReview',
    label: 'Quality Review',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'FlagForReview',
        label: 'flag for quality review',
      },
    ],
    excludeFromSummary: true,
  },
  qualityComment: {
    name: 'qualityComment',
    label: 'Quality Comments (Not part of medical record)',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 5,
    excludeFromSummary: true,
  },
  workflowLogistics: {
    name: 'workflowLogistics',
    label: 'Workflow/Logistics',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'CameraNotWithPatient', label: 'Patient not with camera' },
      // { value: 'CameraWithWrongPatient', label: 'Camera with wrong patient' },
      {
        value: 'StaffNotWithPatient',
        label: 'RN or other staff not with patient to assist',
      },
      /*
      {
        value: 'InappropriateDirectBeamRequest',
        label: 'Inappropriate Direct Beam request',
      },
      */
      {
        value: 'InappropriateStrokeAlerLeveling',
        label: 'Inappropriate stroke alert leveling',
      },
      {
        value: 'PatientInCTOnIinitialBeamIn',
        label: 'Patient in CT on initial beam-in',
      },
    ],
    excludeFromSummary: true,
  },
  paging: {
    name: 'paging',
    label: 'Paging',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'CalledDirectlyNotPaged', label: 'Called directly, not paged' },
      {
        value: 'UnableToReachProviderAtCallbackNumberGiven',
        label: 'Unable to reach provider or department',
      },
      // { value: 'IncompletePage', label: 'Incomplete page' },
      {
        value: 'InitialPageNotReceived',
        label: 'Initial page(s) not received',
      },
      { value: 'WrongDemographics', label: 'Wrong demographics' },
      { value: 'WrongCallbackNumber', label: 'Wrong callback number' },
      { value: 'OnHoldMoreThen5Minutes', label: 'on hold >5 minutes' },
      { value: 'RequestedBackupHelp', label: 'Requested back up help' },
    ],
    excludeFromSummary: true,
  },
  emrPacsImages: {
    name: 'emrPacsImages',
    label: 'EMR/PACS/Images',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'UnableToAccessEmr', label: 'Unable to access EMR' },
      { value: 'UnableToAccessImages', label: 'Unable to access images' },
      { value: 'ImagesNotPushed', label: 'Images not pushed to be viewed' },
    ],
    excludeFromSummary: true,
  },
  equipmentTechIssue: {
    name: 'equipmentTechIssue',
    label: 'Equipment Tech issue',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'PoorNoAudio', label: 'Poor/no audio' },
      { value: 'PoorNoVideo', label: 'Poor/no video' },
      { value: 'CallDroppedMidConsult', label: 'Call dropped mid-consult' },
      { value: 'UnableToConnectEntirely', label: 'Unable to connect entirely' },
      {
        value: 'CameraInPageNotListedInMyEndpoints',
        label: 'Camera in page not listed in my endpoints',
      },
    ],
    excludeFromSummary: true,
  },
  caseReview: {
    name: 'caseReview',
    label: 'Clinical Escalation (details in comments)',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'CaseReview', label: 'Possible Case for PRC' },
      { value: 'SendToSiteForReview', label: 'Send to site for review' },
      { value: 'Trend', label: 'Trend' },
    ],
    excludeFromSummary: true,
  },
  icdCodes: {
    name: 'icdCodes',
    type: ConsultFieldTypeEnum.INPUT,
    label: 'Diagnosis Code (ICD-10)',
    excludeFromSummary: true,
  },
};

export const teleNeuroSignatureFields = {
  ...signatureFields,
  logistics: {
    name: 'logistics',
    label: 'Logistics',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'ExamCompletedFacilityMedicalStaffAssistance',
        label:
          'Exam completed with assistance of facility medical staff present at bedside',
      },
      { value: 'Translator', label: 'Translator' },
      {
        value: 'ConsentObtainedPriorToCameraPresence',
        label: 'Consent obtained prior to my on camera presence',
      },
      {
        value: 'MoreThen50PercentSpentInDirectCounseling',
        label:
          '>50% spent in direct counseling and coord of care in neuro specific issues',
      },
      {
        value: 'CaseReviewedWithAttending',
        label: 'Case reviewed with attending',
      },
      {
        value: 'IhaveReadAndReviewedTheNote',
        label:
          'I have read and reviewed the note and discussed this case with the APP and am in agreement with their findings, assessment, and plan.',
      },
    ],
  },
  attestationOfConsultCompletion: {
    ...signatureFields.attestationOfConsultCompletion,
    options: attestationOfTeleNeuroOptions,
  },
  attendingPhysicianId: {
    name: 'attendingPhysicianId',
    label: 'Attending Physician',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
  },
};

export const clinicSignatureFields = {
  ...signatureFields,
  providerLocationPredefined: {
    ...signatureFields.providerLocationPredefined,
    excludeFromSummary: false,
  },
  providerLocationFreeText: {
    ...signatureFields.providerLocationFreeText,
    excludeFromSummary: false,
  },
  patientLocation: {
    ...signatureFields.patientLocation,
    excludeFromSummary: false,
  },
  totalFaceToFaceTime: {
    ...signatureFields.totalFaceToFaceTime,
    excludeFromSummary: false,
  },
};

export default signatureFields;
