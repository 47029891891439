import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import styled from '@emotion/styled';
import { InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

import InputField from 'shared/components/forms/InputField/InputField';
import colors from 'styles/colors';

export const SearchTitle = styled.label`
  color: ${colors.primary};
  font-size: 0.8rem;
  font-weight: bold;
`;

const SearchIcon = styled(Search)`
  color: #95989a;
`;

const SearchField = forwardRef(
  (
    {
      name = 'search',
      fullWidth = false,
      defaultValue = '',
      onChange,
      placeholder = 'Search',
      size = 'small',
      ...props
    },
    ref,
  ) => (
    <InputField
      {...props}
      ref={ref}
      name={name}
      type="search"
      placeholder={placeholder}
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      size={size}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
      onChange={debounce(({ target }) => onChange(target.value), 500)}
    />
  ),
);

SearchField.propTypes = {
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SearchField;
