import React from 'react';
import { Routes, Route } from 'react-router-dom';

import UserList from './UserList';
import UserAddPage from './UserAddPage';
import UserDetailsPage from './UserDetailsPage';

const Users = () => (
  <Routes>
    <Route index element={<UserList />} />
    <Route path="add" element={<UserAddPage />} />
    <Route path=":userId" element={<UserDetailsPage />} />
  </Routes>
);

export default Users;
