import React from 'react';
// import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import TabContext from '@mui/lab/TabContext';

import { ConsultProvider, useConsult } from 'shared/context/ConsultContext';
import useGoBack from 'navigation/hooks/useGoBack';
/// import { appStateActions } from 'store/appStateSlice';
import { ServiceTypeEnum } from 'utils/enums';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import neuroTabs from 'features/consults/pages/neuro/tabs';
import teleNeuroTabs from 'features/consults/pages/tele-neuro/tabs';
import clinicTabs from 'features/consults/pages/clinic/tabs';
import eegTabs from 'features/consults/pages/eeg/tabs';

import CloseButton from 'shared/components/buttons/CloseButton/CloseButton';
import { FlexBoxSpaceBetween } from 'styles/layout';
import {
  TabsContainer,
  TabHeader,
  TabContent,
} from 'features/consults/components/Tabs/Tabs.style';

const MacroPage = () => {
  const { goBack } = useGoBack();
  const { consult } = useConsult();
  // const dispatch = useDispatch();

  const tabsData = React.useMemo(() => {
    switch (consult?.type) {
      case ServiceTypeEnum.NEURO:
        return neuroTabs;
      case ServiceTypeEnum.TELE_NEURO:
        return teleNeuroTabs;
      case ServiceTypeEnum.CLINIC:
        return clinicTabs;
      case ServiceTypeEnum.EEG:
      case ServiceTypeEnum.PEDS_EEG:
        return eegTabs;
      default:
        return {};
    }
  }, [consult]);

  const [currentTab, setCurrentTab] = React.useState(CONSULT_TABS.DEMOGRAPHICS);

  /*
  React.useEffect(() => {
    dispatch(appStateActions.macroReload());
  }, [currentTab, dispatch]);
  */

  const tabsHeaders = React.useMemo(
    () =>
      Object.values(tabsData)?.map(({ value, label }) => (
        <TabHeader
          key={value}
          label={label}
          value={value}
          _selected={currentTab === value ? 'selected' : ''}
        />
      )),
    [tabsData, currentTab],
  );

  const tabsContent = React.useMemo(
    () =>
      Object.values(tabsData)?.map(({ value, component: Component }) => (
        <TabContent key={value} value={value}>
          <Component />
        </TabContent>
      )),
    [tabsData],
  );

  return (
      <TabContext value={currentTab}>
        <FlexBoxSpaceBetween style={{ alignItems: 'flex-start' }}>
          <TabsContainer onChange={(_, newTab) => setCurrentTab(newTab)}>
            {tabsHeaders}
          </TabsContainer>
          <CloseButton onClick={() => goBack()} />
        </FlexBoxSpaceBetween>
        {tabsContent}
      </TabContext>
  );
};

const MacroUpdatePage = () => {
  const { macroId } = useParams();
  return (
    <ConsultProvider consultId={macroId} isMacro>
      <MacroPage />
    </ConsultProvider>
  );
};

export default MacroUpdatePage;
