import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'styles/colors';

const Title = styled.h3`
  color: ${colors.primary};
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 ${({ singleDayView }) => (singleDayView ? '0' : '5px')};
`;

const DayTitle = ({ children, singleDayView = false }) => (
  <Title singleDayView={singleDayView}>{children}</Title>
);

DayTitle.propTypes = {
  children: PropTypes.string.isRequired,
  singleDayView: PropTypes.bool,
};

export default DayTitle;
