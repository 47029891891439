import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

// Use the breakpoints coming from the Material theme
export const useBreakpoints = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    smDown,
  };
};

export default useBreakpoints;
