import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Add } from '@mui/icons-material';

import Button from 'shared/components/buttons/ActionButton/ActionButton';

import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { successActionSelector } from 'store/selectors';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { consultsActions } from 'features/consults/store/slice';
import useConsultNavigation from 'features/consults/hooks/useConsultNavigation';
import { ServiceTypeLabels } from 'utils/enums';

const NewConsultButton = ({ consultType, replace, ...props }) => {
  const dispatch = useDispatch();
  const { navigateToConsult } = useConsultNavigation(consultType);
  const successAction = useSelector(successActionSelector);

  usePerformActionOnSuccess(SuccessActionTypesEnum.CONSULT_CREATED, () => {
    navigateToConsult(successAction.payload, { replace });
  });

  return (
    <Button
      {...props}
      type="button"
      color="secondary"
      onClick={() => {
        dispatch(consultsActions.addNewConsult(consultType));
      }}
      icon={<Add />}
    >
      {`New ${ServiceTypeLabels[consultType]}`}
    </Button>
  );
};

NewConsultButton.propTypes = {
  consultType: PropTypes.string.isRequired,
  replace: PropTypes.bool,
};

export default NewConsultButton;
