import React from 'react';
import PropTypes from 'prop-types';

import { Star } from '@mui/icons-material';

import { RESET_OPTION } from 'features/consults/utils/constants';
import Button from 'shared/components/buttons/Button/Button';

import { WrapIcon } from './index.styles';

const SetZeroButton = ({ form, disabled, label, group, onSave }) => {
  const setGroup2Zero = React.useCallback(() => {
    const formValues = form.getValues();

    const updateFields = group.fields.reduce((res, field) => {
      const fieldValue = formValues[field.name];
      return !fieldValue || fieldValue === RESET_OPTION.value
        ? { ...res, [field.name]: field.options[1].value }
        : res;
    }, {});

    updateFields[group.name] = group.fields.reduce((res, field) => {
      const fieldValue = formValues[field.name];
      if (!fieldValue || fieldValue === RESET_OPTION.value) return res;
      const fieldScore = field.options.find(
        ({ value }) => value === fieldValue,
      )?.severity;
      return fieldScore ? res + fieldScore : res;
    }, 0);

    form.setFormValues({
      ...formValues,
      ...updateFields,
    });

    onSave(updateFields);
  }, [form, group, onSave]);

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={setGroup2Zero}
      disabled={disabled}
    >
      <WrapIcon>
        <Star fontSize="small" />
      </WrapIcon>
      {label}
    </Button>
  );
};

SetZeroButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  group: PropTypes.shape({
    name: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getValues: PropTypes.func,
    setFormValues: PropTypes.func,
  }).isRequired,
};

export default SetZeroButton;
