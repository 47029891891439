import styled from '@emotion/styled';

import { FlexBoxCenter } from 'styles/layout';

export const Label = styled.label`
  ${({ disabled }) => disabled && 'cursor: default'};
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const AvatarOverlay = styled(FlexBoxCenter)`
  background: rgba(100, 100, 100, 0.5);
  position: absolute;
  height: 90%;
  width: 90%;
  border-radius: 50%;
`;

export const classes = {
  avatar: {
    width: '10rem',
    height: '10rem',
  },
  overlayIcon: {
    width: '3rem',
    height: '3rem',
    color: 'white',
  },
};
