import React from 'react';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';

import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';

import { CONSULT_TABS } from 'features/consults/utils/constants';

import schema from './validationSchema';
import impressionPanel from './data';

const Assessment = () => {
  const { register, control, getValues, getFieldError, setFormValues } =
    useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    isEditable,
    handleFieldChange,
    consultMainType,
  } = useConsultData(CONSULT_TABS.ASSESSMENT, getValues);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  return (
    <QuestionsPanel
      {...impressionPanel(consultMainType)}
      control={control}
      register={register}
      onChange={handleFieldChange}
      getFieldError={getFieldError}
      disabled={!isEditable}
    />
  );
};

export default Assessment;
