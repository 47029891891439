import api from 'api';
import { useMountEffect, useRequest } from 'shared/hooks';
import { MAX_PAGE_SIZE } from 'utils/constants';

const useFacilityNames = (networkType) => {
  const { doRequest, data } = useRequest(api.getFacilityNames);

  useMountEffect(() => {
    doRequest({
      pageSize: MAX_PAGE_SIZE,
      filters: {
        networkType,
        activeOnly: 'true',
      },
    });
  });

  return {
    facilities: data?.facilities || [],
  };
};

export default useFacilityNames;
