import React from 'react';
import PropTypes from 'prop-types';

import { mapToggleFieldOptions } from 'features/consults/utils/formFields';

import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';

import fields from './formFields';

const MRSFields = [
  mapToggleFieldOptions(fields.modifiedRankinScale),
  fields.mrsTotal,
];

const MRSPanel = ({ form, saveConsultChange, ...props }) => {
  const onChange = React.useCallback(
    (fieldName) => {
      const newValue = form.getValues(fieldName);
      const severity = fields.modifiedRankinScale.options.find(
        ({ value }) => value === newValue,
      )?.severity;
      form.setValue(fields.mrsTotal.name, severity);
      saveConsultChange({
        [fieldName]: newValue,
        [fields.mrsTotal.name]: severity,
      });
    },
    [form, saveConsultChange],
  );

  return (
    <QuestionsPanel
      {...props}
      onChange={onChange}
      control={form.control}
      register={form.register}
      getFieldError={form.getFieldError}
      elements={MRSFields}
    />
  );
};

MRSPanel.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func.isRequired,
    control: PropTypes.shape({}).isRequired,
    register: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
  }).isRequired,
  saveConsultChange: PropTypes.func.isRequired,
};

export default MRSPanel;
