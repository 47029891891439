import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useConsult } from 'shared/context/ConsultContext';
import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';

import { Box } from 'styles/layout';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import ConsultIcd from 'features/consults/components/ConsultIcd';
import ConsultLock from 'features/consults/components/ConsultLock/ConsultLock';
import Autocomplete from 'shared/components/forms/Autocomplete';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import QualityReviewPanel from 'features/consults/shared/tabs/Signature/QualityReviewPanel';
import formFields from 'features/consults/shared/tabs/Signature/formFields';
import {
  eegIcds,
  pedsEegIcds,
} from 'features/consults/shared/tabs/Signature/data';
import { ServiceTypeEnum } from 'utils/enums';

import schema from './validationSchema';
import signatureFields from './data';

const SignatureEEG = ({ errors }) => {
  const {
    clearErrors,
    control,
    register,
    getValues,
    getFieldError,
    setFormValues,
    setValue,
    trigger,
    watch,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    isClosed,
    isEditable,
    isMacro,
    handleConsultLock,
    handleFieldChange,
    handleOwnerChange,
    saveConsultChange,
  } = useConsultData(CONSULT_TABS.SIGNATURE, getValues);

  const { consultProviders } = useConsult();

  const currentAttestationValue = watch(
    formFields.attestationOfEEGCompletion.name,
  );

  useConsultInitialFormState(
    consult.id,
    {
      ...consultTabData,
      eegcptCode:
        consultTabData.eegcptCode && String(consultTabData.eegcptCode),
    },
    setFormValues,
  );

  React.useEffect(() => {
    clearErrors();
    if (errors) trigger(errors);
  }, [errors, clearErrors, trigger]);

  return (
    <GridWrapper>
      <Grid item sm={12} lg={6}>
        <QuestionsPanel
          control={control}
          register={register}
          onChange={handleFieldChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
          elements={signatureFields}
          disableElements={
            isMacro
              ? [
                  formFields.attestationOfEEGCompletion.name,
                  formFields.eegTelemedicine.name,
                  formFields.providerLocationPredefined.name,
                  formFields.providerLocationFreeText.name,
                  formFields.patientLocation.name,
                  ...(consult.macroType === "Shared" ? [formFields.eegcptCode.name] : []),
                ]
              : []
          }
        />
        <ConsultIcd
          consultId={consult.id}
          consultData={consultTabData}
          staticOptions={
            consult.type === ServiceTypeEnum.EEG ? eegIcds : pedsEegIcds
          }
          disabled={!isEditable}
          onSetValue={setValue}
          getFieldError={getFieldError}
          isMacro={isMacro}
        />
      </Grid>
      <Grid item sm={12} lg={6}>
        <Box mb={1}>
          <Autocomplete
            control={control}
            name="assignToId"
            label="Neurologist"
            options={consultProviders}
            required
            disabled={isMacro || isClosed}
            handleChange={handleOwnerChange}
          />
        </Box>
        <Box mb={1}>
          <Autocomplete
            control={control}
            name="backupOnCallProviderId"
            label="Are you providing backup for on-call provider? If so select on-call below:"
            options={consultProviders}
            disabled={isClosed || (isMacro && consult.macroType === "Shared")}
            handleChange={() => handleFieldChange('backupOnCallProviderId')}
          />
        </Box>
        {!isMacro && (
          <ConsultLock
            consult={consult}
            attestationValue={currentAttestationValue}
            disabled={!isEditable}
            checked={isClosed}
            onConsultLock={handleConsultLock}
          />
        )}
        <QualityReviewPanel
          form={{
            control,
            register,
            watch,
            getValues,
            setFormValues,
            getFieldError,
          }}
          onChange={saveConsultChange}
          onFieldChange={handleFieldChange}
          disabled={isMacro || !isEditable}
        />
      </Grid>
    </GridWrapper>
  );
};

SignatureEEG.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default SignatureEEG;
