import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';

import api from 'api';
import { useFeedback } from 'shared/hooks';
import {
  ServiceTypeEnum,
  ServiceTypeLabels,
  EegConsultTypes,
  EegConsultLabels,
} from 'utils/enums';
import { CONSULT_TABS } from 'features/consults/utils/constants';

const ConsultCopyButton = ({ consult }) => {
  const [isLoading, setLoading] = React.useState(false);
  const { clearDisplay, errorDisplay } = useFeedback();
  const navigate = useNavigate();

  const showCopyType = React.useMemo(() => {
    switch (consult.type) {
      case ServiceTypeEnum.EEG:
      case ServiceTypeEnum.PEDS_EEG: {
        const consultType =
          consult.type === ServiceTypeEnum.PEDS_EEG
            ? consult[CONSULT_TABS.DEMOGRAPHICS]?.eegPediatricConsultType
            : consult[CONSULT_TABS.DEMOGRAPHICS]?.consultType;
        return (
          [
            EegConsultTypes.AEEG,
            EegConsultTypes.CEEG,
            EegConsultTypes.CERIBELL,
            EegConsultTypes.ZETO,
          ].includes(consultType) && EegConsultLabels[consultType]
        );
      }
      case ServiceTypeEnum.TELE_NEURO:
        return ServiceTypeLabels[consult.type];
      default:
        return null;
    }
  }, [consult]);

  const handleCopyConsult = () => {
    clearDisplay();
    setLoading(true);
    api
      .copyConsult(consult.id)
      .then(({ data }) => navigate(`../${data.id}`))
      .catch(({ response }) => {
        setLoading(false);
        errorDisplay(response?.data?.title ?? 'Unknown error copying consult');
      });
  };

  return (
    showCopyType && (
      <LoadingButton
        color="secondary"
        loading={isLoading}
        variant="contained"
        startIcon={<AddIcon />}
        loadingPosition="start"
        onClick={handleCopyConsult}
      >
        New Linked {showCopyType}
      </LoadingButton>
    )
  );
};

ConsultCopyButton.propTypes = {
  consult: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default ConsultCopyButton;
