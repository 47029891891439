import styled from '@emotion/styled';
import Button from 'shared/components/buttons/Button/Button';

const MacroButton = styled(Button)`
  text-transform: none;
  margin: 0 15px 10px 0;

  ${({ _applied }) =>
    _applied === 'applied' &&
    `
    border: 3px solid rgba(19, 130, 202, 1);
    background-color: rgba(19, 130, 202, 0.07);
    &:hover {
      border: 3px solid rgba(19, 130, 202, 1);
    }
  `}
`;

export default MacroButton;
