import { mapToggleFieldOptions } from 'features/consults/utils/formFields';

import fields from 'features/consults/pages/neuro/tabs/ScoresNeuro/formFields';
import {
  NIHSSFields,
  ICHFields,
  HuntHessSAHFields,
  ABCD2Fields,
} from 'features/consults/pages/neuro/tabs/ScoresNeuro/data';

export const examPanel = {
  elements: [fields.nihssExamTimeAt],
};

const MRSFields = [mapToggleFieldOptions(fields.modifiedRankinScale)];

const GgsFields = [
  mapToggleFieldOptions(fields.eyeOpening),
  mapToggleFieldOptions(fields.verbalResponse),
  mapToggleFieldOptions(fields.motorResponse),
];

export default {
  NIHSS: {
    name: fields.nihssTotal.name,
    label: fields.nihssTotal.label,
    fields: NIHSSFields,
  },
  MRS: {
    name: fields.mrsTotal.name,
    label: fields.mrsTotal.label,
    fields: MRSFields,
  },
  ICH: {
    name: fields.ichTotal.name,
    label: fields.ichTotal.label,
    fields: ICHFields,
  },
  HUNT: {
    name: fields.huntHessTotal.name,
    label: fields.huntHessTotal.label,
    fields: HuntHessSAHFields,
  },
  ABCD2: {
    name: fields.abcD2Total.name,
    label: fields.abcD2Total.label,
    fields: ABCD2Fields,
  },
  GCS: {
    name: fields.gcsTotal.name,
    label: fields.gcsTotal.label,
    fields: GgsFields,
  },
};
