import React from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { useFeedback } from 'shared/hooks';
import { ServiceTypeEnum, ShortServiceValues, ReportTypes } from 'utils/enums';
import { mapConsultFilters } from 'features/consults/utils/mappers';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { downloadFile } from 'utils/helpers';

import DownloadButton from 'shared/components/buttons/DownloadButton';

const ButtonLabels = {
  [ReportTypes.QA]: 'Quality',
  [ReportTypes.Volumes]: 'Volumes/Invoicing',
  [ReportTypes.CaseLog]: 'Case Log',
};

const ConsultListButtons = ({ filters, sortBy, sortOrder }) => {
  const { clearDisplay, errorDisplay } = useFeedback();

  const handleExport = React.useCallback(
    async (reportType) => {
      clearDisplay();
      try {
        const { service, ...restFilters } = filters;
        const { data, headers } = await api.exportConsults(service, {
          sortBy,
          sortOrder,
          pageSize: MAX_PAGE_SIZE,
          filters: {
            ...mapConsultFilters(restFilters),
            reportType,
          },
        });
        downloadFile(data, headers);
      } catch {
        errorDisplay(
          'An error occurred while downloading the file. Please, try again.',
        );
      }
    },
    [clearDisplay, errorDisplay, filters, sortBy, sortOrder],
  );

  const renderButtons = React.useMemo(() => {
    if (!filters.service) return null;
    switch (ShortServiceValues[filters.service]) {
      case ServiceTypeEnum.NEURO:
      case ServiceTypeEnum.TELE_NEURO:
        return [
          ReportTypes.Client,
          ReportTypes.Metrics,
          ReportTypes.CaseLog,
          ReportTypes.QA,
          ReportTypes.Volumes,
        ].map((reportType) => (
          <DownloadButton
            key={reportType}
            title={`${ButtonLabels[reportType] ?? reportType} Report`}
            onExport={async () => handleExport(reportType)}
          />
        ));
      case ServiceTypeEnum.EEG:
      case ServiceTypeEnum.PEDS_EEG:
        return [ReportTypes.CaseLog, ReportTypes.Volumes].map((reportType) => (
          <DownloadButton
            key={reportType}
            title={`${ButtonLabels[reportType] ?? reportType} Report`}
            onExport={async () => handleExport(reportType)}
          />
        ));
      default:
        return (
          <DownloadButton
            title="Download CSV"
            onExport={async () => handleExport(null)}
          />
        );
    }
  }, [filters.service, handleExport]);

  return renderButtons;
};

ConsultListButtons.propTypes = {
  filters: PropTypes.shape({
    service: PropTypes.string,
  }),
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
};

export default ConsultListButtons;
