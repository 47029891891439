import { teleNeuroDemographicsFields as fields } from 'features/consults/shared/tabs/Demographics/formFields';
import {
  mapToggleFieldOptions,
  mapChipFieldOptions,
} from 'features/consults/utils/formFields';

export const leftPanel1 = [
  mapToggleFieldOptions(fields.consultType),
  mapToggleFieldOptions(fields.neuroPatientLocation),
];

export const leftPanel2 = [
  fields.age,
  mapToggleFieldOptions(fields.gender),
  mapChipFieldOptions(fields.prehospitalBeamIn),
];

export const leftPanel3 = [
  fields.referringProvider,
  fields.callBackPhone,
  fields.cameraName,
  fields.notes,
];

export const rightPanel = [
  fields.dateOfAdmission,
  fields.dateOfInitialConsultation,
  fields.dateOfService,
];

export const phoneOnlyPanel = [mapChipFieldOptions(fields.phoneOnly)];

export const phoneOnlyExtraPanel = [
  fields.phoneOnlyFreetext,
  mapChipFieldOptions(fields.phoneAgreement),
];
