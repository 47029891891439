import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';

import SummaryNote from 'features/consults/components/SummaryNote/SummaryNote';
import LockChart from 'features/consults/components/tabFields/LockChart';
import { HiddenWrapper } from 'styles/layout';

import { useConsult } from 'shared/context/ConsultContext';
import { formatSummaryTimestamp } from 'features/consults/utils/dates';
import { TIMESTAMP_FORMAT, formatDate } from 'utils/dates';
import { getNamesWithTitle } from 'utils/helpers';
import { ConsultAttestationEnum } from 'utils/enums';

const ConsultLock = ({
  attestationValue,
  disabled,
  checked,
  onConsultLock,
}) => {
  const {
    firstName,
    lastName,
    title: userTitle,
  } = useSelector(({ profile }) => profile.data);
  const { consultTimezone } = useConsult();
  const summaryContainerRef = React.useRef(null);

  const dialogTitle = React.useMemo(() => {
    const warningText = [
      ConsultAttestationEnum.cancelled,
      ConsultAttestationEnum.duplicate,
    ].includes(attestationValue)
      ? 'Are you sure you would like to cancel this consult? This consult will be closed and the summary information will be discarded.'
      : 'Are you sure you would like to submit this consult?';

    return `${warningText} This cannot be undone.`;
  }, [attestationValue]);

  return (
    <>
      <LockChart
        label="Lock Chart, esign, & fax"
        disabled={disabled}
        checked={checked}
        dialogTitle={dialogTitle}
        onChange={() => {
          const lockAt = formatDate(new Date(), TIMESTAMP_FORMAT);
          const closedByName = getNamesWithTitle(
            firstName,
            lastName,
            userTitle,
          );

          const eSignature = (
            <div style={{ marginTop: '10px' }}>
              {`Electronically signed at ${formatSummaryTimestamp(
                lockAt,
                consultTimezone,
              )} by ${closedByName}`}
            </div>
          );

          onConsultLock({
            summary: `${summaryContainerRef.current.innerText}\n${eSignature.props.children}`,
            summaryFormatted: `${
              summaryContainerRef.current.innerHTML
            }${renderToString(eSignature)}`,
            lockAt,
            attestationValue,
          });
        }}
      />
      <HiddenWrapper>
        <SummaryNote ref={summaryContainerRef} isClosed={false} />
      </HiddenWrapper>
    </>
  );
};

ConsultLock.propTypes = {
  attestationValue: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onConsultLock: PropTypes.func.isRequired,
};

export default ConsultLock;
