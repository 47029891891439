import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import useMacroOptions from 'features/consults/hooks/useMacroOptions';
import Select from 'shared/components/forms/Select';
import { Box } from 'styles/layout';

const MacroListFilters = ({ filters, handleFilter }) => {
  const { macroOptions } = useMacroOptions();
  const { control, reset } = useForm();

  React.useEffect(() => {
    reset(filters);
  }, [reset, filters]);

  return (
    <Box sx={{ width: 200 }}>
      <Select
        control={control}
        name="type"
        placeholder="-- Select Service --"
        options={macroOptions}
        handleChange={({ target }) =>
          handleFilter({ [target.name]: target.value })
        }
      />
    </Box>
  );
};

MacroListFilters.propTypes = {
  filters: PropTypes.shape({}),
  handleFilter: PropTypes.func.isRequired,
};

export default MacroListFilters;
