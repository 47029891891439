import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MaterialRadioGroup,
} from '@mui/material';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';

const RadioGroup = ({
  control,
  name,
  label,
  error = null,
  required = false,
  options = [],
  disabled = false,
  handleChange,
}) => (
  <FormControl required={required} error={!!error}>
    {label && (
      <InputLabel htmlFor={name} required={required} disabled={disabled}>
        {label}
      </InputLabel>
    )}
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { onChange, ...fieldProps } }) => (
        <MaterialRadioGroup
          {...fieldProps}
          row
          id={name}
          onChange={(e, value) => {
            onChange(e, value);
            if (handleChange) handleChange(value);
          }}
        >
          {options.map((opt) => (
            <FormControlLabel
              key={opt.value}
              value={opt.value}
              control={<Radio />}
              label={opt.label}
              disabled={disabled}
            />
          ))}
        </MaterialRadioGroup>
      )}
    />
    {error && <ErrorMsg text={error.message} dense />}
  </FormControl>
);

RadioGroup.propTypes = {
  control: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChange: PropTypes.func,
};

export default RadioGroup;
