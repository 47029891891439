import { ConsultFieldTypeEnum } from 'utils/enums';

export default {
  vteProphylaxis: {
    name: 'vteProphylaxis',
    label: 'VTE prophylaxis (STK-1)',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'SCDs', label: 'SCDs' },
      { value: 'SQHeparin', label: 'SQ heparin' },
      { value: 'SQLovenox', label: 'SQ lovenox' },
      {
        value: 'OnTherapeuticAC',
        label: 'On therapeutic AC',
        summaryLabel: 'on therapeutic anticoagulation',
      },
      { value: 'Contraindicated', label: 'Contraindicated' },
    ],
  },
  vteProphylaxisFreeText: {
    name: 'vteProphylaxisFreeText',
    associatedField: 'vteProphylaxis',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  assessedForRehabs: {
    name: 'assessedForRehabs',
    type: ConsultFieldTypeEnum.CHIP,
    label: 'Assessed for Rehab (STK-10)',
    options: [
      { value: 'PtOtStOrdered', label: 'PT/OT/ST ordered' },
      {
        value: 'PtOtStNotIndicated',
        label: 'PT/OT/ST not indicated',
        summaryLabel: 'PT/OT/ST not indicated patient at functional baseline',
      },
    ],
  },
  assessedForRehabsFreeText: {
    name: 'assessedForRehabsFreeText',
    associatedField: 'assessedForRehabs',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  dischargeRecommendations: {
    name: 'dischargeRecommendations',
    type: ConsultFieldTypeEnum.CHIP,
    label: 'Discharge on antithrombotic therapy recommendation (STK-2)',
    options: [
      { value: 'Aspirin', label: 'Aspirin' },
      { value: 'Plavix', label: 'Plavix' },
      { value: 'Other', label: 'Other (see comment)' },
    ],
  },
  dischargeRecommendationsFreeText: {
    name: 'dischargeRecommendationsFreeText',
    type: ConsultFieldTypeEnum.INPUT,
    associatedField: 'dischargeRecommendations',
    multiline: true,
  },
  anticoagulationTherapyAfibs: {
    name: 'anticoagulationTherapyAfibs',
    type: ConsultFieldTypeEnum.CHIP,
    label: 'Anticoagulation therapy for Afib (STK-3)',
    options: [
      {
        value: 'NotIndicatedNoKnownAfib',
        label: 'Not indicated/no known afib',
      },
      { value: 'BleedRisk', label: 'Bleed risk' },
      { value: 'Warfarin', label: 'Warfarin' },
      { value: 'DOAC', label: 'DOAC' },
    ],
  },
  anticoagulationTherapyAfibsFreeText: {
    name: 'anticoagulationTherapyAfibsFreeText',
    type: ConsultFieldTypeEnum.INPUT,
    associatedField: 'anticoagulationTherapyAfibs',
    multiline: true,
  },
  thrombolyticTherapies: {
    name: 'thrombolyticTherapies',
    type: ConsultFieldTypeEnum.CHIP,
    label: 'Thrombolytic therapy (STK-4)',
    options: [
      {
        value: 'ReceivedIVThrombolyticHospital',
        label: 'Received IV thrombolytic at this hospital',
      },
      {
        value: 'ReceivedIVThrombolyticPrehospital',
        label: 'Received IV thrombolytic prehospital',
      },
      { value: 'Contraindicated', label: 'Contraindicated (see comment)' },
    ],
  },
  thrombolyticTherapiesFreeText: {
    name: 'thrombolyticTherapiesFreeText',
    type: ConsultFieldTypeEnum.INPUT,
    associatedField: 'thrombolyticTherapies',
    multiline: true,
  },
  antithromboticByHospitals: {
    name: 'antithromboticByHospitals',
    type: ConsultFieldTypeEnum.CHIP,
    label: 'Antithrombotic by hospital day two (STK-5)',
    options: [
      { value: 'Yes', label: 'Yes' },
      { value: 'Contraindicated', label: 'Contraindicated (see comment)' },
    ],
  },
  antithromboticByHospitalsFreeText: {
    name: 'antithromboticByHospitalsFreeText',
    type: ConsultFieldTypeEnum.INPUT,
    associatedField: 'antithromboticByHospitals',
    multiline: true,
  },
  dischargeStatinRecommendations: {
    name: 'dischargeStatinRecommendations',
    type: ConsultFieldTypeEnum.CHIP,
    label: 'Discharge on statin recommendation (STK-6)',
    options: [
      { value: 'LDLGoalLessThen70', label: 'LDL goal <70' },
      { value: 'HighDoseStatin', label: 'High dose statin' },
      { value: 'Contraindicated', label: 'Contraindicated (see comment)' },
    ],
  },
  dischargeStatinRecommendationsFreeText: {
    name: 'dischargeStatinRecommendationsFreeText',
    type: ConsultFieldTypeEnum.INPUT,
    associatedField: 'dischargeStatinRecommendations',
    multiline: true,
  },
  strokeEducations: {
    name: 'strokeEducations',
    type: ConsultFieldTypeEnum.CHIP,
    label: 'Stroke education (STK-8)',
    options: [
      {
        value: 'PersonalizedStrokeEducationRecommended',
        label: 'Personalized stroke education recommended',
      },
      {
        value: 'PatientUnableToBeEducatedNoFamily',
        label: 'Patient unable to be educated, no family available',
      },
    ],
  },
  strokeEducationsFreeText: {
    name: 'strokeEducationsFreeText',
    type: ConsultFieldTypeEnum.INPUT,
    associatedField: 'strokeEducations',
    multiline: true,
  },
};
