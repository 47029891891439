import { NetworkLabels, userTitleLabels } from 'utils/enums';

export const capitalizeFirstLetter = (str) =>
  `${str[0].toUpperCase()}${str.slice(1)}`;

export const showFirstLetter = (name) => (name ? name.substr(0, 1) : '');

export const getNamesWithTitle = (firstName, lastName, title) =>
  `${firstName} ${lastName}${title ? `, ${userTitleLabels[title]}` : ''}`;

// https://stackoverflow.com/a/31218143
export const downloadFile = (data, headers, fileType = 'csv') => {
  let filename = `data_export.${fileType}`;
  const contentDispositionHeader = headers['content-disposition'];

  if (contentDispositionHeader) {
    const filenameStr = contentDispositionHeader.match(/filename=(.)+;/g);

    if (filenameStr) {
      const [, name] = filenameStr[0].split('filename=');
      filename = name.replace(/\s/g, '_').replace(/[";]/g, '');
    }
  }

  const csvUrl = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');

  tempLink.href = csvUrl;
  tempLink.style = 'display:none;';
  tempLink.setAttribute('download', filename);
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const isFile = (value) => value && value instanceof File;

export const getFacilityNetworks = (facilities) =>
  Object.entries(NetworkLabels).reduce(
    (res, [service, networkLabel]) =>
      facilities.some((f) => !!f[networkLabel]) ? [...res, service] : res,
    [],
  );
