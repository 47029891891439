import * as yup from 'yup';

import { requiredString } from 'utils/constants';

import signatureFields from './formFields';

export const neuroSignatureSchema = yup.object().shape({
  [signatureFields.attestationOfConsultCompletion.name]: yup
    .string()
    .required(requiredString)
    .typeError(requiredString),
  [signatureFields.totalFaceToFaceTime.name]: yup
    .string()
    .required(requiredString)
    .typeError(requiredString),
  [signatureFields.billingCode.name]: yup
    .string()
    .required(requiredString)
    .typeError(requiredString),
  [signatureFields.icdCodes.name]: yup.array().min(1, requiredString),
});

export const clinicSignatureSchema = yup.object().shape({
  [signatureFields.totalFaceToFaceTime.name]: yup
    .string()
    .required(requiredString)
    .typeError(requiredString),
});
