import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppBar, Toolbar, Hidden, useMediaQuery } from '@mui/material';
import { Menu, ArrowBack } from '@mui/icons-material';

import api from 'api';
import {
  useMountEffect,
  useUpdateEffect,
  useProfile,
  useInterval,
  useBreakpoints,
  useQueryParams,
} from 'shared/hooks';
import { useLogout } from 'shared/context/LogoutContext';
import { consultsActions } from 'features/consults/store/slice';
import {
  FlexBoxCenter,
  FlexBoxAlign,
  FlexBoxSpaceBetween,
} from 'styles/layout';
import Button from 'shared/components/buttons/Button/Button';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import Paths from 'navigation/paths';
import bsnLogo from 'navigation/assets/home-logo.svg';
import DrawerMenu from 'navigation/components/DrawerMenu/DrawerMenu';
import ProfileButton from 'navigation/components/ProfileButton';
import MenuList from 'navigation/components/MenuList/MenuList';
import { HomeLink, HomeLogo, SmallScreenToolbar } from './NavBar.style';

const consultPaths = [
  Paths.Neuro,
  Paths.TeleNeuroHospital,
  Paths.Clinic,
  Paths.Eeg,
];

const NavBar = () => {
  const { onLogout } = useLogout();
  const dispatch = useDispatch();
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const lg = useMediaQuery('(min-width:1050px)');
  const { smDown } = useBreakpoints();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { queryString } = useQueryParams();
  const { isDoctor, isCustomerOnly } = useProfile();
  const { currentConsult } = useSelector((state) => state.consults);
  const {
    data: { viewAndEditSettings, inactivityLogOutThreshold },
  } = useSelector((state) => state.profile);

  useMountEffect(() => {
    if (!isDoctor) return;
    dispatch(consultsActions.doGetConsultRequestCount());
  });

  const fetchConsultCount = React.useCallback(() => {
    if (!isDoctor) return;
    dispatch(consultsActions.getConsultCount());
  }, [dispatch, isDoctor]);

  useMountEffect(fetchConsultCount);

  useUpdateEffect(() => {
    fetchConsultCount();
  }, [
    currentConsult.id,
    currentConsult?.signature?.state,
    currentConsult?.signature?.assignToId,
  ]);

  React.useEffect(() => {
    let timer;
    const timeout = Number(inactivityLogOutThreshold) * 60000;

    if (timeout) {
      timer = setTimeout(onLogout, timeout);
    }

    const iterceptor = api.getInterceptors().use(async (config) => {
      if (!config?.params?.isAutoRefresh && timeout) {
        clearTimeout(timer);
        timer = setTimeout(onLogout, timeout);
      }
      return config;
    });

    return () => {
      clearTimeout(timer);
      api.getInterceptors().eject(iterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactivityLogOutThreshold]);

  useInterval(() => {
    if (!isDoctor) return;
    dispatch(consultsActions.doGetConsultRequestCount(true));
  }, 30000);

  const renderMenuButton = React.useMemo(() => {
    const showBackButton =
      smDown && consultPaths.some((path) => pathname.includes(`${path}/`));
    return (
      <AppBar position="static" color="inherit" elevation={3}>
        <SmallScreenToolbar variant="dense">
          <IconButton
            color="primary"
            onClick={() =>
              showBackButton
                ? navigate({
                    pathname: '..',
                    search: queryString,
                  })
                : setDrawerOpen(true)
            }
          >
            {showBackButton ? <ArrowBack /> : <Menu />}
          </IconButton>
        </SmallScreenToolbar>
      </AppBar>
    );
  }, [smDown, pathname, queryString, navigate]);

  return (
    <>
      <Hidden mdUp>
        {renderMenuButton}
        <DrawerMenu
          isOpen={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
          onLogout={onLogout}
          showProfile={!isCustomerOnly || viewAndEditSettings}
        />
      </Hidden>
      <Hidden smDown>
        <AppBar
          position="static"
          color="inherit"
          elevation={3}
          sx={{ zIndex: 5 }}
        >
          <Toolbar>
            <FlexBoxSpaceBetween width="100%">
              <FlexBoxAlign>
                <FlexBoxCenter width={lg ? 210 : 'auto'}>
                  <HomeLink to="/">
                    <HomeLogo src={bsnLogo} alt="Blue Sky Link Logo" />
                  </HomeLink>
                </FlexBoxCenter>
                <MenuList />
              </FlexBoxAlign>
              <FlexBoxAlign>
                {(!isCustomerOnly || viewAndEditSettings) && <ProfileButton />}
                <Button onClick={onLogout}>Logout</Button>
              </FlexBoxAlign>
            </FlexBoxSpaceBetween>
          </Toolbar>
        </AppBar>
      </Hidden>
    </>
  );
};

export default NavBar;
