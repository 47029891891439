import _isEmpty from 'lodash/isEmpty';

import { isEmptyValue } from 'features/consults/utils/validators';
import { PAGINATION_PAGE_SIZE, ALLOW_EMPTY_KEY_LIST } from 'utils/constants';

export const isEmpty = (value) => {
  if (['number', 'boolean'].includes(typeof value)) return false;
  return _isEmpty(value);
};

export const removeEmptyParams = (obj) =>
  Object.entries(obj).reduce(
    (res, [key, value]) =>
      isEmpty(value) || (ALLOW_EMPTY_KEY_LIST.includes(key) && value === null)
        ? res
        : {
            ...res,
            [key]:
            value === 'unassigned' && ALLOW_EMPTY_KEY_LIST.includes(key) ? '' : value,
          },
    {},
  );

export const buildQueryParams = ({
  page = 1,
  sortBy,
  sortOrder = 'asc',
  pageSize = PAGINATION_PAGE_SIZE,
  filters = {},
  ...moreParams
}) => ({
  ...removeEmptyParams(filters),
  ...moreParams,
  pageIndex: page ? page - 1 : null,
  orderBy: sortBy,
  orderDirection: sortOrder,
  pageSize,
});

const getPatchValue = (path, value) => {
  if (isEmptyValue(value)) {
    return {
      op: 'remove',
      path,
    };
  }
  return {
    op: 'replace',
    path,
    value,
  };
};

export const mapConsultFullChange = (values) =>
  Object.entries(values).map(([key, { tab, value }]) =>
    getPatchValue(`/${tab}/${key}`, value),
  );

export const mapConsultChanges = (tab, changes) =>
  Object.keys(changes).map((key) => {
    const path = `/${tab}/${key}`;
    const value = changes[key];
    return getPatchValue(path, value);
  });
