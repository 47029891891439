import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useMountEffect, useProfile } from 'shared/hooks';
import { consultsActions } from 'features/consults/store/slice';
import { FlexBoxAlign, FlexBoxColumn } from 'styles/layout';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { TIMESTAMP_FORMAT, formatDate } from 'utils/dates';
import { MacroTypes } from 'utils/enums';
import MacrosList from './MacrosList';
import MacrosForm from './MacrosForm';

const ConsultMacros = ({ consultId, consultType, userId }) => {
  const dispatch = useDispatch();
  const { profileData } = useProfile();
  const { data: macros } = useSelector(({ consults }) => consults.macros);

  const { data: sharedMacros } = useSelector(
    ({ consults }) => consults.sharedMacros,
  );

  useMountEffect(() => {
    dispatch(
      consultsActions.listMacros({
        pageSize: MAX_PAGE_SIZE,
        filters: { type: consultType, macroType: MacroTypes.PERSONAL },
      }),
    );
    dispatch(
      consultsActions.listSharedMacros({
        pageSize: MAX_PAGE_SIZE,
        filters: { type: consultType, macroType: MacroTypes.SHARED },
      }),
    );
  });

  const applySelectedMacro = (macroId) => {
    dispatch(
      consultsActions.applyMacro({ consultId, consultMacroId: macroId }),
    );
  };

  const addNewMacro = ({ macroName, macroType }) => {
    dispatch(
      consultsActions.addNewMacro({
        consultId,
        name: macroName,
        macroType,
        type: consultType,
        createdAt: formatDate(new Date(), TIMESTAMP_FORMAT),
        createdById: userId,
      }),
    );
  };

  return (
    <FlexBoxColumn py={2}>
      <MacrosList
        items={sharedMacros}
        type={MacroTypes.SHARED}
        onSelect={applySelectedMacro}
      />
      <MacrosList items={macros} onSelect={applySelectedMacro} />
      <FlexBoxAlign>
        <MacrosForm
          createSharedMacros={profileData.createSharedMacros}
          onSubmit={addNewMacro}
        />
      </FlexBoxAlign>
    </FlexBoxColumn>
  );
};

ConsultMacros.propTypes = {
  consultId: PropTypes.string.isRequired,
  consultType: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default ConsultMacros;
