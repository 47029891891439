import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { useFormState } from 'shared/hooks';
import { ServiceTypeEnum } from 'utils/enums';
import { consultsActions } from 'features/consults/store/slice';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import QualityReviewPanel from 'features/consults/shared/tabs/Signature/QualityReviewPanel';

const QualityForm = ({ consult, disabled }) => {
  const dispatch = useDispatch();

  const { setFormValues, getValues, ...moreForm } = useFormState(
    yup.object().shape({}),
  );

  const withOptions = React.useMemo(
    () =>
      [ServiceTypeEnum.NEURO, ServiceTypeEnum.TELE_NEURO].includes(
        consult.type,
      ),
    [consult.type],
  );

  React.useEffect(() => {
    setFormValues(consult?.signature);
  }, [setFormValues, consult?.signature]);

  const saveQualityChange = React.useCallback(
    (qualityValues) => {
      dispatch(
        consultsActions.editConsult({
          consultId: consult.id,
          tab: CONSULT_TABS.SIGNATURE,
          changes: Object.entries(qualityValues).reduce(
            (res, [key, value]) => ({ ...res, [key]: value }),
            {},
          ),
        }),
      );
    },
    [consult.id, dispatch],
  );

  const onFieldChange = React.useCallback(
    (field) => {
      saveQualityChange({ [field]: getValues(field) });
    },
    [saveQualityChange, getValues],
  );

  return (
    <QualityReviewPanel
      form={{ ...moreForm, getValues, setFormValues }}
      onChange={saveQualityChange}
      onFieldChange={onFieldChange}
      disabled={disabled}
      withOptions={withOptions}
    />
  );
};

QualityForm.propTypes = {
  consult: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    signature: PropTypes.shape({}).isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default QualityForm;
