import styled from '@emotion/styled';

import colors from 'styles/colors';
import { Box, FlexBox, FlexBoxColumnFull } from 'styles/layout';
import { darkerNeutralColor } from 'styles/tables';

export const Container = styled(FlexBox)`
  background: ${({ _index }) =>
    _index % 2 === 0 ? colors.white : colors.neutral};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${darkerNeutralColor};
  }
`;

export const ConsultInfoContainer = styled(FlexBoxColumnFull)`
  justify-content: space-around;
`;

export const Column = styled(Box)`
  width: ${({ width }) => width || '15%'};
  min-width: 50px;
  word-break: break-word;
  padding-right: 10px;
`;

export const BoldColumn = styled(Column)`
  font-weight: 500;
`;
