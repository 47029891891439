import React from 'react';
import PropTypes from 'prop-types';

import { FlexBox, FlexBoxWrap } from 'styles/layout';
import Select from 'shared/components/forms/Select/ControlledSelect';
import { ServiceShortEnum, ServiceTypeLabels } from 'utils/enums';

import { FilterElement } from './ConsultFilters.style';
import ConsultServiceFilter from './ConsultServiceFilter';
import { serviceFilters } from './data';

const ConsultMainFilters = ({
  filters,
  onFilter,
  onResetFilters,
  formProps,
}) => (
  <FlexBox>
    <FlexBoxWrap>
      <FilterElement>
        <Select
          control={formProps.control}
          name="service"
          placeholder="-- Service --"
          options={Object.entries(ServiceShortEnum).map(([key, value]) => ({
            text: ServiceTypeLabels[key],
            value,
          }))}
          onChange={({ target }) =>
            onResetFilters({ [target.name]: target.value })
          }
        />
      </FilterElement>
      {serviceFilters(filters.service)?.map((filter) => (
        <FilterElement key={filter.name}>
          <ConsultServiceFilter
            filter={filter}
            onFilter={onFilter}
            sourcePage="Admin"
            formProps={formProps}
            consultType={filters.service}
          />
        </FilterElement>
      ))}
    </FlexBoxWrap>
  </FlexBox>
);

ConsultMainFilters.propTypes = {
  filters: PropTypes.shape({
    service: PropTypes.string,
  }).isRequired,
  formProps: PropTypes.shape({
    control: PropTypes.shape({}),
  }),
  onFilter: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
};

export default ConsultMainFilters;
