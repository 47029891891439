import styled from '@emotion/styled';

import Button from 'shared/components/buttons/Button/Button';
import colors from 'styles/colors';
import { Box } from 'styles/layout';

export const CardWrapper = styled(Box)`
  overflow-y: hidden;
  position: relative;

  ${({ minHeight }) => minHeight && 'z-index: 10;'}
`;

export const ExpandButtonWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${colors.neutral};
  text-align: center;
  border: 1px solid ${colors.transparent};

  ${({ _today }) =>
    _today === 'today' &&
    `
    border-bottom-color: ${colors.primary};
    border-left-color: ${colors.primary};
    border-right-color: ${colors.primary};
  `}
`;

export const ExpandButton = styled(Button)`
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: none;
`;
