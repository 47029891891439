import styled from '@emotion/styled';

import { FlexBoxColumn } from 'styles/layout';
import { tableRowStyles } from 'styles/tables';

export const NoResultsMessage = styled.p`
  font-size: 15px;
  margin: 10px 0;
  text-align: center;
`;

export const ListWrapper = styled(FlexBoxColumn)`
  ${tableRowStyles}
`;
