import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { useFormState } from 'shared/hooks';

import { FlexBoxAlign } from 'styles/layout';
import { FilterElement } from 'features/admin/pages/Consults/ConsultList/ConsultListFilters/ConsultFilters.style';
import FacilitySelect from 'shared/components/data/forms/FacilitySelect';
import HealthSystemSelect from 'shared/components/data/forms/HealthSystemSelect';
import ProviderSelect from 'shared/components/data/forms/ProviderSelect';

const schema = yup.object().shape({});

const ProviderListFilters = ({ onFilterChanged }) => {
  const { control, handleSubmit, watch } = useFormState(schema);
  const healthSystem = watch('healthSystemId');

  return (
    <FlexBoxAlign flexWrap="wrap">
      <FilterElement>
        <ProviderSelect
          control={control}
          name="providerId"
          handleChange={handleSubmit(onFilterChanged)}
        />
      </FilterElement>
      <FilterElement>
        <HealthSystemSelect
          control={control}
          name="healthSystemId"
          handleChange={handleSubmit(onFilterChanged)}
        />
      </FilterElement>
      <FilterElement>
        <FacilitySelect
          control={control}
          name="facilityId"
          handleChange={handleSubmit(onFilterChanged)}
          healthSystem={healthSystem}
          requiredSystem
        />
      </FilterElement>
    </FlexBoxAlign>
  );
};

ProviderListFilters.propTypes = {
  onFilterChanged: PropTypes.func,
};

export default ProviderListFilters;
