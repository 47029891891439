import React from 'react';
import PropTypes from 'prop-types';
import MaterialIconButton from '@mui/material/IconButton';

const IconButton = ({ children, onClick, ...props }) => (
  <MaterialIconButton onClick={onClick} {...props}>
    {children}
  </MaterialIconButton>
);

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default IconButton;
