import { useMemo, useEffect } from 'react';

import api from 'api';
import { useRequest } from 'shared/hooks';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { ConsultType2RoleEnum, ConsultShortType2RoleEnum, doctorRoles, UNNASIGNED_ITEM } from 'utils/enums';
import { mapDoctorNames } from 'utils/mappers';

const useConsultDoctorsAdmin = (consultType, displayUnassigned = false) => {
  const { data, doRequest } = useRequest(api.getUserNames);

  const roles = consultType ? ConsultType2RoleEnum[consultType] ?? ConsultShortType2RoleEnum[consultType] : doctorRoles;
  useEffect(() => {
    doRequest({
      pageSize: MAX_PAGE_SIZE,
      filters: {
        roles,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);


  const doctors = useMemo(() => mapDoctorNames(data?.users || []), [data]);

  return {
    doctors: displayUnassigned ? [UNNASIGNED_ITEM, ...doctors] : doctors,
  };
};

export default useConsultDoctorsAdmin;
