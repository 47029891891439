import React from 'react';
import PropTypes from 'prop-types';

import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';

import formFields from './formFields';
import {
  qualityReviewPanel,
  qualityCommentPanel,
  qualityOptionsPanel,
} from './data';

const QualityReviewPanel = ({
  form: { control, register, watch, getValues, setFormValues, getFieldError },
  disabled,
  withOptions,
  onChange,
  onFieldChange,
}) => {
  const qualitySection = watch(formFields.qualityReview.name);

  const onQualityChange = React.useCallback(
    (fieldName) => {
      const currentValues = getValues();

      const resetValues = {
        [fieldName]: currentValues[fieldName],
        [formFields.qualityComment.name]: null,
        ...(withOptions
          ? qualityOptionsPanel.elements.reduce(
              (res, el) => ({ ...res, [el.name]: [] }),
              {},
            )
          : {}),
      };
      onChange(resetValues);
      setFormValues({ ...currentValues, ...resetValues });
    },
    [getValues, setFormValues, onChange, withOptions],
  );

  return (
    <>
      <QuestionsPanel
        {...qualityReviewPanel}
        control={control}
        register={register}
        getFieldError={getFieldError}
        onChange={onQualityChange}
        disabled={disabled}
      />
      {!!qualitySection?.length && (
        <>
          {withOptions && (
            <QuestionsPanel
              {...qualityOptionsPanel}
              control={control}
              register={register}
              getFieldError={getFieldError}
              onChange={onFieldChange}
              disabled={disabled}
            />
          )}
          <QuestionsPanel
            {...qualityCommentPanel}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={onFieldChange}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
};

QualityReviewPanel.propTypes = {
  form: PropTypes.shape({
    getValues: PropTypes.func,
    watch: PropTypes.func,
    setFormValues: PropTypes.func,
    control: PropTypes.shape({}),
    register: PropTypes.func,
    getFieldError: PropTypes.func,
  }),
  disabled: PropTypes.bool,
  withOptions: PropTypes.bool,
  onChange: PropTypes.func,
  onFieldChange: PropTypes.func,
};

export default QualityReviewPanel;
