import React from 'react';
import PropTypes from 'prop-types';

import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';

const DownloadButton = ({ title, onExport, ...props }) => {
  const [isDownloading, setDownloading] = React.useState(false);

  const handleExport = async () => {
    setDownloading(true);
    await onExport();
    setDownloading(false);
  };

  return (
    <LoadingButton
      {...props}
      color="secondary"
      loading={isDownloading}
      variant="contained"
      startIcon={<DownloadIcon />}
      loadingPosition="start"
      onClick={handleExport}
    >
      {title}
    </LoadingButton>
  );
};

DownloadButton.propTypes = {
  title: PropTypes.string,
  onExport: PropTypes.func,
};

export default DownloadButton;
