import React from 'react';
import PropTypes from 'prop-types';

import useConsultDoctors from 'features/consults/hooks/useConsultDoctors';
import Autocomplete from 'shared/components/forms/Autocomplete';

const ProviderSelect = ({
  placeholder = '-- Provider --',
  consultType,
  ...moreProps
}) => {
  const { doctors } = useConsultDoctors(consultType, moreProps.displayUnassigned);

  return (
    <Autocomplete {...moreProps} placeholder={placeholder} options={doctors} />
  );
};

ProviderSelect.propTypes = {
  placeholder: PropTypes.string,
  consultType: PropTypes.string,
};

export default ProviderSelect;
