import sortFields from 'api/sortFields';
import { useTableState, useFilterState } from 'shared/hooks';
import { ConsultRequestStatusEnum } from 'utils/enums';

const useConsultRequestListState = () => {
  const { page, handlePageChange, sortBy, sortOrder, setQueryParams } =
    useTableState(sortFields.consultRequests.createdAt, 'desc');
  const { filters, handleFilter } = useFilterState({
    facilityId: null,
    query: null,
    state: ConsultRequestStatusEnum.OPEN,
    consultType: null,
  });

  return {
    page,
    filters,
    handlePageChange,
    sortBy,
    sortOrder,
    handleFilterChange: handleFilter,
    setQueryParams,
  };
};

export default useConsultRequestListState;
