import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ProgressBar from 'shared/components/feedback/ProgressBar/ProgressBar';
import {
  ServiceTypeLabels,
  ConsultRequestStatusEnum,
  ConsultRequestPagingStatusEnum,
} from 'utils/enums';
import { US_DATE_FORMAT, TIME_FORMAT, formatDate } from 'utils/dates';
import { Box, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import {
  Container,
  ConsultInfoContainer,
  Column,
  BoldColumn,
} from './ConsultRequest.style';
import ConsultRequestDelete from './ConsultRequestDelete';
import ProviderPhoto from './ConsultRequestProviderPhoto';

const dateDisplay = (date) =>
  date && formatDate(new Date(date), `${US_DATE_FORMAT} - ${TIME_FORMAT}`);

const getConsultRequestPagingStatus = ({
  state: consultRequestStatus,
  pagingState,
  acceptedAt,
}) => {
  switch (consultRequestStatus) {
    case ConsultRequestStatusEnum.OPEN:
      return ConsultRequestPagingStatusEnum[pagingState];
    default:
      return (
        <>
          <Box>Accepted at</Box>
          <Box>{dateDisplay(acceptedAt)}</Box>
        </>
      );
  }
};

const getConsultRequestProgress = (consultRequestStatus) => {
  switch (consultRequestStatus) {
    case ConsultRequestStatusEnum.ACCEPTED:
      return 50;
    case ConsultRequestStatusEnum.CLOSED:
      return 100;
    default:
      return 0;
  }
};

const ConsultRequest = ({
  id,
  firstName,
  lastName,
  age,
  facilityName,
  type,
  providerId,
  providerFirstName,
  providerLastName,
  createdAt,
  state,
  pagingState,
  itemIndex,
  closedAt,
  acceptedAt,
  createdByFirstName,
  createdByLastName,
}) => {
  const providerName =
    providerFirstName && providerLastName
      ? `${providerFirstName} ${providerLastName}`
      : '';

  const renderProviderPhoto = useMemo(
    () => <ProviderPhoto providerId={providerId} />,
    [providerId],
  );

  return (
    <Container p={2} _index={itemIndex}>
      <ConsultInfoContainer>
        <FlexBoxSpaceBetweenCenter width="100%">
          <BoldColumn>
            <Box>{`${firstName} ${lastName}`}</Box>
            <Box>{age && `${age} years old`}</Box>
          </BoldColumn>
          <BoldColumn>
            <Box>{facilityName}</Box>
            <Box>{ServiceTypeLabels[type]}</Box>
          </BoldColumn>
          <Column>
            <Box>Created By:</Box>
            <Box>{`${createdByFirstName} ${createdByLastName}`}</Box>
          </Column>
          <Column>
            <Box>Provider:</Box>
            <Box>{providerName}</Box>
          </Column>
          <Column>
            <Box>Created at</Box>
            <Box>{dateDisplay(createdAt)}</Box>
          </Column>
          <Column>
            {getConsultRequestPagingStatus({
              state,
              pagingState,
              acceptedAt,
            })}
          </Column>
          {closedAt && (
            <Column>
              <Box>Consult Closed at</Box>
              <Box>{dateDisplay(closedAt)}</Box>
            </Column>
          )}
          <Column width="25px">
            {state === ConsultRequestStatusEnum.OPEN && (
              <ConsultRequestDelete requestId={id} />
            )}
          </Column>
        </FlexBoxSpaceBetweenCenter>
        <Box mt={0.5}>
          <ProgressBar progress={getConsultRequestProgress(state)} />
        </Box>
      </ConsultInfoContainer>
      {renderProviderPhoto}
    </Container>
  );
};

ConsultRequest.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  age: PropTypes.number,
  facilityName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  providerId: PropTypes.number,
  providerFirstName: PropTypes.string,
  providerLastName: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  acceptedAt: PropTypes.string,
  closedAt: PropTypes.string,
  state: PropTypes.string.isRequired,
  pagingState: PropTypes.string,
  itemIndex: PropTypes.number.isRequired,
  createdByFirstName: PropTypes.string,
  createdByLastName: PropTypes.string,
};

export default ConsultRequest;
