import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { NavItem, Link, NotificationBadge } from './NavLink.style';

const NavLink = ({
  text,
  to,
  isActive = false,
  consultCount = null,
  onClick = null,
}) => {
  const renderLink = React.useMemo(
    () => (
      <Link
        component={RouterLink}
        to={to}
        onClick={onClick}
        _active={isActive ? 'active' : ''}
      >
        {text}
      </Link>
    ),
    [isActive, onClick, text, to],
  );

  return (
    <NavItem>
      <NotificationBadge badgeContent={consultCount}>
        {renderLink}
      </NotificationBadge>
    </NavItem>
  );
};

NavLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  consultCount: PropTypes.node,
  onClick: PropTypes.func,
};

export default NavLink;
