import { ServiceTypeEnum } from 'utils/enums';
import {
  getOptions4Type,
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields, { teleNeuroPmhFields } from './formFields';

const neuroLeftElements = (consultType) => [
  mapChipFieldOptions(
    consultType === ServiceTypeEnum.TELE_NEURO
      ? teleNeuroPmhFields.pastMedicalHistoryPredefined
      : fields.pastMedicalHistoryPredefined,
  ),
  fields.pastMedicalHistoryFreeText,
  mapChipFieldOptions(fields.pastSurgicalHistoryPredefined),
  fields.pastSurgicalHistoryFreeText,
  mapChipFieldOptions(fields.familyHistoryFirstDegreeRelativePredefined),
  fields.familyHistoryFirstDegreeRelativeFreeText,
];

export const leftPanel = (consultType) => ({
  elements:
    consultType === ServiceTypeEnum.TELE_NEURO
      ? [
          ...neuroLeftElements(consultType),
          mapChipFieldOptions(teleNeuroPmhFields.homeMeds),
          teleNeuroPmhFields.homeMedsFreeText,
        ]
      : neuroLeftElements(consultType),
});

export const rightPanel = (consultType) => ({
  elements: [
    mapToggleFieldOptions(fields.socialHistorySmoking),
    mapToggleFieldOptions(fields.socialHistoryDrinking),
    mapChipFieldOptions(fields.socialHistoryDrugsPredefined),
    mapChipFieldOptions(fields.socialHistoryLivingPredefined),
    fields.socialHistoryLivingFreeText,
    mapChipFieldOptions(
      getOptions4Type(fields.medicationsPredefined, consultType),
    ),
    fields.medicationsFreeText,
    mapChipFieldOptions(fields.allergiesPredefined),
    fields.allergiesFreeText,
  ],
});
