import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import sortFields from 'api/sortFields';
import {
  useMountEffect,
  useUpdateEffect,
  useTableState,
  useFilterState,
} from 'shared/hooks';
import { adminActions } from 'features/admin/store/slice';
import { getTableHeaders, prepareTableData } from 'utils/tables';

import AddButton from 'navigation/components/AddButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import {
  Box,
  FlexBoxColumn,
  FlexBoxSpaceBetween,
  FlexBoxSpaceBetweenCenter,
} from 'styles/layout';

import HealthSystemsListFilters from './HealthSystemsListFilters';
import { formFields } from './utils';

const healthSystemTableColumns = [
  {
    header: {
      id: sortFields.healthSystems.name,
      value: 'Healthcare System Name',
      isSortable: true,
    },
    dataKey: 'name',
    width: '80%',
  },
  {
    header: {
      id: sortFields.healthSystems.facilityCount,
      value: 'Number of Facilities',
      isSortable: true,
    },
    dataKey: 'facilitiesCount',
    align: 'center',
  },
];

const defaultFilterValues = Object.values(formFields).reduce(
  (res, key) => ({ ...res, [key]: '' }),
  {},
);
const HealthSystemsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: healthSystems, pageCount } = useSelector(
    ({ admin }) => admin.systems,
  );
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setPage,
    setQueryParams,
  } = useTableState(sortFields.healthSystems.name);

  const { filters, handleFilter } = useFilterState(
    defaultFilterValues,
    setPage,
  );

  const fetchList = React.useCallback(() => {
    dispatch(
      adminActions.listHealthSystems({ page, sortBy, sortOrder, filters }),
    );
    setQueryParams({
      ...filters,
      page,
      sortBy,
      sortOrder,
    });
  }, [dispatch, page, sortBy, sortOrder, filters, setQueryParams]);

  useMountEffect(fetchList);

  useUpdateEffect(() => {
    fetchList();
  }, [page, sortBy, sortOrder, filters]);

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter>
        <PageTitle>Healthcare Systems</PageTitle>
        <Box>
          <AddButton to="add">Add System</AddButton>
        </Box>
      </FlexBoxSpaceBetweenCenter>

      <FlexBoxSpaceBetween>
        <HealthSystemsListFilters
          onFilter={handleFilter}
          defaultFilters={filters}
        />
      </FlexBoxSpaceBetween>

      <FlexBoxColumn>
        <PaginatedTable
          headings={getTableHeaders(healthSystemTableColumns)}
          data={prepareTableData(healthSystems, healthSystemTableColumns)}
          fallbackMsg="No Healthcare systems found"
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onClickRow={(id) => navigate(`${id}`)}
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default HealthSystemsPage;
