import React from 'react';
import PropTypes from 'prop-types';
import { signIn } from 'aws-amplify/auth';
import styled from '@emotion/styled';
import {
  Authenticator,
  Button,
  Divider,
  Heading,
  Image,
  View,
  Text,
  useAuthenticator,
  useTheme,
} from '@aws-amplify/ui-react';

import CorporateLogin from 'auth/components/CorporateLogin/CorporateLogin';
import GoogleLoginBtn from 'auth/components/GoogleLoginBtn/GoogleLoginBtn';

import colors from 'styles/colors';

import authBackground from 'auth/assets/bsl-background.jpg';
import logo from 'auth/assets/bsl-logo.svg';

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import 'auth/amplify/config';

import handleMFASelect from './handleMFASelect';

const services = {
  async handleSignIn(formData) {
    const user = await signIn(formData);
    if (user?.nextStep?.signInStep === 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION') {
      try {
        return await handleMFASelect(user);
      } catch (error) {
        window.location.reload();
      }
    }
    return user;
  },
};

export const StyledAuthenticator = styled(Authenticator)`
  background: url(${authBackground}) no-repeat center;
  background-size: cover;
  min-height: 100vh;
  [data-amplify-container] {
    background: white;
    box-shadow:
      0px 3px 3px -2px rgb(0 0 0 / 20%),
      0px 3px 4px 0px rgb(0 0 0 / 14%),
      0px 1px 8px 0px rgb(0 0 0 / 12%);
    width: 420px;
    [data-amplify-router] {
      background-color: white;
      border: none;
      box-shadow: none;
      [data-amplify-form] {
        padding-bottom: 0;
      }
    }
  }

  .amplify-button--primary {
    background-color: ${colors.primary};
    text-transform: uppercase;
    font-weight: bold !important;
    &:hover {
      background-color: ${colors.secondary};
    }
    &:disabled {
      background-color: ${colors.secondary};
    }
  }
`;

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={`${tokens.space.large} 0 0 0`}>
        <Image alt="BSN Logo" width="300px" src={logo} />
      </View>
    );
  },
  SignIn: {
    Footer() {
      const { toForgotPassword } = useAuthenticator();
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={`${tokens.space.xs} 0`}>
          <Button
            onClick={toForgotPassword}
            variation="link"
            size="regular"
            color={colors.neutralDark}
          >
            FORGOT PASSWORD?
          </Button>
          <View padding={`${tokens.space.medium} ${tokens.space.xl}`}>
            <Divider label="OR" />
            <View textAlign="left" padding={`${tokens.space.large} 0`}>
              <CorporateLogin />
            </View>
            <Divider label="OR" />
            <View textAlign="left" padding={`${tokens.space.large} 0`}>
              <GoogleLoginBtn />
            </View>
          </View>
        </View>
      );
    },
  },
  ForgotPassword: {
    Header() {
      return null;
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <>
          <Heading textAlign="center" padding="0 0 0.5rem">
            Do Not Leave This Page.
          </Heading>
          <Text>If your account is found, a code was sent to your email.</Text>
          <Text>
            Please check your email and then enter the code below to reset your
            password.
          </Text>
          <Text>
            Please note that this code could take up to 5 minutes to arrive.
          </Text>
        </>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      return null;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      label: 'Email Address or Username',
      placeholder: 'Email or Username',
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      label: 'Enter your email or username',
      placeholder: 'Email or username',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'Confirm Code *',
    },
  },
};

const AwsAuthenticator = ({ children }) => (
  <StyledAuthenticator
    components={components}
    formFields={formFields}
    services={services}
    hideSignUp
  >
    {children}
  </StyledAuthenticator>
);

AwsAuthenticator.propTypes = {
  children: PropTypes.node,
};

export default AwsAuthenticator;