import { mapToggleFieldOptions } from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/Signature/formFields';

export default {
  elements: [
    fields.attestationOfConsultCompletion,
    mapToggleFieldOptions(fields.consent),
    fields.totalFaceToFaceTime,
    fields.criticalCareTime,
    fields.billingCode,
  ],
};
