import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import InputField from 'shared/components/forms/InputField/InputField';

const PasswordField = React.forwardRef(
  (
    {
      name = 'password',
      label = 'Password',
      autoComplete = 'off',
      error = null,
      disabled = false,
      fullWidth = true,
      ...fieldProps
    },
    ref,
  ) => {
    const [isPasswordVisible, setPasswordVisible] = React.useState(false);

    const togglePasswordField = () => {
      setPasswordVisible(!isPasswordVisible);
    };

    const renderPasswordIcon = () => (
      <InputAdornment position="end">
        <IconButton
          edge="end"
          disabled={disabled}
          onClick={togglePasswordField}
        >
          {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );

    return (
      <InputField
        ref={ref}
        name={name}
        id={name}
        label={label}
        type={isPasswordVisible ? 'text' : 'password'}
        autoComplete={autoComplete}
        endAdornment={renderPasswordIcon()}
        error={error}
        disabled={disabled}
        fullWidth={fullWidth}
        {...fieldProps}
      />
    );
  },
);

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default PasswordField;
