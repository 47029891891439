import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';

import { Autocomplete as StyledAutocomplete } from './styles';

const getFieldValue = ({ value, multiple, options }) => {
  if (!multiple)
    return options.find(({ id }) => String(id) === String(value)) || null;
  return options.filter(({ id }) => value.includes(id));
};

const Autocomple = ({
  control,
  name,
  label = null,
  options = [],
  disabled,
  size = 'small',
  required = false,
  handleChange,
  groupBy,
  placeholder,
  error = null,
  multiple = false,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={multiple ? [] : ''}
    render={({ field }) => (
      <StyledAutocomplete
        {...props}
        {...field}
        id={name}
        multiple={multiple}
        disabled={disabled}
        options={options}
        getOptionLabel={(option) => option.name || ''}
        value={getFieldValue({
          value: field.value,
          multiple,
          options,
        })}
        size={size}
        onChange={(_, newValue) => {
          const updateValue = multiple
            ? newValue.map(({ id }) => id)
            : newValue?.id ?? null;
          field.onChange(updateValue);
          if (handleChange) handleChange(updateValue);
        }}
        groupBy={groupBy}
        renderInput={(params) => (
          <>
            {!!label && (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}
                error={error}
              >
                {label}
              </InputLabel>
            )}
            <TextField {...params} error={!!error} placeholder={placeholder} />
            {error && (
              <ErrorMsg text={error.message} dense={size === 'small'} />
            )}
          </>
        )}
      />
    )}
  />
);

Autocomple.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node,
      name: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  groupBy: PropTypes.func,
  error: PropTypes.shape({}),
  multiple: PropTypes.bool,
};

export default Autocomple;
