import styled from '@emotion/styled';

import { Box, FlexBoxAlignWrap } from 'styles/layout';

export const FiltersWrapper = styled(FlexBoxAlignWrap)`
  max-width: calc(100vw - 600px);
`;

export const FilterBox = styled(Box)`
  margin: 4px 8px 4px 0;
  width: 220px;
`;
