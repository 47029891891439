import styled from '@emotion/styled';

export const WrapIcon = styled.span`
  margin-left: -5px;
  margin-right: 5px;
  display: flex;
`;

export const SectionHeader = styled.p`
  margin-bottom: 5px;
`;
