import React from 'react';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useProfile, useBreakpoints } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';
import BackButton from 'navigation/components/BackButton/BackButton';
import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';

import profileTabs from 'features/profile/components/profileTabs';
import { FlexBoxAlign } from 'styles/layout';

const Profile = () => {
  const { smDown } = useBreakpoints();
  const { isCustomerOnly, profileData, mfaList } = useProfile();
  const [tab, setTab] = React.useState('contactInformation');
  const { goBack } = useGoBack();

  const canViewProfile = React.useMemo(
    () => !isCustomerOnly || profileData.viewAndEditSettings,
    [isCustomerOnly, profileData],
  );

  const tabs = React.useMemo(
    () => Object.entries(profileTabs({ mfaList })),
    [mfaList],
  );

  if (!canViewProfile) return null;

  return (
    <MainWrapper sm={smDown}>
      <FlexBoxAlign>
        <BackButton onBack={goBack} />
        <PageTitle>Profile</PageTitle>
      </FlexBoxAlign>
      <TabContext value={tab}>
        <TabList
          variant="scrollable"
          orientation="horizontal"
          indicatorColor="primary"
          onChange={(_, newTab) => setTab(newTab)}
          aria-label="profile tabs"
          textColor="primary"
        >
          {tabs.map(([key, { label, disabled, icon }]) => (
            <Tab
              sx={{ display: disabled ? 'none' : 'flex' }}
              key={key}
              label={label}
              value={key}
              icon={icon}
            />
          ))}
        </TabList>
        {tabs.map(([key, { label, component: Component }]) => (
          <TabPanel key={key} value={key}>
            <Component title={label} />
          </TabPanel>
        ))}
      </TabContext>
    </MainWrapper>
  );
};

export default Profile;
