import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import useSharedHandlers from 'features/consults/hooks/useSharedHandlers';

import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import TextInput from 'features/consults/components/tabFields/TextInput/TextInput';
import Autocomplete from 'shared/components/forms/Autocomplete';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import formFields, {
  phoneAgreementValue,
} from 'features/consults/shared/tabs/Demographics/formFields';
import { Box } from 'styles/layout';

import schema from './validationSchema';
import {
  leftPanel1,
  leftPanel2,
  leftPanel3,
  rightPanel,
  phoneOnlyPanel,
  phoneOnlyFreetextPanel,
  phoneAgreementPanel,
} from './data';

const {
  firstName,
  lastName,
  dateOfBirth,
  facility,
  phoneOnly,
  phoneOnlyFreetext,
  phoneAgreement,
} = formFields;

const DemographicsNeuro = ({ errors }) => {
  const {
    control,
    clearErrors,
    getFieldError,
    getValues,
    register,
    setValue,
    setFormValues,
    watch,
    trigger,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    consultMainType,
    handleFieldChange,
    isEditable,
    isMacro,
    saveConsultChange,
  } = useConsultData(CONSULT_TABS.DEMOGRAPHICS, getValues);
  const {
    facilities,
    handleNameChange,
    handleFacilityChange,
    handleDateOfBirthChange,
  } = useSharedHandlers(
    consult.id,
    consultMainType,
    saveConsultChange,
    setValue,
    getValues,
  );
  const phoneOnlySection = watch(phoneOnly.name);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  useEffect(() => {
    clearErrors();
    if (errors) trigger(errors);
  }, [errors, clearErrors, trigger]);

  const handlePhoneOnlyChange = (fieldName) => {
    const fieldValue = getValues(fieldName);
    const agreementValue = fieldValue?.length ? [phoneAgreementValue] : [];

    setValue(phoneAgreement.name, agreementValue);
    saveConsultChange({
      [fieldName]: fieldValue,
      [phoneOnlyFreetext.name]: null,
      [phoneAgreement.name]: agreementValue,
    });
  };

  const isDisabled = React.useMemo(
    () => isMacro || !isEditable,
    [isMacro, isEditable],
  );

  return (
    <GridWrapper spacing={10}>
      <Grid item sm={12} lg={7}>
        <QuestionsPanel
          {...leftPanel1}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
        />
        <Box mb={1}>
          <TextInput
            register={register}
            name={firstName.name}
            label={firstName.label}
            required
            disabled={isDisabled}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <TextInput
            register={register}
            label={lastName.label}
            name={lastName.name}
            required
            disabled={isDisabled}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <DatePicker
            control={control}
            name={dateOfBirth.name}
            label={dateOfBirth.label}
            error={getFieldError(dateOfBirth.name)}
            disableFuture
            handleChange={handleDateOfBirthChange}
            disabled={isDisabled}
          />
        </Box>
        <QuestionsPanel
          {...leftPanel2}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
        />
        <Box mb={1}>
          <Autocomplete
            control={control}
            name={facility.name}
            label={facility.label}
            required
            error={getFieldError(facility.name)}
            options={facilities}
            disabled={isDisabled || isMacro}
            handleChange={(value) => {
              handleFacilityChange(value);
              handleFieldChange(facility.name);
            }}
          />
        </Box>
        <QuestionsPanel
          {...leftPanel3}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item sm={12} lg={5}>
        <QuestionsPanel
          {...rightPanel}
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
        />
        <QuestionsPanel
          {...phoneOnlyPanel}
          control={control}
          register={register}
          onChange={handlePhoneOnlyChange}
          getFieldError={getFieldError}
          disabled={isDisabled}
        />
        {!!phoneOnlySection?.length && (
          <>
            <QuestionsPanel
              {...phoneOnlyFreetextPanel}
              control={control}
              register={register}
              onChange={handleFieldChange}
              getFieldError={getFieldError}
              disabled={isDisabled}
            />
            <QuestionsPanel
              {...phoneAgreementPanel}
              control={control}
              register={register}
              onChange={handleFieldChange}
              getFieldError={getFieldError}
              disabled={isDisabled}
            />
          </>
        )}
      </Grid>
    </GridWrapper>
  );
};

DemographicsNeuro.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default DemographicsNeuro;
