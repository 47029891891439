import React from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'api';
import useGoBack from 'navigation/hooks/useGoBack';
import { useFeedback } from 'shared/hooks';

import BackButton from 'navigation/components/BackButton/BackButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import NetworkForm from 'features/admin/components/NetworkForm';

import { FlexBoxColumn, FlexBoxColumnFull, FlexBoxAlign } from 'styles/layout';

const NetworkAddPage = () => {
  const navigate = useNavigate();
  const { goBack } = useGoBack();
  const { clearDisplay, errorDisplay } = useFeedback();

  const onAddNetwork = React.useCallback(
    (values) => {
      clearDisplay();

      api
        .createNetwork(values)
        .then(({ data }) => {
          navigate(`../${data.id}`, { replace: true });
        })
        .catch(() => errorDisplay('Error creating network'));
    },
    [navigate, clearDisplay, errorDisplay],
  );

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton />
        <PageTitle>New Network</PageTitle>
      </FlexBoxAlign>
      <FlexBoxColumnFull p={2}>
        <NetworkForm onSubmit={onAddNetwork} onCancel={() => goBack()} />
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default NetworkAddPage;
