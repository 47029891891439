import { UserRoleEnum, ServiceShortEnum, ServiceTypeEnum } from 'utils/enums';
import invert from 'lodash/invert';

export const consultPaths = Object.entries(ServiceShortEnum).reduce(
  (res, [service, path]) => ({ ...res, [service]: path }),
  {},
);

export const path2Service = invert(consultPaths);

const Paths = {
  Macros: 'macros',
  MyFacility: 'my-facility',
  ConsultRequest: 'consult-request',
  Messages: 'messages',
  Schedule: 'schedule',
  Admin: 'admin',
  Profile: 'profile',
  Restricted: 'restricted',
  ...consultPaths,
};

export default Paths;

export const RoutesForRoleEnum = {
  [UserRoleEnum.NEURO]: {
    priority: 1,
    routes: [Paths[ServiceTypeEnum.NEURO], Paths.Macros, Paths.ConsultRequest],
  },
  [UserRoleEnum.TELE_NEURO]: {
    priority: 2,
    routes: [
      Paths[ServiceTypeEnum.TELE_NEURO],
      Paths.Macros,
      Paths.ConsultRequest,
    ],
  },
  [UserRoleEnum.CLINIC]: {
    priority: 3,
    routes: [Paths[ServiceTypeEnum.CLINIC], Paths.Macros],
  },
  [UserRoleEnum.EEG]: {
    priority: 4,
    routes: [Paths[ServiceTypeEnum.EEG], Paths.Macros, Paths.ConsultRequest],
  },
  [UserRoleEnum.PEDS_EEG]: {
    priority: 5,
    routes: [
      Paths[ServiceTypeEnum.PEDS_EEG],
      Paths.Macros,
      Paths.ConsultRequest,
    ],
  },
  [UserRoleEnum.ADMINISTRATOR]: {
    priority: 6,
    routes: [Paths.Admin],
  },
  [UserRoleEnum.CUSTOMER]: {
    priority: 7,
    routes: [Paths.MyFacility],
  },
};
