import { Amplify } from 'aws-amplify';

Amplify.configure({
  Auth: {
    Cognito: {
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,

      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: process.env.REACT_APP_OAUTH_DOMAIN,
          scopes: ['openid'],
          redirectSignIn: [process.env.REACT_APP_OAUTH_CALLBACK_URL],
          redirectSignOut: [process.env.REACT_APP_OAUTH_CALLBACK_URL],
          responseType: 'code',
        },
      },
    },
  },
  /*
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWSS3_BUCKET, // REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_COGNITO_REGION, // OPTIONAL -  Amazon service region
    },
  },
  */
});

export default Amplify.getConfig();
