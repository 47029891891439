import React from 'react';
import PropTypes from 'prop-types';

import Pagination from 'shared/components/data/Pagination/Pagination';
import { FlexBoxColumnCenter } from 'styles/layout';
import ConsultRequest from 'features/customer/components/ConsultRequest/ConsultRequest';
import { NoResultsMessage, ListWrapper } from './ConsultRequestList.style';

const ConsultRequestList = ({ items, page, totalPages, onPageChange }) => {
  if (!items || items.length === 0) {
    return <NoResultsMessage>No consult requests found</NoResultsMessage>;
  }

  return (
    <FlexBoxColumnCenter>
      <ListWrapper width="100%" my={1.5}>
        {items.map((item, index) => (
          <ConsultRequest key={item.id} {...item} itemIndex={index} />
        ))}
      </ListWrapper>
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </FlexBoxColumnCenter>
  );
};

ConsultRequestList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default ConsultRequestList;
