import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields from './formFields';

export default {
  elements: [
    mapChipFieldOptions(fields.clinicalStatePredefined),
    fields.clinicalStateFreeText,
    mapToggleFieldOptions(fields.photicStimulationPredefined),
    fields.photicStimulationFreeText,
    mapToggleFieldOptions(fields.hyperventilationPredefined),
    mapToggleFieldOptions(fields.durationPredefined),
    fields.durationFreeText,
    mapChipFieldOptions(fields.phasesCapturedPredefined),
    fields.phasesCapturedFreeText,
    mapToggleFieldOptions(fields.ekgPredefined),
    fields.ekgFreeText,
    mapChipFieldOptions(fields.attenuationPredefined),
    fields.attenuationFreeText,
    mapChipFieldOptions(fields.epileptiformAbnormalitiesPredefined),
    fields.epileptiformAbnormalitiesFreeText,
    mapChipFieldOptions(fields.seizurePredefined),
    fields.seizureFreeText,
    fields.artifact,
    mapToggleFieldOptions(fields.stimulationPredefined),
    fields.stimulationFreeText,
    mapChipFieldOptions(fields.impressionPredefined),
    fields.impressionFreeText,
  ],
};
