import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

import { useAssets } from 'shared/hooks';

const avatarStyle = {
  width: '3.6rem',
  height: '3.6rem',
  marginLeft: '1rem',
};

const ConsultRequestProviderPhoto = ({ providerId }) => {
  const { assetUrl } = useAssets();
  return <Avatar src={assetUrl(providerId)} sx={avatarStyle} />;
};

ConsultRequestProviderPhoto.propTypes = {
  providerId: PropTypes.number,
};

export default ConsultRequestProviderPhoto;
