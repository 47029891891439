import {
  format,
  parseISO as fnsParseISO,
  parse,
  isValid,
  differenceInMinutes,
  isBefore,
  subDays,
  startOfMinute,
  startOfMonth,
} from 'date-fns';
import { utcToZonedTime, getTimezoneOffset } from 'date-fns-tz';

export const TIME_FORMAT = 'HH:mm';
export const BROWSER_DATE_FORMAT = 'yyyy-MM-dd';
export const US_DATE_FORMAT = 'MM/dd/yyyy';
export const US_DATE_FORMAT_SMALL = 'MM/dd/yy';
export const TIMESTAMP_FORMAT = `${BROWSER_DATE_FORMAT}'T'${TIME_FORMAT}:ss.SSSXXX`;
export const DEFAULT_FIELD_FORMAT = `${US_DATE_FORMAT} ${TIME_FORMAT}`;

export const formatDate = (date, formatPattern) => format(date, formatPattern);

export const formatToUserTimezone = (date, formatPattern) =>
  `${formatDate(date, formatPattern)} (${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  })`;

export const formatToSpecificTimezone = (date, formatPattern, timezoneOffset) =>
  formatDate(utcToZonedTime(date, timezoneOffset), formatPattern);

export const formatToBrowserDate = (date) => {
  if (!date) {
    return null;
  }

  return formatDate(date, BROWSER_DATE_FORMAT);
};

// https://stackoverflow.com/a/22835394
export const formatDateString = (
  dateInput,
  formatPattern = TIMESTAMP_FORMAT,
) => {
  if (!dateInput) return null;
  const [dateString] = dateInput.split('T');
  const [year, month, day] = dateString.split('-');
  const date = new Date(year, month - 1, day);

  return formatDate(date, formatPattern);
};

export const isValidDate = (date) => date == null || isValid(date);

export const parseISO = (dateString) => dateString && fnsParseISO(dateString);

export const formatISO = (dateString, formatStr = BROWSER_DATE_FORMAT) => {
  const parsedISO = parseISO(dateString);
  if (!parsedISO) return null;
  return formatDate(parsedISO, formatStr);
};

export const dateDiffFormatted = (start, end) => {
  const startDate = startOfMinute(start);
  const endDate = startOfMinute(end);
  if (isBefore(endDate, startDate)) return null;
  const diffMinutes = differenceInMinutes(endDate, startDate);
  const hours = Math.floor(diffMinutes / 60);
  const minutes = diffMinutes % 60;

  if (Number.isNaN(hours) || Number.isNaN(minutes)) {
    return null;
  }

  return `${hours}h ${minutes}m`;
};

export const subtractDays = (date, days) => subDays(date, days);

export const startOfMonthStr = () =>
  formatDate(startOfMonth(new Date()), TIMESTAMP_FORMAT);

export const getTimezoneTimeString = (timezoneStr) => {
  const offsetHours = getTimezoneOffset(timezoneStr) / 3600000;
  const sign = offsetHours < 0 ? '-' : '+';
  const absOffset = Math.abs(offsetHours);
  return `${sign}${absOffset > 9 ? absOffset : `0${absOffset}`}:00`;
};

export const parseDateStr = (str, pattern = BROWSER_DATE_FORMAT) =>
  str ? parse(str, pattern, new Date()) : null;

export const formatedDateStr = (date, pattern = US_DATE_FORMAT) =>
  date ? formatDate(new Date(date), pattern) : '--';
