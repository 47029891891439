import { useNavigate } from 'react-router-dom';

import { useQueryParams } from 'shared/hooks';
import { ServiceShortEnum } from 'utils/enums';

const useConsultNavigation = (consultType) => {
  const navigate = useNavigate();
  const { queryString } = useQueryParams();

  const navigateToConsult = (consultId, params = {}) => {
    navigate(
      `/${ServiceShortEnum[consultType]}/${consultId}${queryString}`,
      params,
    );
  };

  return {
    navigateToConsult,
  };
};

export default useConsultNavigation;
