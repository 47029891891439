import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import styled from '@emotion/styled';

import { useFormState, useUpdateEffect } from 'shared/hooks';

import InputField from 'shared/components/forms/InputField/InputField';

const ConsultTypeInput = styled(InputField)`
  ${({ disabled, theme }) =>
    disabled && `background: ${theme.palette.grey[200]}`}
`;

const ConsultTypeNote = ({ initValue, onUpdate, isDisabled }) => {
  const { register, setFormValues } = useFormState();

  useUpdateEffect(() => {
    setFormValues({
      note: initValue,
    });
  }, [initValue]);

  const updateNote = React.useMemo(
    () =>
      debounce((text) => {
        onUpdate(text);
      }, 1000),
    [onUpdate],
  );

  return (
    <ConsultTypeInput
      {...register('note')}
      multiline
      rows={5}
      readOnly={isDisabled}
      onChange={({ target }) => updateNote(target.value)}
    />
  );
};

ConsultTypeNote.propTypes = {
  initValue: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default ConsultTypeNote;
