import React, { useEffect, useCallback, useMemo } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import api from 'api';
import {
  useRequest,
  useFeedback,
  useTableState,
  useFilterState,
} from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';

import PageTitle from 'shared/components/layout/PageTitle/PageTitle';

import { getTableHeaders, prepareTableData } from 'utils/tables';
import { PAGINATION_PAGE_SIZE } from 'utils/constants';

import AddButton from 'navigation/components/AddButton';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import {
  Box,
  FlexBoxSpaceBetweenCenter,
  FlexBoxSpaceBetween,
  FlexBoxColumn,
} from 'styles/layout';

import ProviderAdd from './ProviderAdd';
import ProviderDetails from './ProviderDetails';
import ProviderListFilters from './ProviderListFilters';

const tableColumns = [
  {
    header: {
      id: 'provider',
      value: 'Provider',
    },
    dataKey: 'providerName',
  },
  {
    header: {
      id: 'healthSystem',
      value: 'Healthcare System',
    },
    dataKey: 'healthSystemName',
  },
  {
    header: {
      id: 'facility',
      value: 'Facility',
    },
    dataKey: 'facilityName',
  },
  {
    header: {
      id: 'hospitalDocId',
      value: 'Mnemonic',
    },
    dataKey: 'hospitalDocId',
  },
];

const ProviderList = () => {
  const { doRequest, data: listData } = useRequest(api.getProviders);
  const navigate = useNavigate();
  const { goBack } = useGoBack();
  const { successDisplay, errorDisplay } = useFeedback();
  const { page, handlePageChange, setPage } = useTableState();
  const { filters, handleFilter } = useFilterState({}, setPage);

  const listItems = useMemo(
    () => listData?.providerAssociations || [],
    [listData],
  );

  const fecthList = useCallback(() => {
    doRequest({
      page,
      pageSize: PAGINATION_PAGE_SIZE,
      filters,
    });
  }, [doRequest, page, filters]);

  useEffect(fecthList, [fecthList]);

  const handleAdd = useCallback(
    (values) => {
      api
        .addProvider(values)
        .then(() => {
          successDisplay('Provider added with success');
          goBack();
          fecthList();
        })
        .catch(({ response }) => {
          errorDisplay(`Error: ${response.data?.title}`);
        });
    },
    [successDisplay, errorDisplay, fecthList, goBack],
  );

  const handleUpdate = useCallback(
    (providerId, values) => {
      api
        .updateProvider(providerId, values)
        .then(() => {
          successDisplay('Provider updated with success');
          fecthList();
        })
        .catch(({ response }) => {
          errorDisplay(`Error: ${response.data?.title}`);
        });
    },
    [successDisplay, errorDisplay, fecthList],
  );

  return (
    <FlexBoxColumn>
      <Routes>
        <Route
          path="/add"
          element={<ProviderAdd onSubmit={handleAdd} onCancel={goBack} />}
        />
        <Route
          path="/:providerId"
          element={
            <ProviderDetails onSubmit={handleUpdate} onCancel={goBack} />
          }
        />
      </Routes>
      <FlexBoxSpaceBetweenCenter>
        <PageTitle>Providers</PageTitle>
        <Box>
          <AddButton to="add">Add Provider</AddButton>
        </Box>
      </FlexBoxSpaceBetweenCenter>

      <FlexBoxSpaceBetween>
        <ProviderListFilters onFilterChanged={handleFilter} />
      </FlexBoxSpaceBetween>

      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(listItems, tableColumns)}
        fallbackMsg="No providers"
        currentPage={page}
        totalPages={listData?.pageCount}
        onPageChange={handlePageChange}
        onClickRow={(providerId) => navigate(`${providerId}`)}
      />
    </FlexBoxColumn>
  );
};

export default ProviderList;
