import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';

import { useFormState } from 'shared/hooks';
import { requiredString } from 'utils/constants';
import { PHONE_NUMBER_REGEX } from 'utils/validators';

import { useConsult } from 'shared/context/ConsultContext';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import InputField from 'shared/components/forms/InputField/InputField';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';

const Fields = {
  text: 'text',
  fax: 'sendToFaxNumber',
};

const schema = yup.object().shape({
  [Fields.text]: yup.string().trim().required(requiredString),
  [Fields.fax]: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid fax number'),
});

const AddendumFax = ({ onAddAddendum }) => {
  const [showForm, setShowForm] = React.useState(false);
  const { consultFaxNumber } = useConsult();

  const {
    handleSubmit,
    register,
    getFieldError,
    control,
    canSubmitForm,
    setFormValues,
    getValues,
    formState: { isSubmitting },
  } = useFormState(schema);

  const helperText = React.useMemo(
    () => (
      <Typography variant="caption" color="gray">
        {consultFaxNumber ? (
          <>
            This facility has a fax number. Adding Addendum will resend Clinical
            Note with Addendum attached.
            <br />
            You may change the number provided to resend somewhere else.
          </>
        ) : (
          <>
            This facility does not have a fax number assigned.
            <br />
            You may add a number to resend the Addendum and Summary.
          </>
        )}
      </Typography>
    ),
    [consultFaxNumber],
  );

  React.useEffect(() => {
    setFormValues({
      [Fields.text]: '',
      [Fields.fax]: consultFaxNumber ?? '',
    });
  }, [consultFaxNumber, setFormValues]);

  const onClose = () => {
    setFormValues({
      ...getValues(),
      [Fields.text]: '',
    });
    setShowForm(false);
  };

  const onSubmit = async (values) => {
    const success = await onAddAddendum({
      [Fields.text]: values[Fields.text],
      [Fields.fax]: values[Fields.fax] || null,
    });
    if (success) onClose();
  };

  return (
    <>
      <ActionButton
        type="button"
        color="secondary"
        onClick={() => setShowForm(true)}
      >
        Add Addendum/Resend Fax
      </ActionButton>

      <Dialog open={showForm} onClose={onClose} maxWidth="xl">
        <DialogTitle>Add Addendum</DialogTitle>
        <DialogContent>
          <FormWrapper
            onSubmit={handleSubmit(onSubmit)}
            onCancel={onClose}
            isSubmitDisabled={!canSubmitForm || isSubmitting}
          >
            <Stack spacing={2} sx={{ width: '60vw' }}>
              <InputField
                {...register(Fields.text, {
                  required: true,
                })}
                label="Addendum"
                required
                multiline
                rows={5}
                error={getFieldError(Fields.text)}
              />
              <PhoneNumberInput
                control={control}
                name={Fields.fax}
                label="Fax Number"
                fullWidth={false}
                error={getFieldError(Fields.fax)}
              />
              {helperText}
            </Stack>
          </FormWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
};

AddendumFax.propTypes = {
  onAddAddendum: PropTypes.func.isRequired,
};

export default AddendumFax;
