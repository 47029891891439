import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ConsultRequestList from './ConsultRequestList';
import ConsultRequestPage from './ConsultRequestPage';

const ConsultRequests = () => (
  <Routes>
    <Route index element={<ConsultRequestList />} />
    <Route path=":consultRequestId" element={<ConsultRequestPage />} />
  </Routes>
);

export default ConsultRequests;
