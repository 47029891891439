import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import api from 'api';
import sortFields from 'api/sortFields';
import {
  useUpdateEffect,
  useMountEffect,
  useTableState,
  useFilterState,
  useFeedback,
} from 'shared/hooks';
import { adminActions } from 'features/admin/store/slice';
import { mapPhoneNumberToMask } from 'utils/mappers';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { userTitleLabels } from 'utils/enums';
import { downloadFile } from 'utils/helpers';

import AddButton from 'navigation/components/AddButton';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import DownloadButton from 'shared/components/buttons/DownloadButton';
import {
  FlexBoxColumn,
  FlexBoxSpaceBetween,
  FlexBoxSpaceBetweenCenter,
} from 'styles/layout';

import UserListFilter from './UserListFilter';
import { formFields } from './utils';

const tableColumns = [
  {
    header: {
      id: sortFields.users.firstName,
      value: 'First Name',
      isSortable: true,
    },
    dataKey: 'firstName',
    width: '15%',
  },
  {
    header: {
      id: sortFields.users.lastName,
      value: 'Last Name',
      isSortable: true,
    },
    dataKey: 'lastName',
    width: '15%',
  },
  {
    header: {
      id: sortFields.users.title,
      value: sortFields.users.title,
      isSortable: true,
    },
    formatValue: (item) => userTitleLabels[item.title] || '-',
    width: '75px',
  },
  {
    header: {
      id: sortFields.users.phoneNumber,
      value: 'Phone Number',
      isSortable: true,
    },
    formatValue: (item) => mapPhoneNumberToMask(item.phoneNumber),
    width: '160px',
  },
  {
    header: {
      id: sortFields.users.email,
      value: sortFields.users.email,
      isSortable: true,
    },
    dataKey: 'email',
  },
  {
    header: {
      id: sortFields.users.username,
      value: sortFields.users.username,
      isSortable: true,
    },
    dataKey: 'username',
  },
  {
    header: {
      id: sortFields.users.enabled,
      value: 'Active',
      isSortable: true,
    },
    formatValue: (item) => (item.enabled ? 'Yes' : 'No'),
    width: '75px',
    align: 'center',
  },
];

const defaultFilterValues = Object.values(formFields).reduce(
  (res, key) => ({ ...res, [key]: '' }),
  {},
);

const UserListPage = () => {
  const { clearDisplay, errorDisplay } = useFeedback();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: users, pageCount } = useSelector(({ admin }) => admin.users);

  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setPage,
    setQueryParams,
  } = useTableState(sortFields.users.firstName);

  const { filters, handleFilter } = useFilterState(
    defaultFilterValues,
    setPage,
  );

  const fecthList = React.useCallback(() => {
    dispatch(adminActions.listUsers({ page, sortBy, sortOrder, filters }));
    setQueryParams({
      ...filters,
      page,
      sortBy,
      sortOrder,
    });
  }, [dispatch, page, sortBy, sortOrder, filters, setQueryParams]);

  useMountEffect(fecthList);

  useUpdateEffect(() => {
    fecthList();
  }, [page, sortBy, sortOrder, filters]);

  const handleExport = async () => {
    try {
      clearDisplay();
      const { data, headers } = await api.exportUsers({
        sortBy,
        sortOrder,
        filters,
        page: null,
        pageSize: null,
      });
      const blob = new Blob([data], { type: headers['content-type'] });
      downloadFile(blob, headers);
    } catch {
      errorDisplay(
        'An error occurred while downloading the file. Please, try again.',
      );
    }
  };

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter>
        <PageTitle>Users</PageTitle>
        <Stack spacing={1} direction="row">
          <AddButton to="add">Add User</AddButton>
          <DownloadButton title="Download CSV" onExport={handleExport} />
        </Stack>
      </FlexBoxSpaceBetweenCenter>
      <FlexBoxSpaceBetween mt={1}>
        <UserListFilter onFilter={handleFilter} defaultFilters={filters} />
      </FlexBoxSpaceBetween>
      <FlexBoxColumn>
        <PaginatedTable
          headings={getTableHeaders(tableColumns)}
          data={prepareTableData(users, tableColumns)}
          fallbackMsg="No users found"
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onClickRow={(rowId) => navigate(String(rowId))}
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default UserListPage;
