import React from 'react';
import PropTypes from 'prop-types';

import { useFormState } from 'shared/hooks';
import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import { ConsultFilterEnum } from 'utils/enums';

import ConsultQuickFilterSelect from './ConsultQuickFilterSelect';

const ConsultQuickFilterToggle = ({ activeQuickFilter, handleChange }) => {
  const { control, setFormValues } = useFormState();

  React.useEffect(() => {
    setFormValues({
      quickFilter: activeQuickFilter,
    });
  }, [setFormValues, activeQuickFilter]);

  const consultSelectValue = React.useMemo(
    () =>
      activeQuickFilter === ConsultFilterEnum.OPEN
        ? ConsultFilterEnum.ALL
        : activeQuickFilter,
    [activeQuickFilter],
  );

  return (
    <ToggleButtonGroup
      control={control}
      name="quickFilter"
      buttons={[
        { value: ConsultFilterEnum.OPEN, content: 'Open' },
        {
          value: consultSelectValue,
          content: (
            <ConsultQuickFilterSelect
              handleChange={handleChange}
              selected={consultSelectValue}
            />
          ),
        },
      ]}
      handleChange={handleChange}
    />
  );
};

ConsultQuickFilterToggle.propTypes = {
  activeQuickFilter: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ConsultQuickFilterToggle;
