import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

import { IntegrationStatusEnum, ShortServiceValues } from 'utils/enums';

import ConsultIntegrationTable from 'features/consults/components/ConsultIntegration/ConsultIntegrationTable';
import ConsultIntegrationMerge from 'features/consults/components/ConsultIntegration/ConsultIntegrationMerge';
import ConsultIntegrationUnmatch from 'features/consults/components/ConsultIntegration/ConsultIntegrationUnmatch';

const BillingLinkingPage = ({ consultData, onLink, onUnlink }) => {
  const { consultId, consultType } = useParams();
  const [orderSelected, selectOrder] = React.useState(null);
  const [toUnlink, setToUnlink] = React.useState(false);

  if (toUnlink) {
    return (
      <ConsultIntegrationUnmatch
        onCancel={() => setToUnlink(false)}
        onUnlink={async () => {
          await onUnlink();
          setToUnlink(false);
        }}
      />
    );
  }

  if (consultData.integrationStatus === IntegrationStatusEnum.MATCHED)
    return (
      <Button variant="contained" onClick={() => setToUnlink(true)}>
        Unmatch
      </Button>
    );

  if (orderSelected)
    return (
      <ConsultIntegrationMerge
        consult={consultData}
        order={orderSelected}
        onMerge={async () => {
          await onLink(orderSelected);
          selectOrder(null);
        }}
        onClose={() => selectOrder(null)}
        closeStr="Back"
      />
    );

  return (
    <ConsultIntegrationTable
      consultId={consultId}
      consultType={ShortServiceValues[consultType]}
      onReview={selectOrder}
    />
  );
};

BillingLinkingPage.propTypes = {
  consultData: PropTypes.shape({
    integrationStatus: PropTypes.string,
  }).isRequired,
  onLink: PropTypes.func.isRequired,
  onUnlink: PropTypes.func.isRequired,
};

export default BillingLinkingPage;
