import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import api from 'api';
import { useMountEffect, useRequest } from 'shared/hooks';

import DialogWrapper from 'shared/components/layout/DialogWrapper';

import ProviderForm from './ProviderForm';

const ProviderDetails = ({ onSubmit, onCancel }) => {
  const { doRequest: getProvider, data } = useRequest(api.getProvider);

  const { providerId } = useParams();

  useMountEffect(() => {
    getProvider(providerId);
  }, [getProvider, providerId]);

  return (
    <DialogWrapper title="Provider Details">
      {data ? (
        <ProviderForm
          onCancel={onCancel}
          onSubmit={(values) => onSubmit(providerId, values)}
          defaultValues={data}
          isEditing
        />
      ) : (
        <CircularProgress />
      )}
    </DialogWrapper>
  );
};

ProviderDetails.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ProviderDetails;
