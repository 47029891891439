import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';

import colors from 'styles/colors';

const LinearProgressBar = styled((props) => (
  <LinearProgress classes={{ bar: 'bar' }} {...props} />
))`
  height: 10px;
  border-radius: 5px;
  padding: 2px;
  background: ${colors.white};
  border: 1px solid #ddd;

  .bar {
    border-radius: 5px;
    background: ${colors.secondary};
  }
`;

const ProgressBar = ({ progress = 0 }) => (
  <LinearProgressBar variant="determinate" value={progress} />
);

ProgressBar.propTypes = {
  progress: PropTypes.number,
};

export default ProgressBar;
