import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import {
  ButtonGroup,
  ToggleButton,
} from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup.style';
import { ServiceTypeEnum } from 'utils/enums';
import { Box, FlexBoxColumn } from 'styles/layout';

const displayTypes = [
  [ServiceTypeEnum.NEURO],
  [ServiceTypeEnum.TELE_NEURO],
  [ServiceTypeEnum.EEG, ServiceTypeEnum.PEDS_EEG],
];

const ConsultRequestTypeSelect = ({
  name,
  control,
  handleChange,
  options,
  error,
  disabled,
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <FlexBoxColumn>
        <InputLabel required error={!!error}>
          Consult Request Type
        </InputLabel>
        {displayTypes.map((types) => {
          const typeButtons = options.filter(({ network }) =>
            types.includes(network),
          );
          return (
            !!typeButtons.length && (
              <Box mb={1} key={uuid()}>
                <ButtonGroup
                  exclusive
                  onChange={(_e, value) => {
                    if (value !== field.value) {
                      field.onChange(value);
                      if (handleChange) handleChange(value);
                    }
                  }}
                >
                  {typeButtons.map(({ value, content, isDisabled }) => (
                    <ToggleButton
                      key={value}
                      value={value}
                      selected={value === field.value}
                      size="small"
                      disabled={disabled || isDisabled}
                      _compact="compact"
                    >
                      {content}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Box>
            )
          );
        })}
        {!!error && <ErrorMsg text={error.message} dense />}
      </FlexBoxColumn>
    )}
  />
);

ConsultRequestTypeSelect.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default ConsultRequestTypeSelect;
