import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from 'shared/components/buttons/IconButton/IconButton';

const CloseButton = ({ onClick, ...props }) => (
  <IconButton color="primary" onClick={onClick} {...props}>
    <CloseIcon />
  </IconButton>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
