import React from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { getTableHeaders, prepareTableData } from 'utils/tables';
import useConsultsListState from 'features/consults/hooks/useConsultsListState';
import useConsultQuickFilter from 'features/consults/hooks/useConsultQuickFilter';

import ConsultFilters from 'features/consults/components/ConsultFilters';
import ConsultTypeToggle from 'features/consults/components/ConsultQuickFilterToggle';
import NewConsultButton from 'features/consults/components/NewConsultButton/NewConsultButton';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import ConsultRequestList from 'features/consults/components/ConsultRequests/ConsultRequestList';
import { FlexBoxColumn } from 'styles/layout';

import {
  consultsTableColumns,
  smallConsultsTableColumns,
} from './tableColumns';
import {
  styleThemes,
  ContentWrapper,
  ActionsOuterWrapper,
  ActionsInnerWrapper,
  ConsultButtonWrapper,
} from './ConsultsList.style';

const ConsultsList = ({ consultType }) => {
  const {
    consults,
    page,
    pageCount,
    sortBy,
    sortOrder,
    filters,
    handleResetFilters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    handleRefresh,
    navigateToConsult,
  } = useConsultsListState(consultType);

  const { activeQuickFilter, quickFilters } = useConsultQuickFilter(filters);

  const sm = useMediaQuery('(max-width:779px)');
  const theme = sm ? styleThemes.sm : styleThemes.lg;

  const tableColumns = sm
    ? smallConsultsTableColumns(consultType)
    : consultsTableColumns(consultType);

  const handleQuickFilter = React.useCallback(
    (buttonValue) => {
      const newFilter = quickFilters[buttonValue];
      if (!newFilter) return;
      const { defaultSort, ...filter2Reset } = newFilter;
      handleResetFilters(filter2Reset);
      if (!defaultSort) return;
      handleSortChange(defaultSort.sortBy, defaultSort.sortOrder);
    },
    [handleResetFilters, quickFilters, handleSortChange],
  );

  return (
    <ThemeProvider theme={theme}>
      <ContentWrapper>
        <ConsultRequestList
          consultType={consultType}
          sm={sm}
          handleRefresh={handleRefresh}
        />
        <ActionsOuterWrapper mb={1}>
          <ConsultFilters
            filters={filters}
            handleFilter={handleFilter}
            consultType={consultType}
            activeQuickFilter={activeQuickFilter}
          />
          <ActionsInnerWrapper>
            <ConsultButtonWrapper>
              <NewConsultButton consultType={consultType} />
            </ConsultButtonWrapper>
            <ConsultTypeToggle
              activeQuickFilter={activeQuickFilter}
              handleChange={handleQuickFilter}
            />
          </ActionsInnerWrapper>
        </ActionsOuterWrapper>
        <FlexBoxColumn>
          <PaginatedTable
            headings={getTableHeaders(tableColumns)}
            data={prepareTableData(consults, tableColumns)}
            fallbackMsg="No consults found"
            sortBy={sortBy}
            sortOrder={sortOrder}
            currentPage={page}
            totalPages={pageCount}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            onClickRow={navigateToConsult}
          />
        </FlexBoxColumn>
      </ContentWrapper>
    </ThemeProvider>
  );
};

ConsultsList.propTypes = {
  consultType: PropTypes.string.isRequired,
};

export default ConsultsList;
