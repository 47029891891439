import React from 'react';
import PropTypes from 'prop-types';

import { Mic, Stop } from '@mui/icons-material';

import { useSpeechButtons } from 'shared/context/SpeechButtonsContext';
import Button from 'shared/components/buttons/Button/Button';
import { Box } from 'styles/layout';

const DictationControls = ({ id, onChange, disabled }) => {
  const {
    browserSupportsSpeechRecognition,
    handleButtonClick,
    buttonIsListening,
    interimTranscript,
    finalTranscript,
    resetTranscript,
  } = useSpeechButtons(id);

  // Adds text on pauses
  React.useEffect(() => {
    if (!buttonIsListening || !finalTranscript || interimTranscript) return;
    onChange(finalTranscript);
    resetTranscript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interimTranscript, finalTranscript]);

  // Adds text when changing buttons
  React.useEffect(
    () => {
      if (!buttonIsListening && interimTranscript) {
        onChange(interimTranscript);
        resetTranscript();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonIsListening],
  );

  const textPreview = React.useMemo(
    () => !!buttonIsListening && interimTranscript,
    [buttonIsListening, interimTranscript],
  );

  if (!browserSupportsSpeechRecognition) return null;

  return (
    <Box>
      <Box mb={0.5}>{textPreview}</Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleButtonClick}
        disabled={disabled}
      >
        {buttonIsListening ? <Stop /> : <Mic />}
        {`${buttonIsListening ? 'stop' : 'start'} dictation`}
      </Button>
    </Box>
  );
};

DictationControls.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DictationControls;
