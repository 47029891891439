import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useConsult } from 'shared/context/ConsultContext';
import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';

import { Box } from 'styles/layout';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import ConsultLock from 'features/consults/components/ConsultLock/ConsultLock';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import Autocomplete from 'shared/components/forms/Autocomplete';
import { clinicSignatureSchema } from 'features/consults/shared/tabs/Signature/validators';

import leftPanel from './data';

const SignatureClinic = ({ errors }) => {
  const {
    register,
    control,
    getValues,
    getFieldError,
    setFormValues,
    clearErrors,
    trigger,
  } = useFormState(clinicSignatureSchema, {
    stateSlice: 'consults',
    showAsSnackbar: true,
  });
  const {
    consult,
    consultTabData,
    isClosed,
    isEditable,
    isMacro,
    handleFieldChange,
    handleOwnerChange,
    handleConsultLock,
  } = useConsultData(CONSULT_TABS.SIGNATURE, getValues);

  const { consultProviders } = useConsult();

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  const isDisabled = React.useMemo(
    () => isMacro || !isEditable,
    [isMacro, isEditable],
  );

  React.useEffect(() => {
    clearErrors();
    if (errors) trigger(errors);
  }, [errors, clearErrors, trigger]);

  return (
    <GridWrapper>
      <Grid item xs={12} lg={6}>
        <Box mb={1}>
          <Autocomplete
            name="coSignatureProviderId"
            label="Provider (who requires co-signature)"
            control={control}
            options={consultProviders}
            disabled={isDisabled}
            handleChange={() => handleFieldChange('coSignatureProviderId')}
          />
        </Box>
        <Box mb={1}>
          <b>If you are a provider who requires co-signature:</b>
          &nbsp;Select your name and name of supervising physician, then Save
          Draft.
          <Box>
            <b>Do not Save Lock Fax.</b>
          </Box>
          <Box>Once attending reviews chart, they will lock.</Box>
        </Box>
        <QuestionsPanel
          {...leftPanel}
          control={control}
          register={register}
          onChange={handleFieldChange}
          getFieldError={getFieldError}
          disabled={isDisabled}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box mb={1}>
          <Autocomplete
            control={control}
            name="assignToId"
            label="Provider"
            options={consultProviders}
            required
            disabled={isMacro}
            error={getFieldError('assignToId')}
            handleChange={handleOwnerChange}
          />
        </Box>
        <ConsultLock
          disabled={isDisabled}
          checked={isClosed}
          onConsultLock={handleConsultLock}
        />
      </Grid>
    </GridWrapper>
  );
};

SignatureClinic.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default SignatureClinic;
