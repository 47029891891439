import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

import {
  buildQueryParams,
  removeEmptyParams,
  mapConsultChanges,
  mapConsultFullChange,
} from './utils';
import sortFields from './sortFields';

const Endpoint = {
  CURRENT_USER: 'user',
  USERS: 'users',
  PROVIDER_ASSOCIATION: 'provider-association',
  HEALTH_SYSTEMS: 'health-systems',
  FACILITIES: 'facilities',
  NETWORKS: 'networks',
  BILLING: 'billing',
  CONSULTS: 'consults',
  EXPORT: 'exports',
  SHIFTS: 'shifts',
  CONSULT_REQ: 'consult-requests',
  HS_NOTE: 'health-system-note',
  FACILITY_NOTE: 'facility-note',
  MACROS: 'consult-macros',
  ORDER: 'order',
  ASSETS: 'assets',
  INSURANCE: 'insurance',
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 150000,
  paramsSerializer: {
    indexes: null,
  },
});

// Add access token to every API request
api.interceptors.request.use(async (config) => {
  try {
    const accessToken = (
      await fetchAuthSession()
    ).tokens?.accessToken.toString();

    return {
      ...config,
      headers: {
        ...config.headers,
        'X-BS-AUTOREFRESH': config?.params?.isAutoRefresh,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  } catch (_e) {
    // If the session is invalid, do not add an Authorization header
    return config;
  }
});

const apiConfig = {
  /* INTERCEPTOR */
  getInterceptors() {
    return api.interceptors.request;
  },

  getInterceptorsResponse() {
    return api.interceptors.response;
  },

  /* Endpoint.USERS */
  getUsers({ sortBy = sortFields.users.firstName, ...params }) {
    return api.get(Endpoint.USERS, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  exportUsers(params) {
    return api.get(`${Endpoint.USERS}/export`, {
      params: buildQueryParams(params),
    });
  },
  getUserNames({ sortBy = sortFields.users.firstName, ...params }) {
    return api.get(`${Endpoint.USERS}/names`, {
      params: buildQueryParams({ ...params, sortBy }),
    });
  },
  createUser(user) {
    return api.post(Endpoint.USERS, user);
  },
  getUser(id) {
    return api.get(`${Endpoint.USERS}/${id}`);
  },
  updateUser(user) {
    return api.put(Endpoint.USERS, user);
  },
  resetUserPassword(id) {
    return api.post(`${Endpoint.USERS}/${id}/reset-password`);
  },
  reInviteUser(id) {
    return api.post(`${Endpoint.USERS}/${id}/re-invite`);
  },

  /* Endpoint.CURRENT USER */
  getProfile() {
    return api.get(Endpoint.CURRENT_USER);
  },
  updateProfile(profile) {
    return api.put(Endpoint.CURRENT_USER, profile);
  },

  /* Endpoint.PROVIDER_ASSOCIATION */
  getProviders({ sortBy = sortFields.users.firstName, ...params }) {
    return api.get(Endpoint.PROVIDER_ASSOCIATION, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  getProvider(id) {
    return api.get(`${Endpoint.PROVIDER_ASSOCIATION}/${id}`);
  },
  addProvider(params) {
    return api.post(Endpoint.PROVIDER_ASSOCIATION, params);
  },
  updateProvider(id, values) {
    return api.put(Endpoint.PROVIDER_ASSOCIATION, {
      id,
      ...values,
    });
  },

  /* Endpoint.HEALTH_SYSTEMS */
  getHealthSystems({ sortBy = sortFields.healthSystems.name, ...params }) {
    return api.get(Endpoint.HEALTH_SYSTEMS, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  getHealthSystemNames({ sortBy = sortFields.healthSystems.name, ...params }) {
    return api.get(`${Endpoint.HEALTH_SYSTEMS}/names`, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  getHealthSystemById(id) {
    return api.get(`${Endpoint.HEALTH_SYSTEMS}/${id}`);
  },
  createHealthSystem(system) {
    return api.post(Endpoint.HEALTH_SYSTEMS, system);
  },
  updateHealthSystem(system) {
    return api.put(Endpoint.HEALTH_SYSTEMS, system);
  },
  createFacility({ locationId, facility }) {
    return api.post(
      `${Endpoint.HEALTH_SYSTEMS}/${locationId}/facilities`,
      facility,
    );
  },

  /* Endpoint.FACILITIES */
  getFacilityById(id) {
    return api.get(`${Endpoint.FACILITIES}/${id}`);
  },
  getCustomerFacilities({ sortBy = sortFields.facilities.name, ...params }) {
    return api.get(`${Endpoint.FACILITIES}/me`, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  updateFacility(facility) {
    return api.put(Endpoint.FACILITIES, facility);
  },
  getFacilities({ sortBy = sortFields.facilities.name, ...params }) {
    return api.get(Endpoint.FACILITIES, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  getFacilityNames({ sortBy = sortFields.facilities.name, ...params }) {
    return api.get(`${Endpoint.FACILITIES}/names`, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },

  /* Endpoint.NETWORKS */
  getNetworks({ sortBy = sortFields.networks.name, ...params }) {
    return api.get(Endpoint.NETWORKS, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  getNetworkNames({ sortBy = sortFields.networks.name, ...params }) {
    return api.get(`${Endpoint.NETWORKS}/names`, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  createNetwork(network) {
    return api.post(Endpoint.NETWORKS, network);
  },
  getNetwork(networkId) {
    return api.get(`${Endpoint.NETWORKS}/${networkId}`);
  },
  updateNetwork(network) {
    return api.put(Endpoint.NETWORKS, network);
  },

  /* Endpoint.BILLING */
  exportBilling(params) {
    return api.get(`${Endpoint.BILLING}/export`, {
      params,
      responseType: 'blob',
    });
  },

  /* Endpoint.CONSULTS */
  getConsults(type, { sortBy = sortFields.consults.createdAt, ...params }) {
    const endpoint = type
      ? `${Endpoint.CONSULTS}/${type}`
      : 'consults-with-facilities';
    return api.get(endpoint, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  exportConsults(type, { sortBy = sortFields.consults.createdAt, ...params }) {
    return api.get(`${Endpoint.EXPORT}/consult/${type}`, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
      responseType: 'blob',
    });
  },
  getConsultCount() {
    return api.get(`${Endpoint.CONSULTS}/count`);
  },
  getConsultById(id) {
    return api.get(`${Endpoint.CONSULTS}/${id}`);
  },
  createConsult(data) {
    return api.post(Endpoint.CONSULTS, data);
  },
  copyConsult(id) {
    return api.post(`${Endpoint.CONSULTS}/${id}/copy`);
  },
  setConsultFlag(id, data) {
    return api.post(`${Endpoint.CONSULTS}/${id}/setcolor`, data);
  },
  updateFullConsult(id, values) {
    const data = mapConsultFullChange(values);
    return api.patch(`${Endpoint.CONSULTS}/${id}`, data, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  },
  updateConsult(id, tab, changes) {
    const data = mapConsultChanges(tab, changes);
    return api.patch(`${Endpoint.CONSULTS}/${id}`, data, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  },
  updateConsultSummary({ consultId, summary, summaryFormatted }) {
    return api.put(`${Endpoint.CONSULTS}/summary`, {
      consultId,
      summary,
      summaryFormatted,
    });
  },
  updateConsultOwner(id, ownerId) {
    const data = [
      { op: 'replace', path: '/signature/assignToId', value: ownerId },
    ];
    return api.patch(`${Endpoint.CONSULTS}/${id}/owner`, data, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  },
  lockConsult(id, data) {
    return api.post(`${Endpoint.CONSULTS}/${id}/lock`, data);
  },
  addConsultAddendum(id, data) {
    return api.post(`${Endpoint.CONSULTS}/${id}/consult-addendum`, data);
  },
  getConsultAddenda(id) {
    return api.get(`consult-addenda/${id}`);
  },
  getOrderConsults(params) {
    return api.get(`${Endpoint.CONSULTS}/unmatched`, {
      params: buildQueryParams(params),
    });
  },
  addConsultIcd(data) {
    return api.post(`consult/icd-code`, data);
  },
  deleteConsultIcd(icdId) {
    return api.delete(`consult/icd-code/${icdId}`);
  },
  getShifts(startDate, endDate, filters = {}) {
    return api.get(Endpoint.SHIFTS, {
      params: {
        ...removeEmptyParams(filters),
        startDate,
        endDate,
      },
    });
  },
  exportShifts(startDate, endDate, filters = {}, fileType = 'csv') {
    return api.get(`${Endpoint.SHIFTS}/export`, {
      params: {
        ...removeEmptyParams(filters),
        startDate,
        endDate,
        fileType,
      },
      responseType: 'blob',
    });
  },
  getConsultRequests(params) {
    return api.get(Endpoint.CONSULT_REQ, {
      params: buildQueryParams(params),
    });
  },
  getAdminConsultRequests(params) {
    return api.get('consult-requests-admin', {
      params: buildQueryParams(params),
    });
  },
  getConsultRequestById(id) {
    return api.get(`${Endpoint.CONSULT_REQ}/${id}`);
  },
  createConsultRequest(data) {
    return api.post(Endpoint.CONSULT_REQ, data);
  },
  acceptConsultRequest(id) {
    return api.post(`${Endpoint.CONSULT_REQ}/${id}/accept`, {});
  },
  declineConsultRequest(id) {
    return api.post(`${Endpoint.CONSULT_REQ}/${id}/decline`, {});
  },
  deleteConsultRequest(id) {
    return api.delete(`${Endpoint.CONSULT_REQ}/${id}`);
  },
  getConsultRequestsNotifications({ consultType, isAutoRefresh }) {
    return api.get(`${Endpoint.CONSULT_REQ}/notifications`, {
      params: {
        type: consultType,
        isAutoRefresh,
      },
    });
  },
  deleteConsultRequestNotification(id) {
    return api.delete(`${Endpoint.CONSULT_REQ}/notifications?Id=${id}`);
  },
  getConsultRequestsCount(isAutoRefresh) {
    return api.get(`${Endpoint.CONSULT_REQ}/count`, {
      params: {
        isAutoRefresh,
      },
    });
  },

  /* HEALTH SYSTEM NOTE */
  getHealthSystemNote(params) {
    return api.get(Endpoint.HS_NOTE, {
      params,
    });
  },
  updateHealthSystemNote(params) {
    return api.put(Endpoint.HS_NOTE, params);
  },
  deleteHealthSystemNote(params) {
    return api.delete(Endpoint.HS_NOTE, { params });
  },
  createHealthSystemNote({ healthSystemId, ...params }) {
    return api.post(
      `${Endpoint.HEALTH_SYSTEMS}/${healthSystemId}/health-system-notes`,
      params,
    );
  },

  /* FACILITY NOTE */
  getFacilityNote(params) {
    return api.get(Endpoint.FACILITY_NOTE, {
      params,
    });
  },
  updateFacilityNote(params) {
    return api.put(Endpoint.FACILITY_NOTE, params);
  },
  deleteFacilityNote(params) {
    return api.delete(Endpoint.FACILITY_NOTE, { params });
  },
  createFacilityNote({ facilityId, ...params }) {
    return api.post(
      `${Endpoint.FACILITIES}/${facilityId}/facility-notes`,
      params,
    );
  },

  /* MACROS */
  getMacros({ sortBy = sortFields.macros.name, ...params }) {
    return api.get(Endpoint.MACROS, {
      params: buildQueryParams({
        ...params,
        sortBy,
      }),
    });
  },
  getMacroById(id) {
    return api.get(`${Endpoint.MACROS}/${id}`);
  },
  createMacro(data) {
    return api.post(`${Endpoint.MACROS}`, data);
  },
  deleteMacro(macroId) {
    return api.delete(`${Endpoint.MACROS}/${macroId}`);
  },
  updateMacro({ macroId, tab, changes }) {
    const data = mapConsultChanges(tab, changes);
    return api.patch(`${Endpoint.MACROS}/${macroId}`, data, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  },
  addMacroIcd(data) {
    return api.post(`consult-macro/icd-code`, data);
  },
  deleteMacroIcd(icdId) {
    return api.delete(`consult-macro/icd-code/${icdId}`);
  },
  applyMacro(data) {
    return api.put(Endpoint.CONSULTS, data);
  },

  /* ORDERS */
  getOrders(params) {
    return api.get(Endpoint.ORDER, {
      params: buildQueryParams(params),
    });
  },
  getOrderDetails(consultId) {
    return api.get(`${Endpoint.ORDER}/details/${consultId}`);
  },
  linkOrder({ consultId, hl7OrderId }) {
    return api.post(`${Endpoint.ORDER}/link`, { consultId, hl7OrderId });
  },
  unlinkOrder({ consultId }) {
    return api.post(`${Endpoint.ORDER}/unlink`, { consultId });
  },
  getAdminOrderList(params) {
    return api.get('orders-list', { params: buildQueryParams(params) });
  },
  getAdminOrderDetails(id) {
    return api.get(`${Endpoint.ORDER}/${id}`);
  },
  exportOrders(params) {
    return api.get(`${Endpoint.EXPORT}/orders`, {
      params: buildQueryParams(params),
      responseType: 'blob',
    });
  },

  /* ASSETS */
  getAsset(assetId) {
    return api.get(`${Endpoint.ASSETS}/${assetId}/download`);
  },
  getAssets(assetIds) {
    return api.post(`${Endpoint.ASSETS}/download-urls`, { assetIds });
  },
  getAssetUploadUrl({ assetId, ContentType }) {
    return api.get(`${Endpoint.ASSETS}/${assetId}/upload`, {
      params: { ContentType },
    });
  },

  /* INSURANCE */
  getInsuranceUploadUrl({ fileName, contentType }) {
    return api.get(`${Endpoint.INSURANCE}/get-hl7insurance-upload-url`, {
      params: { fileName, contentType },
    });
  },
  readInsuranceFile({ fileName }) {
    return api.get(`${Endpoint.INSURANCE}/read-hl7insurance-file`, {
      params: { fileName },
    });
  },
  getInsuranceData(id) {
    return api.get(`${Endpoint.INSURANCE}/${id}/billing-data`);
  },
};

export default apiConfig;
