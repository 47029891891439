import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import api from 'api';
import { appStateActions } from 'store/appStateSlice';
import { isFile } from 'utils/helpers';

import { useFeedback } from './useFeedback';

export const useAssets = () => {
  const dispatch = useDispatch();
  const { assets } = useSelector(({ appState }) => appState);
  const { errorDisplay, clearDisplay } = useFeedback();

  const fetchAssets = useCallback(
    (assetIds) => {
      api.getAssets(assetIds).then(({ data }) => {
        dispatch(appStateActions.updateAssets(data.assets));
      });
    },
    [dispatch],
  );

  const assetUrl = useCallback((assetId) => assets[assetId] ?? null, [assets]);

  const uploadAsset = async (assetId, file) => {
    clearDisplay();
    if (!isFile(file)) {
      errorDisplay('Please select a file');
      return;
    }
    try {
      const { data: uploadUrl } = await api.getAssetUploadUrl({
        assetId,
        ContentType: file.type,
      });
      await axios.put(uploadUrl, file, {
        headers: { 'Content-Type': file.type },
      });
      await fetchAssets([assetId]);
    } catch (e) {
      errorDisplay('An error occurred while uploading image');
      throw e;
    }
  };

  return {
    assets,
    assetUrl,
    fetchAssets,
    uploadAsset,
  };
};

export default useAssets;
