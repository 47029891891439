import { useEffect } from 'react';

import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';

const useConsultInitialFormState = (
  consultId,
  consultTabData,
  setFormValues,
) => {
  useEffect(() => {
    if (consultId) {
      setFormValues(consultTabData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultId]);

  usePerformActionOnSuccess(SuccessActionTypesEnum.MACRO_APPLIED, () => {
    setFormValues(consultTabData);
  });

  usePerformActionOnSuccess(SuccessActionTypesEnum.FORCE_CONSULT_RELOAD, () => {
    setFormValues(consultTabData);
  });
};

export default useConsultInitialFormState;
