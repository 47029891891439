import React from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { useFeedback } from 'shared/hooks';
import { downloadFile } from 'utils/helpers';
import { ShortServiceValues } from 'utils/enums';

import DownloadButton from 'shared/components/buttons/DownloadButton';

const BillingDashboardCSVDownload = ({ filters, sort }) => {
  const { clearDisplay, errorDisplay } = useFeedback();

  const onDownload = React.useCallback(async () => {
    clearDisplay();
    try {
      const { data, headers } = await api.exportBilling({
        ...filters,
        consultType: ShortServiceValues[filters?.consultType],
        from: filters?.fromCreatedAt,
        to: filters?.toCreatedAt,
        integrationStates: filters?.integrationStatusList,
        sort: sort?.sortBy,
        sortDirection: sort?.sortOrder,
        isBilled: true,
      });
      downloadFile(data, headers);
    } catch (e) {
      errorDisplay('Error downloading file');
    }
  }, [filters, sort, clearDisplay, errorDisplay]);

  return <DownloadButton title="Download CSV" onExport={onDownload} />;
};

BillingDashboardCSVDownload.propTypes = {
  filters: PropTypes.shape({
    consultType: PropTypes.string,
    fromCreatedAt: PropTypes.string,
    facilityId: PropTypes.number,
    healthcareSystemId: PropTypes.number,
    toCreatedAt: PropTypes.string,
    billingStatus: PropTypes.string,
    integrationStatusList: PropTypes.string,
    query: PropTypes.string,
  }),
  sort: PropTypes.shape({
    sortBy: PropTypes.string,
    sortOrder: PropTypes.string,
  }),
};

export default BillingDashboardCSVDownload;
