import React from 'react';
import PropTypes from 'prop-types';

import { Box, Dialog, DialogContent, Tabs, Tab } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import api from 'api';
import { useRequest, useMountEffect } from 'shared/hooks';
import { IntegrationStatusEnum } from 'utils/enums';

import ConsultIntegrationHistoryTable from 'features/consults/components/ConsultIntegration/ConsultIntegrationHistoryTable';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import colors from 'styles/colors';

import HL7OrderTab from './tabs/HL7OrderTab';

const menuItems = [
  {
    label: 'HL7 Order',
    render: (data, integrationStatus) => (
      <HL7OrderTab
        details={data?.details}
        integrationStatus={integrationStatus}
      />
    ),
  },
  {
    label: 'History',
    render: (data) => (
      <ConsultIntegrationHistoryTable history={data?.history} />
    ),
  },
];

const ConsultIntegrationDetails = ({ consult: { id, integrationStatus } }) => {
  const [modal, setModal] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const { doRequest, data } = useRequest(api.getOrderDetails);

  useMountEffect(() => {
    doRequest(id);
  });

  const renderTab = React.useMemo(
    () => menuItems[tab].render(data, integrationStatus),
    [tab, data, integrationStatus],
  );

  if (
    ![IntegrationStatusEnum.MATCHED, IntegrationStatusEnum.UNMATCHED].includes(
      integrationStatus,
    )
  )
    return null;

  return (
    <>
      <Dialog
        open={modal}
        maxWidth="lg"
        fullWidth
        onClose={() => setModal(false)}
      >
        <DialogContent>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={tab}
            onChange={(_, newTab) => setTab(newTab)}
            aria-label="disabled tabs example"
          >
            {menuItems.map(({ label }) => (
              <Tab key={label} label={label} />
            ))}
          </Tabs>
          <Box sx={{ padding: '1rem 0rem' }}>
            <Box
              sx={{
                backgroundColor: colors.neutral,
                height: '25rem',
                overflowX: 'hidden',
              }}
            >
              {renderTab}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <IconButton onClick={() => setModal(true)}>
        <ZoomInIcon />
      </IconButton>
    </>
  );
};

ConsultIntegrationDetails.propTypes = {
  consult: PropTypes.shape({
    id: PropTypes.string,
    integrationStatus: PropTypes.string,
  }),
};

export default ConsultIntegrationDetails;
