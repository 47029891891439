import { ServiceTypeEnum } from 'utils/enums';
import {
  getOptions4Type,
  mapToggleFieldOptions,
  mapChipFieldOptions,
} from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/HPI/formFields';

export const leftPanel = {
  elements: [
    mapChipFieldOptions(fields.chiefComplaintPredefined),
    fields.chiefComplaintFreeText,
    mapToggleFieldOptions(fields.handedness),
    fields.additionalHistory,
    mapChipFieldOptions(fields.durationConditionPredefined),
    fields.durationConditionFreeText,
    mapChipFieldOptions(fields.durationPeriodPredefined),
    fields.durationPeriodFreeText,
  ],
};

export const rightPanel = {
  elements: [
    mapChipFieldOptions(fields.associatedSymptomsPredefined),
    fields.associatedSymptomsFreeText,
    mapChipFieldOptions(fields.qualityPredefined),
    fields.qualityFreeText,
    mapChipFieldOptions(
      getOptions4Type(
        fields.contextPreExistingConditionsPredefined,
        ServiceTypeEnum.CLINIC,
      ),
    ),
    fields.contextPreExistingConditionsFreeText,
    mapToggleFieldOptions(fields.severityPredefined),
    fields.severityFreeText,
  ],
};
