import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import DayTitle from 'features/schedule/components/DayTitle/DayTitle';
import DayCard from 'features/schedule/components/DayCard/DayCard';
import { LAST_CALENDAR_DAY_INDEX } from 'features/schedule/utils/constants';
import { FlexBoxSpaceBetweenCenter, FlexBoxColumnAlign } from 'styles/layout';

const CalendarDayView = ({ days }) => {
  const todayIndex = days.findIndex((item) => item.today);
  const [visibleItemIndex, setVisibleItemIndex] = useState(todayIndex);
  const currentDay = days[visibleItemIndex];

  return (
    <FlexBoxColumnAlign pb={2} height="100%">
      <FlexBoxSpaceBetweenCenter width="100%">
        <IconButton
          color="primary"
          disabled={visibleItemIndex === 0}
          onClick={() => setVisibleItemIndex(visibleItemIndex - 1)}
        >
          <ChevronLeft />
        </IconButton>
        <DayTitle singleDayView>{currentDay.title}</DayTitle>
        <IconButton
          color="primary"
          disabled={visibleItemIndex === LAST_CALENDAR_DAY_INDEX}
          onClick={() => setVisibleItemIndex(visibleItemIndex + 1)}
        >
          <ChevronRight />
        </IconButton>
      </FlexBoxSpaceBetweenCenter>
      <DayCard isToday={currentDay.today} items={currentDay.shifts} />
    </FlexBoxColumnAlign>
  );
};

CalendarDayView.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      today: PropTypes.bool.isRequired,
      shifts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ).isRequired,
};

export default CalendarDayView;
