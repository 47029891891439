import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useBreakpoints } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';
import { ConsultProvider, useConsult } from 'shared/context/ConsultContext';
import { ServiceTypeEnum, ServiceShortEnum, ConsultStates } from 'utils/enums';

import Tabs from 'features/consults/components/Tabs/Tabs';
import ConsultMenu from 'features/consults/components/ConsultMenu';
import ConsultSidebar from 'features/consults/components/ConsultSidebar/ConsultSidebar';
import ConsultMacros from 'features/consults/components/ConsultMacros/ConsultMacros';

import {
  OuterWrapper,
  ConsultWrapper,
  DetailsWrapper,
  MacrosWrapper,
} from './ConsultDetailsWrapper.style';

const ConsultWithContext = ({ consultType }) => {
  const { goBack, navigate } = useGoBack();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { consult } = useConsult();
  const { smDown } = useBreakpoints();
  const { id: userId } = useSelector(({ profile }) => profile.data);

  const isClosed = React.useMemo(
    () => consult.signature?.state === ConsultStates.CLOSED,
    [consult.signature?.state],
  );

  const canApplyMacros = React.useMemo(
    () => !isClosed && userId === consult.signature?.assignToId,
    [consult.signature?.assignToId, isClosed, userId],
  );

  React.useEffect(() => {
    if (consultType === consult.type) return;
    navigate(`/${ServiceShortEnum[consult.type]}/${consult.id}`);
  }, [consult, consultType, navigate]);

  return (
    <OuterWrapper _sm={smDown ? 'sm' : ''}>
      <ConsultWrapper _sm={smDown ? 'sm' : ''} _menu={menuOpen ? 'open' : ''}>
        <DetailsWrapper sm={smDown}>
          <Tabs
            isClosed={isClosed}
            consultId={consult.id}
            consultType={consult.type}
            onClose={() => goBack()}
          />
        </DetailsWrapper>
        {canApplyMacros && (
          <MacrosWrapper sm={smDown}>
            <ConsultMacros
              consultId={consult.id}
              consultType={consult.type}
              userId={userId}
            />
          </MacrosWrapper>
        )}
      </ConsultWrapper>
      {consult.type !== ServiceTypeEnum.CLINIC && (
        <ConsultMenu
          isOpen={menuOpen}
          sm={smDown}
          onToggle={() => setMenuOpen(!menuOpen)}
          consult={consult}
          consultType={consult.type}
        />
      )}
    </OuterWrapper>
  );
};

ConsultWithContext.propTypes = {
  consultType: PropTypes.string.isRequired,
};

const ConsultDetailsWrapper = ({ consultType }) => {
  const { consultId } = useParams();
  return (
    <>
      <ConsultSidebar consultType={consultType} />
      <ConsultProvider consultId={consultId}>
        <ConsultWithContext consultType={consultType} />
      </ConsultProvider>
    </>
  );
};

ConsultDetailsWrapper.propTypes = {
  consultType: PropTypes.string.isRequired,
};

export default ConsultDetailsWrapper;
