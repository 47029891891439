import React from 'react';

import { NoOption } from 'styles/icons';
import {
  ConsultStates,
  NeuroConsultLabels,
  EegConsultLabels,
  TeleNeuroConsultLabels,
  IntegrationStatusLabels,
} from 'utils/enums';

export const CONSULT_TABS = {
  DEMOGRAPHICS: 'demographics',
  HPI: 'hpi',
  SCORES: 'scores',
  EXAM: 'exam',
  ROS: 'ros',
  PMH: 'pmhfhsh',
  DATA: 'data',
  ASSESSMENT: 'assessment',
  PLAN: 'plan',
  SUMMARY: 'summary',
  SIGNATURE: 'signature',
  EEG: 'eeg',
  EMG: 'emg',
  BOTOX: 'botox',
  STROKE: 'stroke',
};

export const RESET_OPTION = { value: 'x', content: <NoOption /> };

export const neuroConsultOptions = Object.entries(NeuroConsultLabels).map(
  ([value, label]) => ({ value, label }),
);

export const teleNeuroConsultOptions = Object.entries(
  TeleNeuroConsultLabels,
).map(([value, label]) => ({ value, label }));

export const eegConsultOptions = Object.entries(EegConsultLabels).map(
  ([value, label]) => ({ value, label }),
);

export const ConsultIntegrationOptions = Object.entries(
  IntegrationStatusLabels,
).map(([value, text]) => ({ value, text }));

export const consultFilterStateLabels = [
  { value: ConsultStates.OPEN, text: ConsultStates.OPEN },
  { value: ConsultStates.CLOSED, text: ConsultStates.CLOSED },
  { value: ConsultStates.CANCELLED, text: ConsultStates.CANCELLED },
];

export const LAST24HOURS = 'last24Hours';
