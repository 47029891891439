import React from 'react';
import PropTypes from 'prop-types';
import ChipIcon from '@mui/icons-material/Tonality';

import { MultiChipStatesEnum } from './MultiChipStates.enum';
import { ChipContainer, FormChip } from './MultiChip.style';

const STATES = [
  MultiChipStatesEnum.UNKNOWN,
  MultiChipStatesEnum.YES,
  MultiChipStatesEnum.NO,
];

const MultiChip = ({ text, currentState, onChange, disabled }) => {
  const handleStateChange = () => {
    const currentStateIndex = STATES.findIndex(
      (state) => state === currentState,
    );

    const nextState = STATES[currentStateIndex + 1];
    const newState = nextState !== undefined ? nextState : STATES[0];

    onChange(newState);
  };

  return (
    <ChipContainer disabled={disabled}>
      <FormChip
        label={text}
        variant="outlined"
        icon={<ChipIcon />}
        size="small"
        _state={String(currentState)}
        onClick={handleStateChange}
        disabled={disabled}
      />
    </ChipContainer>
  );
};

MultiChip.propTypes = {
  text: PropTypes.string.isRequired,
  currentState: PropTypes.oneOf(STATES),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default MultiChip;
