import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Chip from 'shared/components/forms/Chip/Chip';

const ChipList = ({ name, options, control, onChange, disabled }) => {
  const handleValuesChange = (selectedValues, newValue) => {
    if (selectedValues?.includes(newValue)) {
      return selectedValues?.filter((item) => item !== newValue);
    }
    return (selectedValues ?? []).concat(newValue);
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      render={({ field }) =>
        options.map(({ value, text, showOnNewLine }, index) => (
          <Chip
            key={value}
            name={`${name}[${index}]`}
            text={text}
            value={value}
            selected={field.value?.includes(value)}
            showOnNewLine={showOnNewLine}
            onChange={(newValue) => {
              const updatedValues = handleValuesChange(field.value, newValue);

              field.onChange(updatedValues);
              if (onChange) onChange();
            }}
            disabled={disabled}
          />
        ))
      }
    />
  );
};

ChipList.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  control: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ChipList;
