import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import { useFormState } from 'shared/hooks';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import FormRow from 'shared/components/forms/FormRow/FormRow';
import InputField from 'shared/components/forms/InputField/InputField';
import schema from './validationSchema';

const NetworkForm = ({
  onSubmit,
  onCancel,
  initValues = null,
  disabled = false,
}) => {
  const {
    register,
    handleSubmit,
    canSubmitForm,
    getFieldError,
    setFormValues,
  } = useFormState(schema, { stateSlice: 'admin', showAsSnackbar: true });

  React.useEffect(() => {
    if (!initValues) return;
    setFormValues(initValues);
  }, [initValues, setFormValues]);

  const cancelFunc = () => {
    setFormValues();
    onCancel();
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit(onSubmit)}
      onCancel={cancelFunc}
      isSubmitDisabled={!canSubmitForm}
      formDisabled={disabled}
    >
      <FormRow xs={7} justify="space-between">
        <Grid item xs={6}>
          <InputField
            {...register('name', {
              required: true,
              disabled,
            })}
            label="Network Name"
            required
            error={getFieldError('name')}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            {...register('shiftAdminFacilityId', {
              required: true,
              disabled,
            })}
            label="Network ID"
            required
            error={getFieldError('shiftAdminFacilityId')}
          />
        </Grid>
      </FormRow>
      {/*
      <FormRow xs={7}>
        <Grid item xs={12}>
          <InputField
            name="emailAddresses"
            label="Schedule Update Email List"
            multiline
            rows={5}
            error={getFieldError('emailAddresses')}
            disabled={disabled}
          />
        </Grid>
      </FormRow>
    */}
    </FormWrapper>
  );
};

NetworkForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initValues: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default NetworkForm;
