import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { Avatar, IconButton } from '@mui/material';
import { AddAPhoto } from '@mui/icons-material';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import { isFile } from 'utils/helpers';

import { AvatarOverlay, Label, HiddenInput, classes } from './ImageInput.style';

const ImageInput = ({ control, name, disabled = false, error }) => {
  const [filePreview, setPreview] = React.useState(null);

  const imagePreview = React.useCallback(
    (value) => (isFile(value) ? filePreview : value),
    [filePreview],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <Label htmlFor={name} disabled={disabled}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            disabled={disabled}
          >
            <Avatar src={imagePreview(field.value)} sx={classes.avatar} />
            {!disabled && (
              <AvatarOverlay>
                <AddAPhoto sx={classes.overlayIcon} />
              </AvatarOverlay>
            )}
          </IconButton>
          <HiddenInput
            disabled={disabled}
            accept="image/*"
            id={name}
            type="file"
            onChange={(e) => {
              const file = e.target.files[0];
              if (!file) return;
              const reader = new FileReader();
              reader.onloadend = () => {
                setPreview(reader.result);
              };
              reader.readAsDataURL(file);
              field.onChange(file);
            }}
          />
          {error && <ErrorMsg text={error.message} dense />}
        </Label>
      )}
    />
  );
};

ImageInput.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default ImageInput;
