import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import api from 'api';
import {
  useMountEffect,
  useUpdateEffect,
  useFormState,
  useRequest,
} from 'shared/hooks';
import listOfStates from 'utils/states';
import { timezoneOptions } from 'utils/timezones';
import { ServiceTypeLabels } from 'utils/enums';
import { MAX_PAGE_SIZE } from 'utils/constants';

import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import InputField from 'shared/components/forms/InputField/InputField';
import Autocomplete from 'shared/components/forms/Autocomplete';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import RadioGroup from 'shared/components/forms/RadioGroup';
import Switch from 'shared/components/forms/Switch/Switch';
import Select from 'shared/components/forms/Select';
import { Box, FlexBoxColumnFull } from 'styles/layout';

import schema from './validationSchema';
import {
  mapFacilityToApiFormat,
  mobileImagingTypes,
  strokeCertificationOptions,
  hospitalSizeOptions,
  thrombolyticValuesOptions,
  facilityFields,
  networkFields,
} from './utils';

const FacilityForm = ({ initValues, disabled, onSave, onCancel, siteCode }) => {
  const {
    register,
    handleSubmit,
    canSubmitForm,
    getFieldError,
    setValue,
    control,
    setFormValues,
    watch,
  } = useFormState(schema);

  const { doRequest: getNetworks, data: networks } = useRequest(
    api.getNetworks,
  );

  useMountEffect(() => {
    getNetworks({ pageSize: MAX_PAGE_SIZE });
  });

  React.useEffect(() => {
    setFormValues(initValues);
  }, [setFormValues, initValues]);

  const onSubmit = (values) => {
    onSave(mapFacilityToApiFormat(values));
  };

  const codeFromSystemValue = watch(facilityFields.codeFromSystem);
  const hasBillingIntegration = watch(facilityFields.r1Integration);
  const isIntegrated = watch(facilityFields.isIntegrated);

  const showFacilityCode = React.useMemo(
    () => hasBillingIntegration || isIntegrated,
    [hasBillingIntegration, isIntegrated],
  );

  useUpdateEffect(() => {
    if (!siteCode || !codeFromSystemValue) return;
    setValue(facilityFields.siteCode, siteCode);
  }, [siteCode, codeFromSystemValue]);

  return (
    <FlexBoxColumnFull p={2}>
      <FormWrapper
        onSubmit={handleSubmit(onSubmit)}
        isSubmitDisabled={!canSubmitForm}
        formDisabled={disabled}
        onCancel={() => {
          setFormValues();
          onCancel();
        }}
      >
        <Grid container spacing={6}>
          <Grid container item xs={12} md={8} spacing={2}>
            <Grid item md={6} xs={12}>
              <Switch
                control={control}
                name={facilityFields.active}
                label="Active"
                disabled={disabled}
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={6} xs={12}>
                <InputField
                  {...register(facilityFields.name, {
                    required: true,
                    disabled,
                  })}
                  required
                  label="Facility Name"
                  error={getFieldError(facilityFields.name)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Switch
                  control={control}
                  name={facilityFields.isIntegrated}
                  label="Bidirectional Integrated Facility"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={6} xs={12}>
                {showFacilityCode && (
                  <Box>
                    <InputField
                      {...register(facilityFields.hL7FacilityCode, {
                        required: showFacilityCode,
                        disabled,
                      })}
                      required
                      label="HL7 Facility Code"
                      error={getFieldError(facilityFields.hL7FacilityCode)}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Switch
                  control={control}
                  name={facilityFields.r1Integration}
                  label="R1 Billing Integration"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              sx={{ display: showFacilityCode ? 'flex' : 'none' }}
            >
              <Grid item md={6} xs={12}>
                <InputField
                  {...register(facilityFields.siteCode, {
                    required: true,
                    disabled,
                  })}
                  readOnly={codeFromSystemValue}
                  required
                  label="Site Code"
                  error={getFieldError(facilityFields.siteCode)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Switch
                  control={control}
                  name={facilityFields.codeFromSystem}
                  label="Inherit from Parent"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputField
                {...register(facilityFields.street, {
                  disabled,
                })}
                label="Address"
                error={getFieldError(facilityFields.street)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                {...register(facilityFields.city, {
                  disabled,
                })}
                label="City"
                error={getFieldError(facilityFields.city)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Autocomplete
                control={control}
                name={facilityFields.state}
                label="State"
                options={listOfStates}
                disabled={disabled}
                error={getFieldError(facilityFields.state)}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <InputField
                {...register(facilityFields.zipCode, {
                  disabled,
                })}
                label="Zip Code"
                inputProps={{ length: 5 }}
                error={getFieldError(facilityFields.zipCode)}
              />
            </Grid>
            <Grid spacing={2} item container xs={12}>
              <Grid item md={6} xs={12}>
                <Select
                  control={control}
                  name={facilityFields.timezone}
                  options={timezoneOptions}
                  label="Time Zone"
                  disabled={disabled}
                  error={getFieldError(facilityFields.timezone)}
                />
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <PhoneNumberInput
                control={control}
                name={facilityFields.phone}
                label="ED Number"
                disabled={disabled}
                error={getFieldError(facilityFields.phone)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <PhoneNumberInput
                control={control}
                name={facilityFields.itPhone}
                label="IT phone number"
                disabled={disabled}
                error={getFieldError(facilityFields.itPhone)}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <PhoneNumberInput
                control={control}
                name={facilityFields.callCenterPhone}
                label="Call Center Phone Number"
                disabled={disabled}
                error={getFieldError(facilityFields.callCenterPhone)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <PhoneNumberInput
                control={control}
                name={facilityFields.fax}
                label="Fax Number"
                disabled={disabled}
                error={getFieldError(facilityFields.fax)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.emrName, {
                  disabled,
                })}
                label="EMR Name"
                error={getFieldError(facilityFields.emrName)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.emrLink, {
                  disabled,
                })}
                label="EMR Link"
                error={getFieldError(facilityFields.emrLink)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.radiology, {
                  disabled,
                })}
                label="Radiology"
                error={getFieldError(facilityFields.radiology)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.radiologyLink, {
                  disabled,
                })}
                label="Radiology Link"
                error={getFieldError(facilityFields.radiologyLink)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.sharedSignout, {
                  disabled,
                })}
                label="Shared Sign-Out"
                error={getFieldError(facilityFields.sharedSignout)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.sharedSignoutLink, {
                  disabled,
                })}
                label="Shared Sign-Out Link"
                error={getFieldError(facilityFields.sharedSignoutLink)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.camera, {
                  disabled,
                })}
                label="Camera"
                error={getFieldError(facilityFields.camera)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.cameraLink, {
                  disabled,
                })}
                label="Camera Link"
                error={getFieldError(facilityFields.cameraLink)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.coverage, {
                  disabled,
                })}
                label="Onsite Neuro Coverage"
                error={getFieldError(facilityFields.coverage)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputField
                {...register(facilityFields.eegSoftware, {
                  disabled,
                })}
                label="EEG Software"
                error={getFieldError(facilityFields.eegSoftware)}
              />
            </Grid>
            <Grid item md={6} xs={12} />
            <Grid item md={6} xs={12}>
              <Select
                control={control}
                name={facilityFields.thrombolytic}
                options={thrombolyticValuesOptions}
                label="Thrombolytic"
                disabled={disabled}
                error={getFieldError(facilityFields.thrombolytic)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <RadioGroup
                control={control}
                name={facilityFields.directBeamIn}
                label="Direct Beam-In"
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                error={getFieldError(facilityFields.directBeamIn)}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                control={control}
                name={facilityFields.mobileImagings}
                options={mobileImagingTypes}
                label="Mobile Imaging"
                disabled={disabled}
                multiple
                error={getFieldError(facilityFields.mobileImagings)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                control={control}
                name={facilityFields.strokeCertification}
                options={strokeCertificationOptions}
                label="Stroke Certification"
                disabled={disabled}
                error={getFieldError(facilityFields.strokeCertification)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Select
                control={control}
                name={facilityFields.hospitalSize}
                options={hospitalSizeOptions}
                label="Hospital Size"
                disabled={disabled}
                error={getFieldError(facilityFields.hospitalSize)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              {Object.entries(networkFields).map(([key, fieldName]) => (
                <Grid item xs={12} key={key}>
                  <Autocomplete
                    control={control}
                    name={fieldName}
                    disabled={disabled}
                    label={`${ServiceTypeLabels[key]} Network`}
                    options={networks?.networks ?? []}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <InputField
                  {...register(facilityFields.note, {
                    disabled,
                  })}
                  multiline
                  rows={6}
                  label="BSL Facility Note"
                  error={getFieldError(facilityFields.note)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormWrapper>
    </FlexBoxColumnFull>
  );
};

FacilityForm.propTypes = {
  initValues: PropTypes.shape({}),
  disabled: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  siteCode: PropTypes.string,
};

export default FacilityForm;
