import { mapToggleFieldOptions } from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/Signature/formFields';

export default [
  fields.attestationOfEEGCompletion,
  mapToggleFieldOptions(fields.eegTelemedicine),
  mapToggleFieldOptions(fields.providerLocationPredefined),
  fields.providerLocationFreeText,
  fields.patientLocation,
  fields.eegcptCode,
];
