import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Delete } from '@mui/icons-material';

import api from 'api';
import sortFields from 'api/sortFields';
import { US_DATE_FORMAT, TIME_FORMAT, formatDate } from 'utils/dates';
import { getTableHeaders, prepareTableData } from 'utils/tables';

import {
  useUpdateEffect,
  useMountEffect,
  useTableState,
  useFilterState,
  useRequest,
} from 'shared/hooks';

import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import { FlexBoxColumn, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import { MacroTypes, ServiceTypeLabels } from 'utils/enums';

import AddMacro from '../components/AddMacro';
import DeleteMacroModal from '../components/DeleteMacroModal';

import MacroListFilters from './MacroListFilters';

const MacrosListPage = ({ macroType }) => {
  const navigate = useNavigate();

  const { createSharedMacros } = useSelector(({ profile }) => profile.data);

  const [toDelete, setToDelete] = React.useState(null);

  const {
    doRequest: getMacros,
    data: macroData,
    isLoading,
  } = useRequest(api.getMacros);

  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setPage,
    setQueryParams,
  } = useTableState(sortFields.macros.name);

  const { filters, handleFilter } = useFilterState({ type: null }, setPage);

  const renderDeleteButton = React.useCallback(
    (item) => (
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setToDelete(item.id);
        }}
      >
        <Delete color="primary" />
      </IconButton>
    ),
    [],
  );

  const tableColumns = React.useMemo(
    () => [
      {
        header: {
          id: sortFields.macros.name,
          value: 'Macro Name',
          isSortable: true,
        },
        dataKey: 'name',
      },
      {
        header: {
          id: sortFields.macros.createdAt,
          value: 'Created Date',
          isSortable: true,
        },
        formatValue: ({ createdAt }) =>
          formatDate(new Date(createdAt), `${US_DATE_FORMAT} - ${TIME_FORMAT}`),
      },
      {
        header: {
          id: sortFields.macros.type,
          value: 'Type',
          isSortable: true,
        },
        formatValue: ({ type }) => ServiceTypeLabels[type],
      },
      ...(macroType !== MacroTypes.SHARED || createSharedMacros
        ? [
            {
              header: {
                id: 'deleteMacro',
                value: '',
              },
              formatValue: renderDeleteButton,
              width: '48px',
              align: 'center',
              empty: true,
            },
          ]
        : []),
    ],
    [renderDeleteButton, createSharedMacros, macroType],
  );

  const fetchMacros = React.useCallback(() => {
    getMacros({
      page,
      sortBy,
      sortOrder,
      filters: { ...filters, macroType },
    });
    setQueryParams({
      ...filters,
      page,
      sortBy,
      sortOrder,
    });
  }, [getMacros, macroType, page, sortBy, sortOrder, filters, setQueryParams]);

  useMountEffect(fetchMacros);

  useUpdateEffect(() => {
    fetchMacros();
  }, [page, sortBy, sortOrder, filters]);

  useUpdateEffect(() => {
    handleFilter({ type: null });
  }, [macroType]);

  return (
    <>
      {toDelete && (
        <DeleteMacroModal
          onConfirm={() =>
            api.deleteMacro(toDelete).then(() => {
              setToDelete(null);
              fetchMacros();
            })
          }
          onCancel={() => setToDelete(null)}
        />
      )}
      <FlexBoxSpaceBetweenCenter mt={1}>
        <MacroListFilters filters={filters} handleFilter={handleFilter} />
        {(macroType !== MacroTypes.SHARED || createSharedMacros) && <AddMacro macroType={macroType} />}
      </FlexBoxSpaceBetweenCenter>
      <FlexBoxColumn mt={2}>
        <PaginatedTable
          headings={getTableHeaders(tableColumns)}
          data={prepareTableData(macroData?.macros && !isLoading ? macroData?.macros : [], tableColumns)}
          fallbackMsg={isLoading ? 'Loading...' : 'No Macros found'}
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={macroData?.pageCount || 0}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onClickRow={(macroId) => navigate(macroId)}
        />
      </FlexBoxColumn>
    </>
  );
};

MacrosListPage.propTypes = {
  macroType: PropTypes.string,
};

export default MacrosListPage;
