import pick from 'lodash/pick';

import { CONSULT_TABS } from 'features/consults/utils/constants';
import {
  ServiceTypeEnum,
  ServiceTypeLabels,
  ConsultFieldTypeEnum,
} from 'utils/enums';
import { getFieldSummaryValueForType } from 'features/consults/utils/formFields';
import { isEmptyValue } from 'features/consults/utils/validators';
import demographicsFields, {
  clinicDemographicsFields,
  eegDemographicsFields,
  teleNeuroDemographicsFields,
} from 'features/consults/shared/tabs/Demographics/formFields';
import hpiFields from 'features/consults/shared/tabs/HPI/formFields';
import examFields, {
  teleNeuroExamFields,
} from 'features/consults/shared/tabs/Exam/formFields';
import rosFields, {
  teleNeuroRosFields,
} from 'features/consults/shared/tabs/ROS/formFields';
import pmhFields, {
  teleNeuroPmhFields,
} from 'features/consults/shared/tabs/PMHFHSH/formFields';
import dataFields, {
  teleNeuroDataFields,
} from 'features/consults/shared/tabs/Data/formFields';
import {
  neuroAssessmentFields,
  clinicAssessmentFields,
  teleNeuroAssessmentFields,
} from 'features/consults/shared/tabs/Assessment/formFields';
import planFields, {
  neuroPlanFields,
} from 'features/consults/shared/tabs/Plan/formFields';
import signatureFields, {
  clinicSignatureFields,
  teleNeuroSignatureFields,
} from 'features/consults/shared/tabs/Signature/formFields';
import scoresFields from 'features/consults/pages/neuro/tabs/ScoresNeuro/formFields';
import emgFields from 'features/consults/pages/clinic/tabs/EMG/formFields';
import botoxFields from 'features/consults/pages/clinic/tabs/Botox/formFields';
import eegFields from 'features/consults/pages/eeg/tabs/EEG/formFields';
import strokeFields from 'features/consults/pages/tele-neuro/tabs/Stroke/formFields';

const neuroConsultSections = {
  [CONSULT_TABS.DEMOGRAPHICS]: {
    title: 'Demographics',
    fields: demographicsFields,
  },
  [CONSULT_TABS.HPI]: {
    title: 'HPI',
    fields: hpiFields,
  },
  [CONSULT_TABS.SCORES]: {
    title: 'Scores',
    fields: scoresFields,
  },
  [CONSULT_TABS.EXAM]: {
    title: 'Exam',
    fields: examFields,
  },
  [CONSULT_TABS.ROS]: {
    title: 'ROS',
    fields: rosFields,
  },
  [CONSULT_TABS.PMH]: {
    title: 'PMH-FH-SH',
    fields: pmhFields,
  },
  [CONSULT_TABS.DATA]: {
    title: 'Data',
    fields: dataFields,
  },
  [CONSULT_TABS.ASSESSMENT]: {
    title: 'Assessment',
    fields: neuroAssessmentFields,
  },
  [CONSULT_TABS.PLAN]: {
    title: 'Plan',
    fields: neuroPlanFields,
  },
  [CONSULT_TABS.SIGNATURE]: {
    title: 'Logistics',
    fields: signatureFields,
  },
};

const teleNeuroConsultSections = {
  [CONSULT_TABS.DEMOGRAPHICS]: {
    title: 'Demographics',
    fields: teleNeuroDemographicsFields,
  },
  [CONSULT_TABS.HPI]: {
    title: 'HPI',
    fields: hpiFields,
  },
  [CONSULT_TABS.PMH]: {
    title: 'PMH-FH-SH',
    fields: teleNeuroPmhFields,
  },
  [CONSULT_TABS.ROS]: {
    title: 'ROS',
    fields: teleNeuroRosFields,
  },
  [CONSULT_TABS.SCORES]: {
    title: 'Scores',
    fields: scoresFields,
  },
  [CONSULT_TABS.EXAM]: {
    title: 'Exam',
    fields: teleNeuroExamFields,
  },
  [CONSULT_TABS.DATA]: {
    title: 'Data',
    fields: teleNeuroDataFields,
  },
  [CONSULT_TABS.ASSESSMENT]: {
    title: 'Assessment',
    fields: teleNeuroAssessmentFields,
  },
  [CONSULT_TABS.PLAN]: {
    title: 'Plan',
    fields: planFields,
  },
  [CONSULT_TABS.STROKE]: {
    title: 'Stroke Core Measures',
    fields: strokeFields,
  },
  [CONSULT_TABS.SIGNATURE]: {
    title: 'Logistics',
    fields: teleNeuroSignatureFields,
  },
};

const clinicConsultSections = {
  [CONSULT_TABS.DEMOGRAPHICS]: {
    title: 'Demographics',
    fields: clinicDemographicsFields,
  },
  [CONSULT_TABS.HPI]: {
    title: 'HPI',
    fields: hpiFields,
  },
  [CONSULT_TABS.ROS]: {
    title: 'ROS',
    fields: rosFields,
  },
  [CONSULT_TABS.PMH]: {
    title: 'PMH-FH-SH',
    fields: pmhFields,
  },
  [CONSULT_TABS.EXAM]: {
    title: 'Exam',
    fields: examFields,
  },
  [CONSULT_TABS.EMG]: {
    title: 'EMG',
    fields: emgFields,
  },
  [CONSULT_TABS.BOTOX]: {
    title: 'Botox',
    fields: botoxFields,
  },
  [CONSULT_TABS.DATA]: {
    title: 'Data',
    fields: dataFields,
  },
  [CONSULT_TABS.ASSESSMENT]: {
    title: 'Assessment/Medical Decision Making',
    fields: clinicAssessmentFields,
  },
  [CONSULT_TABS.PLAN]: {
    title: 'Plan',
    fields: planFields,
  },
  [CONSULT_TABS.SIGNATURE]: {
    title: 'Logistics',
    fields: clinicSignatureFields,
  },
};

const eegConsultSections = {
  [CONSULT_TABS.DEMOGRAPHICS]: {
    title: 'Demographics',
    fields: eegDemographicsFields,
  },
  [CONSULT_TABS.EEG]: {
    title: 'EEG Interpretation',
    fields: pick(eegFields, [
      'startTimeOfEEGReadAt',
      'stopTimeOfEEGReadAt',
      'duration',
      'technicalDetailsPredefined',
      'technicalDetailsFreeText',
      'indicationPredefined',
      'indicationFreeText',
      'additionalPatientHistory',
    ]),
  },
  [`${CONSULT_TABS.EEG}-2`]: {
    title: 'Description',
    fields: pick(eegFields, [
      'photicStimulationPredefined',
      'photicStimulationFreeText',
      'hyperventilationPredefined',
      'hyperventilationFreeText',
      'phasesCapturedPredefined',
      'phasesCapturedFreeText',
      'symmetryPredefined',
      'symmetryFreeText',
      'posteriorDominantRhythmPredefined',
      'posteriorDominantRhythmFreeText',
      'predominantFrequenciesFrequency',
      'predominantFrequenciesFrequencyDuration',
      'predominantFrequenciesFreeText',
      'superimposedFrequenciesFrequency',
      'superimposedFrequenciesFrequencyDuration',
      'superimposedFrequenciesFreeText',
      'amplitudePredefined',
      'amplitudeFreeText',
      'reactivityPredefined',
      'reactivityFreeText',
      'variabilityPredefined',
      'variabilityFreeText',
      'continuityPredefined',
      'continuityFreeText',
      'ekgPredefined',
      'ekgFreeText',
    ]),
    tab: CONSULT_TABS.EEG,
  },
  [`${CONSULT_TABS.EEG}-3`]: {
    title: 'Abnormalities',
    fields: pick(eegFields, [
      'stimulationPredefined',
      'stimulationFreeText',
      'epileptiformAbnormalitiesPredefined',
      'epileptiformAbnormalitiesFreeText',
      'focalSlowingPredefined',
      'focalSlowingFreeText',
      'seizurePredefined',
      'seizureFreeText',
      'artifact',
    ]),
    tab: CONSULT_TABS.EEG,
  },
  [`${CONSULT_TABS.EEG}-4`]: {
    title: 'Impression',
    fields: pick(eegFields, [
      'impressionsPredefined',
      'impressionsSubtypePredefined',
      'abnormalityOptionsFreeText',
    ]),
    tab: CONSULT_TABS.EEG,
  },
  [`${CONSULT_TABS.EEG}-5`]: {
    title: 'Clinical Correlation',
    fields: pick(eegFields, [
      'impressionsClinicalCorrelationFreeText',
      'additionalComments',
    ]),
    tab: CONSULT_TABS.EEG,
  },
  [CONSULT_TABS.SIGNATURE]: {
    title: 'Logistics',
    fields: signatureFields,
  },
};

const getAdditionalValue = (oldValue, newValue) => {
  const newValueToArray = Array.isArray(newValue) ? newValue : [newValue];
  if (!oldValue) return newValueToArray;
  const oldValueToArray = Array.isArray(oldValue) ? oldValue : [oldValue];
  return [...oldValueToArray, ...newValueToArray];
};

export const hasValue = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return !isEmptyValue(value);
};

export const getSummaryFields = (
  fields,
  tabData,
  facility,
  attendingPhysicianName,
) =>
  Object.values(fields).reduce((acc, fieldProps) => {
    const fieldValue = tabData[fieldProps.name];
    const { excludeFromSummary, associatedField } = fieldProps;

    // Show field if it has not been explicitly excluded from the summary tab
    if (!excludeFromSummary) {
      if (!associatedField) {
        if (fieldProps.name === demographicsFields.facility.name) {
          return acc.concat({
            ...fieldProps,
            type: ConsultFieldTypeEnum.INPUT,
            value: facility?.facilityName,
          });
        }
        if (
          fieldProps.name === teleNeuroSignatureFields.attendingPhysicianId.name
        ) {
          return acc.concat({
            ...fieldProps,
            type: ConsultFieldTypeEnum.INPUT,
            value: attendingPhysicianName,
          });
        }
        return acc.concat({ ...fieldProps, value: fieldValue });
      }

      // If a field is associated with another one
      // append its value to the associated field
      return acc.map((addedField) => {
        if (addedField.name === associatedField && hasValue(fieldValue)) {
          const additionalValue = getFieldSummaryValueForType({
            ...fieldProps,
            value: fieldValue,
          });
          return {
            ...addedField,
            additionalValue: getAdditionalValue(
              addedField.additionalValue,
              additionalValue,
            ),
          };
        }
        return addedField;
      });
    }
    return acc;
  }, []);

const summarySections = {
  [ServiceTypeEnum.NEURO]: neuroConsultSections,
  [ServiceTypeEnum.TELE_NEURO]: teleNeuroConsultSections,
  [ServiceTypeEnum.CLINIC]: clinicConsultSections,
  [ServiceTypeEnum.EEG]: eegConsultSections,
  [ServiceTypeEnum.PEDS_EEG]: eegConsultSections,
};

export const getConsultSummaryData = (consultType) => {
  const sections = summarySections[consultType];

  return sections
    ? {
        title: ServiceTypeLabels[consultType],
        sections,
      }
    : {};
};
