import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Switch from '@mui/material/Switch';

import InputLabel from 'shared/components/forms/InputLabel/InputLabel';

const ControlledSwitch = ({
  control,
  name,
  disabled = false,
  size = 'medium',
  required = false,
  color = 'primary',
  onChange,
  label,
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue=""
    render={({
      field: { value, onChange: onControlChange, ...fieldProps },
    }) => (
      <>
        {!!label && (
          <InputLabel htmlFor={name} required={required} disabled={disabled}>
            {label}
          </InputLabel>
        )}
        <Switch
          {...fieldProps}
          checked={Boolean(value)}
          disabled={disabled}
          color={color}
          required={required}
          size={size}
          onChange={(_, newValue) => {
            onControlChange(newValue);
            if (onChange) onChange(newValue);
          }}
        />
      </>
    )}
  />
);

ControlledSwitch.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ControlledSwitch;
