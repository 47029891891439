export const DEFAULT_SNACKBAR_DURATION = 3000;
export const PAGINATION_PAGE_SIZE = 30;
export const MAX_PAGE_SIZE = 10000;
export const MAX_IMAGE_FILE_SIZE = 2097152; // 2 MB
export const DEBOUNCE_TIMEOUT = 600;

export const LOCKED_ERROR_CODE = 423;

export const requiredString = 'This field is required';

export const EMPTY_COLUMN_VALUE = '--';

export const FORCE_CHANGE_PASSWORD = 'ForceChangePassword';

export const YES = 'Yes';
export const NO = 'No';

export const ALLOW_EMPTY_KEY_LIST = ['assignToUserIds'];
