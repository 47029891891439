import React from 'react';
import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';

import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';

import { CONSULT_TABS } from 'features/consults/utils/constants';
import strokePanel from './data';

const Stroke = () => {
  const { control, register, getFieldError, getValues, setFormValues } =
    useFormState(null, { stateSlice: 'consults', showAsSnackbar: true });
  const { consult, consultTabData, isEditable, handleFieldChange } =
    useConsultData(CONSULT_TABS.STROKE, getValues);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  return (
    <GridWrapper>
      <Grid item sm={12}>
        <QuestionsPanel
          {...strokePanel}
          control={control}
          register={register}
          onChange={handleFieldChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
        />
      </Grid>
    </GridWrapper>
  );
};

export default Stroke;
