import React from 'react';
import PropTypes from 'prop-types';

import { OutlinedInput } from '@mui/material';

import { ServiceTypeEnum } from 'utils/enums';
import { Box } from 'styles/layout';

import ToDoList from './ToDoList';
import FacilityInfo from './FacilityInfo';
import SystemNote from './SystemNote';
import FacilityNote from './FacilityNote';

import { MenuHeading } from '../ConsultMenu.style';

const ConsultInfo = ({ consult, consultType, consultFacility }) => (
  <>
    {consultType === ServiceTypeEnum.NEURO && (
      <Box mb={3}>
        <MenuHeading>To Do</MenuHeading>
        <ToDoList consult={consult} consultType={consultType} />
      </Box>
    )}
    <Box mb={3}>
      <MenuHeading>Facility Info</MenuHeading>
      <FacilityInfo
        consultFacility={consultFacility}
        consultType={consultType}
      />
    </Box>
    <Box mb={3}>
      <MenuHeading>My System Notes</MenuHeading>
      <SystemNote
        consultType={consultType}
        healthSystemId={consultFacility?.healthSystem?.id}
      />
    </Box>
    <Box mb={3}>
      <MenuHeading>My Facility Notes</MenuHeading>
      <FacilityNote
        consultType={consultType}
        facilityId={consultFacility?.id}
      />
    </Box>
    <Box mb={3}>
      <MenuHeading>BSL System Notes</MenuHeading>
      <OutlinedInput
        value={consultFacility?.healthSystem?.note ?? ''}
        fullWidth
        multiline
        disabled
        rows={3}
      />
    </Box>
    <Box>
      <MenuHeading>BSL Facility Notes</MenuHeading>
      <OutlinedInput
        value={consultFacility?.note ?? ''}
        fullWidth
        multiline
        disabled
        rows={3}
      />
    </Box>
  </>
);

ConsultInfo.propTypes = {
  consult: PropTypes.shape({}).isRequired,
  consultType: PropTypes.string.isRequired,
  consultFacility: PropTypes.shape({
    id: PropTypes.number,
    healthSystem: PropTypes.shape({
      id: PropTypes.number,
      note: PropTypes.string,
    }),
    note: PropTypes.string,
  }),
};

export default ConsultInfo;
