import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Grid, List, ListItem, ListSubheader, Typography } from '@mui/material';

import { IntegrationStatusEnum } from 'utils/enums';
import { Box, FlexBoxCenter } from 'styles/layout';

const HL7OrderList = ({ list = [] }) =>
  list &&
  Object.values(list)?.map(({ key, value }) => (
    <ListItem key={key}>
      <b>{`${key}:`}</b>
      &nbsp;&nbsp;
      <span>{value || '--'}</span>
    </ListItem>
  ));

const HL7OrderBlock = ({ title, children }) => (
  <Grid item xs={12} md={6}>
    <List
      subheader={
        <ListSubheader disableSticky component="div">
          <Typography variant="h6" mb={2}>
            {title}
          </Typography>
        </ListSubheader>
      }
    >
      {children}
    </List>
  </Grid>
);

HL7OrderBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const HL7OrderScreen = ({ details }) => (
  <Box pl={4} mt={2}>
    <Grid container spacing={5}>
      <HL7OrderBlock title="HL7 Order">
        <HL7OrderList list={details?.hl7Order} />
      </HL7OrderBlock>

      <HL7OrderBlock title="HL7 Patient ">
        <HL7OrderList list={details?.hl7Patient} />
      </HL7OrderBlock>

      <HL7OrderBlock title="HL7 Visits">
        {details?.hl7Visits?.map((visit) => (
          <Box key={uuid()} mb={4}>
            <HL7OrderList list={visit} />
          </Box>
        ))}
      </HL7OrderBlock>
    </Grid>
  </Box>
);

HL7OrderScreen.propTypes = {
  details: PropTypes.shape({
    hl7Order: PropTypes.arrayOf(PropTypes.shape({})),
    hl7Patient: PropTypes.arrayOf(PropTypes.shape({})),
    hl7Visits: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  }),
};

const HL7OrderTab = ({ details, integrationStatus }) =>
  integrationStatus === IntegrationStatusEnum.UNMATCHED ? (
    <FlexBoxCenter height="100%">
      <Typography variant="h6">UNMATCHED</Typography>
    </FlexBoxCenter>
  ) : (
    <HL7OrderScreen details={details} />
  );

HL7OrderTab.propTypes = {
  details: PropTypes.shape({}),
  integrationStatus: PropTypes.string,
};

export default HL7OrderTab;
