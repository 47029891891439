import { createTheme, darken } from '@mui/material/styles';

import colors from './colors';

export const themeOptions = {
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
      dark: darken(colors.secondary, 0.1),
      contrastText: colors.white,
    },
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warningDark,
    },
  },
};

export default createTheme(themeOptions);
