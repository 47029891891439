import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMFAPreference } from 'aws-amplify/auth';

import { profileActions } from 'features/profile/store/slice';
import { UserRoleEnum, doctorRoles } from 'utils/enums';

export const useProfile = () => {
  const dispatch = useDispatch();
  const { data, profilePic, error, mfaList } = useSelector(
    (state) => state.profile,
  );

  const roles = useMemo(() => data?.roles || [], [data?.roles]);

  const isDoctor = useMemo(
    () => roles.some((role) => doctorRoles.includes(role)),
    [roles],
  );

  const isCustomerOnly = useMemo(
    () => roles.every((role) => role === UserRoleEnum.CUSTOMER),
    [roles],
  );

  const fetchMfaList = useCallback(async () => {
    const { enabled } = await fetchMFAPreference();
    dispatch(profileActions.setMfaList(enabled));
  }, [dispatch]);

  const fetchProfile = useCallback(() => {
    dispatch(profileActions.getProfile());
    fetchMfaList();
  }, [dispatch, fetchMfaList]);

  return {
    profileData: data,
    profilePic,
    fetchProfile,
    mfaList,
    fetchMfaList,
    roles,
    isDoctor,
    isCustomerOnly,
    error,
  };
};

export default useProfile;
