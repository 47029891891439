import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, NavLink, Routes, Route } from 'react-router-dom';

import styled from '@emotion/styled';

import Paths from 'navigation/paths';

import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import Sidebar from 'shared/components/layout/Sidebar/Sidebar';
import colors from 'styles/colors';

import ConsultsPage from './pages/Consults';
import ConsultRequestsPage from './pages/ConsultRequests';
import BillingDashboard from './pages/Billing';
import UsersPage from './pages/Users';
import ProvidersPage from './pages/Providers';
import HealthSystemsPage from './pages/Locations';
import NetworksPage from './pages/Networks';
import OrdersPage from './pages/Orders';

import AdminPaths from './paths';

const SidebarItem = styled(NavLink)`
  margin: 10px 0;
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.secondary};

  &:hover {
    background: ${colors.background};
  }

  &.active {
    color: ${colors.white};
    background: ${colors.secondary};
  }
`;

const getAdminRoutes = (viewAdminConsultTable) => ({
  ...(viewAdminConsultTable
    ? {
        [AdminPaths.Consults]: {
          label: 'Consults',
          element: <ConsultsPage />,
        },
      }
    : {}),
  [AdminPaths.ConsultRequests]: {
    label: 'Consult Requests',
    element: <ConsultRequestsPage />,
  },
  [AdminPaths.Billing]: {
    label: 'Billing',
    element: <BillingDashboard />,
  },
  [AdminPaths.Users]: {
    label: 'Users',
    element: <UsersPage />,
  },
  [AdminPaths.Providers]: {
    label: 'Providers',
    element: <ProvidersPage />,
  },
  [AdminPaths.HealthSystems]: {
    label: 'Locations',
    element: <HealthSystemsPage />,
  },
  [AdminPaths.Networks]: {
    label: 'Networks',
    element: <NetworksPage />,
  },
  [AdminPaths.Orders]: {
    label: 'Orders',
    element: <OrdersPage />,
  },
});

const Admin = () => {
  const { viewAdminConsultTable } = useSelector(({ profile }) => profile.data);

  const availableRoutes = React.useMemo(
    () => getAdminRoutes(viewAdminConsultTable),
    [viewAdminConsultTable],
  );

  const homePage = React.useMemo(
    () => Object.keys(availableRoutes)[0],
    [availableRoutes],
  );

  return (
    <>
      <Sidebar>
        {Object.entries(availableRoutes).map(([path, { label }]) => (
          <SidebarItem
            key={path}
            to={`/${Paths.Admin}/${path}`}
            replace
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {label}
          </SidebarItem>
        ))}
      </Sidebar>
      <MainWrapper>
        <Routes>
          {Object.entries(availableRoutes).map(([path, { element }]) => (
            <Route key={path} path={`${path}/*`} element={element} />
          ))}
          <Route path="/" element={<Navigate to={homePage} />} />
        </Routes>
      </MainWrapper>
    </>
  );
};

export default Admin;
