import { useCallback } from 'react';
import { useNavigate, useNavigationType } from 'react-router-dom';

const useGoBack = () => {
  const navigate = useNavigate();
  const type = useNavigationType();
  const goBack = useCallback(
    (options) => {
      if (type === 'POP') {
        navigate(options?.backTo || '..', { replace: !!options?.replace });
        return;
      }
      navigate(-1, { replace: !!options?.replace });
    },
    [navigate, type],
  );

  return {
    goBack,
    navigate,
  };
};

export default useGoBack;
