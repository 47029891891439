import styled from '@emotion/styled';
import { Paper } from '@mui/material';

import CloseBtn from 'shared/components/buttons/CloseButton/CloseButton';

import { Box, FlexBox, FlexBoxColumn } from 'styles/layout';
import colors from 'styles/colors';

export const NotesWrapper = styled(Paper)`
  margin: 20px 0;
`;

export const NotesContent = styled(FlexBoxColumn)`
  padding: ${({ _open }) => (_open ? '8px 12px 8px 24px' : '8px 4px')};
  min-width: ${({ _open }) => _open && '320px'};
`;

export const SmToggleWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1101; /* Show above the navigation bar */
`;

export const ToggleIconWrapper = styled(FlexBox)`
  align-self: flex-end;
  flex-direction: ${({ _open }) => (_open ? 'row' : 'column')};
`;

export const ExpandedNotesWrapper = styled(FlexBoxColumn)`
  padding: 0px 12px 0 0;
`;

export const CloseButton = styled(CloseBtn)`
  padding: 0.5rem;
  margin-left: -0.8rem;
  margin-top: 0.2rem;
  svg {
    color: ${colors.primary};
    font-size: 1.5rem;
  }
`;

export const MenuHeading = styled.h3`
  color: ${colors.primary};
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 10px 0;
`;
