import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ConsultRequestStatusEnum } from 'utils/enums';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import {
  Box,
  FlexBoxCenter,
  FlexBoxColumnSpaceBetween,
  FlexBoxColumnCenter,
} from 'styles/layout';

import { RequestDetails } from './ConsultRequestDetails.style';
import ConsultRequestTypeDetails from './ConsultRequestTypeDetails';

const ConsultRequestDetails = ({ consultRequest, onAccept, onDecline }) => {
  if (consultRequest.state !== ConsultRequestStatusEnum.OPEN) {
    return (
      <FlexBoxColumnCenter height="100%">
        {consultRequest.state === ConsultRequestStatusEnum.ACCEPTED && (
          <>
            <RequestDetails>
              Consult has already been accepted by
            </RequestDetails>
            <RequestDetails>{`${consultRequest.acceptedByFirstName} ${consultRequest.acceptedByLastName}`}</RequestDetails>
          </>
        )}
        {consultRequest.state === ConsultRequestStatusEnum.CLOSED && (
          <RequestDetails>Consult is closed</RequestDetails>
        )}
        <ActionButton component={Link} type="button" to="/">
          Homepage
        </ActionButton>
      </FlexBoxColumnCenter>
    );
  }

  return (
    <FlexBoxCenter height="100%">
      <FlexBoxColumnSpaceBetween>
        <Box mb={5}>
          <ConsultRequestTypeDetails consultRequest={consultRequest} />
        </Box>
        <FlexBoxColumnCenter style={{ gap: 30 }}>
          <ActionButton
            type="button"
            color="secondary"
            size="large"
            fullWidth
            onClick={onAccept}
          >
            Accept
          </ActionButton>
          <ActionButton
            type="button"
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={onDecline}
          >
            Decline
          </ActionButton>
        </FlexBoxColumnCenter>
      </FlexBoxColumnSpaceBetween>
    </FlexBoxCenter>
  );
};

ConsultRequestDetails.propTypes = {
  consultRequest: PropTypes.shape({
    id: PropTypes.string,
    state: PropTypes.string,
    acceptedByFirstName: PropTypes.string,
    acceptedByLastName: PropTypes.string,
  }).isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

export default ConsultRequestDetails;
