import React from 'react';
import { signInWithRedirect } from 'aws-amplify/auth';
import { Button, Image, Text } from '@aws-amplify/ui-react';

import googleLogo from 'auth/assets/google-logo.svg';

const GoogleLoginBtn = () => (
  <Button
    isFullWidth
    onClick={() => signInWithRedirect({ provider: 'Google' })}
  >
    <Image src={googleLogo} margin="0 10px 0 0" />
    <Text color="gray">Google Login</Text>
  </Button>
);

export default GoogleLoginBtn;
