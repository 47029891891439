import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { ServiceTypeEnum, IntegrationStatusEnum } from 'utils/enums';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { consultsActions } from 'features/consults/store/slice';
import { appStateActions } from 'store/appStateSlice';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import IntegrationIcon from 'shared/components/icons/IntegrationIcon';
import ConsultIntegrationUnmatch from 'features/consults/components/ConsultIntegration/ConsultIntegrationUnmatch';

const IntegrationLink = ({
  consultId,
  consultType,
  isClosed,
  currentConsult: { integrationStatus },
}) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { currentConsultFacility } = useSelector(({ consults }) => consults);

  usePerformActionOnSuccess(SuccessActionTypesEnum.ORDER_UNLINKED, () => {
    if (!isClosed) {
      dispatch(
        consultsActions.editConsult({
          consultId,
          tab: CONSULT_TABS.DEMOGRAPHICS,
          changes: {
            firstName: '',
          },
          cb: () =>
            dispatch(
              consultsActions.getConsultById({
                consultId,
                forceReload: true,
              }),
            ),
        }),
      );
    }

    setShowModal(false);
    dispatch(
      appStateActions.showSnackbar({
        text: 'Unlinked successfully',
        type: SnackbarTypeEnum.SUCCESS,
        duration: 5000,
      }),
    );
  });

  if (
    consultType !== ServiceTypeEnum.NEURO ||
    !currentConsultFacility?.isIntegrated
  )
    return null;

  return (
    <>
      <Dialog
        open={showModal}
        maxWidth="md"
        onClose={() => setShowModal(false)}
      >
        <DialogContent>
          <ConsultIntegrationUnmatch
            onUnlink={() =>
              dispatch(consultsActions.unlinkOrder({ consultId, consultType }))
            }
            onCancel={() => setShowModal(false)}
          />
        </DialogContent>
      </Dialog>
      <IconButton
        disabled={
          integrationStatus !== IntegrationStatusEnum.MATCHED || isClosed
        }
        onClick={() => setShowModal(true)}
      >
        <IntegrationIcon integrationStatus={integrationStatus} />
      </IconButton>
    </>
  );
};

IntegrationLink.propTypes = {
  consultId: PropTypes.string.isRequired,
  consultType: PropTypes.string.isRequired,
  isClosed: PropTypes.bool,
  currentConsult: PropTypes.shape({
    integrationStatus: PropTypes.string,
  }).isRequired,
};

export default IntegrationLink;
