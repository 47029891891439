import React from 'react';
import { useParams } from 'react-router-dom';

import api from 'api';
import { useMountEffect, useFeedback, useEditMode } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';

import EditButton from 'shared/components/buttons/EditButton';
import BackButton from 'navigation/components/BackButton/BackButton';
import FacilityForm, {
  mapFacilityToForm,
} from 'features/admin/components/FacilityForm';
import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';

import {
  FlexBoxAlign,
  FlexBoxColumn,
  FlexBoxSpaceBetween,
} from 'styles/layout';

const FacilityDetailsPage = () => {
  const [facility, setFacility] = React.useState(null);
  const { facilityId } = useParams();
  const { goBack } = useGoBack();
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { clearDisplay, errorDisplay } = useFeedback();

  useMountEffect(() => {
    api
      .getFacilityById(facilityId)
      .then(({ data }) => setFacility(data.facility));
  });

  const initValues = React.useMemo(
    () => facility && mapFacilityToForm(facility),
    [facility],
  );

  const onFacilytyUpdate = React.useCallback(
    (values) => {
      clearDisplay();
      api
        .updateFacility(values)
        .then(({ data }) => {
          setFacility(data);
          disableEditMode();
        })
        .catch(() => errorDisplay('Error updating Facility'));
    },
    [clearDisplay, errorDisplay, disableEditMode],
  );

  if (!initValues) return <LoadingSpinner />;

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxSpaceBetween>
        <FlexBoxAlign>
          {!isEditMode && <BackButton onBack={goBack} />}
          <PageTitle>{initValues.name}</PageTitle>
        </FlexBoxAlign>
        {!isEditMode && (
          <FlexBoxAlign>
            <EditButton onClick={enableEditMode} />
          </FlexBoxAlign>
        )}
      </FlexBoxSpaceBetween>

      <FacilityForm
        initValues={initValues}
        onSave={onFacilytyUpdate}
        onCancel={disableEditMode}
        disabled={!isEditMode}
        siteCode={facility?.healthSystem?.siteCode}
      />
    </FlexBoxColumn>
  );
};

export default FacilityDetailsPage;
