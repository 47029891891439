import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ConsultsList from 'features/consults/components/ConsultsList/ConsultsList';
import ConsultDetailsWrapper from 'features/consults/components/ConsultDetailsWrapper/ConsultDetailsWrapper';
import { ServiceTypeEnum } from 'utils/enums';

const NeuroConsults = () => (
  <Routes>
    <Route
      path="/"
      element={<ConsultsList consultType={ServiceTypeEnum.NEURO} />}
    />
    <Route
      path="/:consultId"
      element={<ConsultDetailsWrapper consultType={ServiceTypeEnum.NEURO} />}
    />
  </Routes>
);

export default NeuroConsults;
