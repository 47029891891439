import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import {
  Stack,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material';

import {
  CheckCircleOutlined as MatchIcon,
  CancelOutlined as UnmatchIcon,
  HelpOutlineOutlined as WaringIcon,
} from '@mui/icons-material';

import { EMPTY_COLUMN_VALUE } from 'utils/constants';
import { formatDateString } from 'utils/dates';

import Button from 'shared/components/buttons/Button/Button';
import { FlexBoxColumnAlign, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import colors from 'styles/colors';

const isMatch = (consultValue, orderValue) => {
  if (!(consultValue || orderValue)) return null;
  return consultValue?.toLowerCase() === orderValue?.toLowerCase();
};

const MatchedIcon = ({ matched }) => {
  switch (matched) {
    case true:
      return <MatchIcon fontSize="large" color="success" />;
    case false:
      return (
        <UnmatchIcon fontSize="large" sx={{ color: colors.notification }} />
      );
    default:
      return <WaringIcon fontSize="large" color="warning" />;
  }
};

MatchedIcon.propTypes = {
  matched: PropTypes.bool,
};

const ConsultIntegrationMerge = ({
  consult,
  order,
  onMerge,
  onClose,
  closeStr = 'Cancel',
}) => {
  const rows = useMemo(() => {
    const {
      demographics: { firstName, lastName, dateOfBirth },
    } = consult;
    const { familyName: orderLastName, givenName: orderFirstName, dob } = order;

    const consultDob = formatDateString(dateOfBirth, 'MM-dd-yyyy');
    const orderDob = formatDateString(dob, 'MM-dd-yyyy');

    return [
      [
        'First Name',
        firstName,
        <MatchedIcon matched={isMatch(firstName, orderFirstName)} />,
        orderFirstName,
      ],
      [
        'Last Name',
        lastName,
        <MatchedIcon matched={isMatch(lastName, orderLastName)} />,
        orderLastName,
      ],
      [
        'DOB',
        consultDob,
        <MatchedIcon matched={isMatch(consultDob, orderDob)} />,
        orderDob,
      ],
    ];
  }, [consult, order]);

  return (
    <FlexBoxColumnAlign style={{ padding: '2rem 8rem' }}>
      <FlexBoxSpaceBetweenCenter
        width="100%"
        mb={3}
        sx={{ color: colors.neutralDark }}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Patient MRN:
          </Typography>
          <Typography variant="subtitle1">{order.mrn}</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            Patient Account Number:
          </Typography>
          <Typography variant="subtitle1">
            {order.patientAccountNumber}
          </Typography>
        </Stack>
      </FlexBoxSpaceBetweenCenter>

      <Typography variant="h6" align="center">
        Are you sure you want to reconcile this Consult with the associated ADT
        Order?
        <br />
        This will update the information entered on the selected Consult to
        match the ADT Order
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Consult</TableCell>
            <TableCell />
            <TableCell>ADT Order</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row[0]}>
              {row.map((el, index) => (
                <TableCell key={uuid()} sx={{ fontWeight: index ? 400 : 500 }}>
                  {el || EMPTY_COLUMN_VALUE}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
        <Button onClick={onMerge} color="secondary" variant="contained">
          Merge
        </Button>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {closeStr}
        </Button>
      </Stack>
    </FlexBoxColumnAlign>
  );
};

ConsultIntegrationMerge.propTypes = {
  consult: PropTypes.shape({
    demographics: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      dateOfBirth: PropTypes.string,
    }),
  }).isRequired,
  order: PropTypes.shape({
    familyName: PropTypes.string,
    givenName: PropTypes.string,
    dob: PropTypes.string,
    patientAccountNumber: PropTypes.string,
    mrn: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onMerge: PropTypes.func.isRequired,
  closeStr: PropTypes.string,
};

export default ConsultIntegrationMerge;
