import { ConsultFieldTypeEnum } from 'utils/enums';

const generateNormalSummaryLabels = (mainLabelText) => ({
  summaryLabels: {
    positive: `normal ${mainLabelText}`,
    negative: `abnormal ${mainLabelText}`,
  },
});

const examFields = {
  timeOfExam: {
    name: 'timeOfExam',
    type: ConsultFieldTypeEnum.DATETIME,
    label: 'Time of Exam',
  },
  vitalsPredefined: {
    name: 'vitalsPredefined',
    title: 'Vitals',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'VitalSignsReviewed', label: 'vital signs reviewed' },
      {
        value: 'VitalSignsReviewedAbnormal',
        label: 'vital signs reviewed: abnormal',
      },
      {
        value: 'VitalSignsReviewedNormal',
        label: 'vital signs reviewed: normal',
      },
    ],
  },
  vitalsFreeText: {
    name: 'vitalsFreeText',
    associatedField: 'vitalsPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  sbp: {
    name: 'sbp',
    label: 'SBP',
    type: ConsultFieldTypeEnum.INPUT,
    inlineLabel: true,
  },
  dbp: {
    name: 'dbp',
    label: 'DBP',
    type: ConsultFieldTypeEnum.INPUT,
    inlineLabel: true,
  },
  mentalStatusPositivePredefined: {
    name: 'mentalStatusPositivePredefined',
    label: 'Mental Status',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'Awake',
        label: 'awake',
        summaryLabels: { negative: 'not awake' },
      },
      {
        value: 'AlertAndOrientedByThree',
        label: 'alert and oriented x 3',
        summaryLabels: { negative: 'not alert oriented x 3' },
      },
      {
        value: 'FollowsCommands',
        label: 'follows commands',
        summaryLabels: { negative: 'does not follow commands' },
      },
    ],
  },
  mentalStatusNegativePredefined: {
    name: 'mentalStatusNegativePredefined',
    associatedField: 'mentalStatusPositivePredefined',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Confused', label: 'confused' },
      { value: 'Sleepy', label: 'sleepy' },
      { value: 'Obtunded', label: 'obtunded' },
      { value: 'Lethargic', label: 'lethargic' },
      { value: 'Comatose', label: 'comatose' },
      { value: 'DisorientedToPerson', label: 'disoriented to person' },
      { value: 'DisorientedToPlace', label: 'disoriented to place' },
      { value: 'DisorientedToTime', label: 'disoriented to time' },
    ],
  },
  mentalStatusFreeText: {
    name: 'mentalStatusFreeText',
    associatedField: 'mentalStatusPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  languagePositivePredefined: {
    name: 'languagePositivePredefined',
    label: 'Language',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'NormalSpeech', label: 'normal speech' },
      { value: 'NoAphasia', label: 'no aphasia' },
      { value: 'NoDysarthria', label: 'no dysarthria' },
    ],
  },
  languageNegativePredefined: {
    name: 'languageNegativePredefined',
    associatedField: 'languagePositivePredefined',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Aphasia', label: 'aphasia' },
      { value: 'ReceptiveAphasia', label: 'receptive aphasia' },
      { value: 'ExpressiveAphasia', label: 'expressive aphasia' },
      { value: 'GlobalAphasia', label: 'global aphasia' },
      { value: 'Dysarthria', label: 'dysarthria' },
    ],
  },
  languageFreeText: {
    name: 'languageFreeText',
    associatedField: 'languagePositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  cranialNervesPositivePredefined: {
    name: 'cranialNervesPositivePredefined',
    label: 'Cranial Nerves',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Normal', label: 'normal' },
      {
        value: 'Eomi',
        label: 'eomi',
        summaryLabels: {
          positive: 'extra ocular movements intact',
          negative: 'abnormal extraocular movements',
        },
      },
      {
        value: 'PERRLA',
        label: 'PERRLA',
        summaryLabels: { negative: 'pupils abnormal' },
      },
      {
        value: 'NormalVisFields',
        label: 'normal vis fields',
        ...generateNormalSummaryLabels('visual fields'),
      },
      { value: 'NoFacialDroop', label: 'no facial droop' },
      {
        value: 'NlFacialSensation',
        label: 'nl facial sensation',
        ...generateNormalSummaryLabels('facial sensation'),
      },
      { value: 'NoDysarthria', label: 'no dysarthria' },
    ],
  },
  cranialNervesFacialDroop: {
    name: 'cranialNervesFacialDroop',
    associatedField: 'cranialNervesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'FacialDroop', label: 'facial droop' },
      { value: 'R', label: 'R', summaryLabel: 'right facial droop' },
      { value: 'L', label: 'L', summaryLabel: 'left facial droop' },
      { value: 'No', label: 'no', summaryLabel: 'no facial droop' },
    ],
  },
  cranialNervesDecSensationFace: {
    name: 'cranialNervesDecSensationFace',
    associatedField: 'cranialNervesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'DecFacialSens',
        label: 'dec facial sens',
        summaryLabel: 'decreased facial sensation',
      },
      {
        value: 'R',
        label: 'R',
        summaryLabel: 'decreased right facial sensation',
      },
      {
        value: 'L',
        label: 'L',
        summaryLabel: 'decreased left facial sensation',
      },
      {
        value: 'No',
        label: 'no',
        summaryLabel: 'normal facial sensation',
      },
    ],
  },
  cranialNervesGag: {
    name: 'cranialNervesGag',
    associatedField: 'cranialNervesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'DiminishedGag',
        label: 'diminished gag',
        summaryLabel: 'diminished gag reflex',
      },
      {
        value: 'Absent',
        label: 'absent',
        summaryLabel: 'absent gag reflex',
      },
      { value: 'Nl', label: 'nl', summaryLabel: 'normal gag reflex' },
    ],
  },
  cranialNervesPtosis: {
    name: 'cranialNervesPtosis',
    associatedField: 'cranialNervesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Ptosis', label: 'ptosis' },
      { value: 'R', label: 'R', summaryLabel: 'right ptosis' },
      { value: 'L', label: 'L', summaryLabel: 'left ptosis' },
      { value: 'No', label: 'no', summaryLabel: 'no ptosis' },
    ],
  },
  cranialNervesTongueDeviation: {
    name: 'cranialNervesTongueDeviation',
    associatedField: 'cranialNervesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'TongueDeviation', label: 'tongue deviation' },
      { value: 'R', label: 'R', summaryLabel: 'right tongue deviation' },
      { value: 'L', label: 'L', summaryLabel: 'left tongue deviation' },
      { value: 'No', label: 'no', summaryLabel: 'no tongue deviation' },
    ],
  },
  cranialNervesHemianopsia: {
    name: 'cranialNervesHemianopsia',
    associatedField: 'cranialNervesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Hemianopia', label: 'hemianopia' },
      {
        value: 'RPartial',
        label: 'R partial',
        summaryLabel: 'right partial hemianopia',
      },
      {
        value: 'RComplete',
        label: 'R complete',
        summaryLabel: 'right complete hemianopia',
      },
      {
        value: 'LPartial',
        label: 'L partial',
        summaryLabel: 'left partial hemianopia',
      },
      {
        value: 'LComplete',
        label: 'L complete',
        summaryLabel: 'left complete hemianopia',
      },
      { value: 'No', label: 'no', summaryLabel: 'no hemianopia' },
    ],
  },
  cranialNervesFreeText: {
    name: 'cranialNervesFreeText',
    associatedField: 'cranialNervesPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  motorPositivePredefined: {
    name: 'motorPositivePredefined',
    label: 'Motor',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'NormalStrength', label: 'normal strength' },
      { value: 'NormalBulk', label: 'normal bulk' },
      { value: 'NoDrift', label: 'no drift' },
    ],
  },
  motorFacialDroop: {
    name: 'motorFacialDroop',
    associatedField: 'motorPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'FacialDroop', label: 'facial droop' },
      { value: 'R', label: 'R', summaryLabel: 'right facial droop' },
      { value: 'L', label: 'L', summaryLabel: 'left facial droop' },
      { value: 'B', label: 'B', summaryLabel: 'bilateral facial droop' },
      { value: 'No', label: 'no', summaryLabel: 'no facial droop' },
    ],
  },
  motorUpperExtremity: {
    name: 'motorUpperExtremity',
    associatedField: 'motorPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'UpExtWeak',
        label: 'up ext weak',
        summaryLabel: 'upper extremity weakness',
      },
      {
        value: 'R',
        label: 'R',
        summaryLabel: 'right upper extremity weakness',
      },
      { value: 'L', label: 'L', summaryLabel: 'left upper extremity weakness' },
      {
        value: 'B',
        label: 'B',
        summaryLabel: 'bilateral upper extremity weakness',
      },
      { value: 'No', label: 'no', summaryLabel: 'no upper extremity weakness' },
    ],
  },
  motorLowerExtremity: {
    name: 'motorLowerExtremity',
    associatedField: 'motorPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'LowExtWeak',
        label: 'low ext weak',
        summaryLabel: 'lower extremity weakness',
      },
      {
        value: 'R',
        label: 'R',
        summaryLabel: 'right lower extremity weakness',
      },
      { value: 'L', label: 'L', summaryLabel: 'left lower extremity weakness' },
      {
        value: 'B',
        label: 'B',
        summaryLabel: 'bilateral lower extremity weakness',
      },
      { value: 'No', label: 'no', summaryLabel: 'no lower extremity weakness' },
    ],
  },
  motorDrift: {
    name: 'motorDrift',
    associatedField: 'motorPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Drift', label: 'drift' },
      { value: 'R', label: 'R', summaryLabel: 'right drift' },
      { value: 'L', label: 'L', summaryLabel: 'left drift' },
      { value: 'B', label: 'B', summaryLabel: 'bilateral drift' },
      { value: 'No', label: 'no', summaryLabel: 'no drift' },
    ],
  },
  motorFreeText: {
    name: 'motorFreeText',
    associatedField: 'motorPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  sensationPositivePredefined: {
    name: 'sensationPositivePredefined',
    label: 'Sensation',
    summaryLabel: 'Sensory',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'NlSens',
        label: 'nl sens',
        ...generateNormalSummaryLabels('sensation'),
      },
      {
        value: 'NlSensLightTouch',
        label: 'nl sens light touch',
        ...generateNormalSummaryLabels('sensation to light touch'),
      },
      {
        value: 'NlSensPinPrick',
        label: 'nl sens pin prick',
        ...generateNormalSummaryLabels('sensation to pin prick'),
      },
    ],
  },
  sensationNegativePredefined: {
    name: 'sensationNegativePredefined',
    associatedField: 'sensationPositivePredefined',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'NlSensEXCEPT',
        label: 'nl sens EXCEPT',
        summaryLabel: 'normal sensation EXCEPT',
      },
      {
        value: 'DecSensRFace',
        label: 'dec sens R face',
        summaryLabel: 'decreased sensation right face',
      },
      {
        value: 'DecSensLFace',
        label: 'dec sens L face',
        summaryLabel: 'decreased sensation left face',
      },
      {
        value: 'DecSensRUpExt',
        label: 'dec sens R up ext',
        summaryLabel: 'decreased sensation right upper extremity',
      },
      {
        value: 'DecSensLUpExt',
        label: 'dec sens L up ext',
        summaryLabel: 'decreased sensation left upper extremity',
      },
      {
        value: 'DecSensRLowExt',
        label: 'dec sens R low ext',
        summaryLabel: 'decreased sensation right lower extremity',
      },
      {
        value: 'DecSensLLowExt',
        label: 'dec sens L low ext',
        summaryLabel: 'decreased sensation left lower extremity',
      },
      {
        value: 'DecSensInDermatomeListedBelow',
        label: 'dec sens in dermatome listed below',
        summaryLabel: 'decreased sensation in dermatome listed below',
      },
    ],
  },
  sensationFreeText: {
    name: 'sensationFreeText',
    associatedField: 'sensationPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  cerebellarPositivePredefined: {
    name: 'cerebellarPositivePredefined',
    label: 'Cerebellar',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'NormalCerebellar',
        label: 'normal cerebellar',
        ...generateNormalSummaryLabels('cerebellar exam'),
      },
      {
        value: 'NlFingerNose',
        label: 'nl finger nose',
        ...generateNormalSummaryLabels('finger nose'),
      },
      {
        value: 'NlHeelShin',
        label: 'nl heel shin',
        ...generateNormalSummaryLabels('heel shin'),
      },
    ],
  },
  cerebellarNegativePredefined: {
    name: 'cerebellarNegativePredefined',
    associatedField: 'cerebellarPositivePredefined',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Ataxia', label: 'ataxia' },
      { value: 'TruncalAtaxia', label: 'truncal ataxia' },
      {
        value: 'RFingerNoseAtaxia',
        label: 'R finger nose ataxia',
        summaryLabel: 'right finger nose ataxia',
      },
      {
        value: 'LFingerNoseAtaxia',
        label: 'L finger nose ataxia',
        summaryLabel: 'left finger nose ataxia',
      },
      {
        value: 'RHeelShinAtaxia',
        label: 'R heel shin ataxia',
        summaryLabel: 'right heel shin ataxia',
      },
      {
        value: 'LHeelShinAtaxia',
        label: 'L heel shin ataxia',
        summaryLabel: 'left heel shin ataxia',
      },
    ],
  },
  cerebellarFreeText: {
    name: 'cerebellarFreeText',
    associatedField: 'cerebellarPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  reflexesPositivePredefined: {
    name: 'reflexesPositivePredefined',
    label: 'Reflexes',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [{ value: 'NormalReflexes', label: 'normal reflexes' }],
  },
  reflexesAbnormalHypo: {
    name: 'reflexesAbnormalHypo',
    associatedField: 'reflexesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'HypoReflexive', label: 'hypo reflexive' },
      { value: 'HyperReflexive', label: 'hyper reflexive' },
    ],
  },
  reflexesBabinski: {
    name: 'reflexesBabinski',
    associatedField: 'reflexesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'PlusBabinski', label: '+ Babinski (toe up)' },
      { value: 'MinusBabinski', label: '- Babinski (toe down)' },
    ],
  },
  reflexesWitdrawal: {
    name: 'reflexesWitdrawal',
    associatedField: 'reflexesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'LocalizesToPain', label: 'localizes to pain' },
      {
        value: 'FlexionFromPain',
        label: 'flexion w/d from pain',
        summaryLabel: 'flexion withdrawal from pain',
      },
      {
        value: 'AbnlFlex',
        label: 'abnl flex (decorticate)',
        summaryLabel: 'abnormal flexion (decorticate)',
      },
      {
        value: 'AbnlExt',
        label: 'abnl ext (decerebrate)',
        summaryLabel: 'abnormal extension (decerebrate)',
      },
      { value: 'NoResponseToPain', label: 'no response to pain' },
    ],
  },
  reflexesFreeText: {
    name: 'reflexesFreeText',
    associatedField: 'reflexesPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  additionalNeurologicExam: {
    name: 'additionalNeurologicExam',
    label: 'Additional Neurologic Exam',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 2,
  },
  constitutionalInteractivePredefined: {
    name: 'constitutionalInteractivePredefined',
    label: 'Constitutional',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'AwakeAlert',
        label: 'awake/alert',
        summaryLabels: { negative: 'not awake/alert' },
      },
      {
        value: 'Interactive',
        label: 'interactive',
        summaryLabels: { negative: 'not interactive' },
      },
    ],
  },
  constitutionalAnxious: {
    name: 'constitutionalAnxious',
    associatedField: 'constitutionalInteractivePredefined',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'Anxious',
        label: 'anxious',
        summaryLabels: { negative: 'not anxious' },
      },
    ],
  },
  constitutionalSleepy: {
    name: 'constitutionalSleepy',
    associatedField: 'constitutionalInteractivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Sleepy', label: 'sleepy' },
      { value: 'Lethargic', label: 'lethargic' },
      { value: 'Comatose', label: 'comatose' },
    ],
  },
  constitutionalIntoxicated: {
    name: 'constitutionalIntoxicated',
    associatedField: 'constitutionalInteractivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Sober', label: 'sober' },
      { value: 'Intoxicated', label: 'intoxicated' },
    ],
  },
  constitutionalCombative: {
    name: 'constitutionalCombative',
    associatedField: 'constitutionalInteractivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Combative', label: 'combative' },
      { value: 'Cooperative', label: 'cooperative' },
    ],
  },
  constitutionalFreeText: {
    name: 'constitutionalFreeText',
    associatedField: 'constitutionalInteractivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  eyesPositivePredefined: {
    name: 'eyesPositivePredefined',
    label: 'Eyes',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'NlConjSclera',
        label: 'nl conj sclera',
        ...generateNormalSummaryLabels('conjunctiva and sclera'),
      },
      {
        value: 'EOMI',
        label: 'EOMI',
        summaryLabels: {
          negative: 'extra ocular movements abnormal',
        },
      },
      {
        value: 'PERRL',
        label: 'PERRL',
        summaryLabels: { negative: 'abnormal pupils' },
      },
      {
        value: 'NlCornealReflex',
        label: 'nl corneal reflex',
        ...generateNormalSummaryLabels('corneal reflex'),
      },
    ],
  },
  eyesNegativePredefined: {
    name: 'eyesNegativePredefined',
    associatedField: 'eyesPositivePredefined',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Photophobia', label: 'photophobia' },
      { value: 'DysconjugateGaze', label: 'dysconjugate gaze' },
    ],
  },
  eyesNystagmus: {
    name: 'eyesNystagmus',
    associatedField: 'eyesPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Nystagmus', label: 'nystagmus' },
      {
        value: 'HorizNystag',
        label: 'horiz nystag',
        summaryLabel: 'horizontal nystagmus',
      },
      {
        value: 'HorizNystagFastR',
        label: 'horiz nystag fast R',
        summaryLabel: 'horizontal nystagmus fast right',
      },
      {
        value: 'HorizNystagFastL',
        label: 'horiz nystag fast L',
        summaryLabel: 'horizontal nystagmus fast left',
      },
      { value: 'VerticalNystagmus', label: 'vertical nystagmus' },
      { value: 'RotatoryNystagmus', label: 'rotatory nystagmus' },
      { value: 'NoNystagmus', label: 'no nystagmus' },
    ],
  },
  eyesFreeText: {
    name: 'eyesFreeText',
    associatedField: 'eyesPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  hentPositivePredefined: {
    name: 'hentPositivePredefined',
    label: 'HENT',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'NlInspection',
        label: 'nl inspection',
        ...generateNormalSummaryLabels('inspection'),
      },
      {
        value: 'Atraumatic',
        label: 'atraumatic',
        summaryLabels: { negative: 'evidence of trauma' },
      },
      {
        value: 'NeckSuppleNoMeningismus',
        label: 'neck supple/no meningismus',
        summaryLabels: { negative: 'neck stiff/meningismus present' },
      },
      {
        value: 'NlHearing',
        label: 'nl hearing',
        ...generateNormalSummaryLabels('hearing'),
      },
    ],
  },
  hentEpistaxis: {
    name: 'hentEpistaxis',
    associatedField: 'hentPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Epistaxis', label: 'epistaxis' },
      { value: 'DriedBlood', label: 'dried blood' },
    ],
  },
  hentFreeText: {
    name: 'hentFreeText',
    associatedField: 'hentPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  respiratoryPositivePredefined: {
    name: 'respiratoryPositivePredefined',
    label: 'Respiratory',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'CTA',
        label: 'CTA',
        summaryLabels: {
          positive: 'clear to auscultation bilaterally',
          negative: 'not clear to auscultation',
        },
      },
      {
        value: 'NlBreathSounds',
        label: 'nl breath sounds',
        ...generateNormalSummaryLabels('breath sounds'),
      },
      { value: 'NoRespiratoryDistress', label: 'no respiratory distress' },
      { value: 'NoWheezing', label: 'no wheezing' },
    ],
  },
  respiratoryDecreasedBreathSounds: {
    name: 'respiratoryDecreasedBreathSounds',
    associatedField: 'respiratoryPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'DecBreathSounds',
        label: 'dec breath sounds',
        summaryLabel: 'decreased breath sounds',
      },
      { value: 'R', label: 'R', summaryLabel: 'decreased breath sounds right' },
      { value: 'L', label: 'L', summaryLabel: 'decreased breath sounds left' },
      {
        value: 'B',
        label: 'B',
        summaryLabel: 'decreased breath sounds bilaterally',
      },
    ],
  },
  respiratoryDistress: {
    name: 'respiratoryDistress',
    associatedField: 'respiratoryPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'RespDistress',
        label: 'resp distress',
        summaryLabel: 'respiratory distress',
      },
      {
        value: 'Mild',
        label: 'mild',
        summaryLabel: 'mild respiratory distress',
      },
      {
        value: 'Mod',
        label: 'mod',
        summaryLabel: 'moderate respiratory distress',
      },
      {
        value: 'Severe',
        label: 'severe',
        summaryLabel: 'severe respiratory distress',
      },
    ],
  },
  respiratoryFreeText: {
    name: 'respiratoryFreeText',
    associatedField: 'respiratoryPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  cardiovascularPositivePredefined: {
    name: 'cardiovascularPositivePredefined',
    label: 'Cardiovascular',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'RateNormal',
        label: 'rate normal',
        summaryLabels: { negative: 'rate abnormal' },
      },
      {
        value: 'RegularRhythm',
        label: 'regular rhythm',
        summaryLabels: { negative: 'abnormal rhythm' },
      },
      { value: 'NoEdema', label: 'no edema' },
      {
        value: 'NlDistalPulses',
        label: 'nl distal pulses',
        ...generateNormalSummaryLabels('distal pulses'),
      },
    ],
  },
  cardiovascularRhythmAbnormal: {
    name: 'cardiovascularRhythmAbnormal',
    associatedField: 'cardiovascularPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'IrregularRhythm', label: 'irregular rhythm' },
      { value: 'IRIR', label: 'IRIR', summaryLabel: 'irregularly irregular' },
      { value: 'RegIR', label: 'reg IR', summaryLabel: 'regularly irregular' },
      { value: 'Regular', label: 'regular', summaryLabel: 'regular rhythm' },
    ],
  },
  cardiovascularRateAbnormal: {
    name: 'cardiovascularRateAbnormal',
    associatedField: 'cardiovascularPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Tachycardic', label: 'tachycardic' },
      { value: 'Bradycardic', label: 'bradycardic' },
    ],
  },
  cardiovascularMurmurPredefined: {
    name: 'cardiovascularMurmurPredefined',
    associatedField: 'cardiovascularPositivePredefined',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [{ value: 'Murmur', label: 'murmur' }],
  },
  cardiovascularPulsesAbnormal: {
    name: 'cardiovascularPulsesAbnormal',
    associatedField: 'cardiovascularPositivePredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'AsymRadPulse',
        label: 'asym rad pulse',
        summaryLabel: 'asymmetric radial pulse',
      },
      {
        value: 'AsymFemPulse',
        label: 'asym fem pulse',
        summaryLabel: 'asymmetric femoral pulse',
      },
      {
        value: 'AbsentRadPulse',
        label: 'absent rad pulse',
        summaryLabel: 'absent radial pulse',
      },
      {
        value: 'AbsentFemPulse',
        label: 'absent fem pulse',
        summaryLabel: 'absent femoral pulse',
      },
    ],
  },
  cardiovascularFreeText: {
    name: 'cardiovascularFreeText',
    associatedField: 'cardiovascularPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  gastrointestinalPositivePredefined: {
    name: 'gastrointestinalPositivePredefined',
    label: 'Gastrointestinal',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Soft', label: 'soft', summaryLabels: { negative: 'not soft' } },
      { value: 'NonTender', label: 'non tender' },
      {
        value: 'NoRebound',
        label: 'no rebound',
        summaryLabels: { negative: 'rebound tenderness' },
      },
    ],
  },
  gastrointestinalPulsatileMassPredefined: {
    name: 'gastrointestinalPulsatileMassPredefined',
    associatedField: 'gastrointestinalPositivePredefined',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [{ value: 'PulsatileMass', label: 'pulsatile mass' }],
  },
  gastrointestinalFreeText: {
    name: 'gastrointestinalFreeText',
    associatedField: 'gastrointestinalPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  genitourinaryPositivePredefined: {
    name: 'genitourinaryPositivePredefined',
    label: 'Genitourinary',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'NoCVATenderness', label: 'no CVA tenderness' },
      {
        value: 'NoHerniaMass',
        label: 'no hernia mass',
        summaryLabels: { negative: 'hernia present' },
      },
    ],
  },
  genitourinaryFreeText: {
    name: 'genitourinaryFreeText',
    associatedField: 'genitourinaryPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  skinPositivePredefined: {
    name: 'skinPositivePredefined',
    label: 'Skin',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'WellPerfused',
        label: 'well perfused',
        summaryLabels: { negative: 'poorly perfused' },
      },
      { value: 'NoRash', label: 'no rash' },
      {
        value: 'SkinAtraumatic',
        label: 'skin atraumatic',
        summaryLabels: { negative: 'skin trauma present' },
      },
    ],
  },
  skinNegativePredefined: {
    name: 'skinNegativePredefined',
    associatedField: 'skinPositivePredefined',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'Diaphoretic',
        label: 'diaphoretic',
        summaryLabels: { negative: 'no diaphoresis' },
      },
    ],
  },
  skinFreeText: {
    name: 'skinFreeText',
    associatedField: 'skinPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  musculoskeletalPositivePredefined: {
    name: 'musculoskeletalPositivePredefined',
    label: 'Musculoskeletal',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'NlInspectionOfExt',
        label: 'nl inspection of ext',
        ...generateNormalSummaryLabels('inspection of extremities'),
      },
      {
        value: 'FullROM',
        label: 'full ROM',
        summaryLabels: {
          positive: 'full range of motion of extremities',
          negative: 'abnormal range of motion of extremities',
        },
      },
      {
        value: 'NonTender',
        label: 'non tender',
        summaryLabels: {
          positive: 'no tenderness of extremities',
          negative: 'tenderness of extremities',
        },
      },
    ],
  },
  musculoskeletalNegativePredefined: {
    name: 'musculoskeletalNegativePredefined',
    associatedField: 'musculoskeletalPositivePredefined',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [{ value: 'MeningealSigns', label: 'meningeal signs' }],
  },
  musculoskeletalFreeText: {
    name: 'musculoskeletalFreeText',
    associatedField: 'musculoskeletalPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  hemeLymphImmunePositivePredefined: {
    name: 'hemeLymphImmunePositivePredefined',
    label: 'Heme/Lymph/Immune',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'NoLymphadenopathy',
        label: 'no lymphadenopathy',
        summaryLabels: { negative: 'lymphadenopathy present' },
      },
    ],
  },
  hemeLymphImmuneNegativePredefined: {
    name: 'hemeLymphImmuneNegativePredefined',
    associatedField: 'hemeLymphImmunePositivePredefined',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [{ value: 'Bruising', label: 'bruising' }],
  },
  hemeLymphImmuneFreeText: {
    name: 'hemeLymphImmuneFreeText',
    associatedField: 'hemeLymphImmunePositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  psychiatricPositivePredefined: {
    name: 'psychiatricPositivePredefined',
    label: 'Psychiatric',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [{ value: 'NormalMoodAffect', label: 'normal mood/affect' }],
  },
  psychiatricFreeText: {
    name: 'psychiatricFreeText',
    associatedField: 'psychiatricPositivePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  additionalExamFindings: {
    name: 'additionalExamFindings',
    label: 'Additional Exam Findings',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 2,
  },
};

export const teleNeuroExamFields = {
  ...examFields,
  vitalsPredefined: {
    ...examFields.vitalsPredefined,
    label: 'Vitals',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Placeholder', label: '***' },
      ...examFields.vitalsPredefined.options,
    ],
  },
  mentalStatusNegativePredefined: {
    ...examFields.mentalStatusNegativePredefined,
    options: [
      ...examFields.mentalStatusNegativePredefined.options,
      { value: 'PsychomotorSlowing', label: 'psychomotor slowing' },
    ],
  },
  cranialNervesPositivePredefined: {
    ...examFields.cranialNervesPositivePredefined,
    options: [
      { value: 'Normal', label: 'normal' },
      { value: 'Eomi', label: 'EOMI' },
      { value: 'ConjugateGaze', label: 'conjugate gaze' },
      { value: 'TracksExaminer', label: 'tracks examiner' },
      { value: 'PERRLA', label: 'PERRLA' },
      { value: 'NormalVisFields', label: 'normal visual fields' },
      {
        value: 'FaceSymmetric',
        label: 'face symmetric',
        summaryLabels: {
          positive: 'face symmetric',
          negative: 'facial droop',
        },
      },
      { value: 'HearingIntact', label: 'hearing intact' },
      { value: 'BilateralHeadTurn', label: 'bilateral head turn' },
      { value: 'Dysarthria', label: 'dysarthria' },
      { value: 'TongueProtrusionIntact', label: 'tongue protrusion intact' },
    ],
  },
  motorPositivePredefined: {
    ...examFields.motorPositivePredefined,
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      ...examFields.motorPositivePredefined.options,
      { value: 'MovesAllExtremities', label: 'moves all extremities' },
    ],
  },
  motorPositivesNoFocalWeakness: {
    name: 'motorPositivesNoFocalWeakness',
    associatedField: 'motorPositivePredefined',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'NoFocalWeaknessChange',
        label: 'no focal weakness',
        summaryLabel: 'no clear focal motor weakness',
      },
      {
        value: 'TestingLimited',
        label: 'testing limited',
        summaryLabel: 'strength testing limited by mental status',
      },
    ],
  },
  cerebellarPositivePredefined: {
    ...examFields.cerebellarPositivePredefined,
    options: [
      ...examFields.cerebellarPositivePredefined.options,
      { value: 'InvoluntaryMovement', label: 'involuntary movement' },
    ],
  },
};

export default examFields;
