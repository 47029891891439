import { useMemo } from 'react';

import { ServiceTypeLabels } from 'utils/enums';
import { getFacilityNetworks } from 'utils/helpers';

const useScheduleFilterOptions = (facilities) => {
  const serviceOptions = useMemo(
    () =>
      getFacilityNetworks(facilities).map((service) => ({
        id: service,
        name: ServiceTypeLabels[service],
      })),
    [facilities],
  );

  return { serviceOptions };
};

export default useScheduleFilterOptions;
