import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFormState as useHookState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { appStateActions } from 'store/appStateSlice';
import { isValidDate } from 'utils/dates';

import useClearError from './useClearError';

const trimObjectValues = (obj) =>
  obj &&
  Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (typeof value !== 'object' || isValidDate(value)) {
        acc[key] = typeof value === 'string' ? value.trim() : value;
        return acc;
      }

      acc[key] = trimObjectValues(value);
      return acc;
    },
    Array.isArray(obj) ? [] : {},
  );

export const useFormState = (
  validationSchema,
  serverErrorConfig = { stateSlice: 'appState', showAsSnackbar: false },
  defaultValues = {},
) => {
  const dispatch = useDispatch();
  const {
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    ...rest
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const formState = useHookState({ control });

  const { stateSlice, showAsSnackbar } = serverErrorConfig;

  const hasFormErrors = !!Object.keys(errors).length;

  const serverErrorMsg = useSelector((state) => state[stateSlice].error);
  const visibleSnackBar = useSelector(
    ({ appState }) => appState.snackbar.visible,
  );

  useClearError(hasFormErrors, stateSlice, showAsSnackbar, visibleSnackBar);

  useEffect(() => {
    if (showAsSnackbar && serverErrorMsg) {
      dispatch(
        appStateActions.showSnackbar({
          text: serverErrorMsg,
          type: SnackbarTypeEnum.ERROR,
          duration: null,
          closable: true,
          requireManualClose: true,
        }),
      );
    }
  }, [dispatch, formState.isDirty, serverErrorMsg, showAsSnackbar]);

  const getTrimmedValues = (fieldName = null) => {
    if (fieldName) {
      const fieldValue = getValues(fieldName);
      return fieldValue ? fieldValue.trim() : null;
    }
    return trimObjectValues(getValues());
  };

  const getFieldError = useCallback(
    (fieldName) => errors?.[fieldName],
    [errors],
  );

  const getServerError = () => {
    if (hasFormErrors) return null;
    return serverErrorMsg;
  };

  const canSubmitForm = useMemo(
    () => formState.isDirty && !hasFormErrors,
    [formState.isDirty, hasFormErrors],
  );

  return {
    control,
    getValues,
    getTrimmedValues,
    handleSubmit,
    formState,
    getFieldError,
    hasFormErrors,
    canSubmitForm,
    serverError: getServerError(),
    setFormValues: reset,
    errors,
    ...rest,
  };
};

export default useFormState;
