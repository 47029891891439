import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ConsultsList from 'features/consults/components/ConsultsList/ConsultsList';
import ConsultDetailsWrapper from 'features/consults/components/ConsultDetailsWrapper/ConsultDetailsWrapper';
import { ServiceTypeEnum } from 'utils/enums';

const TeleNeuroConsults = () => (
  <Routes>
    <Route
      path="/"
      element={<ConsultsList consultType={ServiceTypeEnum.TELE_NEURO} />}
    />
    <Route
      path="/:consultId"
      element={<ConsultDetailsWrapper consultType={ServiceTypeEnum.TELE_NEURO} />}
    />
  </Routes>
);

export default TeleNeuroConsults;
