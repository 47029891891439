import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import Table from 'shared/components/data/Table/Table';
import { FlexBoxCenter } from 'styles/layout';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { formatISO, DEFAULT_FIELD_FORMAT } from 'utils/dates';

const tableColumns = [
  {
    header: {
      id: 'id',
      value: 'ID',
    },
    dataKey: 'id',
    width: '15%',
  },
  {
    header: {
      id: 'actionDateTime',
      value: 'Date',
    },
    formatValue: ({ actionDateTime }) =>
      formatISO(actionDateTime, DEFAULT_FIELD_FORMAT),
  },
  {
    header: {
      id: 'action',
      value: 'Action',
    },
    formatValue: ({ isMatched, isAutomatched }) =>
      (!!isMatched && `Matched${isAutomatched ? ' (Auto)' : ''}`) ||
      'Unmatched',
  },
  {
    header: {
      id: 'userId',
      value: 'User Id',
    },
    formatValue: ({ userId }) => userId || '-',
  },
  {
    header: {
      id: 'username',
      value: 'User Name',
    },
    formatValue: ({ username }) => username || '-',
  },
];

const ConsultIntegrationHistoryTable = ({ history = [] }) =>
  history?.length ? (
    <Table
      headings={getTableHeaders(tableColumns)}
      data={prepareTableData(history, tableColumns)}
    />
  ) : (
    <FlexBoxCenter height="100%">
      <Typography variant="h6">NO HISTORY AVAILABLE</Typography>
    </FlexBoxCenter>
  );

ConsultIntegrationHistoryTable.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ConsultIntegrationHistoryTable;
