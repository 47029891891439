import React from 'react';
import {
  ContactPhone,
  AccountCircle,
  VpnKey,
  ScreenLockLandscape,
} from '@mui/icons-material';

import FormGeneralInfo from 'features/profile/components/FormGeneralInfo';
import FormProfileImage from 'features/profile/components/FormProfileImage';
import FormPassword from 'features/profile/components/FormPassword';
import FormMFA from 'features/profile/components/FormMFA';

export default ({ mfaList }) => ({
  contactInformation: {
    label: 'Contact Information',
    icon: <ContactPhone />,
    component: FormGeneralInfo,
  },
  profileImage: {
    label: 'Profile Image',
    icon: <AccountCircle />,
    component: FormProfileImage,
  },
  password: {
    label: 'Change Password',
    icon: <VpnKey />,
    component: FormPassword,
  },
  mfaManager: {
    label: 'MFA Manager',
    disabled: !mfaList?.length,
    icon: <ScreenLockLandscape />,
    component: FormMFA,
  },
});
