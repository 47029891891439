import React from 'react';

import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import FormFields from 'features/consults/shared/tabs/Exam/formFields';

import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';

import ExamPanel from './ExamPanel';
import schema from './validationSchema';
import { leftPanels, rightPanels } from './data';

const Exam = () => {
  const { control, register, getValues, setFormValues, getFieldError } =
    useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    isEditable,
    isMacro,
    handleFieldChange,
    consultMainType,
  } = useConsultData(CONSULT_TABS.EXAM, getValues);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  const leftFields = React.useMemo(
    () => leftPanels(consultMainType),
    [consultMainType],
  );

  return (
    <GridWrapper>
      <Grid item xs={12} lg={6}>
        {leftFields.map((section, index) => (
          <ExamPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            section={section}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
            disabledElements={isMacro ? [FormFields.timeOfExam.name] : []}
          />
        ))}
      </Grid>
      <Grid item xs={12} lg={6}>
        {rightPanels.map((section, index) => (
          <ExamPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            section={section}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
          />
        ))}
      </Grid>
    </GridWrapper>
  );
};

export default Exam;
