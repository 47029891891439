import React from 'react';
import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';

import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import schema from './validationSchema';
import { leftPanel, rightPanel } from './data';

const DataNeuro = () => {
  const { register, control, getValues, getFieldError, setFormValues } =
    useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const { consult, consultTabData, isEditable, handleFieldChange } =
    useConsultData(CONSULT_TABS.DATA, getValues);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  return (
    <GridWrapper>
      <Grid item xs={12} lg={6}>
        <QuestionsPanel
          {...leftPanel}
          control={control}
          register={register}
          onChange={handleFieldChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <QuestionsPanel
          {...rightPanel}
          control={control}
          register={register}
          onChange={handleFieldChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
        />
      </Grid>
    </GridWrapper>
  );
};

export default DataNeuro;
