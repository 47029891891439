import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';

export const SLICE_PREFIX = 'admin';

/** HEALTH SYSTEMS * */
export const listHealthSystems = createAsyncThunk(
  `${SLICE_PREFIX}/listHealthSystems`,
  async (
    { page, sortBy, sortOrder, pageSize, filters },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.getHealthSystems({
        page,
        sortBy,
        sortOrder,
        pageSize,
        filters,
      });

      return {
        data: data.healthSystems,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/** NETWORKS * */
export const listNetworks = createAsyncThunk(
  `${SLICE_PREFIX}/listNetworks`,
  async ({ page, sortBy, sortOrder, pageSize }, { rejectWithValue }) => {
    try {
      const { data } = await api.getNetworks({
        page,
        sortBy,
        sortOrder,
        pageSize,
      });
      return {
        data: data.networks,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/** USERS * */
export const listUsers = createAsyncThunk(
  `${SLICE_PREFIX}/listUsers`,
  async (
    { page, sortBy, sortOrder, pageSize, filters },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.getUsers({
        page,
        sortBy,
        sortOrder,
        pageSize,
        filters,
      });
      return {
        data: data.users,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addUser = createAsyncThunk(
  `${SLICE_PREFIX}/addUser`,
  async (user, { rejectWithValue }) => {
    try {
      const { data } = await api.createUser(user);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserById = createAsyncThunk(
  `${SLICE_PREFIX}/getUserById`,
  async (userId, { rejectWithValue }) => {
    try {
      const {
        data: { userWithHealthSystemAssociationDto },
      } = await api.getUser(userId);
      return userWithHealthSystemAssociationDto;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateUser = createAsyncThunk(
  `${SLICE_PREFIX}/updateUser`,
  async (user, { rejectWithValue }) => {
    try {
      const {
        data: { userWithHealthSystemAssociationDto },
      } = await api.updateUser(user);
      return userWithHealthSystemAssociationDto;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetUserPassword = createAsyncThunk(
  `${SLICE_PREFIX}/resetUserPassword`,
  async (id, { rejectWithValue }) => {
    try {
      await api.resetUserPassword(id);
      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const reInviteUser = createAsyncThunk(
  `${SLICE_PREFIX}/reInviteUser`,
  async (id, { rejectWithValue }) => {
    try {
      await api.reInviteUser(id);
      return null;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/** FACILITIES * */

export const listFacilities = createAsyncThunk(
  `${SLICE_PREFIX}/listFacilities`,
  async (
    { page, sortBy, sortOrder, pageSize, filters },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.getFacilities({
        page,
        sortBy,
        sortOrder,
        pageSize,
        filters,
      });
      return {
        data: data.facilities,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

/* CONSULTS */
export const listConsults = createAsyncThunk(
  `${SLICE_PREFIX}/listConsults`,
  async ({ service, ...args }, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsults(service, args);
      return {
        data: data.consults,
        pageCount: data.pageCount,
        totalCount: data.totalCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchConsult = createAsyncThunk(
  `${SLICE_PREFIX}/fetchConsult`,
  async (consultId, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsultById(consultId);
      return { ...data.consult, id: String(consultId) };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateAdminConsult = createAsyncThunk(
  `${SLICE_PREFIX}/updateAdminConsult`,
  async ({ consultId, data }, { rejectWithValue }) => {
    try {
      await api.updateFullConsult(consultId, data);
      const { data: updatedData } = await api.getConsultById(consultId);
      return { ...updatedData.consult, id: String(consultId) };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const linkOrder = createAsyncThunk(
  `${SLICE_PREFIX}/linkOrder`,
  async (linkData, { rejectWithValue }) => {
    try {
      const { data } = await api.linkOrder(linkData);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const unlinkOrder = createAsyncThunk(
  `${SLICE_PREFIX}/unlinkOrder`,
  async ({ consultId }, { rejectWithValue }) => {
    try {
      const { data } = await api.unlinkOrder({ consultId });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
