import styled from '@emotion/styled';

import colors from 'styles/colors';

export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: ${({ theme }) => theme.listWrapper.direction};
  margin: ${({ theme }) => theme.listWrapper.margin};
`;

export const styleThemes = {
  desktop: {
    listWrapper: {
      direction: 'row',
      margin: '0 25px',
    },
    link: {
      color: colors.primary,
      padding: '0 15px',
      justifyContent: 'center',
    },
  },
  mobile: {
    listWrapper: {
      direction: 'column',
      margin: '0 0 0 30px',
    },
    link: {
      color: colors.white,
      padding: '10px 0',
      justifyContent: 'flex-start',
    },
  },
};
