import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Checkbox } from '@mui/material';

import { FlexBoxSpaceBetweenCenter } from 'styles/layout';
import colors from 'styles/colors';

const TodoCheck = styled(Checkbox)`
  padding-left: 0px;
`;

const TodoLabel = styled.span`
  font-size: 12px;
  color: ${({ isCompleted }) =>
    isCompleted ? colors.primary : colors.neutralDark};
  text-decoration: ${({ isCompleted }) =>
    isCompleted ? 'line-through' : 'none'};
`;

const ToDo = ({ text, isCompleted }) => (
  <FlexBoxSpaceBetweenCenter>
    <TodoCheck color="primary" checked={isCompleted} disabled />
    <TodoLabel isCompleted={isCompleted}>{text}</TodoLabel>
  </FlexBoxSpaceBetweenCenter>
);

ToDo.propTypes = {
  text: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
};

export default ToDo;
