import fields from 'features/consults/shared/tabs/Demographics/formFields';
import {
  mapToggleFieldOptions,
  mapChipFieldOptions,
} from 'features/consults/utils/formFields';

export const leftPanel1 = {
  elements: [
    mapToggleFieldOptions(fields.consultType),
    mapToggleFieldOptions(fields.neuroPatientLocation),
  ],
};

export const leftPanel2 = {
  elements: [
    fields.age,
    mapToggleFieldOptions(fields.gender),
    mapChipFieldOptions(fields.prehospitalBeamIn),
  ],
};

export const leftPanel3 = {
  elements: [
    fields.referringProvider,
    fields.callBackPhone,
    fields.cameraName,
    fields.notes,
  ],
};

export const rightPanel = {
  elements: [fields.initialPageAt, fields.returnCallAt],
};

export const phoneOnlyPanel = {
  elements: [mapChipFieldOptions(fields.phoneOnly)],
};

export const phoneOnlyFreetextPanel = { elements: [fields.phoneOnlyFreetext] };

export const phoneAgreementPanel = {
  elements: [mapChipFieldOptions(fields.phoneAgreement)],
};
