import React from 'react';
import PropTypes from 'prop-types';

import { useAuthenticator } from '@aws-amplify/ui-react';
import api from 'api';

const LogoutContext = React.createContext({});

const LOGOUT_STR = 'LOGOUT';

export const LogoutProvider = ({ children }) => {
  const { signOut } = useAuthenticator();
  const channel = React.useRef(null);

  const onLogout = React.useCallback(() => {
    channel.current.postMessage(LOGOUT_STR);
    signOut({ global: true });
  }, [signOut]);

  React.useEffect(() => {
    channel.current = new BroadcastChannel('logout_channel');
    channel.current.onmessage = ({ type, data }) => {
      if (type === 'message' && data === LOGOUT_STR) {
        signOut({ global: true });
      }
    };
    return () => {
      channel.current.close();
    };
  }, [signOut]);

  React.useEffect(() => {
    const interceptor = api.getInterceptorsResponse().use(
      (config) => config,
      (error) => {
        if (error.response && error.response.status === 401) {
          onLogout();
        }
        return Promise.reject(error);
      }
    );
    return () => {
      api.getInterceptorsResponse().eject(interceptor);
    };
  }, [onLogout]);

  const value = React.useMemo(
    () => ({
      onLogout,
    }),
    [onLogout],
  );

  return (
    <LogoutContext.Provider value={value}>{children}</LogoutContext.Provider>
  );
};

LogoutProvider.propTypes = {
  children: PropTypes.node,
};

export const useLogout = () => React.useContext(LogoutContext);
