import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, IconButton } from '@mui/material';

import { useProfile } from 'shared/hooks';
import Paths from 'navigation/paths';
import colors from 'styles/colors';

const avatarStyle = {
  backgroundColor: colors.primary,
  width: '2rem',
  height: '2rem',
};

const ProfileButton = () => {
  const { profilePic } = useProfile();
  return (
    <IconButton component={Link} to={Paths.Profile}>
      <Avatar src={profilePic} sx={avatarStyle} />
    </IconButton>
  );
};

export default ProfileButton;
