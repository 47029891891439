import * as yup from 'yup';

import { requiredString } from 'utils/constants';
import demographicsFields from 'features/consults/shared/tabs/Demographics/formFields';

export default yup.object().shape({
  [demographicsFields.firstName.name]: yup
    .string()
    .trim()
    .required(requiredString),
  [demographicsFields.lastName.name]: yup
    .string()
    .trim()
    .required(requiredString),
  [demographicsFields.age.name]: yup
    .string()
    .trim()
    .matches(/^[1-9]\d*$/, 'Invalid age'),
  [demographicsFields.consultType.name]: yup
    .string()
    .nullable()
    .trim()
    .required(requiredString),
  [demographicsFields.neuroPatientLocation.name]: yup
    .string()
    .nullable()
    .trim()
    .required(requiredString),
  [demographicsFields.facility.name]: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required(requiredString),
});
