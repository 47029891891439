import React from 'react';
import PropTypes from 'prop-types';

import useConsultDoctorsAdmin from 'features/admin/hooks/useConsultDoctorsAdmin';
import Autocomplete from 'shared/components/forms/Autocomplete';

const NeurologistSelect = ({
  placeholder = '-- Provider --',
  consultType,
  ...moreProps
}) => {
  const { doctors } = useConsultDoctorsAdmin(consultType, moreProps.displayUnassigned);

  return (
    <Autocomplete {...moreProps} placeholder={placeholder} options={doctors} />
  );
};

NeurologistSelect.propTypes = {
  placeholder: PropTypes.string,
  consultType: PropTypes.string,
};

export default NeurologistSelect;
