import { ServiceTypeEnum } from 'utils/enums';
import { mapChipFieldOptions } from 'features/consults/utils/formFields';
import {
  neuroAssessmentFields,
  teleNeuroAssessmentFields,
} from 'features/consults/shared/tabs/Assessment/formFields';

export default (consultType) => ({
  elements: [
    mapChipFieldOptions(
      consultType === ServiceTypeEnum.TELE_NEURO
        ? teleNeuroAssessmentFields.impressionPredefined
        : neuroAssessmentFields.impressionPredefined,
    ),
    neuroAssessmentFields.impressionFreeText,
  ],
});
