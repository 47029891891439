import React from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'api';
import { useFeedback } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';

import BackButton from 'navigation/components/BackButton/BackButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import HealthSystemForm from 'features/admin/components/HealthSystemForm/HealthSystemForm';

import { FlexBoxColumn, FlexBoxColumnFull, FlexBoxAlign } from 'styles/layout';

const AddHealthSystemPage = () => {
  const navigate = useNavigate();
  const { goBack } = useGoBack();
  const { clearDisplay, errorDisplay } = useFeedback();

  const onAddSystem = (values) => {
    clearDisplay();
    api
      .createHealthSystem(values)
      .then(({ data }) => navigate(`../${data.id}`, { replace: true }))
      .catch(() => errorDisplay('Error creating Health System'));
  };

  return (
    <FlexBoxColumn>
      <FlexBoxAlign>
        <BackButton />
        <PageTitle>New Healthcare System</PageTitle>
      </FlexBoxAlign>
      <FlexBoxColumnFull p={2}>
        <HealthSystemForm
          initValues={{ active: true }}
          onSave={onAddSystem}
          onCancel={() => goBack()}
        />
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default AddHealthSystemPage;
