import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import useRoleBasedRouting from 'auth/hooks/useRoleBasedRouting';
import { useProfile, useMountEffect } from 'shared/hooks';
import { useLogout } from 'shared/context/LogoutContext';

import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';
import RestrictedPage from 'auth/pages/RestrictedPage/RestrictedPage';
import ProfileErrorPage from 'auth/pages/ProfileErrorPage';
import NavBar from 'navigation/components/NavBar/NavBar';
import NeuroConsults from 'features/consults/pages/neuro';
import TeleNeuroConsults from 'features/consults/pages/tele-neuro';
import ClinicConsults from 'features/consults/pages/clinic';
import EegConsults from 'features/consults/pages/eeg';
import PedsEegConsults from 'features/consults/pages/peds-eeg';
import ConsultRequest from 'features/consults/pages/consult-request';
import Macros from 'features/consults/pages/macros';
import Profile from 'features/profile';
import Schedule from 'features/schedule';
import CustomerDashboard from 'features/customer';
import Admin from 'features/admin';

import { FlexBoxFull, FullPageColumnWrapper } from 'styles/layout';

import Paths from './paths';

const PathComponents = {
  [Paths.Admin]: <Admin />,
  [Paths.Neuro]: <NeuroConsults />,
  [Paths.TeleNeuroHospital]: <TeleNeuroConsults />,
  [Paths.Clinic]: <ClinicConsults />,
  [Paths.Eeg]: <EegConsults />,
  [Paths.EegPediatric]: <PedsEegConsults />,
  [Paths.ConsultRequest]: <ConsultRequest />,
  [Paths.Macros]: <Macros />,
  [Paths.Profile]: <Profile />,
  [Paths.Schedule]: <Schedule />,
  [Paths.MyFacility]: <CustomerDashboard />,
};

const AppRoutes = () => {
  const { fetchProfile, error, profileData } = useProfile();
  const { onLogout } = useLogout();

  const { homePage, permittedRoutes } = useRoleBasedRouting();

  useMountEffect(fetchProfile);

  if (error) return <ProfileErrorPage error={error} onSignOut={onLogout} />;

  if (!profileData?.id) return <LoadingSpinner />;

  return (
    <FullPageColumnWrapper>
      <NavBar />
      <FlexBoxFull>
        <Routes>
          {permittedRoutes?.map((path) => (
            <Route
              key={path}
              path={`${path}/*`}
              element={PathComponents[path] ?? <div>NOT IMPLEMENTED</div>}
            />
          ))}
          <Route index element={<Navigate to={homePage} replace />} />
          <Route path="*" element={<RestrictedPage />} />
        </Routes>
      </FlexBoxFull>
    </FullPageColumnWrapper>
  );
};

export default AppRoutes;
