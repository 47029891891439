import { useState } from 'react';

export const useEditMode = () => {
  const [isEditMode, setEditMode] = useState(false);

  const enableEditMode = () => setEditMode(true);

  const disableEditMode = () => setEditMode(false);

  return {
    isEditMode,
    enableEditMode,
    disableEditMode,
  };
};

export default useEditMode;
