import * as yup from 'yup';

import formFields from 'features/consults/shared/tabs/Plan/formFields';

export default yup.object().shape({
  [formFields.thrombolyticInterventionPredefined.name]: yup.string().nullable(),
  [formFields.timeIVThrombolyticRecommendedMountainTime.name]: yup
    .mixed()
    .nullable(),
  [formFields.timeThrombolyticBolusMountainTime.name]: yup.mixed().nullable(),
  [formFields.timeThrombolyticInfusionMountainTime.name]: yup
    .mixed()
    .nullable(),
});
