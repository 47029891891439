import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import { DialogActions, DialogContent, Stack } from '@mui/material';

import { useFormState } from 'shared/hooks';
import { useConsult } from 'shared/context/ConsultContext';
import { ServiceTypeEnum } from 'utils/enums';
import { mapToggleFieldOptions } from 'features/consults/utils/formFields';
import demographicsFields, {
  eegDemographicsFields,
  teleNeuroDemographicsFields,
} from 'features/consults/shared/tabs/Demographics/formFields';
import signatureFields, {
  teleNeuroSignatureFields,
} from 'features/consults/shared/tabs/Signature/formFields';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import { PanelField } from 'features/consults/components/tabFields/QuestionsPanel';
import Button from 'shared/components/buttons/Button/Button';

import {
  neuroValidations,
  teleNeuroValidations,
  eegValidations,
  pedsEegValidations,
} from './validations';

const EditConsultForm = ({ consult, handleClose, onEdit, isSubmitting }) => {
  const formValidation = React.useMemo(() => {
    switch (consult.type) {
      case ServiceTypeEnum.NEURO:
        return neuroValidations;
      case ServiceTypeEnum.TELE_NEURO:
        return teleNeuroValidations;
      case ServiceTypeEnum.EEG:
        return eegValidations;
      case ServiceTypeEnum.PEDS_EEG:
        return pedsEegValidations;
      default:
        return {};
    }
  }, [consult.type]);

  const {
    canSubmitForm,
    getFieldError,
    control,
    register,
    setFormValues,
    handleSubmit,
  } = useFormState(formValidation, {
    stateSlice: 'admin',
    showAsSnackbar: true,
  });

  const { consultFacilities } = useConsult();

  const availableFacilities = React.useMemo(
    () =>
      consultFacilities?.map((fac) => ({
        value: fac.id,
        label: fac.name,
      })) ?? [],
    [consultFacilities],
  );

  const fields = React.useMemo(() => {
    switch (consult.type) {
      case ServiceTypeEnum.NEURO:
        return [
          {
            ...mapToggleFieldOptions(demographicsFields.consultType),
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...demographicsFields.facility,
            options: availableFacilities,
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...signatureFields.attestationOfConsultCompletion,
            tab: CONSULT_TABS.SIGNATURE,
          },
        ];
      case ServiceTypeEnum.TELE_NEURO:
        return [
          {
            ...mapToggleFieldOptions(teleNeuroDemographicsFields.consultType),
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...teleNeuroDemographicsFields.facility,
            options: availableFacilities,
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...teleNeuroSignatureFields.attestationOfConsultCompletion,
            tab: CONSULT_TABS.SIGNATURE,
          },
        ];
      case ServiceTypeEnum.EEG:
        return [
          {
            ...mapToggleFieldOptions(eegDemographicsFields.consultType),
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...eegDemographicsFields.facility,
            options: availableFacilities,
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...signatureFields.attestationOfEEGCompletion,
            tab: CONSULT_TABS.SIGNATURE,
          },
        ];
      case ServiceTypeEnum.PEDS_EEG:
        return [
          {
            ...mapToggleFieldOptions(eegDemographicsFields.eegPedsConsultType),
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...eegDemographicsFields.facility,
            options: availableFacilities,
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...signatureFields.attestationOfEEGCompletion,
            tab: CONSULT_TABS.SIGNATURE,
          },
        ];
      default:
        return null;
    }
  }, [consult.type, availableFacilities]);

  const initValues = React.useMemo(
    () =>
      fields?.reduce(
        (res, field) => ({
          ...res,
          [field.name]: consult[field.tab][field.name],
        }),
        {},
      ),
    [consult, fields],
  );

  const onSubmit = React.useCallback(
    (formValues) => {
      const reducedData = fields.reduce(
        (res, { name, tab }) => ({
          ...res,
          [name]: {
            tab,
            value: formValues[name],
          },
        }),
        {},
      );
      onEdit(reducedData);
    },
    [fields, onEdit],
  );

  React.useEffect(() => {
    setFormValues(initValues);
  }, [initValues, setFormValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent sx={{ paddingTop: 0 }}>
        <Stack spacing={2}>
          {fields?.map((field) => (
            <PanelField
              {...omit(field, ['excludeFromSummary', 'summaryContent'])}
              key={field.name}
              getFieldError={getFieldError}
              control={control}
              register={register}
              onChange={() => {}}
            />
          ))}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          disabled={!canSubmitForm || isSubmitting}
        >
          Save
        </Button>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </form>
  );
};

EditConsultForm.propTypes = {
  consult: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  isSubmitting: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default EditConsultForm;
