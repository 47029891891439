import React from 'react';
import { Routes, Route, useMatch } from 'react-router-dom';

import Paths from 'navigation/paths';
import AdminPaths from 'features/admin/paths';

import { FlexBoxColumn } from 'styles/layout';

import BillingDashboard from './BillingDashboard';
import BillingInfoPage from './BillingInfoPage';

const Billing = () => {
  const isChild = useMatch(
    `${Paths.Admin}/${AdminPaths.Billing}/:consultType/:consultId`,
  );

  return (
    <FlexBoxColumn>
      <BillingDashboard isChild={!!isChild} />
      <Routes>
        <Route path="/:consultType/:consultId" element={<BillingInfoPage />} />
      </Routes>
    </FlexBoxColumn>
  );
};
export default Billing;
