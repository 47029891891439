import { createSlice } from '@reduxjs/toolkit';

import { mapErrorResponse } from 'utils/mappers';
import {
  SLICE_PREFIX,
  getProfile,
  updateProfile,
  getProfilePhoto,
  setMfaList,
} from './actionCreators';

const initialState = {
  data: {},
  profilePic: '',
  mfaList: [],
  error: null,
};

/* eslint-disable no-param-reassign */
const profileStateSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.data = payload;
      })
      .addCase(getProfilePhoto.fulfilled, (state, { payload }) => {
        state.profilePic = payload;
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.data = payload;
      })
      .addCase(setMfaList.fulfilled, (state, { payload }) => {
        state.mfaList = payload;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith(`${SLICE_PREFIX}/`) &&
          action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = mapErrorResponse(action);
        },
      );
  },
});
/* eslint-enable no-param-reassign */

const { reducer, actions } = profileStateSlice;

export { reducer as profileReducer };
export const profileActions = {
  ...actions,
  getProfile,
  updateProfile,
  getProfilePhoto,
  setMfaList,
};

export default profileStateSlice;
