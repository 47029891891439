import * as yup from 'yup';

import { MAX_IMAGE_FILE_SIZE, requiredString } from 'utils/constants';
import { isFile } from 'utils/helpers';
import { PHONE_NUMBER_REGEX, PASSWORD_REGEX } from 'utils/validators';

export const FormFields = {
  firstName: 'firstName',
  lastName: 'lastName',
  title: 'title',
  email: 'email',
  phone: 'phoneNumber',
};

export const profileFormSchema = yup.object().shape({
  [FormFields.firstName]: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `First Name should not exceed ${max} characters`),
  [FormFields.lastName]: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `Last Name should not exceed ${max} characters`),
  [FormFields.title]: yup.string().trim(),
  [FormFields.email]: yup
    .string()
    .trim()
    .required(requiredString)
    .email('Invalid Email')
    .max(2048, ({ max }) => `Email should not exceed ${max} characters`),
  [FormFields.phone]: yup
    .string()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
    .required(requiredString)
    .typeError(requiredString),
});

export const userImageSchema = yup.object().shape({
  userImage: yup
    .mixed()
    .test(
      'fileSize',
      `The uploaded file exceeds the maximum allowed size of ${
        MAX_IMAGE_FILE_SIZE / 1024 ** 2
      }MB`,
      (value) => {
        if (isFile(value)) {
          return value.size <= MAX_IMAGE_FILE_SIZE;
        }
        return true;
      },
    ),
});

export const PassFormFields = {
  new: 'newPassword',
  confirm: 'newPasswordConfirm',
  current: 'currentPassword',
};

export const passwordSchema = yup.object().shape({
  [PassFormFields.new]: yup
    .string()
    .nullable()
    .matches(PASSWORD_REGEX, 'Invalid password'),
  [PassFormFields.confirm]: yup
    .string()
    .nullable()
    .when(PassFormFields.new, {
      is: (value) => value,
      then: (schema) =>
        schema
          .required(requiredString)
          .oneOf([yup.ref(PassFormFields.new)], 'Passwords must match'),
    }),
  [PassFormFields.current]: yup
    .string()
    .nullable()
    .matches(PASSWORD_REGEX, 'Invalid password'),
});
