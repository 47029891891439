import React from 'react';
import PropTypes from 'prop-types';
import { Add } from '@mui/icons-material';
import styled from '@emotion/styled';
import * as yup from 'yup';

import { useFormState, usePerformActionOnSuccess } from 'shared/hooks';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';

import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import InputField from 'shared/components/forms/InputField/InputField';
import { Box } from 'styles/layout';
import { MacroTypes } from 'utils/enums';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  align-self: flex-end;
  margin: 10px 0 3px;
  gap: 15px;

  ${({ _hasErrors }) =>
    _hasErrors &&
    `
    align-self: center;
    margin: 1px 0 23px;
  `}
`;

const schema = yup.object().shape({
  macroName: yup.string().trim().required('Macro name is required'),
});

const MacrosForm = ({ onSubmit, createSharedMacros }) => {
  const {
    register,
    canSubmitForm,
    hasFormErrors,
    getFieldError,
    setValue,
    getTrimmedValues,
    handleSubmit,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });

  usePerformActionOnSuccess(SuccessActionTypesEnum.MACRO_CREATED, () => {
    setValue('macroName', null);
  });

  const handleSubmitForm = (macroType) =>
    handleSubmit(() => {
      const values = getTrimmedValues();
      values.macroType = macroType;
      onSubmit(values);
    });

  return (
    <Form onSubmit={handleSubmit(() => onSubmit(getTrimmedValues()))}>
      <Box mr={2}>
        <InputField
          {...register('macroName', { required: true })}
          label="Macro Name"
          placeholder="Macro Name"
          required
          fullWidth={false}
          error={getFieldError('macroName')}
        />
      </Box>
      <ButtonWrapper _errors={hasFormErrors ? 'errors' : ''}>
        <ActionButton
          type="button"
          color="secondary"
          disabled={!canSubmitForm}
          icon={<Add />}
          onClick={handleSubmitForm(MacroTypes.PERSONAL)}
        >
          New Macro
        </ActionButton>
        {createSharedMacros && (
          <ActionButton
            type="button"
            color="secondary"
            disabled={!canSubmitForm}
            icon={<Add />}
            onClick={handleSubmitForm(MacroTypes.SHARED)}
          >
            New Shared Macro
          </ActionButton>
        )}
      </ButtonWrapper>
    </Form>
  );
};

MacrosForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  createSharedMacros: PropTypes.bool,
};

export default MacrosForm;
