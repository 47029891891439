import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { appStateActions } from 'store/appStateSlice';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';

export const useFeedback = () => {
  const dispatch = useDispatch();

  const showSnackbar = useCallback(
    (text, type) =>
      dispatch(
        appStateActions.showSnackbar({
          text,
          duration: 5000,
          type,
        }),
      ),
    [dispatch],
  );

  const successDisplay = useCallback(
    (text) => showSnackbar(text, SnackbarTypeEnum.SUCCESS),
    [showSnackbar],
  );

  const warningDisplay = useCallback(
    (text) => showSnackbar(text, SnackbarTypeEnum.WARNING),
    [showSnackbar],
  );

  const errorDisplay = useCallback(
    (text) => showSnackbar(text, SnackbarTypeEnum.ERROR),
    [showSnackbar],
  );

  const clearDisplay = useCallback(
    () => dispatch(appStateActions.hideSnackbar()),
    [dispatch],
  );

  return {
    successDisplay,
    warningDisplay,
    errorDisplay,
    clearDisplay,
  };
};

export default useFeedback;
