import MaterialAutocomplete from '@mui/material/Autocomplete';
import styled from '@emotion/styled';

import colors from 'styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const Autocomplete = styled(MaterialAutocomplete)({
  '& .MuiAutocomplete-option': {
    '&[aria-selected="true"]': {
      backgroundColor: colors.optionSelected,
    },
  },
});
