export const fontStyle = {
  fontFamily: "'Roboto', sans-serif !important",
};

export const pageTileStyle = {
  fontSize: '22px',
  padding: 0,
};

export const sectionHeaderStyle = {
  fontSize: '18px',
  margin: '0 0 10px 0',
};

export const fieldRowStyle = {
  fontSize: '15px',
  lineHeight: '20px',
  whiteSpace: 'pre-line',
};

export const lineBreakStyle = {
  whiteSpace: 'pre',
};
