import React from 'react';
import PropTypes from 'prop-types';

import BasicDialog from 'shared/components/feedback/BasicDialog';

const DeleteMacroModal = ({ onConfirm, onCancel }) => (
  <BasicDialog
    title="Are you sure you want to delete this Macro?"
    open
    confirmText="Confirm"
    handleConfirm={onConfirm}
    handleCancel={onCancel}
  />
);

DeleteMacroModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteMacroModal;
