import React from 'react';
import PropTypes from 'prop-types';
import { Chip as MaterialChip } from '@mui/material';
import styled from '@emotion/styled';

import colors from 'styles/colors';

const LineBreak = styled.hr`
  flex-basis: 100%;
  height: 0;
  margin: 4px 0;
  border: 0;
`;

const ChipContainer = styled.label`
  margin-right: 5px;
  margin-bottom: 4px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  & .MuiChip-root{
    min-height:24px;
    height: auto;
  }
  & .MuiChip-label{
    white-space: normal;
  }
`;

const FormChip = styled(MaterialChip)`
  background-color: ${({ selected }) =>
    `${selected ? colors.primary : colors.white} !important`};
  color: ${({ selected }) => (selected ? colors.white : colors.dark)};
  @media (hover: hover) {
    &:hover {
      ${({ selected }) =>
        !selected && `background-color: ${colors.secondary} !important;`}
    }
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const Chip = ({
  name,
  text,
  value,
  selected,
  showOnNewLine = false,
  disabled = false,
  onChange,
}) => (
  <>
    {showOnNewLine && <LineBreak />}
    <ChipContainer disabled={disabled}>
      <FormChip
        label={text}
        variant="outlined"
        size="small"
        selected={selected}
        clickable={!disabled}
        disabled={disabled}
      />
      <HiddenInput
        name={name}
        type="checkbox"
        checked={selected}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        disabled={disabled}
      />
    </ChipContainer>
  </>
);

Chip.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  showOnNewLine: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Chip;
