import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { DEBOUNCE_TIMEOUT } from 'utils/constants';

import InputField from 'shared/components/forms/InputField/InputField';

const TextInput = ({
  register,
  name,
  disabled = false,
  type = 'text',
  label = null,
  getFieldError,
  required = false,
  onChange,
  ...props
}) => {
  const field = register(name, { required, disabled });

  const handleChange = React.useMemo(
    () =>
      debounce(() => {
        onChange(field.name, type);
      }, DEBOUNCE_TIMEOUT),
    [field, type, onChange],
  );

  return (
    <InputField
      {...field}
      {...props}
      type={type}
      label={label}
      required={required}
      error={getFieldError(name)}
      onChange={(e) => {
        field.onChange(e);
        handleChange();
      }}
    />
  );
};

TextInput.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  getFieldError: PropTypes.func.isRequired,
};

export default TextInput;
