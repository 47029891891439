import React from 'react';
import LaunchIcon from '@mui/icons-material/Launch';

import { ServiceTypeEnum } from 'utils/enums';
import { TIMEZONE_LABELS, TIMEZONE_OFFSETS } from 'utils/timezones';

const mapLocation = (facility) =>
  !facility?.address?.streetAddress || !facility?.address?.locality
    ? null
    : `https://www.google.com/maps/search/?api=1&query=${facility.address.streetAddress},${facility.address.locality}`;

const phoneNumberUrl = (phoneNumber) => phoneNumber && `tel:${phoneNumber}`;

const timezoneLabel = (timezone) =>
  timezone && `${TIMEZONE_LABELS[timezone]} (${TIMEZONE_OFFSETS[timezone]})`;

const urlField = ({ value, label, url }) => ({
  value,
  label,
  url,
  blankUrl: true,
});

const mapField = (facility) =>
  urlField({
    label: 'Map',
    value: <LaunchIcon />,
    url: mapLocation(facility),
  });

const locationField = ({ address }) => ({
  label: 'Location',
  value: `${address?.locality ? address?.locality.concat(', ') : ''} ${
    address?.region || ''
  }`,
});

const timezoneField = ({ timezone }) => ({
  label: 'Timezone',
  value: timezoneLabel(timezone),
});

const phoneField = ({ number, label }) => ({
  label,
  value: number,
  url: phoneNumberUrl(number),
});

const neuroFacilityInfoMap = (facility) => [
  mapField(facility),
  locationField(facility),
  timezoneField(facility),
  phoneField({ number: facility.edNumber, label: 'ED Number' }),
  phoneField({ number: facility.itPhoneNumber, label: 'IT Number' }),
  phoneField({
    number: facility.callCenterPhoneNumber,
    label: 'Call Center Number',
  }),
  urlField({ label: 'EMR', value: facility.emrName, url: facility.emrLink }),
  urlField({
    label: 'Radiology',
    value: facility.radiology,
    url: facility.radiologyLink,
  }),
  urlField({
    label: 'Shared Sign-Out',
    value: facility.sharedSignout,
    url: facility.sharedSignoutLink,
  }),
  { label: 'Mobile Imaging', value: facility.mobileImagings?.join(', ') },
  urlField({
    label: 'Camera',
    value: facility.camera,
    url: facility.cameraLink,
  }),
  { label: 'Onsite Neuro Coverage', value: facility.onsiteNeuroCoverage },
  { label: 'Thrombolytic', value: facility.thrombolytic },
  { label: 'Direct Beam-In', value: facility.directBeamIn ? 'Yes' : 'No' },
];

const eegFacilityInfoMap = (facility) => [
  mapField(facility),
  locationField(facility),
  timezoneField(facility),
  phoneField({ number: facility.edNumber, label: 'ED Number' }),
  phoneField({ number: facility.itPhoneNumber, label: 'IT Number' }),
  urlField({ label: 'EMR', value: facility.emrName, url: facility.emrLink }),
  { label: 'EEG Software', value: facility.eegSoftware },
];

// eslint-disable-next-line import/prefer-default-export
export const facilityInfoMap = (facility, consultType) => {
  switch (consultType) {
    case ServiceTypeEnum.NEURO:
    case ServiceTypeEnum.TELE_NEURO:
      return neuroFacilityInfoMap(facility);
    case ServiceTypeEnum.EEG:
    case ServiceTypeEnum.PEDS_EEG:
      return eegFacilityInfoMap(facility);
    default:
      return null;
  }
};
