import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Container } from 'react-modal-promise';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Global } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import store from 'store';
import AwsAuthenticator from 'auth/components/Auth/AwsAuthenticator';
import { LogoutProvider } from 'shared/context/LogoutContext';
import Snackbar from 'shared/components/feedback/Snackbar/Snackbar';
import AppRoutes from 'navigation/AppRoutes';
import globalStyles from 'styles/global';
import theme from 'styles/theme';

import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Global styles={globalStyles} />
            <AwsAuthenticator>
              <LogoutProvider>
                <AppRoutes />
              </LogoutProvider>
            </AwsAuthenticator>
            <Snackbar />
            <Container />
          </LocalizationProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
