import * as yup from 'yup';

import formFields from 'features/consults/pages/eeg/tabs/EEG/formFields';
import { requiredString } from 'utils/constants';

export default yup.object().shape({
  [formFields.startTimeOfEEGReadAt.name]: yup.string().required(requiredString),
  [formFields.stopTimeOfEEGReadAt.name]: yup.string().required(requiredString),
  [formFields.duration.name]: yup.string().required(requiredString),
});
