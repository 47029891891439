import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';

const SingleSelect = ({
  control,
  name,
  title = '',
  buttons,
  required = false,
  compact = true,
  disabled = false,
  onChange,
  error,
}) => {
  const handleChange = useMemo(
    () =>
      debounce(() => {
        onChange(name);
      }, 500),
    [name, onChange],
  );

  return (
    <ToggleButtonGroup
      control={control}
      buttons={buttons}
      name={name}
      title={title}
      required={required}
      compact={compact}
      handleChange={handleChange}
      disabled={disabled}
      error={error}
    />
  );
};

SingleSelect.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  compact: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
};

export default SingleSelect;
