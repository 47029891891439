import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import { clinicSignatureFields as fields } from 'features/consults/shared/tabs/Signature/formFields';

export default {
  elements: [
    mapToggleFieldOptions(fields.telemedicineOptions),
    mapChipFieldOptions(fields.patientConsent),
    mapToggleFieldOptions(fields.providerLocationPredefined),
    fields.providerLocationFreeText,
    fields.patientLocation,
    fields.totalFaceToFaceTime,
    fields.additionalTelemedicineParticipants,
  ],
};
