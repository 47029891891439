import { useMemo, useCallback } from 'react';

import api from 'api';
import { useMountEffect, useUpdateEffect, useRequest } from 'shared/hooks';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { ConsultType2RoleEnum, UNNASIGNED_ITEM, doctorRoles } from 'utils/enums';
import { mapDoctorNames } from 'utils/mappers';

const useConsultDoctors = (consultType, displayUnassigned = false) => {
  const { data, doRequest } = useRequest(api.getUserNames);

  const fetchDoctors = useCallback(() => {
    doRequest({
      pageSize: MAX_PAGE_SIZE,
      filters: {
        roles: consultType ? [ConsultType2RoleEnum[consultType]] : doctorRoles,
      },
    });
  }, [doRequest, consultType]);

  useMountEffect(fetchDoctors);

  useUpdateEffect(() => {
    fetchDoctors();
  }, [fetchDoctors]);

  const doctors = useMemo(() => mapDoctorNames(data?.users || []), [data]);

  return {
    doctors: displayUnassigned ? [UNNASIGNED_ITEM, ...doctors] : doctors,
  };
};

export default useConsultDoctors;
