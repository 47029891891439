import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Paths, { path2Service } from 'navigation/paths';
import { consultsActions } from 'features/consults/store/slice';
import {
  ConsultFieldTypeEnum,
  ConsultStates,
  ConsultAttestationEnum,
} from 'utils/enums';
import { consultErrors } from 'features/consults/utils/validators';

const formatInput = (value, fieldType) => {
  if (!value) {
    return null;
  }

  switch (fieldType) {
    case 'number':
      return Number(value);
    case 'text':
    case ConsultFieldTypeEnum.DICTATION:
      return value.trim();
    default:
      return value;
  }
};

const useConsultData = (tabName, formFields) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { macroId } = useParams();

  const isMacro = useMemo(
    () => pathname.startsWith(`/${Paths.Macros}`),
    [pathname],
  );

  const consult = useSelector(({ consults }) => consults.currentConsult);
  const consultFacility = useSelector(
    ({ consults }) => consults.currentConsultFacility,
  );
  const profileData = useSelector(({ profile }) => profile.data);

  const consultTabData = useMemo(
    () => consult[tabName] || {},
    [consult, tabName],
  );

  // Extract consult type from URL path
  const consultMainType = useMemo(
    () => (isMacro ? consult.type : path2Service[pathname.split('/')[1]]),
    [pathname, consult, isMacro],
  );

  const isClosed = useMemo(
    () =>
      [ConsultStates.CLOSED, ConsultStates.CANCELLED].includes(
        consult.signature?.state,
      ),
    [consult.signature],
  );

  const isEditable = useMemo(
    () =>
      (isMacro &&
        consult.macroType === 'Shared' &&
        profileData.createSharedMacros) ||
      (isMacro && consult.macroType !== 'Shared') ||
      (consult.signature?.assignToId === profileData.id && !isClosed),
    [
      profileData.id,
      profileData.createSharedMacros,
      consult.macroType,
      consult.signature,
      isClosed,
      isMacro,
    ],
  );

  const saveConsultChange = useCallback(
    (changes) => {
      const payload = { consultId: consult.id, tab: tabName, changes };

      dispatch(consultsActions.saveConsultChanges(payload));
      if (isMacro) {
        dispatch(consultsActions.updateMacro({ macroId, ...payload }));
        return;
      }
      dispatch(consultsActions.editConsult(payload));
    },
    [consult.id, dispatch, tabName, isMacro, macroId],
  );

  const handleFieldChange = useCallback(
    (fieldName, type) => {
      const value = formFields(fieldName);
      if (value !== undefined) {
        saveConsultChange({
          [fieldName]: formatInput(value, type),
        });
      }
    },
    [formFields, saveConsultChange],
  );

  const handleOwnerChange = useCallback(
    (ownerId) => {
      if (isMacro || !ownerId) return;

      dispatch(
        consultsActions.updateConsultOwner({
          consultId: consult.id,
          ownerId,
        }),
      );
    },
    [dispatch, consult.id, isMacro],
  );

  const handleConsultLock = useCallback(
    ({ summary, summaryFormatted, lockAt, attestationValue }) => {
      if (isMacro) return;
      const lockErrors = consultErrors(
        consult,
        consultMainType,
        consultFacility,
      );
      const lockAction = [
        ConsultAttestationEnum.cancelled,
        ConsultAttestationEnum.duplicate,
      ].includes(attestationValue)
        ? ConsultStates.CANCELLED
        : ConsultStates.CLOSED;

      if (lockAction === ConsultStates.CANCELLED || isEmpty(lockErrors)) {
        dispatch(
          consultsActions.lockConsult({
            consultId: consult.id,
            lockById: profileData.id,
            lockAt,
            lockAction,
            summary,
            summaryFormatted,
          }),
        );
      } else {
        dispatch(consultsActions.setLockErrors(lockErrors));
      }
    },
    [
      consult,
      consultFacility,
      consultMainType,
      isMacro,
      profileData.id,
      dispatch,
    ],
  );

  return {
    consult,
    consultTabData,
    consultMainType,
    consultFacility,
    isClosed,
    isEditable,
    saveConsultChange,
    handleFieldChange,
    handleOwnerChange,
    handleConsultLock,
    isMacro,
  };
};

export default useConsultData;
