import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import useSharedHandlers from 'features/consults/hooks/useSharedHandlers';

import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import Autocomplete from 'shared/components/forms/Autocomplete';
import TextInput from 'features/consults/components/tabFields/TextInput/TextInput';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { eegDemographicsFields as formFields } from 'features/consults/shared/tabs/Demographics/formFields';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { Box } from 'styles/layout';
import schema from './validationSchema';
import {
  eEGTypeFields,
  demographicFields1,
  demographicFields3,
  demographicFields4,
  getDemographicsSecondType,
} from './data';

const { firstName, lastName, dateOfBirth, facility } = formFields;

const DemographicsEEG = ({ errors }) => {
  const {
    control,
    register,
    getFieldError,
    getValues,
    setValue,
    setFormValues,
    clearErrors,
    trigger,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    consultMainType,
    isEditable,
    isMacro,
    handleFieldChange,
    saveConsultChange,
  } = useConsultData(CONSULT_TABS.DEMOGRAPHICS, getValues);
  const {
    facilities,
    handleNameChange,
    handleFacilityChange,
    handleDateOfBirthChange,
  } = useSharedHandlers(
    consult.id,
    consultMainType,
    saveConsultChange,
    setValue,
    getValues,
  );

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  React.useEffect(() => {
    clearErrors();
    if (errors) trigger(errors);
  }, [errors, clearErrors, trigger]);

  const isDisabled = React.useMemo(
    () => isMacro || !isEditable,
    [isMacro, isEditable],
  );

  return (
    <GridWrapper spacing={10}>
      <Grid item sm={12} lg={7}>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
          elements={eEGTypeFields(consultMainType, getValues)}
        />
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
          elements={demographicFields1}
        />
        <Box mb={1}>
          <TextInput
            register={register}
            name={firstName.name}
            label={firstName.label}
            required
            disabled={isDisabled}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <TextInput
            register={register}
            label={lastName.label}
            name={lastName.name}
            required
            disabled={isDisabled}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <DatePicker
            control={control}
            name={dateOfBirth.name}
            label={dateOfBirth.label}
            error={getFieldError(dateOfBirth.name)}
            disableFuture
            disabled={isDisabled}
            handleChange={handleDateOfBirthChange}
          />
        </Box>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
          elements={getDemographicsSecondType(consultMainType)}
        />
        <Box mb={1}>
          <Autocomplete
            control={control}
            name={facility.name}
            label={facility.label}
            required
            options={facilities}
            handleChange={(value) => {
              handleFacilityChange(value);
              handleFieldChange(facility.name);
            }}
            error={getFieldError(facility.name)}
            disabled={isDisabled || isMacro}
          />
        </Box>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
          elements={demographicFields3}
        />
      </Grid>
      <Grid item sm={12} lg={5}>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
          elements={demographicFields4}
        />
        <InputLabel>
          For cEEG reads, timestamps may be left blank unless stat requested
        </InputLabel>
      </Grid>
    </GridWrapper>
  );
};

DemographicsEEG.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default DemographicsEEG;
