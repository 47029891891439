import React from 'react';
import { useParams } from 'react-router-dom';

import api from 'api';
import { useMountEffect, useFeedback, useEditMode } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';

import BackButton from 'navigation/components/BackButton/BackButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import EditButton from 'shared/components/buttons/EditButton';
import NetworkForm from 'features/admin/components/NetworkForm';
import FacilitiesTable from 'features/admin/components/FacilitiesTable/FacilitiesTable';

import {
  FlexBoxColumn,
  FlexBoxAlign,
  FlexBoxSpaceBetween,
} from 'styles/layout';

const NetworkDetailsPage = () => {
  const { networkId } = useParams();
  const { clearDisplay, errorDisplay } = useFeedback();
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { goBack } = useGoBack();
  const [network, setNetwork] = React.useState(null);

  useMountEffect(() => {
    api.getNetwork(networkId).then(({ data }) => {
      setNetwork(data.network);
    });
  });

  const onNetworkUpdate = (values) => {
    clearDisplay();
    api
      .updateNetwork(values)
      .then(({ data }) => {
        setNetwork(data);
        disableEditMode();
      })
      .catch(() => errorDisplay('error updating network'));
  };

  if (!network) return null;

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxSpaceBetween>
        <FlexBoxAlign>
          {!isEditMode && <BackButton onBack={goBack} />}
          <PageTitle>Network Details</PageTitle>
        </FlexBoxAlign>
        {!isEditMode && (
          <FlexBoxAlign>
            <EditButton onClick={enableEditMode} />
          </FlexBoxAlign>
        )}
      </FlexBoxSpaceBetween>
      <FlexBoxColumn p={2}>
        <NetworkForm
          disabled={!isEditMode}
          onSubmit={onNetworkUpdate}
          onCancel={disableEditMode}
          initValues={network}
        />
        <FacilitiesTable networkId={networkId} />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default NetworkDetailsPage;
