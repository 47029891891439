import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { adminActions } from 'features/admin/store/slice';
import { appStateActions } from 'store/appStateSlice';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import {
  IntegrationStatusEnum,
  ConsultStates,
  ShortServiceValues,
} from 'utils/enums';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import IntegrationIcon from 'shared/components/icons/IntegrationIcon';
import ConsultIntegrationTable from 'features/consults/components/ConsultIntegration/ConsultIntegrationTable';
import ConsultIntegrationMerge from 'features/consults/components/ConsultIntegration/ConsultIntegrationMerge';
import ConsultIntegrationDialog from 'features/consults/components/ConsultIntegration/ConsultIntegrationDialog';
import ConsultIntegrationUnmatch from 'features/consults/components/ConsultIntegration/ConsultIntegrationUnmatch';

const modalEnums = {
  LIST: 'list',
  REVIEW: 'review',
  UNLINK: 'unlink',
};

const ConsultIntegration = ({ consult }) => {
  const { consultType } = useParams();
  const [modal, setModal] = React.useState(null);
  const dispatch = useDispatch();

  const showSuccessFeedback = (text) => {
    dispatch(
      appStateActions.showSnackbar({
        text,
        type: SnackbarTypeEnum.SUCCESS,
        duration: 5000,
      }),
    );
  };

  usePerformActionOnSuccess(SuccessActionTypesEnum.ADMIN_ORDER_LINKED, () => {
    setModal(null);
    dispatch(adminActions.fetchConsult(consult.id));
    showSuccessFeedback('Linked successfully');
  });

  usePerformActionOnSuccess(SuccessActionTypesEnum.ADMIN_ORDER_UNLINKED, () => {
    // TODO: is name update on unlink required anymore?
    /* sets the name of the patient to '' on unlink */
    if (consult[CONSULT_TABS.SIGNATURE]?.state === ConsultStates.OPEN) {
      dispatch(
        adminActions.updateAdminConsult({
          consultId: consult.id,
          data: {
            firstName: {
              tab: CONSULT_TABS.DEMOGRAPHICS,
              value: '',
            },
          },
        }),
      );
    }
    setModal(null);
    showSuccessFeedback('Unlinked successfully');
    dispatch(adminActions.fetchConsult(consult.id));
  });

  const handleButtonClick = React.useCallback(() => {
    switch (consult.integrationStatus) {
      case IntegrationStatusEnum.MATCHED:
        setModal({ type: modalEnums.UNLINK });
        break;
      case IntegrationStatusEnum.UNMATCHED:
        setModal({ type: modalEnums.LIST });
        break;
      default:
        break;
    }
  }, [consult.integrationStatus]);

  const handleLink = React.useCallback(() => {
    dispatch(
      adminActions.linkOrder({
        hl7OrderId: modal?.order?.id,
        consultId: consult.id,
      }),
    );
  }, [consult.id, dispatch, modal]);

  const handleUnlink = React.useCallback(() => {
    dispatch(
      adminActions.unlinkOrder({
        consultId: consult.id,
      }),
    );
  }, [consult.id, dispatch]);

  const modalContent = React.useMemo(() => {
    switch (modal?.type) {
      case modalEnums.LIST:
        return (
          <ConsultIntegrationTable
            consultId={consult.id}
            consultType={ShortServiceValues[consultType]}
            onReview={(order) => setModal({ type: modalEnums.REVIEW, order })}
          />
        );
      case modalEnums.REVIEW:
        return (
          <ConsultIntegrationMerge
            consult={consult}
            order={modal.order}
            onMerge={handleLink}
            onClose={() => setModal({ type: modalEnums.LIST })}
            closeStr="Back"
          />
        );
      case modalEnums.UNLINK:
        return (
          <ConsultIntegrationUnmatch
            onCancel={() => setModal(null)}
            onUnlink={handleUnlink}
          />
        );
      default:
        return null;
    }
  }, [modal, consult, handleLink, handleUnlink, consultType]);

  if (
    ![IntegrationStatusEnum.MATCHED, IntegrationStatusEnum.UNMATCHED].includes(
      consult.integrationStatus,
    )
  )
    return null;

  return (
    <>
      <ConsultIntegrationDialog open={!!modal} onClose={() => setModal(null)}>
        {modalContent}
      </ConsultIntegrationDialog>
      <IconButton onClick={handleButtonClick}>
        <IntegrationIcon integrationStatus={consult.integrationStatus} />
      </IconButton>
    </>
  );
};

ConsultIntegration.propTypes = {
  consult: PropTypes.shape({
    id: PropTypes.string,
    integrationStatus: PropTypes.string,
  }),
};

export default ConsultIntegration;
