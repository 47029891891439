import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';

import IconButton from 'shared/components/buttons/IconButton/IconButton';

const BackButton = ({ onBack = null }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
      return;
    }
    navigate(-1);
  };

  return (
    <IconButton color="inherit" onClick={handleBack}>
      <ArrowBack />
    </IconButton>
  );
};

BackButton.propTypes = {
  onBack: PropTypes.func,
};

export default BackButton;
