import React from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { useMountEffect, useRequest } from 'shared/hooks';
import { MAX_PAGE_SIZE } from 'utils/constants';
import Autocomplete from 'shared/components/forms/Autocomplete';

const HealthSystemSelect = ({
  placeholder = '-- Healthcare System --',
  ...moreProps
}) => {
  const { doRequest: fetchSystems, data: systemData } = useRequest(
    api.getHealthSystemNames,
  );

  useMountEffect(() => {
    fetchSystems({ pageSize: MAX_PAGE_SIZE });
  });

  const systemOptions = React.useMemo(
    () => systemData?.healthSystems || [],
    [systemData],
  );

  return (
    <Autocomplete
      {...moreProps}
      placeholder={placeholder}
      options={systemOptions}
    />
  );
};

HealthSystemSelect.propTypes = {
  placeholder: PropTypes.string,
};

export default HealthSystemSelect;
