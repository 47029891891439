import React from 'react';
import { Routes, Route } from 'react-router-dom';

import RestrictedPage from 'auth/pages/RestrictedPage/RestrictedPage';

import ConsultRequestDetailsPage from './ConsultRequestDetailsPage';

export default () => (
  <Routes>
    <Route path="/:requestId" element={<ConsultRequestDetailsPage />} />
    <Route path="*" element={<RestrictedPage />} />
  </Routes>
);
