import { mapToggleFieldOptions } from 'features/consults/utils/formFields';
import { clinicDemographicsFields as fields } from 'features/consults/shared/tabs/Demographics/formFields';

export const leftPanel = {
  elements: [fields.age, mapToggleFieldOptions(fields.gender)],
};

export const rightPanel = {
  elements: [fields.dateOfVisit, fields.referringProvider],
};
