import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ConsultList from './ConsultList';
import ConsultPage from './ConsultPage';

const Consults = () => (
  <Routes>
    <Route index element={<ConsultList />} />
    <Route path=":consultId" element={<ConsultPage />} />
  </Routes>
);

export default Consults;
