import { useMemo } from 'react';

import { useConsult } from 'shared/context/ConsultContext';

import {
  hasValue,
  getSummaryFields,
  getConsultSummaryData,
} from 'features/consults/shared/tabs/Summary/data';

const useSummaryTabState = () => {
  const { consult, consultFacility, consultTimezone, consultProviders } =
    useConsult();
  const { title, sections } = getConsultSummaryData(consult.type);

  const physicianName = useMemo(
    () =>
      consultProviders?.find(
        ({ id }) => id === consult.signature?.attendingPhysicianId,
      )?.name,
    [consult.signature?.attendingPhysicianId, consultProviders],
  );

  const summarySections = useMemo(
    () =>
      Object.entries(sections ?? {}).reduce(
        (acc, [name, { title: sectionTitle, fields, tab }]) => {
          const tabData = consult[tab || name];

          if (!tabData) return acc;

          // Filter fields without any values to show
          const summaryFields = getSummaryFields(
            fields,
            tabData,
            consultFacility,
            physicianName,
          ).filter(
            ({ value, additionalValue }) =>
              hasValue(value) || hasValue(additionalValue),
          );

          if (summaryFields.length === 0) return acc;

          return acc.concat({
            sectionTitle,
            summaryFields,
          });
        },
        [],
      ),
    [sections, consult, consultFacility, physicianName],
  );

  return {
    title,
    summarySections,
    consult,
    facilityName: consultFacility?.facilityName,
    facilityTimezone: consultTimezone,
    facilityFaxNumber: consultFacility?.faxNumber,
  };
};

export default useSummaryTabState;
