import styled from '@emotion/styled';
import { MenuItem as MaterialMenuItem } from '@mui/material';

import Button from 'shared/components/buttons/Button/Button';
import colors from 'styles/colors';

export const MenuItem = styled(MaterialMenuItem)({
  '& .Mui-selected': {
    backgroundColor: `${colors.optionSelected} !important`,
  },
});

export const SelectAllButton = styled(Button)({
  padding: 0,
});
