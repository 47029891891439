import React from 'react';
import PropTypes from 'prop-types';
import { createModal } from 'react-modal-promise';

import SmsIcon from '@mui/icons-material/Sms';
import KeyIcon from '@mui/icons-material/VpnKey';
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';

const fmaOptions = {
  SMS: {
    label: 'SMS',
    icon: <SmsIcon />,
  },
  TOTP: {
    label: 'Software Token',
    icon: <KeyIcon />,
  },
};

const MFASelectModal = ({ isOpen, onResolve, onReject, allowedMFATypes }) => (
  <Dialog
    open={isOpen}
    onClose={onReject}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Choose MFA Method</DialogTitle>
    <DialogContent>
      <List sx={{ pt: 0 }}>
        {allowedMFATypes?.map((fmaOption) => {
          const { label, icon } = fmaOptions[fmaOption];
          return (
            <ListItem
              key={fmaOption}
              button
              onClick={() => onResolve(fmaOption)}
            >
              <ListItemAvatar>
                <Avatar>{icon}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={label} />
            </ListItem>
          );
        })}
      </List>
    </DialogContent>
  </Dialog>
);

MFASelectModal.propTypes = {
  isOpen: PropTypes.bool,
  onResolve: PropTypes.func,
  onReject: PropTypes.func,
  allowedMFATypes: PropTypes.arrayOf(PropTypes.string),
};

export default createModal(MFASelectModal);
