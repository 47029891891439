import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import api from 'api';
import {
  useMountEffect,
  useBreakpoints,
  useRequest,
  useProfile,
  useFeedback,
} from 'shared/hooks';

import ConsultRequestDetails from 'features/consults/components/ConsultRequestDetails/ConsultRequestDetails';
import { ConsultType2RoleEnum, ServiceShortEnum } from 'utils/enums';

import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import RestrictedPage from 'auth/pages/RestrictedPage/RestrictedPage';
import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';

const ConsultRequestDetailsPage = () => {
  const navigate = useNavigate();
  const { requestId } = useParams();
  const { errorDisplay } = useFeedback();
  const { roles } = useProfile();

  const {
    doRequest: fetchRequest,
    data: requestData,
    isLoading,
    error,
  } = useRequest(api.getConsultRequestById);

  useMountEffect(() => {
    if (!requestId) return;
    fetchRequest(requestId);
  }, [fetchRequest, requestId]);

  const canAccessRequest = React.useMemo(() => {
    if (!requestData) return false;
    return roles.includes(
      ConsultType2RoleEnum[requestData.consultRequest.type],
    );
  }, [roles, requestData]);

  const onAccept = React.useCallback(() => {
    api
      .acceptConsultRequest(requestId)
      .then(({ data }) => {
        const returnTo = ServiceShortEnum[requestData.consultRequest.type];
        navigate(`/${returnTo}/${data.consultId}`, { replace: true });
      })
      .catch(() => errorDisplay('Error accepting request'));
  }, [requestId, errorDisplay, requestData, navigate]);

  const onDecline = React.useCallback(() => {
    api
      .declineConsultRequest(requestId)
      .then(() => navigate('/', { replace: true }))
      .catch(() => errorDisplay('Error declining request'));
  }, [navigate, errorDisplay, requestId]);

  if (isLoading) return <LoadingSpinner />;

  if (error || !canAccessRequest) return <RestrictedPage />;

  return (
    requestData && (
      <ConsultRequestDetails
        consultRequest={requestData.consultRequest}
        onAccept={onAccept}
        onDecline={onDecline}
      />
    )
  );
};

const WrappedPageDetailsPage = () => {
  const { smDown } = useBreakpoints();
  return (
    <MainWrapper sm={smDown}>
      <ConsultRequestDetailsPage />
    </MainWrapper>
  );
};

export default WrappedPageDetailsPage;
