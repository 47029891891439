import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const EditButton = ({ onClick }) => (
  <IconButton onClick={onClick} color="primary">
    <EditIcon />
  </IconButton>
);

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EditButton;
