import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colors from 'styles/colors';
import { FlexBoxCenter } from 'styles/layout';

const ShiftWrapper = styled(FlexBoxCenter)`
  background: ${({ color }) => color};
  border: 1px solid ${colors.black};
  border-radius: 25px;
  color: ${colors.dark2};
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
`;

const Shift = ({ network, provider, startTime, endTime, color }) => (
  <ShiftWrapper color={color} p={1} m={0.5}>
    {`${network ? `${network} - ` : ''}${provider} - ${startTime} - ${endTime}`}
  </ShiftWrapper>
);

Shift.propTypes = {
  network: PropTypes.string,
  provider: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Shift;
