import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import useSharedHandlers from 'features/consults/hooks/useSharedHandlers';

import { Box } from 'styles/layout';
import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import TextInput from 'features/consults/components/tabFields/TextInput/TextInput';
import { clinicDemographicsFields as formFields } from 'features/consults/shared/tabs/Demographics/formFields';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import schema from './validationSchema';
import { leftPanel, rightPanel } from './data';

const { firstName, lastName, dateOfBirth } = formFields;

const DemographicsClinic = ({ errors }) => {
  const {
    control,
    register,
    getFieldError,
    getValues,
    setValue,
    setFormValues,
    clearErrors,
    trigger,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    consultMainType,
    isEditable,
    isMacro,
    handleFieldChange,
    saveConsultChange,
  } = useConsultData(CONSULT_TABS.DEMOGRAPHICS, getValues);
  const { handleNameChange, handleDateOfBirthChange } = useSharedHandlers(
    consult.id,
    consultMainType,
    saveConsultChange,
    setValue,
    getValues,
  );

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  React.useEffect(() => {
    clearErrors();
    if (errors) trigger(errors);
  }, [errors, clearErrors, trigger]);

  const isDisabled = React.useMemo(
    () => isMacro || !isEditable,
    [isMacro, isEditable],
  );

  return (
    <GridWrapper spacing={10}>
      <Grid item xs={12} lg={6}>
        <Box mb={1}>
          <TextInput
            register={register}
            name={firstName.name}
            label={firstName.label}
            required
            disabled={isDisabled}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <TextInput
            register={register}
            label={lastName.label}
            name={lastName.name}
            required
            disabled={isDisabled}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <DatePicker
            control={control}
            name={dateOfBirth.name}
            label={dateOfBirth.label}
            error={getFieldError(dateOfBirth.name)}
            disableFuture
            handleChange={handleDateOfBirthChange}
            disabled={isDisabled}
          />
        </Box>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
          {...leftPanel}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={isDisabled}
          {...rightPanel}
        />
      </Grid>
    </GridWrapper>
  );
};

DemographicsClinic.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default DemographicsClinic;
