import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import ChipList from 'shared/components/forms/Chip/ChipList';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { FlexBoxWrap, FlexBoxAlign, FlexBoxColumn } from 'styles/layout';

const MultiSelect = ({
  control,
  name,
  label = null,
  inlineLabel = false,
  disabled = false,
  options,
  onChange,
  error,
}) => {
  const Container = inlineLabel ? FlexBoxAlign : FlexBoxColumn;

  const handleChange = useMemo(
    () =>
      debounce(() => {
        onChange(name);
      }, 500),
    [name, onChange],
  );

  return (
    <Container>
      {label && <InputLabel inline={inlineLabel}>{label}</InputLabel>}
      <FlexBoxWrap>
        <ChipList
          control={control}
          name={name}
          options={options}
          onChange={handleChange}
          disabled={disabled}
          error={error}
        />
      </FlexBoxWrap>
    </Container>
  );
};

MultiSelect.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  inlineLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
};

export default MultiSelect;
