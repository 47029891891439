import { ServiceTypeEnum, ConsultFieldTypeEnum } from 'utils/enums';

const pmhFields = {
  pastMedicalHistoryPredefined: {
    name: 'pastMedicalHistoryPredefined',
    label: 'Past Medical History',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Anxiety', label: 'anxiety' },
      { value: 'Arthritis', label: 'arthritis' },
      { value: 'Asthma', label: 'asthma' },
      { value: 'AFib', label: 'A-fib' },
      { value: 'Aneurysm', label: 'aneurysm' },
      { value: 'Bipolar', label: 'bipolar' },
      { value: 'CAD', label: 'CAD', summaryLabel: 'coronary artery disease' },
      { value: 'Cancer', label: 'cancer' },
      { value: 'CHF', label: 'CHF', summaryLabel: 'congestive heart failure' },
      { value: 'CKD', label: 'CKD', summaryLabel: 'chronic kidney disease' },
      { value: 'COPD', label: 'COPD' },
      { value: 'Dementia', label: 'dementia' },
      { value: 'Depression', label: 'depression' },
      { value: 'Diabetes', label: 'DM', summaryLabel: 'Diabetes' },
      { value: 'GERD', label: 'GERD' },
      { value: 'HLD', label: 'HLD', summaryLabel: 'hyperlipidemia' },
      { value: 'HTN', label: 'HTN', summaryLabel: 'hypertension' },
      { value: 'Hypothyroid', label: 'hypothyroid' },
      { value: 'Migraine', label: 'migraine' },
      { value: 'Neuropathy', label: 'neuropathy' },
      { value: 'ICH', label: 'ICH', summaryLabel: 'intracranial hemorrhage' },
      { value: 'Parkinson', label: "Parkinson's" },
      { value: 'Seizure', label: 'seizure' },
      { value: 'Stroke', label: 'stroke' },
      { value: 'TIA', label: 'TIA' },
      { value: 'Denies', label: 'denies' },
    ],
  },
  pastMedicalHistoryFreeText: {
    name: 'pastMedicalHistoryFreeText',
    associatedField: 'pastMedicalHistoryPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  pastSurgicalHistoryPredefined: {
    name: 'pastSurgicalHistoryPredefined',
    label: 'Past Surgical History',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Appy', label: 'appy', summaryLabel: 'appendectomy' },
      { value: 'CABG', label: 'CABG' },
      { value: 'CEA', label: 'CEA', summaryLabel: 'carotid endarterectomy' },
      { value: 'CSect', label: 'c-sect', summaryLabel: 'cesarian section' },
      { value: 'Chole', label: 'chole', summaryLabel: 'cholecystectomy' },
      { value: 'ColectomyPartial', label: 'colectomy (partial)' },
      { value: 'Craniotomy', label: 'craniotomy' },
      { value: 'DandC', label: 'D&C' },
      { value: 'Hernia', label: 'hernia', summaryLabel: 'hernia repair' },
      { value: 'Hyst', label: 'hyst', summaryLabel: 'hysterectomy' },
      { value: 'Mastectomy', label: 'mastectomy' },
      { value: 'Pacemaker', label: 'pacemaker' },
      { value: 'PFORepair', label: 'PFO repair' },
      { value: 'Prostatectomy', label: 'prostatectomy' },
      { value: 'SpineProcedure', label: 'spine procedure' },
      {
        value: 'TotalHip',
        label: 'total hip',
        summaryLabel: 'total hip replacement',
      },
      {
        value: 'TotalKnee',
        label: 'total knee',
        summaryLabel: 'total knee replacement',
      },
      { value: 'TandA', label: 'T&A' },
      {
        value: 'ValveReplaceMech',
        label: 'valve replacement: mech',
        summaryLabel: 'valve replacement: mechanical',
      },
      { value: 'ValveReplaceTissue', label: 'valve replacement: tissue' },
      { value: 'Denies', label: 'denies' },
    ],
  },
  pastSurgicalHistoryFreeText: {
    name: 'pastSurgicalHistoryFreeText',
    associatedField: 'pastSurgicalHistoryPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  familyHistoryFirstDegreeRelativePredefined: {
    name: 'familyHistoryFirstDegreeRelativePredefined',
    label: 'Family History: first degree relative',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Aneurysm', label: 'aneurysm' },
      { value: 'Cancer', label: 'cancer' },
      { value: 'ClottingDisorder', label: 'clotting disorder' },
      { value: 'Stroke', label: 'stroke' },
    ],
  },
  familyHistoryFirstDegreeRelativeFreeText: {
    name: 'familyHistoryFirstDegreeRelativeFreeText',
    associatedField: 'familyHistoryFirstDegreeRelativePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  socialHistorySmoking: {
    name: 'socialHistorySmoking',
    title: 'Social History',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'NonSmoker', label: 'non-smoker' },
      { value: 'Smoker', label: 'smoker' },
      {
        value: 'RecentlyQuit',
        label: 'recently quit',
        summaryLabel: 'recently quit smoking',
      },
    ],
  },
  socialHistoryDrinking: {
    name: 'socialHistoryDrinking',
    associatedField: 'socialHistorySmoking',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'NonDrinker', label: 'non-drinker' },
      { value: 'OccasionalAlcohol', label: 'occasional alcohol' },
      { value: 'DailyDrinker', label: 'daily drinker' },
      { value: 'ExcessiveAlcohol', label: 'excessive alcohol' },
    ],
  },
  socialHistoryDrugsPredefined: {
    name: 'socialHistoryDrugsPredefined',
    associatedField: 'socialHistorySmoking',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NoDrugs', label: 'no drugs' },
      { value: 'DrugUse', label: 'drug use' },
      { value: 'Cocaine', label: 'cocaine' },
      { value: 'Heroin', label: 'heroin' },
      { value: 'Meth', label: 'meth', summaryLabel: 'methamphetamine' },
      { value: 'Opiates', label: 'opiates' },
      { value: 'THC', label: 'THC' },
      { value: 'BathSalts', label: 'bath salts' },
      { value: 'MDMA', label: 'MDMA' },
    ],
  },
  socialHistoryLivingPredefined: {
    name: 'socialHistoryLivingPredefined',
    associatedField: 'socialHistorySmoking',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'LivesIndependently', label: 'lives independently' },
      {
        value: 'WFamily',
        label: 'w family',
        summaryLabel: 'lives with family',
      },
      {
        value: 'WSpouse',
        label: 'w spouse',
        summaryLabel: 'lives with spouse',
      },
      {
        value: 'SrLiving',
        label: 'sr living',
        summaryLabel: 'senior living',
      },
      { value: 'Assisted', label: 'assisted', summaryLabel: 'assisted living' },
      {
        value: 'MemoryCare',
        label: 'memory care',
        summaryLabel: 'memory care unit',
      },
      { value: 'SNF', label: 'SNF', summaryLabel: 'skilled nursing facility' },
      { value: 'NursingHome', label: 'nursing home' },
      {
        value: 'Ambulatory',
        label: 'ambulatory',
        summaryLabel: 'ambulatory at baseline',
      },
      {
        value: 'NonAmbulatory',
        label: 'non-ambulatory',
        summaryLabel: 'non-ambulatory at baseline',
      },
      { value: 'DNR', label: 'DNR' },
      { value: 'Hospice', label: 'hospice' },
      { value: 'ComfortCare', label: 'comfort care' },
    ],
  },
  socialHistoryLivingFreeText: {
    name: 'socialHistoryLivingFreeText',
    associatedField: 'socialHistorySmoking',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  medicationsPredefined: {
    name: 'medicationsPredefined',
    label: 'Medications',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Anticoagulant', label: 'anticoagulant' },
      { value: 'Antihypertensive', label: 'antihypertensive' },
      { value: 'Antiplatelet', label: 'antiplatelet' },
      { value: 'Aspirin', label: 'aspirin' },
      { value: 'DiabeticMedication', label: 'diabetic medication' },
      { value: 'LipidLoweringAgent', label: 'lipid lowering agent' },
      { value: 'Plavix', label: 'plavix' },
      { value: 'NOAC', label: 'NOAC' },
      { value: 'Warfarin', label: 'warfarin' },
      {
        value: 'NoAsaThinners',
        label: 'no asa/thinners',
        summaryLabel: 'No antithrombotics or anticoagulants reported',
        showOn: [ServiceTypeEnum.NEURO, ServiceTypeEnum.TELE_NEURO],
      },
      { value: 'Denies', label: 'denies' },
      {
        value: 'NonCompliantWithMeds',
        label: 'non-compliant with meds',
        summaryLabel: 'non-compliant with medications',
        showOn: [ServiceTypeEnum.NEURO, ServiceTypeEnum.TELE_NEURO],
      },
    ],
  },
  medicationsFreeText: {
    name: 'medicationsFreeText',
    associatedField: 'medicationsPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  allergiesPredefined: {
    name: 'allergiesPredefined',
    label: 'Allergies',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NKDA', label: 'NKDA' },
      { value: 'Heparin', label: 'Heparin' },
      { value: 'IVContrast', label: 'IV contrast' },
      { value: 'Penicillin', label: 'penicillin' },
      { value: 'Sulfa', label: 'sulfa' },
      { value: 'Statins', label: 'statins' },
      { value: 'Alteplase', label: 'alteplase' },
      { value: 'Other', label: 'other' },
    ],
  },
  allergiesFreeText: {
    name: 'allergiesFreeText',
    associatedField: 'allergiesPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
};

export const teleNeuroPmhFields = {
  ...pmhFields,
  pastMedicalHistoryPredefined: {
    ...pmhFields.pastMedicalHistoryPredefined,
    options: [
      { value: 'Placeholder', label: '***' },
      ...pmhFields.pastMedicalHistoryPredefined.options,
    ],
  },
  homeMeds: {
    name: 'homeMeds',
    label: 'Home Meds',
    type: ConsultFieldTypeEnum.CHIP,
    options: [{ value: 'Placeholder', label: '***' }],
  },
  homeMedsFreeText: {
    name: 'homeMedsFreeText',
    associatedField: 'homeMeds',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
};

export default pmhFields;
