import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@mui/material/Button';

const Button = ({
  children,
  color = 'primary',
  type = 'button',
  size = 'medium',
  variant = 'text',
  disabled = false,
  fullWidth = false,
  ...props
}) => (
  <MaterialButton
    {...props}
    color={color}
    variant={variant}
    type={type}
    size={size}
    disabled={disabled}
    fullWidth={fullWidth}
  >
    {children}
  </MaterialButton>
);

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  color: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Button;
