import React from 'react';
import { updatePassword } from 'aws-amplify/auth';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import {
  useFeedback,
  useEditMode,
  useFormState,
  useMountEffect,
} from 'shared/hooks';

import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import EditButton from 'shared/components/buttons/EditButton';
import PasswordField from 'shared/components/forms/PasswordField/PasswordField';
import { FlexBoxColumnFull, FlexBoxSpaceBetweenCenter } from 'styles/layout';

import { PassFormFields, passwordSchema } from './helpers';

const FormPassword = ({ title }) => {
  const { successDisplay, errorDisplay, clearDisplay } = useFeedback();
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const {
    register,
    handleSubmit,
    getFieldError,
    setFormValues,
    hasFormErrors,
    watch,
  } = useFormState(passwordSchema, {
    stateSlice: 'profile',
    showAsSnackbar: true,
  });

  const watchedValues = watch();

  const formReset = React.useCallback(() => {
    setFormValues(
      Object.values(PassFormFields).reduce(
        (res, field) => ({
          ...res,
          [field]: '',
        }),
        {},
      ),
    );
  }, [setFormValues]);

  useMountEffect(formReset);

  const onSubmit = async ({ currentPassword, newPassword }) => {
    clearDisplay();
    try {
      await updatePassword({
        oldPassword: currentPassword,
        newPassword,
      });
      formReset();
      disableEditMode();
      successDisplay('Password changed');
    } catch (err) {
      errorDisplay(err.message || 'Unexpected error (please contact support)');
    }
  };

  return (
    <FlexBoxColumnFull>
      <FlexBoxSpaceBetweenCenter>
        <h3>{title}</h3>
        {!isEditMode && <EditButton onClick={enableEditMode} />}
      </FlexBoxSpaceBetweenCenter>
      <FormWrapper
        isSubmitDisabled={hasFormErrors}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => {
          clearDisplay();
          setFormValues();
          disableEditMode();
        }}
        formDisabled={!isEditMode}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <PasswordField
              {...register(PassFormFields.new, {
                required: true,
                disabled: !isEditMode,
              })}
              required
              label="New Password"
              error={getFieldError(PassFormFields.new)}
            />
          </Grid>
          {watchedValues[PassFormFields.new] && (
            <Grid item xs={12} md={6}>
              <PasswordField
                {...register(PassFormFields.confirm, {
                  required: true,
                  disabled: !isEditMode,
                })}
                required
                label="Confirm New Password"
                error={getFieldError(PassFormFields.confirm)}
              />
            </Grid>
          )}
          {watchedValues[PassFormFields.confirm] && (
            <Grid item xs={12} md={6}>
              <PasswordField
                {...register(PassFormFields.current, {
                  required: true,
                  disabled: !isEditMode,
                })}
                required
                label="Current Password"
                error={getFieldError(PassFormFields.current)}
              />
            </Grid>
          )}
        </Grid>
      </FormWrapper>
    </FlexBoxColumnFull>
  );
};

FormPassword.propTypes = {
  title: PropTypes.string,
};

export default FormPassword;
