import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import styled from '@emotion/styled';

import {
  getFieldSummaryLabel,
  renderFieldValue,
} from 'features/consults/utils/formFields';
import useSummaryTabState from 'features/consults/hooks/useSummaryTabState';
import demographicsFields from 'features/consults/shared/tabs/Demographics/formFields';
import { Box } from 'styles/layout';
import {
  fontStyle,
  pageTileStyle,
  sectionHeaderStyle,
  fieldRowStyle,
  lineBreakStyle,
} from './SummaryNote.style';
import AddendumList from './AddendumList';

const SavedSummary = styled(Box)`
  & div [style='white-space: pre;'] {
    height: 0 !important;
  }
`;

const renderBlankSpace = <div style={lineBreakStyle}>{'\r'}</div>;

const SummaryNote = React.forwardRef(
  ({ addenda = [], copyRef, isClosed }, ref) => {
    const { consult, title, summarySections, facilityTimezone, facilityName } =
      useSummaryTabState();

    const renderTabData = React.useMemo(
      () =>
        summarySections.map(({ sectionTitle, summaryFields }) => (
          <div key={sectionTitle} style={{ marginBottom: '15px' }}>
            <h3 style={sectionHeaderStyle}>{`# ${sectionTitle}`}</h3>
            {summaryFields.map((field) => (
              <div
                key={field.name}
                style={{ ...fieldRowStyle, marginBottom: '10px' }}
              >
                <b>{getFieldSummaryLabel(field, facilityTimezone)}</b>
                {renderFieldValue({
                  field,
                  facilityTimezone,
                  facilityName,
                  consult,
                })}
                {renderBlankSpace}
              </div>
            ))}
          </div>
        )),
      [summarySections, facilityTimezone, facilityName, consult],
    );

    const renderDemographicsFooter = React.useMemo(
      () => (
        <div style={{ marginBottom: 3 }}>
          <h3 style={sectionHeaderStyle}># Demographics</h3>
          {summarySections[0]?.summaryFields
            .filter((element) =>
              [
                demographicsFields.firstName.name,
                demographicsFields.lastName.name,
                demographicsFields.facility.name,
              ].includes(element.name),
            )
            .map((field) => (
              <div
                key={field.name}
                style={{ ...fieldRowStyle, marginBottom: 3 }}
              >
                <b>{getFieldSummaryLabel(field, facilityTimezone)}</b>
                {renderFieldValue({
                  field,
                  facilityTimezone,
                  facilityName,
                  consult,
                })}
                {renderBlankSpace}
              </div>
            ))}
        </div>
      ),
      [summarySections, facilityTimezone, facilityName, consult],
    );

    const renderAddenda = React.useMemo(
      () =>
        addenda?.length ? (
          <AddendumList addenda={addenda} facilityTimezone={facilityTimezone} />
        ) : null,
      [addenda, facilityTimezone],
    );

    const renderSavedHTML = React.useMemo(
      () => consult.summaryFormatted && parse(consult.summaryFormatted),
      [consult.summaryFormatted],
    );

    return (
      <SavedSummary style={fontStyle} ref={copyRef}>
        {isClosed && renderAddenda}
        <Box style={fontStyle} ref={ref} pt={2}>
          {(isClosed && renderSavedHTML) || (
            <>
              <h2 style={pageTileStyle}>{`Blue Sky ${title} Note`}</h2>
              {renderBlankSpace}
              {renderTabData}
              {renderDemographicsFooter}
              {renderBlankSpace}
            </>
          )}
        </Box>
      </SavedSummary>
    );
  },
);

SummaryNote.propTypes = {
  addenda: PropTypes.arrayOf(PropTypes.shape({})),
  copyRef: PropTypes.shape({}),
  isClosed: PropTypes.bool,
};

export default SummaryNote;
