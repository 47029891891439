import { useState, useCallback } from 'react';

export const useRequest = (requestFunc) => {
  const [requestData, setRequestData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const doRequest = useCallback(
    async (params, extraParams) => {
      setLoading(true);
      setError(null);
      try {
        const { data } = await requestFunc(params, extraParams);
        setRequestData(data);
      } catch (err) {
        setRequestData(null);
        setError(err);
      }
      setLoading(false);
    },
    [requestFunc],
  );

  return {
    doRequest,
    isLoading,
    error,
    data: requestData,
    setData: setRequestData,
  };
};

export default useRequest;
