import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields, { muscleGroupFields } from './formFields';

const muscleElements = [...Array(6)].flatMap((_, index) => {
  const muscleFields = muscleGroupFields(index + 1);
  const [sideGroup, muscleGroup, muscleGroupText, multiplier, unit, unitText] =
    Object.keys(muscleFields);

  return [
    mapToggleFieldOptions(muscleFields[sideGroup]),
    mapChipFieldOptions(muscleFields[muscleGroup]),
    muscleFields[muscleGroupText],
    mapToggleFieldOptions(muscleFields[multiplier]),
    mapToggleFieldOptions(muscleFields[unit]),
    muscleFields[unitText],
  ];
});

export default [
  mapChipFieldOptions(fields.consentPredefined),
  mapToggleFieldOptions(fields.botulinumToxinRatioPredefined),
  mapToggleFieldOptions(fields.botulinumToxinUnitPredefined),
  fields.botulinumToxinRatioFreeText,
  mapToggleFieldOptions(fields.emgGuidance),
  ...muscleElements,
  mapChipFieldOptions(fields.standardProtocolsPredefined),
  fields.standardProtocolsTotalDoseUnits,
  fields.standardProtocolsWastedUnits,
  mapChipFieldOptions(fields.complicationsPredefined),
  fields.complicationsFreeText,
];
