import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';

import api from 'api';
import { adminActions } from 'features/admin/store/slice';
import { ConsultProvider, useConsult } from 'shared/context/ConsultContext';
import useGoBack from 'navigation/hooks/useGoBack';
import { ConsultStates, ServiceTypeEnum } from 'utils/enums';

import BackButton from 'navigation/components/BackButton/BackButton';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import { ConsultSummary } from 'features/consults/shared/tabs/Summary';
import {
  FlexBoxColumn,
  FlexBoxAlign,
  FlexBoxSpaceBetweenCenter,
} from 'styles/layout';

import EditConsultModal from './EditConsultModal';
import ConsultIntegration from './ConsultIntegration';
import ConsultIntegrationDetails from './ConsultIntegrationDetails';

const ConsultPageWithContext = () => {
  const { consult } = useConsult();
  const [editing, setEditing] = React.useState(false);
  const { goBack } = useGoBack();
  const dispatch = useDispatch();

  const isClosed = React.useMemo(
    () => consult?.signature?.state === ConsultStates.CLOSED,
    [consult],
  );

  const isEditable = React.useMemo(
    () =>
      isClosed &&
      [
        ServiceTypeEnum.NEURO,
        ServiceTypeEnum.TELE_NEURO,
        ServiceTypeEnum.EEG,
        ServiceTypeEnum.PEDS_EEG,
      ].includes(consult.type),
    [isClosed, consult.type],
  );

  const onUpdateSummary = React.useCallback(
    async ({ summary, summaryFormatted }) => {
      const {
        data: { consult: updatedConsult },
      } = await api.updateConsultSummary({
        consultId: consult.id,
        summary,
        summaryFormatted,
      });
      dispatch(adminActions.updateConsult(updatedConsult));
    },
    [consult.id, dispatch],
  );

  return (
    <FlexBoxColumn height="100%">
      {!!editing && <EditConsultModal handleClose={() => setEditing(false)} />}
      <FlexBoxSpaceBetweenCenter>
        <FlexBoxAlign>
          <BackButton onBack={goBack} />
          <PageTitle>Consult Details</PageTitle>
        </FlexBoxAlign>

        <FlexBoxAlign>
          {consult.type === ServiceTypeEnum.NEURO && (
            <>
              <ConsultIntegration consult={consult} />
              <ConsultIntegrationDetails consult={consult} />
            </>
          )}
          {isClosed && <LockIcon />}
          {isEditable && (
            <IconButton color="secondary" onClick={() => setEditing(true)}>
              <EditIcon />
            </IconButton>
          )}
        </FlexBoxAlign>
      </FlexBoxSpaceBetweenCenter>
      <ConsultSummary onUpdateSummary={onUpdateSummary} />
    </FlexBoxColumn>
  );
};

const ConsultPage = () => {
  const { consultId } = useParams();
  return (
    <ConsultProvider consultId={consultId} isAdmin>
      <ConsultPageWithContext />
    </ConsultProvider>
  );
};

export default ConsultPage;
