import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';

import { consultRequestActions } from 'features/customer/store/slice';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import BasicDialog from 'shared/components/feedback/BasicDialog';

const ConsultRequestDelete = ({ requestId }) => {
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState(false);

  return (
    <>
      <IconButton onClick={() => setDialog(true)}>
        <DeleteIcon color="primary" />
      </IconButton>
      <BasicDialog
        title="Are you sure you want to delete this consult request? This will cancel your request for a consultation and no Neurologist will contact you."
        open={dialog}
        confirmText="Delete"
        handleConfirm={() => {
          setDialog(false);
          dispatch(consultRequestActions.deleteConsultRequest(requestId));
        }}
        handleCancel={() => setDialog(false)}
      />
    </>
  );
};

ConsultRequestDelete.propTypes = {
  requestId: PropTypes.string.isRequired,
};

export default ConsultRequestDelete;
