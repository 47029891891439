import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';

import { FlexBoxColumn } from 'styles/layout';

const Sidebar = ({ children }) => (
  <Paper square>
    <FlexBoxColumn width={260} pt={5}>
      {children}
    </FlexBoxColumn>
  </Paper>
);

Sidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Sidebar;
