import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { useInterval } from 'shared/hooks';
import useRoleBasedRouting from 'auth/hooks/useRoleBasedRouting';
import Paths from 'navigation/paths';
import NavLink from 'navigation/components/NavLink/NavLink';
import { ServiceTypeEnum, ServiceTypeLabels } from 'utils/enums';

import { ListWrapper, styleThemes } from './MenuList.style';

const navLabels = {
  [Paths.Neuro]: ServiceTypeLabels[ServiceTypeEnum.NEURO],
  [Paths.TeleNeuroHospital]: ServiceTypeLabels[ServiceTypeEnum.TELE_NEURO],
  [Paths.Clinic]: ServiceTypeLabels[ServiceTypeEnum.CLINIC],
  [Paths.Eeg]: ServiceTypeLabels[ServiceTypeEnum.EEG],
  [Paths.EegPediatric]: ServiceTypeLabels[ServiceTypeEnum.PEDS_EEG],
  [Paths.Macros]: 'Macros',
  [Paths.Admin]: 'Admin',
  [Paths.MyFacility]: 'My Facility',
  [Paths.Schedule]: 'Schedule',
};

const consultNotifications = {
  [Paths.Neuro]: 'totalNeuro',
  [Paths.TeleNeuroHospital]: 'totalNeuroHospital',
  [Paths.Clinic]: 'totalClinic',
  [Paths.Eeg]: 'totalEeg',
  [Paths.EegPediatric]: 'totalEegPediatric',
};

const MenuList = ({ isMobile = false, onNavigationChange = null }) => {
  const [showNotification, setShowNotification] = React.useState(false);
  const { pathname } = useLocation();
  const { permittedRoutes } = useRoleBasedRouting();
  const theme = isMobile ? styleThemes.mobile : styleThemes.desktop;
  const { consultCount, consultRequestCount } = useSelector(
    (state) => state.consults,
  );

  useInterval(() => {
    setShowNotification(!showNotification);
  }, 3000);

  const getConsultCount = React.useCallback(
    (navPath) => {
      const notificationKey = consultNotifications[navPath];
      if (!notificationKey) return null;

      const notificationCount = consultRequestCount[notificationKey];

      return showNotification && notificationCount
        ? `${notificationCount} new`
        : consultCount[notificationKey];
    },
    [consultCount, consultRequestCount, showNotification],
  );

  const rootPath = React.useMemo(() => pathname.split('/')[1], [pathname]);

  const navLinks = React.useMemo(
    () =>
      Object.entries(navLabels)
        .filter(([navPath]) => permittedRoutes.includes(navPath))
        .map(([navPath, text]) => (
          <NavLink
            key={navPath}
            text={text}
            to={navPath}
            isActive={navPath === rootPath}
            consultCount={getConsultCount(navPath)}
            onClick={onNavigationChange}
          />
        )),
    [getConsultCount, onNavigationChange, permittedRoutes, rootPath],
  );

  return (
    <ThemeProvider theme={theme}>
      <nav>
        <ListWrapper>{navLinks}</ListWrapper>
      </nav>
    </ThemeProvider>
  );
};

MenuList.propTypes = {
  isMobile: PropTypes.bool,
  onNavigationChange: PropTypes.func,
};

export default MenuList;
