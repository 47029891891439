export default {
  Users: 'users',
  HealthSystems: 'locations',
  Networks: 'networks',
  Consults: 'consults',
  ConsultRequests: 'consult-requests',
  Providers: 'providers',
  Billing: 'billing',
  Orders: 'orders',
};

export const LocationPaths = {
  Facility: 'facility',
};
