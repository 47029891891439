import useConsultData from 'features/consults/hooks/useConsultData';
import { CONSULT_TABS } from 'features/consults/utils/constants';

const DemographicsHeader = () => {
  const { consultTabData } = useConsultData(CONSULT_TABS.DEMOGRAPHICS);
  const { firstName, lastName } = consultTabData;
  const headerText = 'Demographics';

  if (firstName || lastName) {
    const names = [firstName, lastName].filter((name) => name).join(' ');

    return `${headerText} - ${names}`;
  }

  return headerText;
};

export default DemographicsHeader;
