import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import MenuList from '@mui/material/MenuList';

import { ConsultFilterLabels } from 'utils/enums';
import { Box, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import { DropdownIcon, ConsultOption } from './ConsultQuickFilterSelect.style';

const ConsultQuickFilterSelect = ({ handleChange, selected }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isOpen = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    handleChange(option);
    handleClose();
  };

  return (
    <FlexBoxSpaceBetweenCenter sx={{ width: '100%' }}>
      <Box component="span" mx={2}>
        {ConsultFilterLabels[selected]}
      </Box>
      <DropdownIcon onClick={handleClick} />
      <Popover
        id={isOpen ? 'simple-popover' : undefined}
        open={isOpen}
        anchorEl={anchorEl}
        sx={{ transform: 'translate(-2rem, .5rem)' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuList>
          {Object.entries(ConsultFilterLabels).map(([key, label]) => (
            <ConsultOption key={key} onClick={() => handleOptionClick(key)}>
              {label}
            </ConsultOption>
          ))}
        </MenuList>
      </Popover>
    </FlexBoxSpaceBetweenCenter>
  );
};

ConsultQuickFilterSelect.propTypes = {
  handleChange: PropTypes.func,
  selected: PropTypes.string,
};

export default ConsultQuickFilterSelect;
