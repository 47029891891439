import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { updateMFAPreference, setUpTOTP } from 'aws-amplify/auth';
import { Grid } from '@mui/material';

import {
  useProfile,
  useFeedback,
  useEditMode,
  useUpdateEffect,
} from 'shared/hooks';

import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import RadioGroup from 'shared/components/forms/RadioGroup';
import EditButton from 'shared/components/buttons/EditButton';
import { FlexBoxColumnFull, FlexBoxSpaceBetweenCenter } from 'styles/layout';

import TotPModal from './TotPModal';

const MfaTypes = {
  sms: 'SMS',
  topP: 'TOTP',
};

const mfaOptions = [
  { value: MfaTypes.sms, label: 'Text Message (SMS)' },
  { value: MfaTypes.topP, label: 'Authentication App (One-Time Passcode)' },
];

const FormMFA = ({ title }) => {
  const { mfaList, fetchMfaList } = useProfile();
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { clearDisplay, errorDisplay } = useFeedback();

  const defaultValues = React.useMemo(
    () => ({ method: mfaList[0] }),
    [mfaList],
  );

  const { control, handleSubmit, setValue, reset, formState } = useForm({
    defaultValues,
  });

  useUpdateEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const onMethodChange = async (newMethod) => {
    const { method: currentMethod } = defaultValues;
    if (newMethod !== MfaTypes.topP || currentMethod === MfaTypes.topP) return;
    clearDisplay();
    setValue('method', currentMethod);
    try {
      const totpSetupDetails = await setUpTOTP();
      const setupUri = totpSetupDetails.getSetupUri('BSN');
      await TotPModal({ setupUri });
      setValue('method', MfaTypes.topP, { shouldDirty: true });
    } catch (e) {
      errorDisplay('Confirmation code is mandatory to enable mfa');
    }
  };

  const onSubmit = React.useCallback(
    async ({ method }) => {
      await updateMFAPreference(
        Object.values(MfaTypes).reduce(
          (res, type) => ({
            ...res,
            [type.toLowerCase()]: method === type ? 'PREFERRED' : 'DISABLED',
          }),
          {},
        ),
      );
      disableEditMode();
      fetchMfaList();
    },
    [disableEditMode, fetchMfaList],
  );

  if (!mfaList) return null;

  return (
    <FlexBoxColumnFull>
      <FlexBoxSpaceBetweenCenter>
        <h3>{title}</h3>
        {!isEditMode && <EditButton onClick={enableEditMode} />}
      </FlexBoxSpaceBetweenCenter>
      <FormWrapper
        isSubmitDisabled={!formState.isDirty}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => {
          reset();
          disableEditMode();
        }}
        formDisabled={!isEditMode}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <RadioGroup
              name="method"
              control={control}
              options={mfaOptions}
              disabled={!isEditMode}
              handleChange={onMethodChange}
            />
          </Grid>
        </Grid>
      </FormWrapper>
    </FlexBoxColumnFull>
  );
};

FormMFA.propTypes = {
  title: PropTypes.string,
};

export default FormMFA;
