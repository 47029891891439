import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useMountEffect } from 'shared/hooks';
import { ServiceTypeEnum } from 'utils/enums';

import Drawer from 'shared/components/layout/Drawer/Drawer';
import { FlexBoxSpaceBetweenCenter } from 'styles/layout';

import ConsultMenuToggle, { tabsEnum } from './ConsultMenuToggle';
import ConsultInfo from './ConsultInfo';
import ConsultIntegration from './ConsultIntegration';

import {
  NotesWrapper,
  NotesContent,
  SmToggleWrapper,
  ToggleIconWrapper,
  ExpandedNotesWrapper,
  CloseButton,
} from './ConsultMenu.style';

const ConsultMenuWrapper = ({ children, sm, isOpen, onToggle }) =>
  sm ? (
    <Drawer anchor="right" isOpen={isOpen} onClose={onToggle}>
      {children}
    </Drawer>
  ) : (
    <NotesWrapper elevation={2}>{children}</NotesWrapper>
  );

ConsultMenuWrapper.propTypes = {
  children: PropTypes.node,
  sm: PropTypes.bool,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

const ConsultMenu = ({
  isOpen = false,
  sm = false,
  onToggle,
  consult,
  consultType,
}) => {
  const { currentConsultFacility } = useSelector(({ consults }) => consults);
  const [tab, setTab] = React.useState(null);

  const onTabClick = React.useCallback(
    (type) => {
      setTab(type);
      if (!isOpen) onToggle();
    },
    [isOpen, onToggle],
  );

  const onClose = React.useCallback(() => {
    setTab(null);
    onToggle();
  }, [onToggle]);

  const isIntegrated = React.useMemo(() => {
    if (!currentConsultFacility) return false;
    return currentConsultFacility.isIntegrated;
  }, [currentConsultFacility]);

  const isConsultOpen = React.useMemo(
    () => consult.signature?.state === 'Open',
    [consult.signature],
  );

  const hasIntegrationTab = React.useMemo(
    () =>
      isIntegrated && isConsultOpen && consultType === ServiceTypeEnum.NEURO,
    [isIntegrated, isConsultOpen, consultType],
  );

  useMountEffect(() => {
    if (isOpen) setTab(tabsEnum.INFO);
  });

  const renderTabButtons = React.useMemo(
    () => (
      <>
        <ConsultMenuToggle
          onTabClick={() => onTabClick(tabsEnum.INFO)}
          type={tabsEnum.INFO}
          disabled={tabsEnum.INFO === tab}
        />
        {hasIntegrationTab && (
          <ConsultMenuToggle
            onTabClick={() => onTabClick(tabsEnum.INTEGRATION)}
            type={tabsEnum.INTEGRATION}
            disabled={tabsEnum.INTEGRATION === tab}
          />
        )}
      </>
    ),
    [tab, onTabClick, hasIntegrationTab],
  );

  const renderTab = React.useMemo(() => {
    switch (tab) {
      case tabsEnum.INFO:
        return (
          <ConsultInfo
            consult={consult}
            consultType={consultType}
            consultFacility={currentConsultFacility}
          />
        );
      case tabsEnum.INTEGRATION:
        return (
          <ConsultIntegration consult={consult} consultType={consultType} />
        );
      default:
        return null;
    }
  }, [tab, consult, consultType, currentConsultFacility]);

  return (
    <>
      {sm && <SmToggleWrapper>{renderTabButtons}</SmToggleWrapper>}
      <ConsultMenuWrapper isOpen={isOpen} sm={sm} onToggle={onToggle}>
        <NotesContent _open={isOpen ? 'open' : ''}>
          <FlexBoxSpaceBetweenCenter>
            {isOpen && <CloseButton onClick={onClose} />}
            <ToggleIconWrapper _open={isOpen ? 'open' : ''}>
              {renderTabButtons}
            </ToggleIconWrapper>
          </FlexBoxSpaceBetweenCenter>
          {isOpen && <ExpandedNotesWrapper>{renderTab}</ExpandedNotesWrapper>}
        </NotesContent>
      </ConsultMenuWrapper>
    </>
  );
};

ConsultMenu.propTypes = {
  isOpen: PropTypes.bool,
  sm: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  consult: PropTypes.shape({
    id: PropTypes.string,
    demographics: PropTypes.shape({
      facilityId: PropTypes.string,
    }),
    signature: PropTypes.shape({
      state: PropTypes.string,
    }),
  }).isRequired,
  consultType: PropTypes.string.isRequired,
};

export default ConsultMenu;
