import styled from '@emotion/styled';

import { Box } from 'styles/layout';

// eslint-disable-next-line import/prefer-default-export
export const CalendarContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(4, 350px);
  grid-column-gap: 5px;
  grid-row-gap: 20px;
`;
