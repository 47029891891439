import * as yup from 'yup';

import demographicsFields, {
  eegDemographicsFields,
  teleNeuroDemographicsFields,
} from 'features/consults/shared/tabs/Demographics/formFields';
import signatureFields, {
  teleNeuroSignatureFields,
} from 'features/consults/shared/tabs/Signature/formFields';
import {
  neuroConsultOptions,
  eegConsultOptions,
  teleNeuroConsultOptions,
} from 'features/consults/utils/constants';

export const neuroValidations = yup.object().shape({
  [demographicsFields.consultType.name]: yup
    .string()
    .required('Required')
    .oneOf(
      neuroConsultOptions.map(({ value }) => value),
      'Required',
    ),
  [demographicsFields.facility.name]: yup
    .string()
    .nullable()
    .required('Required'),
  [signatureFields.attestationOfConsultCompletion.name]: yup
    .string()
    .nullable()
    .required('Required'),
});

export const teleNeuroValidations = yup.object().shape({
  [teleNeuroDemographicsFields.consultType.name]: yup
    .string()
    .required('Required')
    .oneOf(
      teleNeuroConsultOptions.map(({ value }) => value),
      'Required',
    ),
  [teleNeuroDemographicsFields.facility.name]: yup
    .string()
    .nullable()
    .required('Required'),
  [teleNeuroSignatureFields.attestationOfConsultCompletion.name]: yup
    .string()
    .nullable()
    .required('Required'),
});

export const eegValidations = yup.object().shape({
  [eegDemographicsFields.consultType.name]: yup
    .string()
    .required('Required')
    .oneOf(
      eegConsultOptions.map(({ value }) => value),
      'Required',
    ),
  [eegDemographicsFields.facility.name]: yup
    .string()
    .nullable()
    .required('Required'),
  [signatureFields.attestationOfEEGCompletion.name]: yup
    .string()
    .nullable()
    .required('Required'),
});

export const pedsEegValidations = yup.object().shape({
  [eegDemographicsFields.eegPedsConsultType.name]: yup
    .string()
    .required('Required')
    .oneOf(
      eegConsultOptions.map(({ value }) => value),
      'Required',
    ),
  [eegDemographicsFields.facility.name]: yup
    .string()
    .nullable()
    .required('Required'),
  [signatureFields.attestationOfEEGCompletion.name]: yup
    .string()
    .nullable()
    .required('Required'),
});
