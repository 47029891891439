import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Table, TableHead, TableRow, TableCell } from '@mui/material';
import { alpha } from '@mui/material/styles';

import colors from 'styles/colors';
import { darkerNeutralColor, tableRowStyles } from 'styles/tables';

const generateRowBackgroundStyles = (rowIndex, props) => {
  const backgroundColor = rowIndex % 2 === 0 ? colors.white : colors.neutral;

  return css`
    background: ${backgroundColor};

    &:hover {
      cursor: ${props.clickable ? 'pointer' : ''};
      background: ${props.clickable ? darkerNeutralColor : ''};
    }

    &.selected,
    &.selected:hover {
      background: ${alpha(colors.primary, 0.2)};
    }
  `;
};

export const DataTable = styled(Table)`
  border: 1px solid ${colors.neutral};
  table-layout: fixed;
`;

export const TableHeader = styled(TableHead)`
  ${tableRowStyles}
  background: rgba(19, 130, 202, 0.11);
`;

export const DataRow = styled((props) => (
  <TableRow classes={{ selected: 'selected' }} {...props} />
))`
  ${tableRowStyles}
  ${({ index, ...rest }) => generateRowBackgroundStyles(index, rest)}
`;

export const HeaderCell = styled(TableCell)`
  padding: ${({ _empty, _padding }) => (_empty ? '0' : _padding ?? '8px 15px')};
  border: none;
  width: ${({ width }) => width || 'auto'};
  position: ${({ _empty }) => (_empty ? 'relative' : '')};
`;

export const DataCell = styled(HeaderCell)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DataCellTooltip = styled(HeaderCell)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: visible;
  position:relative;

  &:hover .cellTooltip {
    opacity: 1;
  }
`;

export const TextWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Tooltip = styled.span`
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition:
    opacity 0.3s,
    transform 0.3s;
  z-index: 10;
  width: max-content;
  min-width: 120px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

export const FallbackMsg = styled.p`
  font-size: 15px;
  margin: 10px 0;
`;
