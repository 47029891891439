import * as yup from 'yup';

export const formFields = {
  search: 'query',
  role: 'roles',
  healthSystem: 'healthSystemId',
  facility: 'facilityId',
  status: 'enabled',
};

export const filterSchema = yup.object().shape({
  [formFields.search]: yup.string(),
  [formFields.role]: yup.string(),
});
