import React from 'react';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

import { FlexBoxCenter } from 'styles/layout';

const BackdropOverlay = styled(FlexBoxCenter)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const LoadingSpinner = () => (
  <BackdropOverlay>
    <CircularProgress />
  </BackdropOverlay>
);

export default LoadingSpinner;
