import React from 'react';
import axios from 'axios';

import api from 'api';
import { useFeedback } from 'shared/hooks';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';

const BillingUploadButton = () => {
  const fileRef = React.useRef(null);
  const [isUploading, setUploading] = React.useState(false);
  const { clearDisplay, successDisplay, warningDisplay, errorDisplay } =
    useFeedback();

  const onFileSelected = async ({ target: { files } }) => {
    clearDisplay();
    if (!files.length) return;
    const [file] = files;
    setUploading(true);

    try {
      const {
        data: { fileName, presginedUrl },
      } = await api.getInsuranceUploadUrl({
        fileName: file.name,
        contentType: file.type,
      });

      await axios.put(presginedUrl, file, {
        headers: { 'Content-Type': file.type },
      });

      const {
        data: { processedTotal, failedTotal, validationErrors },
      } = await api.readInsuranceFile({ fileName });

      const callbackFunc = validationErrors?.length
        ? warningDisplay
        : successDisplay;

      const failedLine = !!failedTotal && `Failed: ${failedTotal}`;

      callbackFunc([
        `Processed: ${processedTotal}`,
        failedLine,
        ...validationErrors,
      ]);
    } catch (e) {
      errorDisplay('Error Uploading File');
    }

    setUploading(false);
    fileRef.current.value = '';
  };

  return (
    <ActionButton disabled={isUploading} color="secondary" component="label">
      Upload File
      <input
        ref={fileRef}
        onChange={onFileSelected}
        hidden
        accept=".txt"
        type="file"
      />
    </ActionButton>
  );
};

export default BillingUploadButton;
