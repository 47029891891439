export default {
  healthSystems: {
    name: 'HealthSystemName',
    facilityCount: 'FacilityCount',
  },
  facilities: {
    name: 'FacilityName',
    neuroNetwork: 'NeuroNetwork',
    teleneuroNetwork: 'TeleNeuroHospitalistNetwork',
    eegNetwork: 'EegNetwork',
    eegPediatricNetwork: 'EEGPediatricNetwork',
    city: 'City',
    state: 'State',
    emrName: 'EMRName',
  },
  networks: {
    name: 'NetworkName',
    facilityCount: 'FacilityCount',
  },
  users: {
    firstName: 'FirstName',
    lastName: 'LastName',
    title: 'Title',
    email: 'Email',
    phoneNumber: 'PhoneNumber',
    username: 'Username',
    enabled: 'Enabled',
  },
  consults: {
    color: 'Color',
    facilityName: 'FacilityName',
    firstName: 'PatientFirstName',
    lastName: 'PatientLastName',
    type: 'Type',
    phone: 'Phone',
    assignTo: 'AssignTo',
    createdAt: 'CreatedAt',
    urgency: 'StatOption',
  },
  consultRequests: {
    firstName: 'FirstName',
    lastName: 'LastName',
    facilityName: 'FacilityName',
    consultType: 'ConsultType',
    providerFirstName: 'ProviderFirstName',
    providerLastName: 'ProviderLastName',
    state: 'ConsultRequestState',
    createdAt: 'CreatedAt',
  },
  macros: {
    name: 'Name',
    type: 'Type',
    createdAt: 'CreatedAt',
  },
};
