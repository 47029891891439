import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, FlexBoxColumn } from 'styles/layout';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import { useFormState } from 'shared/hooks';

import { FilterElement } from 'features/admin/pages/Consults/ConsultList/ConsultListFilters/ConsultFilters.style';

import ConsultMainFilters from './ConsultMainFilters';
import { mapAdminConsultFilters } from './data';

const ConsultListFilters = ({
  filters,
  onFilter,
  onResetFilters,
  children,
}) => {
  const { register, setFormValues, ...formProps } = useFormState();

  useEffect(() => {
    setFormValues(mapAdminConsultFilters(filters));
  }, [filters, setFormValues]);

  return (
    <FlexBoxColumn>
      <Box>
        <FilterElement>
          <SearchField
            {...register('query')}
            onChange={(query) => onFilter({ query })}
          />
          {children}
        </FilterElement>
      </Box>
      <ConsultMainFilters
        formProps={{ ...formProps, register }}
        filters={filters}
        onFilter={onFilter}
        onResetFilters={onResetFilters}
      />
      
    </FlexBoxColumn>
  );
};

ConsultListFilters.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  onFilter: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ConsultListFilters;
