/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { CONSULT_TABS } from 'features/consults/utils/constants';
import {
  ConsultStates,
  IntegrationStatusEnum,
  MacroTypes,
  ServiceShortEnum,
} from 'utils/enums';

import { mapErrorResponse } from 'utils/mappers';
import {
  SLICE_PREFIX,
  listConsults,
  getConsultById,
  getConsultFacility,
  getConsultCount,
  addNewConsult,
  editConsult,
  updateConsultOwner,
  updateSummary,
  lockConsult,
  editConsultFlag,
  listMacros,
  listSharedMacros,
  addNewMacro,
  applyMacro,
  linkOrder,
  unlinkOrder,
  updateMacro,
  getMacroById,
  doGetConsultRequests,
  doGetConsultRequestCount,
  addIcd,
  deleteIcd,
} from './actionCreators';

const newConsultList = ({ consults, consultId, propToUpdate }) =>
  consults.map((item) =>
    item.id === consultId
      ? {
          ...item,
          ...propToUpdate,
        }
      : item,
  );

const initialState = {
  ...Object.values(ServiceShortEnum).reduce(
    (res, service) => ({
      ...res,
      [service]: {
        data: [],
        pageCount: 0,
      },
    }),
    {},
  ),
  macros: {
    data: [],
    pageCount: 0,
  },
  sharedMacros: {
    data: [],
    pageCount: 0,
  },
  consultLock: {
    requested: false,
    errors: null,
  },
  currentConsult: {},
  currentConsultFacility: null,
  consultCount: {},
  consultRequests: {
    Neuro: [],
    Clinic: [],
    EEG: [],
    TeleNeuroHospital: [],
  },
  consultRequestCount: {},
  error: null,
};

const consultsStateSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearConsult: (state) => {
      state.currentConsult = {};
      state.consultLock = initialState.consultLock;
    },
    updateConsultInList: (state, { payload }) => {
      const { id, consultType, propToUpdate } = payload;

      const stateSlice = ServiceShortEnum[consultType];
      if (!state[stateSlice]?.data) return;

      state[stateSlice].data = newConsultList({
        consults: state[stateSlice].data,
        consultId: id,
        propToUpdate,
      });
    },
    saveConsultChanges: (state, { payload }) => {
      const { tab, changes } = payload;

      state.currentConsult = {
        ...state.currentConsult,
        [tab]: {
          ...state.currentConsult[tab],
          ...changes,
        },
      };
    },
    setLockErrors: (state, { payload }) => {
      state.consultLock = { requested: true, errors: payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listConsults.fulfilled, (state, { payload }) => {
        const { consultType, ...rest } = payload;
        state[consultType] = rest;
      })
      .addCase(getConsultById.fulfilled, (state, { payload }) => {
        const { icdCodes, ...consult } = payload;
        state.currentConsult = {
          ...consult,
          [CONSULT_TABS.SIGNATURE]: {
            ...consult[CONSULT_TABS.SIGNATURE],
            icdCodes,
          },
        };
        state.consultLock = initialState.consultLock;
      })
      .addCase(getConsultFacility.fulfilled, (state, { payload }) => {
        state.currentConsultFacility = payload;
      })
      .addCase(updateConsultOwner.fulfilled, (state, { payload }) => {
        state.currentConsult[CONSULT_TABS.SIGNATURE].assignToId = payload;
      })
      .addCase(lockConsult.fulfilled, (state, { payload }) => {
        const { lockById, lockAt, lockAction, summary, summaryFormatted } =
          payload;

        state.currentConsult[CONSULT_TABS.SIGNATURE].state = lockAction;
        state.currentConsult.summary = summary;
        state.currentConsult.summaryFormatted = summaryFormatted;

        if (lockAction === ConsultStates.CANCELLED) {
          state.currentConsult[CONSULT_TABS.SIGNATURE].cancelledById = lockById;
          state.currentConsult[CONSULT_TABS.SIGNATURE].cancelledAt = lockAt;
        } else {
          state.currentConsult[CONSULT_TABS.SIGNATURE].closedById = lockById;
          state.currentConsult[CONSULT_TABS.SIGNATURE].closedAt = lockAt;
        }
        state.consultLock = initialState.consultLock;
      })
      .addCase(updateSummary.fulfilled, (state, { payload }) => {
        state.currentConsult = {
          ...state.currentConsult,
          ...payload.consult,
        };
      })
      .addCase(getConsultCount.fulfilled, (state, { payload }) => {
        state.consultCount = payload;
      })
      .addCase(listMacros.fulfilled, (state, { payload }) => {
        state.macros = payload;
      })
      .addCase(listSharedMacros.fulfilled, (state, { payload }) => {
        state.sharedMacros = payload;
      })
      .addCase(
        addIcd.fulfilled,
        (state, { payload: { code, description, id } }) => {
          state.currentConsult[CONSULT_TABS.SIGNATURE].icdCodes = [
            ...state.currentConsult[CONSULT_TABS.SIGNATURE].icdCodes,
            { code, description, id },
          ];
        },
      )
      .addCase(deleteIcd.fulfilled, (state, { payload }) => {
        const { icdCodes } = state.currentConsult[CONSULT_TABS.SIGNATURE];
        state.currentConsult[CONSULT_TABS.SIGNATURE].icdCodes = icdCodes
          .map((code) => ({ ...code }))
          .filter((item) => item.id !== payload.icdId);
      })
      .addCase(getMacroById.fulfilled, (state, { payload }) => {
        const { icdCodes, ...consult } = payload;
        state.currentConsult = {
          ...consult,
          [CONSULT_TABS.SIGNATURE]: {
            ...consult[CONSULT_TABS.SIGNATURE],
            icdCodes,
          },
        };
      })
      .addCase(addNewMacro.fulfilled, (state, { payload }) => {
        if (payload.macroType === MacroTypes.SHARED) {
          state.sharedMacros.data = state.sharedMacros.data
            .concat(payload)
            .sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );
        } else {
          state.macros.data = state.macros.data
            .concat(payload)
            .sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );
        }
      })
      .addCase(applyMacro.fulfilled, (state, { payload }) => {
        const { icdCodes, ...consult } = payload;
        state.currentConsult = {
          ...consult,
          [CONSULT_TABS.SIGNATURE]: {
            ...consult[CONSULT_TABS.SIGNATURE],
            icdCodes,
          },
        };
      })
      .addCase(linkOrder.fulfilled, (state, { payload }) => {
        if (payload?.result) {
          const { consultId, consultType } = payload;

          state.currentConsult.integrationStatus =
            IntegrationStatusEnum.MATCHED;

          const stateSlice = ServiceShortEnum[consultType];
          if (!state[stateSlice]?.data) return;

          state[stateSlice].data = newConsultList({
            consults: state[stateSlice].data,
            consultId,
            propToUpdate: { integrationStatus: IntegrationStatusEnum.MATCHED },
          });
        }
      })
      .addCase(unlinkOrder.fulfilled, (state, { payload }) => {
        if (payload?.result) {
          const { consultId, consultType } = payload;
          state.currentConsult.integrationStatus =
            IntegrationStatusEnum.UNMATCHED;

          const stateSlice = ServiceShortEnum[consultType];
          if (!state[stateSlice]?.data) return;

          state[stateSlice].data = newConsultList({
            consults: state[stateSlice].data,
            consultId,
            propToUpdate: {
              integrationStatus: IntegrationStatusEnum.UNMATCHED,
            },
          });
        }
      })
      .addCase(doGetConsultRequests.fulfilled, (state, { payload }) => {
        state.consultRequests[payload.consultType] = payload.data;
      })
      .addCase(doGetConsultRequestCount.fulfilled, (state, { payload }) => {
        state.consultRequestCount = payload;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith(`${SLICE_PREFIX}/`) &&
          action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = mapErrorResponse(action);
        },
      );
  },
});

const { reducer, actions } = consultsStateSlice;

export { reducer as consultsReducer };
export const consultsActions = {
  ...actions,
  listConsults,
  getConsultById,
  getConsultFacility,
  getConsultCount,
  addNewConsult,
  editConsult,
  updateSummary,
  updateConsultOwner,
  lockConsult,
  editConsultFlag,
  listMacros,
  listSharedMacros,
  addNewMacro,
  applyMacro,
  linkOrder,
  unlinkOrder,
  updateMacro,
  getMacroById,
  doGetConsultRequests,
  doGetConsultRequestCount,
  addIcd,
  deleteIcd,
};

export default consultsStateSlice;
