import { DEFAULT_FIELD_FORMAT, formatToSpecificTimezone } from 'utils/dates';
import { TIMEZONE_OFFSETS, TIMEZONE_LABELS } from 'utils/timezones';

// eslint-disable-next-line import/prefer-default-export
export const formatSummaryTimestamp = (timestamp, facilityTimezone) =>
  `${formatToSpecificTimezone(
    new Date(timestamp),
    DEFAULT_FIELD_FORMAT,
    TIMEZONE_OFFSETS[facilityTimezone],
  )} (${TIMEZONE_LABELS[facilityTimezone]})`;
