import React from 'react';
import PropTypes from 'prop-types';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

const SpeechButtonsContext = React.createContext({});

export const SpeechButtonsProvider = ({ children }) => {
  const [activeButton, setActiveButton] = React.useState(null);
  const speechProps = useSpeechRecognition();

  const value = React.useMemo(
    () => ({
      ...speechProps,
      activeButton,
      setActiveButton,
    }),
    [activeButton, speechProps],
  );

  return (
    <SpeechButtonsContext.Provider value={value}>
      {children}
    </SpeechButtonsContext.Provider>
  );
};

SpeechButtonsProvider.propTypes = {
  children: PropTypes.node,
};

export const useSpeechButtons = (buttonId) => {
  const { activeButton, setActiveButton, listening, ...moreContext } =
    React.useContext(SpeechButtonsContext);

  React.useEffect(() => {
    SpeechRecognition.stopListening();
  }, []);

  const buttonIsListening = React.useMemo(
    () => listening && activeButton === buttonId,
    [listening, activeButton, buttonId],
  );

  const handleButtonClick = React.useCallback(() => {
    if (buttonIsListening) {
      setActiveButton(null);
      SpeechRecognition.stopListening();
      return;
    }
    setActiveButton(buttonId);
    if (!listening) {
      SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
    }
  }, [buttonIsListening, listening, buttonId, setActiveButton]);

  return {
    ...moreContext,
    buttonIsListening,
    handleButtonClick,
  };
};
