import { createSlice } from '@reduxjs/toolkit';

import { mapErrorResponse } from 'utils/mappers';
import { SLICE_PREFIX, listScheduleShifts } from './actionCreators';

const initialState = {
  shifts: [],
};

/* eslint-disable no-param-reassign */
const scheduleStateSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listScheduleShifts.fulfilled, (state, { payload }) => {
        state.shifts = payload;
      })
      .addCase(listScheduleShifts.rejected, (state) => {
        state.shifts = [];
      })
      .addMatcher(
        (action) =>
          action.type.startsWith(`${SLICE_PREFIX}/`) &&
          action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = mapErrorResponse(action);
        },
      );
  },
});
/* eslint-enable no-param-reassign */

const { reducer, actions } = scheduleStateSlice;

export { reducer as scheduleReducer };
export const scheduleActions = {
  ...actions,
  listScheduleShifts,
};

export default scheduleStateSlice;
