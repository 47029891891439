import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from 'api';
import { useProfile } from 'shared/hooks';
import { consultsActions } from 'features/consults/store/slice';

const useConsultRequests = (consultType, handleRefresh) => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { isDoctor } = useProfile();

  const consultRequests = useSelector(
    ({ consults }) => consults.consultRequests[consultType],
  );

  const fetchConsultRequests = React.useCallback(
    (isAutoRefresh) => {
      if (!isDoctor) return;
      dispatch(
        consultsActions.doGetConsultRequests({
          consultType,
          isAutoRefresh,
        }),
      );
    },
    [dispatch, consultType, isDoctor],
  );

  const fetchConsultRequestsCount = React.useCallback(() => {
    if (!isDoctor) return;
    dispatch(consultsActions.doGetConsultRequestCount());
  }, [dispatch, isDoctor]);

  const onAcceptConsultRequest = React.useCallback(
    ({ id, cb, errCb }) => {
      setIsUpdating(true);
      api
        .acceptConsultRequest(id)
        .then(() => {
          setIsUpdating(false);
          // REFRESH CONSULT REQUEST LIST
          fetchConsultRequests();
          // REFRESH CONSULT LIST
          handleRefresh();
          fetchConsultRequestsCount();
          if (cb) cb();
        })
        .catch(() => {
          setIsUpdating(false);
          if (errCb) errCb();
        });
    },
    [fetchConsultRequests, fetchConsultRequestsCount, handleRefresh],
  );

  const onDeclineConsultRequest = React.useCallback(
    ({ id, cb, errCb }) => {
      setIsUpdating(true);
      api
        .declineConsultRequest(id)
        .then(() => {
          fetchConsultRequests();
          setIsUpdating(false);
          fetchConsultRequestsCount();
          if (cb) cb();
        })
        .catch(() => {
          setIsUpdating(false);
          if (errCb) errCb();
        });
    },
    [fetchConsultRequestsCount, fetchConsultRequests],
  );

  const onDismissConsultRequest = React.useCallback(
    ({ id, cb, errCb }) => {
      setIsUpdating(true);
      api
        .deleteConsultRequestNotification(id)
        .then(() => {
          fetchConsultRequests();
          setIsUpdating(false);
          fetchConsultRequestsCount();
          if (cb) cb();
        })
        .catch(() => {
          setIsUpdating(false);
          if (errCb) errCb();
        });
    },
    [fetchConsultRequestsCount, fetchConsultRequests],
  );

  return {
    isUpdating,
    fetchConsultRequests,
    onAcceptConsultRequest,
    onDeclineConsultRequest,
    onDismissConsultRequest,
    consultRequests,
  };
};

export default useConsultRequests;
