import React from 'react';
import PropTypes from 'prop-types';
import ListIcon from '@mui/icons-material/List';
import LinkIcon from '@mui/icons-material/Link';

import IconButton from 'shared/components/buttons/IconButton/IconButton';

export const tabsEnum = {
  INFO: 'info',
  INTEGRATION: 'integration',
};

const tabIcons = {
  [tabsEnum.INFO]: <ListIcon />,
  [tabsEnum.INTEGRATION]: <LinkIcon />,
};

const ConsultMenuToggle = ({ onTabClick, type, disabled }) => (
  <IconButton color="secondary" disabled={disabled} onClick={onTabClick}>
    {tabIcons[type]}
  </IconButton>
);

ConsultMenuToggle.propTypes = {
  onTabClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ConsultMenuToggle;
