import React from 'react';
import PropTypes from 'prop-types';
import { verifyTOTPSetup } from 'aws-amplify/auth';
import { useForm } from 'react-hook-form';
import { createModal } from 'react-modal-promise';
import { QRCodeSVG } from 'qrcode.react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import InputField from 'shared/components/forms/InputField/InputField';
import BasicDialog from 'shared/components/feedback/BasicDialog';
import { Box, FlexBoxColumnFull } from 'styles/layout';

const fieldValue = 'code';

const validationSchema = yup.object({
  [fieldValue]: yup
    .string()
    .required('Required')
    .min(6, 'Must be exactly 6 digits'),
});

const TotPModal = ({ setupUri, isOpen, onResolve, onReject }) => {
  const resolver = yupResolver(validationSchema);
  const { register, handleSubmit, formState, setError, clearErrors } = useForm({
    resolver,
  });

  const submitFunc = async ({ [fieldValue]: code }) => {
    clearErrors();
    try {
      await verifyTOTPSetup({ code });
      onResolve();
    } catch ({ message }) {
      setError(fieldValue, {
        message,
        type: 'required',
      });
    }
  };

  return (
    <BasicDialog
      open={isOpen}
      title="Confirm TOTP"
      confirmText="Confirm"
      handleConfirm={handleSubmit(submitFunc)}
      handleCancel={onReject}
      content={
        <FlexBoxColumnFull sx={{ maxWidth: '16rem' }}>
          <QRCodeSVG size={256} value={setupUri} />
          <Box mt={2}>
            <InputField
              {...register(fieldValue, {
                required: true,
              })}
              label="Insert authenticator code"
              required
              error={formState.errors[fieldValue]}
            />
          </Box>
        </FlexBoxColumnFull>
      }
    />
  );
};

TotPModal.propTypes = {
  setupUri: PropTypes.shape({}),
  isOpen: PropTypes.bool,
  onResolve: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default createModal(TotPModal);
