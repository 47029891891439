import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Toolbar } from '@mui/material';

export const HomeLink = styled(Link)`
  display: block;
  padding: 0 10px;
`;

export const HomeLogo = styled.img`
  max-width: 100%;
  width: 65px;
`;

export const SmallScreenToolbar = styled(Toolbar)`
  padding: 0 10px;
`;
