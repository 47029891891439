import React from 'react';
import { Routes, Route } from 'react-router-dom';

import OrderListPage from './list';
import OrderDetailsPage from './details';

const OrdersPage = () => (
  <Routes>
    <Route index element={<OrderListPage />} />
    <Route path=":orderId" element={<OrderDetailsPage />} />
  </Routes>
);

export default OrdersPage;
