import React from 'react';
import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';

import { Box } from 'styles/layout';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import schema from './validationSchema';
import { topPanel, motorPanels, sensoryPanels, bottomPanel } from './data';

const EMG = () => {
  const { control, register, getValues, getFieldError, setFormValues } =
    useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const { consult, consultTabData, isEditable, handleFieldChange } =
    useConsultData(CONSULT_TABS.EMG, getValues);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  return (
    <Box>
      <QuestionsPanel
        control={control}
        register={register}
        onChange={handleFieldChange}
        getFieldError={getFieldError}
        disabled={!isEditable}
        {...topPanel}
      />
      <GridWrapper>
        <Grid item sm={12} lg={6}>
          {motorPanels.map((section, index) => (
            <QuestionsPanel
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              control={control}
              register={register}
              onChange={handleFieldChange}
              getFieldError={getFieldError}
              disabled={!isEditable}
              {...section}
            />
          ))}
        </Grid>
        <Grid item sm={12} lg={6}>
          {sensoryPanels.map((section, index) => (
            <QuestionsPanel
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              control={control}
              register={register}
              onChange={handleFieldChange}
              getFieldError={getFieldError}
              disabled={!isEditable}
              {...section}
            />
          ))}
        </Grid>
      </GridWrapper>
      <QuestionsPanel
        control={control}
        register={register}
        onChange={handleFieldChange}
        getFieldError={getFieldError}
        disabled={!isEditable}
        {...bottomPanel}
      />
    </Box>
  );
};

export default EMG;
