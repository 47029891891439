import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import Stack from '@mui/material/Stack';

import api from 'api';
import {
  useMountEffect,
  useProfile,
  useFormState,
  useRequest,
} from 'shared/hooks';
import { FlexBoxAlignWrap, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import DownloadButton from 'shared/components/buttons/DownloadButton';
import Autocomplete from 'shared/components/forms/Autocomplete';
import ProviderSelect from 'shared/components/data/forms/ProviderSelect';
import ScheduleViewToggle from 'features/schedule/components/ScheduleViewToggle/ScheduleViewToggle';
import useScheduleFilterOptions from 'features/schedule/hooks/useScheduleFilterOptions';
import { ScheduleViewEnum } from 'features/schedule/utils/constants';
import { MAX_PAGE_SIZE } from 'utils/constants';
import FilterElement from './ScheduleFilters.style';

const ScheduleFilters = ({
  filters,
  disableExport = false,
  onFilterChange,
  onExport,
}) => {
  const { isCustomerOnly } = useProfile();
  const { control, setValue, watch, setFormValues } = useFormState();
  const { doRequest: fetchFacilities, data: facilityData } = useRequest(
    api.getFacilities,
  );
  const { doRequest: fetchNetworks, data: networkData } = useRequest(
    api.getNetworkNames,
  );

  const { facilityId, network } = watch();

  React.useEffect(() => {
    setFormValues({
      networkType: filters.networkType,
      facilityId: filters.facilityId,
      networks: filters.networks,
      userId: filters.userId,
    });
  }, [filters, setFormValues]);

  const facilities = React.useMemo(
    () => facilityData?.facilities ?? [],
    [facilityData],
  );

  const networks = React.useMemo(
    () => networkData?.networks ?? [],
    [networkData],
  );

  const { serviceOptions } = useScheduleFilterOptions(facilities);

  useMountEffect(() => {
    fetchFacilities({
      pageSize: MAX_PAGE_SIZE,
    });
  });

  useMountEffect(() => {
    if (isCustomerOnly) return;
    fetchNetworks({
      pageSize: MAX_PAGE_SIZE,
    });
  });

  const handleScheduleViewChange = (value) => {
    let filterValues = { scheduleView: value };

    if (value === ScheduleViewEnum.MY) {
      filterValues = {
        ...filterValues,
        userId: null,
      };
      setValue('assignee', null);
    }
    onFilterChange(filterValues);
  };

  return (
    <>
      <FlexBoxAlignWrap>
        {facilities.length > 1 && (
          <FilterElement>
            <Autocomplete
              control={control}
              name="facilityId"
              placeholder="Facility"
              options={sortBy(facilities, ['healthSystemId', 'name'])}
              disabled={!!network}
              handleChange={(facility) => {
                onFilterChange({ facilityId: facility.toString() });
              }}
              groupBy={(o) => o.healthSystemName}
            />
          </FilterElement>
        )}
        {!isCustomerOnly && (
          <FilterElement>
            <Autocomplete
              control={control}
              name="networks"
              placeholder="Network"
              options={networks}
              disabled={!!facilityId}
              handleChange={(newNetwork) =>
                onFilterChange({ networks: newNetwork })
              }
            />
          </FilterElement>
        )}
        {serviceOptions.length > 1 && (
          <FilterElement>
            <Autocomplete
              control={control}
              name="networkType"
              placeholder="Services"
              options={serviceOptions}
              handleChange={(networkType) => {
                onFilterChange({ networkType });
              }}
            />
          </FilterElement>
        )}
        {filters.scheduleView !== ScheduleViewEnum.MY && (
          <FilterElement>
            <ProviderSelect
              control={control}
              consultType={filters.networkType ?? null}
              name="userId"
              placeholder="Assignee"
              handleChange={(userId) => onFilterChange({ userId })}
            />
          </FilterElement>
        )}
      </FlexBoxAlignWrap>
      <FlexBoxSpaceBetweenCenter>
        {isCustomerOnly && (
          <Stack spacing={1} direction="row">
            <DownloadButton
              title="Export as CSV"
              onExport={async () => onExport('csv')}
              disabled={disableExport}
            />
            <DownloadButton
              title="Export as XLS"
              onExport={async () => onExport('xlsx')}
              disabled={disableExport}
            />
          </Stack>
        )}
        <ScheduleViewToggle
          defaultValue={filters.scheduleView}
          handleChange={handleScheduleViewChange}
        />
      </FlexBoxSpaceBetweenCenter>
    </>
  );
};

ScheduleFilters.propTypes = {
  filters: PropTypes.shape({
    scheduleView: PropTypes.oneOf([ScheduleViewEnum.MY, ScheduleViewEnum.ALL])
      .isRequired,
    facilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    networks: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    networkType: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  disableExport: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default ScheduleFilters;
