import * as yup from 'yup';

export default yup.object().shape({
  /*
  chiefComplaint: yup.string().trim(),
  additionalHistory: yup.string().trim(),
  lastKnownNormal: yup.string().trim(),
  durationFirst: yup.string().trim(),
  durationSecond: yup.string().trim(),
  possibleAlteplaseCandidate: yup.string().trim(),
  associatedSymptoms: yup.string().trim(),
  quality: yup.string().trim(),
  contextPreExistingConditions: yup.string().trim(),
  severityText: yup.string().trim(),
	*/
});
