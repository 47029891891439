import * as yup from 'yup';

import { requiredString } from 'utils/constants';

export default yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `Network name should not exceed ${max} characters`),
  shiftAdminFacilityId: yup
    .string()
    .trim()
    .required(requiredString)
    .max(20, ({ max }) => `Network ID should not exceed ${max} characters`)
    .matches(/^[1-9]\d*$/, 'Invalid ID'),
});
