import React from 'react';
import { useParams } from 'react-router-dom';

import { Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import api from 'api';
import { useMountEffect, useRequest, useFeedback } from 'shared/hooks';
import useGoBack from 'navigation/hooks/useGoBack';

import BackButton from 'navigation/components/BackButton/BackButton';
import { FlexBoxAlign } from 'styles/layout';
import ConsultIntegrationDialog from 'features/consults/components/ConsultIntegration/ConsultIntegrationDialog';
import ConsultIntegrationHistoryTable from 'features/consults/components/ConsultIntegration/ConsultIntegrationHistoryTable';
import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';

import BillingInfoOrder from './BillingInfoOrder';
import BillingLinkingPage from './BillingLinkingPage';

const BillingInfoPage = () => {
  const { consultId } = useParams();
  const { goBack } = useGoBack();
  const { errorDisplay } = useFeedback();

  const { doRequest: getOrderDetails, data: orderDetails } = useRequest(
    api.getOrderDetails,
  );
  const { doRequest: getInsuranceData, data: insuranceData } = useRequest(
    api.getInsuranceData,
  );
  const { doRequest: fetchConsult, data: consultData } = useRequest(
    api.getConsultById,
  );

  const fecthPageData = React.useCallback(() => {
    fetchConsult(consultId);
    getOrderDetails(consultId);
    getInsuranceData(consultId);
  }, [consultId, getOrderDetails, getInsuranceData, fetchConsult]);

  useMountEffect(fecthPageData);

  const onLink = React.useCallback(
    async (order) => {
      try {
        await api.linkOrder({ consultId, hl7OrderId: order.id });
        fecthPageData();
      } catch (error) {
        errorDisplay('Error Linking Order');
      }
    },
    [consultId, errorDisplay, fecthPageData],
  );

  const onUnlink = React.useCallback(async () => {
    try {
      await api.unlinkOrder({ consultId });
      fecthPageData();
    } catch (error) {
      errorDisplay('Error UnLinking Order');
    }
  }, [consultId, errorDisplay, fecthPageData]);

  const tabs = React.useMemo(
    () => [
      {
        key: 'hl7Order',
        label: 'HL7 Order',
        render: () => <BillingInfoOrder data={insuranceData} />,
      },
      {
        key: 'history',
        label: 'Linking History',
        render: () => (
          <ConsultIntegrationHistoryTable history={orderDetails?.history} />
        ),
      },
      {
        key: 'linking',
        label: 'ADT Linking',
        render: () => (
          <BillingLinkingPage
            onLink={onLink}
            onUnlink={onUnlink}
            consultData={consultData?.consult}
          />
        ),
      },
    ],
    [
      insuranceData,
      orderDetails?.history,
      consultData?.consult,
      onLink,
      onUnlink,
    ],
  );

  const [tab, setTab] = React.useState(tabs[0].key);

  return (
    <ConsultIntegrationDialog open onClose={goBack}>
      <TabContext value={tab}>
        <FlexBoxAlign>
          <BackButton onBack={goBack} />
          <FlexBoxAlign
            width="100%"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <TabList
              onChange={(_, newValue) => setTab(newValue)}
              aria-label="hl7Order"
            >
              {tabs.map(({ key, label }) => (
                <Tab key={key} label={label} value={key} />
              ))}
            </TabList>
          </FlexBoxAlign>
        </FlexBoxAlign>
        {!consultData ? (
          <LoadingSpinner />
        ) : (
          tabs.map(({ key, render }) => (
            <TabPanel
              key={key}
              value={key}
              sx={{
                padding: 0,
                minWidth: '65vw',
                marginTop: 2,
                height: '65vh',
              }}
            >
              {render()}
            </TabPanel>
          ))
        )}
      </TabContext>
    </ConsultIntegrationDialog>
  );
};

export default BillingInfoPage;
