import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';

import api from 'api';
import { useFormState } from 'shared/hooks';
import { appStateActions } from 'store/appStateSlice';
import { requiredString } from 'utils/constants';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';

import useMacroOptions from 'features/consults/hooks/useMacroOptions';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import InputField from 'shared/components/forms/InputField/InputField';
import Select from 'shared/components/forms/Select';
import Dialog from 'shared/components/feedback/BasicDialog';
import { MacroTypes } from 'utils/enums';

const schema = yup.object().shape({
  name: yup.string().required(requiredString),
  type: yup.string().required(requiredString),
});

const AddMacro = ({ macroType }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, control, getFieldError, handleSubmit } = useFormState(
    schema,
    {
      stateSlice: 'consults',
      showAsSnackbar: true,
    },
  );
  const { macroOptions } = useMacroOptions();

  const [isOpen, setOpen] = React.useState(false);

  const onCreateMacro = React.useCallback(
    (macroValues) => {
      const payload = {
        ...macroValues,
        macroType
      };
      api
        .createMacro(payload)
        .then(({ data }) => navigate(data.id))
        .catch(({ response }) => {
          dispatch(
            appStateActions.showSnackbar({
              text: response?.data?.title ?? 'Unknown Error',
              type: SnackbarTypeEnum.ERROR,
              duration: 5000,
            }),
          );
        });
    },
    [dispatch, navigate, macroType],
  );

  const formContent = React.useMemo(
    () => (
      <>
        <InputField
          {...register('name', {
            required: true,
          })}
          label="Name"
          required
          error={getFieldError('name')}
        />
        <Box mt={2} mb={2}>
          <Select
            control={control}
            name="type"
            label="Type"
            options={macroOptions}
            required
            error={getFieldError('type')}
          />
        </Box>
      </>
    ),
    [register, control, getFieldError, macroOptions],
  );

  return (
    <>
      <ActionButton
        color="secondary"
        icon={<Add />}
        onClick={() => setOpen(true)}
      >
        {macroType === MacroTypes.SHARED ? "Add Shared Macro" : "Add Macro"}
      </ActionButton>
      <Dialog
        open={isOpen}
        handleConfirm={handleSubmit(onCreateMacro)}
        handleCancel={() => setOpen(false)}
        title={macroType === MacroTypes.SHARED ? "Add Shared Macro" : "Add Macro"}
        content={formContent}
        confirmText="Confirm"
      />
    </>
  );
};

AddMacro.propTypes = {
  macroType:  PropTypes.string
};

export default AddMacro;
