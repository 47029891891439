import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Box, FlexBoxColumn } from 'styles/layout';
import { formatSummaryTimestamp } from 'features/consults/utils/dates';
import { getNamesWithTitle } from 'utils/helpers';
import { sectionHeaderStyle, lineBreakStyle } from './SummaryNote.style';

const renderBlankSpace = <div style={lineBreakStyle}>{'\r'}</div>;

const Addenda = ({
  text,
  addedAt,
  addedByFirstName,
  addedByLastName,
  addedByTitle,
  facilityTimezone,
}) => (
  <FlexBoxColumn mb={1}>
    <h3 style={sectionHeaderStyle}>Addendum</h3>
    <Box>
      {text.split('\n').map((line) => (
        <Box key={uuid()}>{line || <br />}</Box>
      ))}
    </Box>
    <Box fontSize="14px" mt={1.5}>
      {`Addendum added and electronically signed at ${formatSummaryTimestamp(
        addedAt,
        facilityTimezone,
      )} by ${getNamesWithTitle(
        addedByFirstName,
        addedByLastName,
        addedByTitle,
      )}`}
    </Box>
    {renderBlankSpace}
  </FlexBoxColumn>
);

Addenda.propTypes = {
  text: PropTypes.string,
  facilityTimezone: PropTypes.string,
  addedByFirstName: PropTypes.string,
  addedByLastName: PropTypes.string,
  addedByTitle: PropTypes.string,
  addedAt: PropTypes.string,
};

const AddendumList = ({ addenda, facilityTimezone }) => (
  <Box mt={3} mb={2}>
    {addenda.map((item) => (
      <Addenda key={uuid()} {...item} facilityTimezone={facilityTimezone} />
    ))}
  </Box>
);

AddendumList.propTypes = {
  addenda: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  facilityTimezone: PropTypes.string,
};

export default AddendumList;
