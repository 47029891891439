import { confirmSignIn } from 'aws-amplify/auth';

import MFASelectModal from './MFASelectModal';

const handleMFASelect = async ({ nextStep }) => {
  const mfaType = await MFASelectModal({
    allowedMFATypes: nextStep.allowedMFATypes,
  });

  return confirmSignIn({
    challengeResponse: mfaType,
  });
};

export default handleMFASelect;
