import React from 'react';

import { useMountEffect, useProfile } from 'shared/hooks';
import useUserEdit from 'features/admin/hooks/useUserEdit';
import useGoBack from 'navigation/hooks/useGoBack';
import BackButton from 'navigation/components/BackButton/BackButton';
import UserForm from 'features/admin/components/UserForm';
import { mapApi2Form } from 'features/admin/components/UserForm/utils';
import UserActionButton from 'features/admin/components/UserForm/UserActionButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import EditButton from 'shared/components/buttons/EditButton';
import { FORCE_CHANGE_PASSWORD } from 'utils/constants';
import {
  FlexBoxColumn,
  FlexBoxColumnFull,
  FlexBoxAlign,
  FlexBoxSpaceBetween,
} from 'styles/layout';

const UserDetailsPage = () => {
  const { goBack } = useGoBack();
  const { profileData } = useProfile();
  const {
    userId,
    userData,
    isEditMode,
    enableEditMode,
    disableEditMode,
    fetchUser,
    onUserUpdate,
  } = useUserEdit();

  useMountEffect(fetchUser);

  const selfDetails = React.useMemo(
    () => userId === profileData.id,
    [profileData, userId],
  );

  const shouldInvite = React.useMemo(
    () => userData?.userAccountStatus === FORCE_CHANGE_PASSWORD,
    [userData?.userAccountStatus],
  );

  const initValues = React.useMemo(() => mapApi2Form(userData), [userData]);

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxSpaceBetween>
        <FlexBoxAlign>
          {!isEditMode && <BackButton onBack={goBack} />}
          <PageTitle>User Details</PageTitle>
        </FlexBoxAlign>
        <FlexBoxAlign>
          {!selfDetails &&
            !!userData?.enabled &&
            !userData?.isExternalProvider && 
            shouldInvite && (
              <UserActionButton userData={userData} />
            )}
          {!isEditMode && !selfDetails && (
            <EditButton onClick={enableEditMode} />
          )}
        </FlexBoxAlign>
      </FlexBoxSpaceBetween>
      <FlexBoxColumnFull p={2}>
        <UserForm
          initValues={initValues}
          disabled={!isEditMode}
          onSubmit={onUserUpdate}
          onCancel={disableEditMode}
          isExternalProvider={userData?.isExternalProvider}
        />
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default UserDetailsPage;
