import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import { Typography } from '@mui/material';

import { useMountEffect, useInterval } from 'shared/hooks';
import useConsultRequests from 'features/consults/hooks/useConsultRequests';
import Table from 'shared/components/data/Table/Table';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { showFirstLetter } from 'utils/helpers';
import { EMPTY_COLUMN_VALUE } from 'utils/constants';
import { US_DATE_FORMAT, TIME_FORMAT, formatToUserTimezone } from 'utils/dates';
import { mapPhoneNumberToMask } from 'utils/mappers';
import { ServiceTypeLabels } from 'utils/enums';
import { FlexBoxColumn } from 'styles/layout';
import colors from 'styles/colors';

import ConsultRequestDetails from './ConsultRequestDetails';

const tableColumns = [
  {
    header: {
      id: 'patientInitials',
      value: '',
    },
    formatValue: ({ firstName, lastName }) =>
      firstName || lastName
        ? `${showFirstLetter(firstName)}${showFirstLetter(lastName)}`
        : EMPTY_COLUMN_VALUE,
    width: '35px',
    align: 'center',
    empty: true,
  },
  {
    header: {
      id: 'facility',
      value: 'Facility',
    },
    formatValue: ({ facility }) => facility?.name || EMPTY_COLUMN_VALUE,
  },
  {
    header: {
      id: 'firstName',
      value: 'First Name',
    },
    dataKey: 'firstName',
    width: '10%',
  },
  {
    header: {
      id: 'lastName',
      value: 'Last Name',
    },
    dataKey: 'lastName',
    width: '10%',
  },
  {
    header: {
      id: 'type',
      value: 'Type',
    },
    formatValue: ({ consultRequest }) =>
      ServiceTypeLabels[consultRequest.type] || EMPTY_COLUMN_VALUE,
  },
  {
    header: {
      id: 'phone',
      value: 'Phone',
    },
    formatValue: (item) =>
      mapPhoneNumberToMask(item.phoneNumber) || EMPTY_COLUMN_VALUE,
    width: '150px',
  },
  {
    header: {
      id: 'requestStatus',
      value: 'Request Status',
    },
    formatValue: ({ event }) => (event ? startCase(event) : EMPTY_COLUMN_VALUE),
    width: '15%',
  },
  {
    header: {
      id: 'Created At',
      value: 'Created At',
    },
    formatValue: (item) =>
      formatToUserTimezone(
        new Date(item.createdAt),
        `${US_DATE_FORMAT} - ${TIME_FORMAT}`,
      ),
  },
];

export default function ConsultRequestList({ consultType, sm, handleRefresh }) {
  const {
    consultRequests,
    isUpdating,
    onAcceptConsultRequest,
    onDeclineConsultRequest,
    onDismissConsultRequest,
    fetchConsultRequests,
  } = useConsultRequests(consultType, handleRefresh);

  const [showDetails, setShowDetails] = React.useState(null);

  const viewTables = React.useMemo(
    () => (sm ? tableColumns.slice(0, 2) : tableColumns),
    [sm],
  );

  const onClickRow = React.useCallback(
    (clickedId) => {
      setShowDetails(consultRequests.find(({ id }) => id === clickedId));
    },
    [consultRequests],
  );

  useMountEffect(fetchConsultRequests);

  useInterval(() => fetchConsultRequests(true), 30000);

  if (!consultRequests?.length) return null;

  return (
    <FlexBoxColumn mt={1} mb={4}>
      <Typography
        variant='h6'
        sx={{
          color: colors.neutralDark,
          fontSize: '1rem',
        }}
      >
        New Consult Requests
      </Typography>
      <ConsultRequestDetails
        showDetails={showDetails}
        onClose={() => setShowDetails(null)}
        onAccept={onAcceptConsultRequest}
        onDecline={onDeclineConsultRequest}
        onDismiss={onDismissConsultRequest}
        isUpdating={isUpdating}
      />
      <Table
        headings={getTableHeaders(viewTables)}
        data={prepareTableData(consultRequests, viewTables)}
        onClickRow={onClickRow}
      />
    </FlexBoxColumn>
  );
}

ConsultRequestList.propTypes = {
  consultType: PropTypes.string.isRequired,
  sm: PropTypes.bool,
  handleRefresh: PropTypes.func.isRequired,
};
