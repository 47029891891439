import styled from '@emotion/styled';

import colors from 'styles/colors';

export const Label = styled.label`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.375;
  color: ${({ error }) => (error ? colors.error : colors.black)};
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  margin: ${({ inline }) => (inline ? '0 .5rem 0 0' : '0 0 0.2rem 0')};
  ${({ disabled }) => disabled && 'cursor: default'};
`;

export const Required = styled.span`
  color: ${colors.notification};
`;
