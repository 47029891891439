import { mapChipFieldOptions } from 'features/consults/utils/formFields';

import formFields from './formFields';

export default {
  elements: [
    mapChipFieldOptions(formFields.vteProphylaxis),
    formFields.vteProphylaxisFreeText,
    mapChipFieldOptions(formFields.assessedForRehabs),
    formFields.assessedForRehabsFreeText,
    mapChipFieldOptions(formFields.dischargeRecommendations),
    formFields.dischargeRecommendationsFreeText,
    mapChipFieldOptions(formFields.anticoagulationTherapyAfibs),
    formFields.anticoagulationTherapyAfibsFreeText,
    mapChipFieldOptions(formFields.thrombolyticTherapies),
    formFields.thrombolyticTherapiesFreeText,
    mapChipFieldOptions(formFields.antithromboticByHospitals),
    formFields.antithromboticByHospitalsFreeText,
    mapChipFieldOptions(formFields.dischargeStatinRecommendations),
    formFields.dischargeStatinRecommendationsFreeText,
    mapChipFieldOptions(formFields.strokeEducations),
    formFields.strokeEducationsFreeText,
  ],
};
