import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import sortFields from 'api/sortFields';
import { useMountEffect, useUpdateEffect, useTableState } from 'shared/hooks';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { adminActions } from 'features/admin/store/slice';
import {
  ConsultNetworkTypesEnum,
  NetworkLabels,
  ServiceTypeLabels,
} from 'utils/enums';

const facilityTableColumns = [
  {
    header: {
      id: sortFields.facilities.name,
      value: 'Facility Name',
      isSortable: true,
    },
    dataKey: 'name',
  },
  ...Object.entries(ConsultNetworkTypesEnum).map(([service, network]) => ({
    header: {
      id: network,
      value: `${ServiceTypeLabels[service]} Network`,
      isSortable: true,
    },
    dataKey: NetworkLabels[service],
  })),
  {
    header: {
      id: sortFields.facilities.city,
      value: 'City',
      isSortable: true,
    },
    dataKey: 'locality',
  },
  {
    header: {
      id: sortFields.facilities.state,
      value: 'State',
      isSortable: true,
    },
    dataKey: 'region',
    width: '80px',
  },
  {
    header: {
      id: sortFields.facilities.emrName,
      value: 'EMR',
      isSortable: true,
    },
    dataKey: 'emrName',
    align: 'center',
  },
];

const FacilitiesTable = ({ networkId, healthSystemId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: facilities, pageCount } = useSelector(
    ({ admin }) => admin.facilities,
  );
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setQueryParams,
  } = useTableState(sortFields.facilities.name);

  const fetchList = React.useCallback(() => {
    dispatch(
      adminActions.listFacilities({
        page,
        sortBy,
        sortOrder,
        filters: {
          networkId,
          healthSystemIds: healthSystemId,
        },
      }),
    );
    setQueryParams({
      page,
      sortBy,
      sortOrder,
    });
  }, [
    page,
    sortBy,
    sortOrder,
    dispatch,
    networkId,
    healthSystemId,
    setQueryParams,
  ]);

  useMountEffect(fetchList);

  useUpdateEffect(() => {
    fetchList();
  }, [page, sortBy, sortOrder]);

  return (
    <PaginatedTable
      headings={getTableHeaders(facilityTableColumns)}
      data={prepareTableData(facilities, facilityTableColumns)}
      fallbackMsg="No Facilities found"
      sortBy={sortBy}
      sortOrder={sortOrder}
      currentPage={page}
      totalPages={pageCount}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
      onClickRow={(id) => navigate(`facility/${id}`)}
    />
  );
};

FacilitiesTable.propTypes = {
  networkId: PropTypes.string,
  healthSystemId: PropTypes.string,
};

export default FacilitiesTable;
