import { useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

const params2Object = (searchParams) =>
  Array.from(searchParams.entries()).reduce((res, [key, value]) => {
    const resValue = res[key];
    if (!resValue) return { ...res, [key]: value };
    return {
      ...res,
      [key]: Array.isArray(resValue) ? [...resValue, value] : [resValue, value],
    };
  }, {});

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo(
    () => params2Object(searchParams),
    [searchParams],
  );

  const setQueryParams = useCallback(
    (queryObj = {}, replace = true) => {
      const reduced = Object.entries(queryObj).reduce(
        (res, [key, value]) => (value ? { ...res, [key]: value } : res),
        {},
      );
      setSearchParams(reduced, { replace });
    },
    [setSearchParams],
  );

  const queryString = useMemo(
    () => `?${searchParams.toString()}`,
    [searchParams],
  );

  return {
    queryString,
    queryParams,
    setQueryParams,
  };
};

export default useQueryParams;
