import React from 'react';
import PropTypes from 'prop-types';

import OutboxIcon from '@mui/icons-material/Outbox';

import colors from 'styles/colors';

const SentIcon = ({ isOn }) => (
  <OutboxIcon sx={{ color: isOn ? colors.success : colors.neutralDark }} />
);

SentIcon.propTypes = { isOn: PropTypes.bool };

export default SentIcon;
