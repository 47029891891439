import React from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { useMountEffect, useRequest } from 'shared/hooks';
import { MAX_PAGE_SIZE } from 'utils/constants';
import Autocomplete from 'shared/components/forms/Autocomplete';

const NetworkSelect = ({ placeholder = '-- Network --', ...moreProps }) => {
  const { doRequest: fetchNetworks, data: networkData } = useRequest(
    api.getNetworkNames,
  );

  useMountEffect(() => {
    fetchNetworks({ pageSize: MAX_PAGE_SIZE });
  }, [fetchNetworks]);

  const options = React.useMemo(
    () => networkData?.networks || [],
    [networkData],
  );

  return (
    <Autocomplete {...moreProps} placeholder={placeholder} options={options} />
  );
};

NetworkSelect.propTypes = {
  placeholder: PropTypes.string,
};

export default NetworkSelect;
