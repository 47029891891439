import React from 'react';
import styled from '@emotion/styled';

import { Tab } from '@mui/material';
import { TabList, TabPanel } from '@mui/lab';
import LockIcon from '@mui/icons-material/Lock';

import colors from 'styles/colors';

export const TabsContainer = styled((props) => (
  <TabList
    classes={{ flexContainer: 'container', indicator: 'indicator' }}
    {...props}
  />
))`
  .container {
    flex-wrap: wrap;
  }

  .indicator {
    background-color: ${colors.transparent};
  }
`;

export const TabHeader = styled(Tab)`
  min-width: 80px;
  border-bottom: 3px solid
    ${({ _selected }) =>
      _selected === 'selected' ? colors.secondary : colors.transparent};

  ${({ _error }) => _error === 'error' && `border: 3px solid ${colors.error}`}
`;

export const TabContent = styled(TabPanel)`
  padding: 20px 10px;
`;

export const Locked = styled(LockIcon)`
  margin: 10px 0;
  color: ${colors.neutralDark};
`;
