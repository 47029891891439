import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import intersection from 'lodash/intersection';

import { Grid, InputAdornment } from '@mui/material';

import { Box } from 'styles/layout';
import HealthSystemSelect from 'shared/components/data/forms/HealthSystemSelect';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import InputField from 'shared/components/forms/InputField/InputField';
import Select from 'shared/components/forms/Select';
import Switch from 'shared/components/forms/Switch/Switch';
import RadioGroup from 'shared/components/forms/RadioGroup';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import Autocomplete from 'shared/components/forms/Autocomplete';
import ImageInput from 'shared/components/forms/ImageInput';
import { adminActions } from 'features/admin/store/slice';
import { useFormState } from 'shared/hooks';

import listOfStates from 'utils/states';
import { MAX_PAGE_SIZE } from 'utils/constants';
import {
  UserRoleEnum,
  userRolesLabels,
  userTitleLabels,
  doctorRoles,
} from 'utils/enums';

import {
  schema,
  mapForm2Api,
  enabledOptions,
  isPhoneRequired,
  FormFields,
} from './utils';

const UserForm = ({
  onSubmit,
  onCancel,
  initValues = null,
  disabled = false,
  isExternalProvider = false,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    control,
    handleSubmit,
    getFieldError,
    getValues,
    setFormValues,
    canSubmitForm,
    watch,
    setValue,
  } = useFormState(schema, { stateSlice: 'admin', showAsSnackbar: true });

  const currentHealthSystems = watch(FormFields.healthSystem);
  const autologout = watch(FormFields.autologout);
  const currentRoles = watch(FormFields.roles);
  const showFullNames = watch('showFullPatientNames');

  const {
    facilities: { data: facilities },
  } = useSelector(({ admin }) => admin);

  useEffect(() => {
    if (!currentHealthSystems?.length) return;
    dispatch(
      adminActions.listFacilities({
        filters: { healthSystemIds: currentHealthSystems },
        pageSize: MAX_PAGE_SIZE,
      }),
    );
  }, [dispatch, currentHealthSystems]);

  const isDoctor = useMemo(
    () => !!intersection(currentRoles, doctorRoles).length,
    [currentRoles],
  );

  useEffect(() => {
    if (!initValues) return;
    setFormValues(initValues);
  }, [initValues, setFormValues]);

  const facilityOptions = useMemo(
    () =>
      currentHealthSystems?.length
        ? facilities.map(({ id, name, ...rest }) => ({
            ...rest,
            text: name,
            value: id,
          }))
        : [],
    [currentHealthSystems, facilities],
  );

  const handleHealthSystemChange = (currentSystems) => {
    if (currentSystems?.length < currentHealthSystems?.length) {
      const currentFacilities = getValues('facilities');
      const filteredFacilities = currentFacilities.filter((fId) => {
        const fac = facilities.find(({ id }) => id === fId);
        return fac && currentSystems.includes(fac.healthSystemId);
      });
      setValue('facilities', filteredFacilities);
    }
  };

  const onFormSubmit = React.useCallback(
    (submitData) => {
      onSubmit(mapForm2Api(submitData));
    },
    [onSubmit],
  );

  return (
    <FormWrapper
      onSubmit={handleSubmit(onFormSubmit)}
      onCancel={() => {
        setFormValues();
        onCancel();
      }}
      formDisabled={disabled}
      isSubmitDisabled={!canSubmitForm}
    >
      {initValues?.id && (
        <Box mb={3}>
          <ImageInput
            control={control}
            name={FormFields.userImage}
            disabled={disabled}
            error={getFieldError(FormFields.userImage)}
          />
        </Box>
      )}
      <Box mb={3}>
        <RadioGroup
          control={control}
          name={FormFields.enabled}
          required
          label="ACTIVE/INACTIVE"
          options={[
            { label: 'Active', value: '1' },
            { label: 'Inactive', value: '0' },
          ]}
          error={getFieldError(FormFields.enabled)}
          disabled={disabled}
        />
      </Box>
      <Grid container spacing={10}>
        <Grid item xs={12} lg={7}>
          <Grid item xs={12} md={12}>
            <Box mb={3}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} lg={4}>
                  <InputField
                    {...register('firstName', {
                      required: true,
                      disabled,
                    })}
                    label="First Name"
                    required
                    error={getFieldError('firstName')}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <InputField
                    {...register('lastName', {
                      required: true,
                      disabled,
                    })}
                    label="Last Name"
                    required
                    error={getFieldError('lastName')}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Select
                    control={control}
                    name="title"
                    disabled={disabled}
                    label="Title"
                    options={Object.entries(userTitleLabels).map(
                      ([value, text]) => ({ value, text }),
                    )}
                    error={getFieldError('title')}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box mb={3}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <InputField
                    {...register('email', {
                      required: true,
                      disabled,
                    })}
                    label="Email"
                    required
                    readOnly={
                      !!initValues?.email &&
                      (!initValues?.isEmailMutable || isExternalProvider)
                    }
                    error={getFieldError('email')}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PhoneNumberInput
                    control={control}
                    name="phoneNumber"
                    label="Phone Number"
                    required={isPhoneRequired(currentRoles)}
                    disabled={disabled}
                    error={getFieldError('phoneNumber')}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box mb={3}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <InputField
                    {...register('username', {
                      required: true,
                      disabled: disabled || !!initValues?.username,
                    })}
                    label="Username"
                    required
                    error={getFieldError('username')}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  {isDoctor && (
                    <InputField
                      {...register('shiftAdminUserId', {
                        disabled,
                      })}
                      label="ShiftAdmin Id"
                      type="number"
                      disabled={disabled}
                      error={getFieldError('shiftAdminUserId')}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box mb={3}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Switch
                    control={control}
                    name="autologout"
                    label="Enable Auto Logout"
                    disabled={disabled}
                    error={getFieldError('autologout')}
                    onChange={(enabled) => {
                      setValue(
                        'inactivityLogOutThreshold',
                        (enabled && initValues.inactivityLogOutThreshold) || null,
                      );
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={{ display: autologout ? 'flex' : 'none' }}
                >
                  <InputField
                    {...register('inactivityLogOutThreshold', {
                      required: autologout,
                      disabled,
                    })}
                    label="Logout After"
                    required={autologout}
                    type="number"
                    error={getFieldError('inactivityLogOutThreshold')}
                    endAdornment={
                      <InputAdornment position="end">min</InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {isDoctor && (
            <Grid item xs={12} md={12}>
              <Box mb={3}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Autocomplete
                      control={control}
                      name="primaryLocation"
                      label="Primary Location"
                      options={listOfStates}
                      disabled={disabled}
                      error={getFieldError('primaryLocation')}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputField
                      {...register('npi', {
                        required: true,
                        disabled,
                      })}
                      label="NPI"
                      required
                      error={getFieldError('npi')}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} lg={4}>
          <Box mb={3}>
            <Select
              name={FormFields.roles}
              label="Role(s)"
              control={control}
              options={Object.entries(userRolesLabels).map(([value, text]) => ({
                value,
                text,
              }))}
              multiple
              required
              disabled={disabled}
              error={getFieldError(FormFields.roles)}
            />
          </Box>
          {currentRoles?.includes(UserRoleEnum.CUSTOMER) && (
            <Box>
              <Box mb={3}>
                <HealthSystemSelect
                  name={FormFields.healthSystem}
                  control={control}
                  placeholder="Client Health System"
                  label="Client Health System(s)"
                  error={getFieldError(FormFields.healthSystem)}
                  required
                  multiple
                  disabled={disabled}
                  handleChange={handleHealthSystemChange}
                />
              </Box>
              <Box mb={3}>
                {/* TODO: change this to FacilitySelect */}
                <Select
                  name="facilities"
                  label="Client Facility(s)"
                  control={control}
                  options={facilityOptions}
                  required
                  hasSelectAll
                  groupBy="healthSystemName"
                  multiple
                  disabled={disabled || !facilityOptions?.length}
                  error={getFieldError('facilities')}
                />
              </Box>
              <Box mb={3}>
                <RadioGroup
                  control={control}
                  name="showAllConsultRequests"
                  required
                  label="View All Consult Requests"
                  options={enabledOptions}
                  error={getFieldError('showAllConsultRequests')}
                  disabled={disabled}
                />
              </Box>
              <Box mb={3}>
                <RadioGroup
                  control={control}
                  name="viewClosedConsultRequests"
                  required
                  label="View Closed Consult Requests"
                  options={enabledOptions}
                  error={getFieldError('viewClosedConsultRequests')}
                  disabled={disabled}
                />
              </Box>
              <Box mb={3}>
                <RadioGroup
                  control={control}
                  name="viewAndEditSettings"
                  required
                  label="View and Edit Settings"
                  options={enabledOptions}
                  error={getFieldError('viewAndEditSettings')}
                  disabled={disabled}
                />
              </Box>
              <Box mb={3}>
                <RadioGroup
                  control={control}
                  name="showFullPatientNames"
                  required
                  label="Show Full Patient Names"
                  options={enabledOptions}
                  error={getFieldError('showFullPatientNames')}
                  disabled={disabled}
                />
              </Box>
              {showFullNames === '0' && (
                <Box mb={3}>
                  <InputField
                    {...register('numberOfCharactersToShowPatientNames', {
                      required: true,
                      disabled,
                    })}
                    type="number"
                    label="Show Characters of Patient Names"
                    required
                    error={getFieldError(
                      'numberOfCharactersToShowPatientNames',
                    )}
                  />
                </Box>
              )}
            </Box>
          )}
          {currentRoles?.includes(UserRoleEnum.ADMINISTRATOR) && (
            <Box mb={3}>
              <RadioGroup
                control={control}
                name="viewAdminConsultTable"
                required
                label="View Admin Consult Table"
                options={enabledOptions}
                error={getFieldError('viewAdminConsultTable')}
                disabled={disabled}
              />
            </Box>
          )}
          {doctorRoles.some(role => currentRoles?.includes(role)) && (
            <Box mb={3}>
              <RadioGroup
                control={control}
                name="createSharedMacros"
                label="Create Shared Macros"
                options={enabledOptions}
                error={getFieldError('createSharedMacros')}
                disabled={disabled}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </FormWrapper>
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initValues: PropTypes.shape({}),
  disabled: PropTypes.bool,
  isExternalProvider: PropTypes.bool,
};

export default UserForm;
