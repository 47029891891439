import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import { Typography } from '@mui/material';

import { Box, FlexBoxAlign } from 'styles/layout';
import colors from 'styles/colors';

const BillingInfoOrder = ({ data }) => {
  const billingDetails = React.useMemo(
    () => data && Object.entries(data),
    [data],
  );

  return (
    <Box
      sx={{
        backgroundColor: colors.neutral,
        padding: 2,
        overflow: 'auto',
        height: '100%',
      }}
    >
      {billingDetails?.length ? (
        billingDetails.map(([key, value]) => (
          <FlexBoxAlign key={key} sx={{ marginBottom: '10px' }}>
            <Typography variant="h6" mr={1}>
              {`${startCase(key.toLowerCase())}:`}
            </Typography>
            <Typography>{value}</Typography>
          </FlexBoxAlign>
        ))
      ) : (
        <Typography variant="h6">No data available</Typography>
      )}
    </Box>
  );
};

BillingInfoOrder.propTypes = {
  data: PropTypes.shape({}),
};

export default BillingInfoOrder;
