import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { Grid } from '@mui/material';

import { useFormState } from 'shared/hooks';
import Button from 'shared/components/buttons/Button/Button';
import InputField from 'shared/components/forms/InputField/InputField';
import FacilitySelect from 'shared/components/data/forms/FacilitySelect';
import HealthSystemSelect from 'shared/components/data/forms/HealthSystemSelect';
import ProviderSelect from 'shared/components/data/forms/ProviderSelect';
import { requiredString } from 'utils/constants';

const schema = yup.object().shape({
  providerId: yup.string().nullable().required(requiredString),
  healthSystemId: yup.string().nullable().required(requiredString),
});

const ProviderForm = ({ defaultValues, onSubmit, onCancel, isEditing }) => {
  const { getFieldError, control, register, setValue, handleSubmit, watch } =
    useFormState(
      schema,
      { stateSlice: 'admin', showAsSnackbar: true },
      defaultValues,
    );

  const healthSystem = watch('healthSystemId');

  const handleFormSubmit = ({ providerId, healthSystemId, ...moreValues }) => {
    onSubmit({
      ...moreValues,
      providerId,
      healthSystemId
    });
  };

  const facilityKey = React.useMemo(
    () => (isEditing ? 'facilityId' : 'facilityIds'),
    [isEditing],
  );

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ProviderSelect
            name="providerId"
            control={control}
            label="Provider"
            error={getFieldError('providerId')}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HealthSystemSelect
            control={control}
            name="healthSystemId"
            label="Healthcare System"
            error={getFieldError('healthSystemId')}
            handleChange={() => {
              setValue(facilityKey, isEditing ? null : []);
            }}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FacilitySelect
            control={control}
            name={facilityKey}
            requiredSystem
            healthSystem={healthSystem}
            label={
              <span>
                <span>Set Per Facility</span>
                &nbsp;&nbsp;
                <span style={{ color: 'gray' }}>(Uncommon)</span>
              </span>
            }
            error={getFieldError(facilityKey)}
            multiple={!isEditing}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            {...register('hospitalDocId', {
              required: true,
            })}
            required
            label="Mnemonic (ID for location)"
            placeholder="Mnemonic"
            error={getFieldError('hospitalDocId')}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button color="secondary" variant="contained" type="submit">
            Save
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            style={{ marginLeft: '1rem' }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ProviderForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
};

export default ProviderForm;
