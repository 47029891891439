import { mapChipFieldOptions } from 'features/consults/utils/formFields';
import { clinicAssessmentFields as fields } from 'features/consults/shared/tabs/Assessment/formFields';

export default {
  elements: [
    mapChipFieldOptions(fields.impressionPredefined),
    fields.impressionFreeText,
    mapChipFieldOptions(fields.differentialDiagnosisPredefined),
    fields.differentialDiagnosisFreeText,
    mapChipFieldOptions(fields.medicalDecisionMakingHighLevelPredefined),
    mapChipFieldOptions(fields.medicalDecisionMakingModerateLevelPredefined),
  ],
};
