import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';

import Grid from '@mui/material/Grid';

import { appStateActions } from 'store/appStateSlice';
import { profileActions } from 'features/profile/store/slice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import {
  useProfile,
  useFormState,
  useEditMode,
  usePerformActionOnSuccess,
} from 'shared/hooks';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import EditButton from 'shared/components/buttons/EditButton';
import InputField from 'shared/components/forms/InputField/InputField';
import Select from 'shared/components/forms/Select/ControlledSelect';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import { userTitleLabels } from 'utils/enums';
import { FlexBoxColumnFull, FlexBoxSpaceBetweenCenter } from 'styles/layout';

import { FormFields, profileFormSchema } from './helpers';

const userTitleOptions = Object.entries(userTitleLabels).map(
  ([value, text]) => ({ value, text }),
);

const FormGeneralInfo = ({ title }) => {
  const dispatch = useDispatch();
  const { profileData } = useProfile();
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const {
    register,
    control,
    handleSubmit,
    getFieldError,
    setFormValues,
    canSubmitForm,
  } = useFormState(profileFormSchema, {
    stateSlice: 'profile',
    showAsSnackbar: true,
  });

  usePerformActionOnSuccess(SuccessActionTypesEnum.PROFILE_UPDATED, () => {
    dispatch(appStateActions.hideSnackbar());
    disableEditMode();
  });

  const initValues = React.useMemo(
    () => pick(profileData, Object.values(FormFields)),
    [profileData],
  );

  React.useEffect(() => {
    setFormValues(initValues);
  }, [initValues, setFormValues]);

  const onSubmit = (values) => {
    dispatch(profileActions.updateProfile(values));
  };

  return (
    <FlexBoxColumnFull>
      <FlexBoxSpaceBetweenCenter>
        <h3>{title}</h3>
        {!isEditMode && <EditButton onClick={enableEditMode} />}
      </FlexBoxSpaceBetweenCenter>
      <FormWrapper
        isSubmitDisabled={!canSubmitForm}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => {
          setFormValues();
          disableEditMode();
          dispatch(appStateActions.hideSnackbar());
        }}
        formDisabled={!isEditMode}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <InputField
              {...register(FormFields.firstName, {
                required: true,
                disabled: !isEditMode,
              })}
              required
              label="First Name"
              error={getFieldError(FormFields.firstName)}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <InputField
              {...register(FormFields.lastName, {
                required: true,
                disabled: !isEditMode,
              })}
              required
              label="Last Name"
              error={getFieldError(FormFields.lastName)}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Select
              control={control}
              name={FormFields.title}
              disabled={!isEditMode}
              label="Title"
              options={userTitleOptions}
              error={getFieldError(FormFields.title)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              {...register(FormFields.email, {
                required: true,
                disabled: !isEditMode,
              })}
              label="Email"
              error={getFieldError(FormFields.email)}
              readOnly={!profileData.isEmailMutable}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneNumberInput
              control={control}
              name={FormFields.phone}
              label="Phone Number"
              required
              disabled={!isEditMode}
              error={getFieldError(FormFields.phone)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name="username"
              label="Username"
              disabled
              defaultValue={profileData.username}
            />
          </Grid>
        </Grid>
      </FormWrapper>
    </FlexBoxColumnFull>
  );
};

FormGeneralInfo.propTypes = {
  title: PropTypes.string,
};

export default FormGeneralInfo;
