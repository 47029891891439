import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import MultiChipList from 'shared/components/forms/MultiChip/MultiChipList';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';

const MultiChipSelect = ({
  control,
  name,
  label = null,
  disabled = false,
  options,
  onChange,
}) => {
  const handleChange = useMemo(
    () =>
      debounce(() => {
        onChange(name);
      }, 500),
    [name, onChange],
  );

  return (
    <>
      {!!label && <InputLabel>{label}</InputLabel>}
      <MultiChipList
        control={control}
        name={name}
        options={options}
        onChange={handleChange}
        disabled={disabled}
      />
    </>
  );
};

MultiChipSelect.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiChipSelect;
