import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields, { motorGroupFields, sensoryGroupFields } from './formFields';

export const topPanel = {
  elements: [mapChipFieldOptions(fields.procedurePredefined)],
};

export const motorPanels = [...Array(6)].map((_, index) => {
  const motorFields = motorGroupFields(index + 1);
  const [nerve, laterality, normal, amplitude, latency, velocity, wave, text] =
    Object.keys(motorFields);

  return {
    elements: [
      mapToggleFieldOptions(motorFields[nerve]),
      mapToggleFieldOptions(motorFields[laterality]),
      mapChipFieldOptions(motorFields[normal]),
      mapToggleFieldOptions(motorFields[amplitude]),
      mapToggleFieldOptions(motorFields[latency]),
      mapToggleFieldOptions(motorFields[velocity]),
      mapToggleFieldOptions(motorFields[wave]),
      motorFields[text],
    ],
  };
});

export const sensoryPanels = [...Array(6)].map((_, index) => {
  const sensoryFields = sensoryGroupFields(index + 1);
  const [location, laterality, normal, velocity, latency, text] =
    Object.keys(sensoryFields);

  return {
    elements: [
      mapToggleFieldOptions(sensoryFields[location]),
      mapToggleFieldOptions(sensoryFields[laterality]),
      mapChipFieldOptions(sensoryFields[normal]),
      mapToggleFieldOptions(sensoryFields[velocity]),
      mapToggleFieldOptions(sensoryFields[latency]),
      sensoryFields[text],
    ],
  };
});

export const bottomPanel = {
  elements: [
    mapToggleFieldOptions(fields.needleMyotomesPredefined),
    fields.needleMyotomesFreeText,
    fields.findingsSubheadFreeText,
    mapToggleFieldOptions(fields.findingsSubheadPredefined),
    mapToggleFieldOptions(fields.impressionLaterality),
    mapChipFieldOptions(fields.impressionPredefined),
    fields.impressionFreeText,
    mapChipFieldOptions(fields.impressionClinicalCorrelationPredefined),
    mapChipFieldOptions(fields.noEvidencePredefined),
    fields.noEvidenceFreeText,
  ],
};
