import React from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { useMountEffect, useUpdateEffect } from 'shared/hooks';

import ConsultTypeNote from './ConsultTypeNote';

const FacilityNote = ({ facilityId, consultType }) => {
  const [note, setNote] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);

  const fetchNote = React.useCallback(() => {
    if (!facilityId) {
      setNote(null);
      return;
    }
    setLoading(true);
    api
      .getFacilityNote({
        facilityId,
        type: consultType,
      })
      .then(({ data }) => setNote(data.facilityNote))
      .catch(() => setNote(null))
      .finally(() => setLoading(false));
  }, [facilityId, consultType]);

  useUpdateEffect(() => {
    fetchNote();
  }, [fetchNote]);

  useMountEffect(fetchNote);

  const onUpdateNote = React.useCallback(
    (text) => {
      if (text) {
        if (note) {
          api
            .updateFacilityNote({ facilityId, type: consultType, text })
            .then(({ data }) => {
              setNote(data);
            });
          return;
        }
        api
          .createFacilityNote({
            facilityId,
            type: consultType,
            text,
          })
          .then(({ data }) => {
            setNote({
              id: data,
              text,
            });
          });
        return;
      }
      if (note) {
        api
          .deleteFacilityNote({ facilityId, type: consultType })
          .then(() => setNote(null));
      }
    },
    [note, facilityId, consultType],
  );

  return (
    <ConsultTypeNote
      isDisabled={!facilityId || isLoading}
      initValue={note?.text ?? ''}
      onUpdate={onUpdateNote}
    />
  );
};

FacilityNote.propTypes = {
  consultType: PropTypes.string.isRequired,
  facilityId: PropTypes.number,
};

export default FacilityNote;
