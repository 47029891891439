import React from 'react';
import PropTypes from 'prop-types';

import CalendarDayView from 'features/schedule/components/CalendarDayView/CalendarDayView';
import CalendarList from 'features/schedule/components/CalendarList/CalendarList';
import ScheduleFilters from 'features/schedule/components/ScheduleFilters/ScheduleFilters';
import ScheduleViewToggle from 'features/schedule/components/ScheduleViewToggle/ScheduleViewToggle';
import useCalendarData from 'features/schedule/hooks/useCalendarData';
import { FlexBoxColumn, FlexBoxSpaceBetween } from 'styles/layout';

const SchedulePage = ({ sm = false }) => {
  const {
    calendarDays,
    hasShifts,
    filters,
    handleFilter,
    handleExport,
  } = useCalendarData();

  if (sm) {
    return (
      <FlexBoxColumn height="100%">
        <ScheduleViewToggle
          defaultValue={filters.scheduleView}
          handleChange={(value) =>
            handleFilter({ scheduleView: value, userId: null })
          }
          isMobile
        />
        <CalendarDayView days={calendarDays} />
      </FlexBoxColumn>
    );
  }

  return (
    <>
      <FlexBoxSpaceBetween mt={1}>
        <ScheduleFilters
          filters={filters}
          disableExport={!hasShifts}
          onFilterChange={handleFilter}
          onExport={handleExport}
        />
      </FlexBoxSpaceBetween>
      <FlexBoxColumn>
        <CalendarList days={calendarDays} />
      </FlexBoxColumn>
    </>
  );
};

SchedulePage.propTypes = {
  sm: PropTypes.bool,
};

export default SchedulePage;
