import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { Box, FlexBoxColumn } from 'styles/layout';

import { ButtonGroup, ToggleButton } from './ToggleButtonGroup.style';

const ToggleButtonGroup = ({
  buttons,
  control,
  name,
  title = '',
  defaultValue = '',
  required = false,
  exclusive = true,
  compact = false,
  handleChange,
  disabled = false,
  error = null,
  inlineLabel = false,
}) => (
  <FlexBoxColumn>
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Box display={inlineLabel ? 'flex' : 'block'}>
          {!!title && (
            <Box mr={inlineLabel ? 2 : 0}>
              <InputLabel required={required} error={error}>
                {title}
              </InputLabel>
            </Box>
          )}
          <ButtonGroup
            ref={field.ref}
            exclusive={exclusive}
            onChange={(_e, value) => {
              if (value !== field.value) {
                field.onChange(value);
                if (handleChange) handleChange(value);
              }
            }}
          >
            {buttons.map(({ value, content }) => (
              <ToggleButton
                key={value}
                value={value}
                selected={value === field.value}
                size="small"
                disabled={disabled}
                _compact={compact ? 'compact' : ''}
              >
                {content}
              </ToggleButton>
            ))}
          </ButtonGroup>
          {error && <ErrorMsg text={error.message} dense />}
        </Box>
      )}
    />
  </FlexBoxColumn>
);

ToggleButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  exclusive: PropTypes.bool,
  compact: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  handleChange: PropTypes.func,
  error: PropTypes.shape({}),
};

export default ToggleButtonGroup;
