import * as yup from 'yup';

import { requiredString } from 'utils/constants';
import { teleNeuroDemographicsFields as fields } from 'features/consults/shared/tabs/Demographics/formFields';

export default yup.object().shape({
  [fields.firstName.name]: yup.string().trim().required(requiredString),
  [fields.lastName.name]: yup.string().trim().required(requiredString),
  [fields.dateOfBirth.name]: yup.string().nullable().required(requiredString),
  [fields.age.name]: yup
    .string()
    .trim()
    .matches(/^[1-9]\d*$/, 'Invalid age'),
  [fields.consultType.name]: yup
    .string()
    .nullable()
    .trim()
    .required(requiredString),
  [fields.neuroPatientLocation.name]: yup.string().nullable().trim(),
  [fields.facility.name]: yup.number().nullable().required(requiredString),
  [fields.dateOfAdmission.name]: yup
    .string()
    .nullable()
    .required(requiredString),
  [fields.dateOfInitialConsultation.name]: yup
    .string()
    .nullable()
    .required(requiredString),
  [fields.dateOfService.name]: yup.string().nullable().required(requiredString),
});
