import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Snackbar as MaterialSnackbar, Box } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { appStateActions } from 'store/appStateSlice';

const Snackbar = () => {
  const dispatch = useDispatch();
  const { closable, duration, requireManualClose, text, type, visible } =
    useSelector(({ appState }) => appState.snackbar);

  const handleClose = React.useCallback(() => {
    dispatch(appStateActions.hideSnackbar());
  }, [dispatch]);

  return (
    <MaterialSnackbar
      open={visible}
      autoHideDuration={duration}
      onClose={handleClose}
      ClickAwayListenerProps={{
        // Prevent the snackbar from being closed by clicking outside of it
        mouseEvent: requireManualClose ? false : 'onClick',
        touchEvent: requireManualClose ? false : 'onTouchEnd',
      }}
    >
      <Alert severity={type} onClose={closable ? handleClose : null}>
        {Array.isArray(text)
          ? text.map((msg) => <Box key={uuid()}>{msg}</Box>)
          : text}
      </Alert>
    </MaterialSnackbar>
  );
};

export default Snackbar;
