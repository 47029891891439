import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import { Box, FlexBox, FlexBoxColumnFull } from 'styles/layout';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: ${({ buttonsPosition }) =>
    buttonsPosition === 'right' && '10px'};
  position: ${({ buttonsPosition }) =>
    buttonsPosition === 'left' && 'relative'};
`;

const ButtonsWrapper = styled(FlexBox)`
  align-self: flex-end;

  ${({ position }) =>
    position === 'left' &&
    `
      align-self: flex-start;
      position: absolute;
      bottom: -35px;
  `}
`;

const FormButton = styled(ActionButton)`
  width: 120px;
`;

const FormWrapper = ({
  children,
  onSubmit,
  onCancel,
  isSubmitDisabled = false,
  formDisabled = false,
  buttonsPosition = 'right',
}) => (
  <Form onSubmit={onSubmit} buttonsPosition={buttonsPosition}>
    <FlexBoxColumnFull>{children}</FlexBoxColumnFull>
    {!formDisabled && (
      <ButtonsWrapper mt={3} position={buttonsPosition}>
        <Box mr={3.5}>
          <FormButton color="secondary" disabled={isSubmitDisabled}>
            Save
          </FormButton>
        </Box>
        <FormButton
          type="button"
          variant="outlined"
          color="secondary"
          onClick={onCancel}
        >
          Cancel
        </FormButton>
      </ButtonsWrapper>
    )}
  </Form>
);

FormWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  formDisabled: PropTypes.bool,
  buttonsPosition: PropTypes.string,
};

export default FormWrapper;
