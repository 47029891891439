import React from 'react';
import PropTypes from 'prop-types';

import MuiIcon from '@mui/icons-material/AttachMoney';

import colors from 'styles/colors';

const BillingIcon = ({ isOn }) => (
  <MuiIcon sx={{ color: isOn ? colors.success : colors.neutralDark }} />
);

BillingIcon.propTypes = {
  isOn: PropTypes.bool,
};

export default BillingIcon;
